/** @format */

import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../reducers/app';

import Sound from 'react-sound';

import goal from '../media/sounds/goal.mp3';
import start from '../media/sounds/start.mp3';
import redcard from '../media/sounds/redcard.mp3';
import money from '../media/sounds/money.mp3';
import failed from '../media/sounds/failed.mp3';
import success from '../media/sounds/success.mp3';

/**
 * This method renders the component via React
 */

let stopSound = (props) => (sound) => {
  return () => {
    props.actions.stopSound({ sound });
  };
};

const SoundManager = (props) => {
  return props.contentLayout === 'narrow' ? null : (
    <div className="sounds" styles={{ display: 'none' }}>
      <Sound
        url={redcard}
        playStatus={props.redcard ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('redcard')}
      />
      <Sound
        url={goal}
        playStatus={props.goal ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('goal')}
      />
      <Sound
        url={start}
        playStatus={props.start ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('start')}
      />
      <Sound
        url={money}
        playStatus={props.money ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('money')}
      />
      <Sound
        url={failed}
        playStatus={props.failed ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('failed')}
      />
      <Sound
        url={success}
        playStatus={props.success ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={stopSound(props)('success')}
      />
    </div>
  );
};

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => ({
  redcard: state.getIn(['trade', 'sounds', 'redcard'], false),
  goal: state.getIn(['trade', 'sounds', 'goal'], false),
  start: state.getIn(['trade', 'sounds', 'start'], false),
  money: state.getIn(['betbar', 'sounds', 'money'], false),
  failed: state.getIn(['betbar', 'sounds', 'failed'], false),
  success: state.getIn(['betbar', 'sounds', 'success'], false),
  contentLayout: state.getIn(['ui', 'uiSpecs', 'contentLayout'], 'normal'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SoundManager);
