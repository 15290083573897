/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import {
  toCamelCase,
  toCamelCaseAll,
  toSnakeCaseAll,
} from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  formOpen: false,
  customer: '',
  error: '',
  errorData: null,
  status: '',
  isLoading: false,

  editedAccas: {},
  isLoadingAccasToEdit: false,
});

const functions = {
  //open the customer edit-creation modal
  openEditCustomerAccas: (state, action) => {
    state = state.asMutable();
    state = state.set('formOpen', true);
    state = state.set('customer', action.data.customer);
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    state = state.set('isLoadingAccasToEdit', false);
    state = state.remove('editedAccas');
    return state.asImmutable();
  },

  //close edit-creation modal
  closeEditCustomerAccas: (state, _action) => {
    state = state.asMutable();
    state = state.set('formOpen', false);
    state = state.set('customer', '');
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    state = state.set('isLoadingAccasToEdit', false);
    state = state.remove('editedAccas');
    return state.asImmutable();
  },

  //request existing accas data
  loadExistingAccas: (state, action) => {
    let customer = state.get('customer', '');

    if (customer) {
      DSM.last(
        `/web/configs/accas/${customer}/`,
        {
          method: 'GET',
          caseConvert: false,
          message: 'loadExistingAccasResponse',
        },
        action.data.actions,
        'loadExistingAccas'
      );
      state = state.set('isLoadingAccasToEdit', true);
    }

    return state;
  },

  //respond to accas data
  loadExistingAccasResponse: (state, action) => {
    state = state.set('isLoadingAccasToEdit', false);

    if (action.data.status !== 'ok') {
      state = state.set('status', action.data.status);
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
      return state;
    } else {
      state = state.set('isLoadingAccasToEdit', false);
      let data = action.data.data;

      return state.set('editedAccas', fromJS(data));
    }
  },

  editAccas: (state, action) => {
    let customer = state.get('customer', '');
    state = state.asMutable();

    const data = action.data.data;

    const body = {
      max_single: data.maxSingle,
      max_daily: data.maxDaily,
    }

    if (customer) {
      if (!data.maxSingle && !data.maxDaily) {
        DSM.create(
          `/web/configs/accas/${customer}/`,
          {
            method: 'DELETE',
            caseConvert: false,
            message: 'editAccasResponse',
          },
          action.data.actions
        );
      } else {
        DSM.create(
          `/web/configs/accas/${customer}/`,
          {
            method: 'PATCH',
            body,
            caseConvert: false,
            message: 'editAccasResponse',
          },
          action.data.actions
        );
      }

      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
      state = state.set('isLoadingAccasToEdit', false);
    }

    return state.asImmutable();
  },

  //response to customer edit or creation
  editAccasResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
