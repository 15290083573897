/** @format */

import React, { useEffect, useState } from 'react';
import {
  FeedbackDialogPropsType,
  FeedbackOptionType,
  FeedbackTextAreaHelpPropsType,
  MapDispatchToPropsType,
  MapStateToPropsType,
  SendButtonElementPropsType,
  SendButtonPropsType,
  SendingStatusEnum,
  SentAnimationElementPropsType,
  SentAnimationPropsType,
} from './types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions } from '../../reducers/app';
import { Button, Dialog, DialogContent, DialogTitle, Icon } from '../interface';
import styled, { css, keyframes, withTheme } from 'styled-components';
import { Select, TextField } from '@mollybet/ui';
import { ValueType } from 'react-select';
import { mdiCheck } from '@mdi/js';
import ComponentErrorBoundary from '../error-boundary/ComponentErrorBoundary';

const FeedbackDialogElement = styled(Dialog)``;

const FeedbackDialogTitleElement = styled(DialogTitle)`
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.black};
`;

const FeedbackDialogContentElement = styled(DialogContent)`
  position: relative;
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.black}
  width: 100%;
  max-width: 400px;
  min-height: 220px;
`;

const FeedbackTypeSelectElement = styled(Select)`
  margin-bottom: 1rem;
`;

const FeedbackTextAreaElement = styled(TextField)`
  width: 100%;
`;

const FeedbackTextAreaHelpElement = styled.div`
  text-align: right;
`;

const FeedbackTextAreaHelpRemainingElement = styled.span<{ remaining: number }>`
  font-weight: bold;
  color: ${({ remaining, theme }) => {
    if (remaining < 20) return theme.colours.error;
    if (remaining < 50) return theme.colours.warning;
    return 'inherit';
  }};

  ${Button} {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const FeedbackButtonLineElement = styled.div`
  width: 100%;
  margin-top: 1rem;
  text-align: right;
`;

const ClearButtonElement = styled(Button)`
  margin-right: 0.5rem;
`;

const SendButtonElement = styled(Button)<SendButtonElementPropsType>`
  color: ${({ theme }) => theme.colours.white};

  ${({ sendingStatus, theme }) => {
    if (sendingStatus === SendingStatusEnum.SENT) {
      return css`
        background-color: ${theme.colours.success};
      `;
    }
    if (sendingStatus === SendingStatusEnum.ERROR) {
      return css`
        background-color: ${theme.colours.error};
      `;
    }
    return '';
  }};
`;

const SentAnimationKeyframes = keyframes`
  0% { opacity: 0 }
  10% { opacity: 1 }
  100% { opacity: 1 }
`;

const SentAnimationElement = styled.div<SentAnimationElementPropsType>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colours.white};
  animation-name: ${SentAnimationKeyframes};
  animation-duration: ${({ duration }) => duration}ms;
`;

const SentAnimationTextElement = styled.div`
  font-size: 1.5rem;
`;

const feedbackOptions: FeedbackOptionType[] = [
  {
    value: 'feature',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.feature" defaultMessage="Feature or Suggestion" />
      </span>
    ),
  },
  {
    value: 'missing',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.missing" defaultMessage="Missing Events or Competitions" />
      </span>
    ),
  },
  {
    value: 'bookie',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.bookie" defaultMessage="Bookie Accounts" />
      </span>
    ),
  },
  {
    value: 'settlement',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.settlement" defaultMessage="Bet Settlement" />
      </span>
    ),
  },
  {
    value: 'money',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.money" defaultMessage="Withdraw / Deposit" />
      </span>
    ),
  },
  {
    value: 'access',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.access" defaultMessage="Access" />
      </span>
    ),
  },
  {
    value: 'other',
    label: (
      <span className="option">
        <FormattedMessage id="feedback.general" defaultMessage="Other" />
      </span>
    ),
  },
];

const FeedbackTextAreaHelp: React.FunctionComponent<FeedbackTextAreaHelpPropsType> = ({
  remaining,
}) => {
  return (
    <FeedbackTextAreaHelpElement>
      <FeedbackTextAreaHelpRemainingElement remaining={remaining}>
        {remaining}
      </FeedbackTextAreaHelpRemainingElement>{' '}
      <FormattedMessage id="feedback.charactersRemaining" defaultMessage="characters remaining" />
    </FeedbackTextAreaHelpElement>
  );
};

const SendButton: React.FunctionComponent<SendButtonPropsType> = ({
  sendingStatus,
  sendFeedback,
}) => {
  const icon = null;
  let message = <FormattedMessage id="feedback.send" defaultMessage="Send" />;

  if (sendingStatus === SendingStatusEnum.SENDING) {
    message = <FormattedMessage id="feedback.sending" defaultMessage="Sending" />;
  } else if (sendingStatus === SendingStatusEnum.SENT) {
    message = <FormattedMessage id="feedback.sent" defaultMessage="Sent" />;
  } else if (sendingStatus === SendingStatusEnum.ERROR) {
    message = <FormattedMessage id="feedback.error" defaultMessage="Error" />;
  }

  const onClick = (): void => {
    if (!sendingStatus) sendFeedback();
  };

  return (
    <SendButtonElement
      variant="primary"
      onClick={onClick}
      sendingStatus={sendingStatus}
      busy={sendingStatus === SendingStatusEnum.SENDING}
    >
      {icon}
      {message}
    </SendButtonElement>
  );
};

const SentAnimation: React.FunctionComponent<SentAnimationPropsType> = ({
  duration,
  theme,
  sendingStatus,
}) => {
  if (sendingStatus !== SendingStatusEnum.SENT) return null;

  return (
    <SentAnimationElement duration={duration}>
      <Icon path={mdiCheck} size={2} color={theme.colours.success} />
      <SentAnimationTextElement>Feedback sent.</SentAnimationTextElement>
    </SentAnimationElement>
  );
};

const DumbFeedbackDialog: React.FunctionComponent<FeedbackDialogPropsType> = ({
  actions,
  sendingStatus,
  isFeedbackOverlayOpen,
}) => {
  const SENT_ANIMATION_DURATION = 3000;
  const MAX_FEEDBACK_LENGTH = 500;
  const [selectedFeedbackValue, setSelectedFeedbackValue] = useState<string>(
    feedbackOptions[0].value
  );
  const [feedbackText, setFeedbackText] = useState<string>('');
  // Slippy is the opposite of sticky right ?
  const [slippySendingStatus, setSlippySendingStatus] = useState<SendingStatusEnum | null>(null);

  useEffect(() => {
    setSlippySendingStatus(sendingStatus);

    let splashTimeout;
    if (sendingStatus === SendingStatusEnum.SENT) {
      splashTimeout = setTimeout(() => {
        if (isFeedbackOverlayOpen) actions.toggleFeedbackOverlay();
        setSlippySendingStatus(null);
        setSelectedFeedbackValue(feedbackOptions[0].value);
        setFeedbackText('');
      }, SENT_ANIMATION_DURATION);
    }

    return () => {
      clearTimeout(splashTimeout);
    };
  }, [actions, isFeedbackOverlayOpen, sendingStatus]);

  const currentFeedbackOption = feedbackOptions.find(
    (option: FeedbackOptionType): boolean => option.value === selectedFeedbackValue
  );

  const feedbackSelectOnchange = (event: ValueType<FeedbackOptionType, false>): void => {
    // @ts-ignore: Single select for event is not a list.
    setSelectedFeedbackValue((event && event.value) || feedbackOptions[0].value);
  };
  const feedbackTextOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFeedbackText(event.target.value.slice(0, MAX_FEEDBACK_LENGTH));
  };
  const clearButtonOnClick = (): void => setFeedbackText('');
  const sendFeedback = (): void => {
    actions.sendFeedback({
      message: feedbackText,
      type: selectedFeedbackValue,
      actions: actions,
    });
  };

  const ThemedSentAnimation = withTheme(SentAnimation);

  return (
    <ComponentErrorBoundary>
      <FeedbackDialogElement
        open={isFeedbackOverlayOpen}
        closeWith={actions.toggleFeedbackOverlay}
        backdropClick={actions.toggleFeedbackOverlay}
      >
        <FeedbackDialogTitleElement>
          <FormattedMessage id="feedback.contactUs" defaultMessage="Contact Us" />
        </FeedbackDialogTitleElement>
        <FeedbackDialogContentElement>
          <FeedbackTypeSelectElement
            options={feedbackOptions}
            value={currentFeedbackOption}
            shrinked={true}
            // @ts-ignore: Element labels are fine
            onChange={feedbackSelectOnchange}
            label={<FormattedMessage id="feedback.selectTitle" defaultMessage="Feedback Type" />}
          />
          <FeedbackTextAreaElement
            value={feedbackText}
            onChange={feedbackTextOnChange}
            help={<FeedbackTextAreaHelp remaining={MAX_FEEDBACK_LENGTH - feedbackText.length} />}
            multipleLines={true}
          />
          <FeedbackButtonLineElement>
            <ClearButtonElement onClick={clearButtonOnClick}>
              <FormattedMessage id="feedback.clear" defaultMessage="Clear" />
            </ClearButtonElement>
            <SendButton sendingStatus={slippySendingStatus} sendFeedback={sendFeedback} />
          </FeedbackButtonLineElement>
          <ThemedSentAnimation
            duration={SENT_ANIMATION_DURATION}
            sendingStatus={slippySendingStatus}
          />
        </FeedbackDialogContentElement>
      </FeedbackDialogElement>
    </ComponentErrorBoundary>
  );
};

const mapDispatchToProps: MapDispatchToPropsType = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const getFeedbackSendingStatus = (state): SendingStatusEnum | null => {
  if (state.getIn(['base', 'flags', 'feedbackError'], false)) return SendingStatusEnum.ERROR;
  if (state.getIn(['base', 'flags', 'feedbackSent'], false)) return SendingStatusEnum.SENT;
  if (state.getIn(['base', 'flags', 'feedbackSending'], false)) return SendingStatusEnum.SENDING;
  return null;
};

const mapStateToProps: MapStateToPropsType = (state) => ({
  sendingStatus: getFeedbackSendingStatus(state),
  isFeedbackOverlayOpen: state.getIn(['ui', 'isFeedbackOverlayOpen'], false),
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbFeedbackDialog);
