/** @format */

import styled from 'styled-components';
import config from '../../../config';
import { IconButton } from '@mollybet/ui';
import { Button } from '../../interface';

export const SearchBoxContainerElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${config.nav.styles.wide.height};
`;

export const SearchBarIconButton = styled(IconButton)`
  margin-right: -34px;
`;

export const ButtonElement = styled(Button)`
  margin-top: 5px;
  cursor: pointer;
  margin-left: 15px;

  @media only screen and (max-width: ${400}px) {
    margin: 0px 0px 0px 5px;
    margin: 8px 5px 0px 15px;
    width: 60px;
  }
`;

export const TextFieldElement = styled.input`
  width: 400px;
  background: rgb(6, 6, 6);
  color: white;
  border: none;
  border-bottom: 1px solid lightgrey;
  height: 30px;
  font-size: 14px;
  color: white;
  padding-left: 30px;

  @media only screen and (max-width: ${400}px) {
    width: 100%;
  }
`;
