/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';
import config from '../../../config';
import { toStdDate } from '@mollybet/frontend-common/dist/lib/time';

import { fromJS } from 'immutable';

const defaultDate = toStdDate();
let initialState = fromJS({
  formOpen: false,
  formData: [
    {
      username: '',
      date: defaultDate,
      amount: '',
      discount: 0,
      description: '',
      status: '',
      errorInfo: '',
    },
  ],
  csvFormOpen: false,
  csvFormData: '',
});

let batchIdent = Math.floor(Math.random() * 1000);

const functions = {
  //opens the batch transfer modal
  openBatchTransfer: (state, _action) => {
    batchIdent = Math.floor(Math.random() * 1000);
    return state.set('formOpen', true);
  },

  //closes the batch transfer modal
  closeBatchTransfer: (state, _action) => {
    return state.set('formOpen', false);
  },

  //updates the batch transfer forms
  updateBatchFormData: (state, action) => {
    return state.set('formData', fromJS(action.data.formData));
  },

  //makes each transfer in the batch
  newBatchTransfer: (state, action) => {
    const transferData = Object.values(action.data.data);
    const batchLength = transferData.length;

    for (let j = 0; j < batchLength; j++) {
      transferData[j]['status'] = 'pending';
      transferData[j]['errorInfo'] = '';
      state = state.set('formData', fromJS(transferData));
    }

    // func called once for each form row (each transfer)
    let i = 0;
    const makeTransfer = () => {
      DSM.create(
        `/v1/transfers/`,
        {
          method: 'POST',
          body: {
            amount: action.data.data[i].amount,
            description: action.data.data[i].description + ` [b:${batchIdent}]`,
            discount: action.data.data[i].discount,
            username: action.data.data[i].username,
            onlyIfAvailable: false,
          },
          extras: {
            transfer: {
              index: i,
              username: action.data.data[i]['username'],
              formData: Object.values(action.data.data),
            },
            streams: ['xfer', 'discount', 'balance'],
            targetUser: action.data.data[i]['username'],
            currentUser: action.data.currentUser,
          },
          message: 'newCustomerTransferResponse',
        },
        action.data.actions
      );

      i++;
      if (i < batchLength) {
        setTimeout(makeTransfer, config.timings.timeBetweenBatchCalls);
      }
    };

    if (batchLength) {
      makeTransfer();
    }

    return state;
  },

  //records the status of batch transfer calls
  newCustomerTransferResponse: (state, action) => {
    if (action.data.extras.transfer) {
      let formData = action.data.extras.transfer.formData;
      if (action.data.status === 'ok') {
        formData[action.data.extras.transfer.index]['status'] = 'success';
        state = state.set('formData', fromJS(formData));
      } else {
        const parsedError = extractErrorMessage(action.data.data);
        formData[action.data.extras.transfer.index]['status'] = 'failure';
        formData[action.data.extras.transfer.index]['errorInfo'] = parsedError;
        state = state.set('formData', fromJS(formData));
      }
    }
    return state;
  },

  //makes a transfer attempt again for a single row
  //sometimes the API fails for random reasons
  retryTransfer: (state, action) => {
    let formData = state.get('formData');
    formData = Object.values(formData.toJS());
    const i = action.data.index;
    let body = { ...formData[i] };
    if (!body.description) {
      body.description = '';
    }
    body.description += ` [r:${batchIdent}]`;

    DSM.create(
      `/v1/transfers/`,
      {
        method: 'POST',
        body,
        extras: {
          transfer: {
            index: i,
            username: formData[i]['username'],
            formData: formData,
          },
          streams: ['xfer', 'discount'],
          targetUser: formData[i]['username'],
          currentUser: action.data.currentUser,
        },
        message: 'newCustomerTransferResponse',
      },
      action.data.actions
    );

    formData[i]['status'] = 'pending';
    formData[i]['errorInfo'] = '';
    return state.set('formData', fromJS(formData));
  },

  //toggles whether the csv form is shown
  toggleCSVForm: (state, _action) => {
    const csvFormState = state.get('csvFormOpen');
    return state.set('csvFormOpen', !csvFormState);
  },

  //updates state with the contents of the csv form
  handleChangeCSVFormData: (state, action) => {
    return state.set('csvFormData', action.data.data);
  },

  //parses the contents of the csv form and turns them into rows in the form
  parseBatchTransferCSVData: (state, _action) => {
    const csvDataRows = state.get('csvFormData').trim().split(/\r?\n/); //parse data adding it to formData
    const formData = [];
    csvDataRows.forEach((row) => {
      const rowData = row.split(',');
      const transfer = {
        username: rowData[0],
        date: defaultDate,
        amount: rowData[1],
        discount: rowData[2],
        description: rowData[3],
        status: '',
        errorInfo: '',
      };
      formData.push({ ...transfer });
    });
    return state.set('formData', fromJS(formData));
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
