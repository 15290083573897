/** @format */

import merge from 'lodash/merge';
import config from '@mollybet/frontend-common/dist/config';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import { Icons } from '@mollybet/ui';

let local = {
  supportedLanguages: ['en', 'pt', 'ru', 'es', 'tr'], //you have to have the default language in here
  defaultLanguage: 'en', //default interface language
  defaultTheme: 'default', //default interface theme
  agentVirtualOverscan: 10, //how much virtual scrolling overscan agent/admin views have

  tradePaletteColors: [
    '#CCFFCC',
    '#FFCCCC',
    '#CCCCFF',
    '#FFFF33',
    '#99FF33',
    '#66CCCC',
    '#CC99FF',
    '#FF99CC',
  ],

  betbar: {
    minHeight: 100, //minimum betbar height in pixels
    heightNarrow: 150, //betbar height in narrow mode
    maxOrders: 100, //max number of orders in the betbar (pageSizes oldOpenOrdersBetbarPageSize + allOrdersBetbarPageSize)
  },

  sportIdToNavName: {
    fb: 'football',
    basket: 'basketball',
    hand: 'handball',
    darts: 'darts',
    tennis: 'tennis',
    af: 'americanFootball',
    ih: 'iceHockey',
    baseball: 'baseball',
    esports: 'esports',
    mma: 'mma',
    golf: 'golf',
    cricket: 'cricket',
    ru: 'rugbyUnion',
    boxing: 'boxing',
    rl: 'rugbyLeague',
    arf: 'aussieRulesFootball',
    horse: 'horse',
  },

  pageSizes: {
    statementMovements: 50,
    historyOrders: 50,
    historyOrdersAPIDefault: 25,
    historyBets: 50,
    orderLogs: 6,
  },

  nav: {
    marketSelector: {
      groupItems: true,
      collapseSingleMarketSports: true,
      showSportIcon: false,
      showMarketIcon: true,
      hideFullTimeMarketQualifier: true, // 'Tennis: Full Time' -> 'Tennis'
      maxMarketsToDisplay: 0, // 0 will cause the dropdown to expand to full height.
      displayOrder: [
        'football',
        'basketball',
        'tennis',
        'golf',
        'horse',
        'americanFootball',
        'iceHockey',
        'baseball',
        'esports',
        'mma',
        'cricket',
        'rugbyUnion',
        'boxing',
        'rugbyLeague',
        'handball',
        'darts',
        // 'aussieRulesFootball',
      ],
      sports: {
        americanFootball: {
          id: 'af',
          name: { id: 'sport.americanFootball', defaultMessage: 'American Football' },
          iconPath: Icons.sports.americanFootball.default,
          hidden: !config.sports.af.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'af',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        aussieRulesFootball: {
          id: 'arf',
          name: { id: 'sport.aussieRulesFootball', defaultMessage: 'Aussie Rules Football' },
          iconPath: Icons.sports.australianFootball.default,
          hidden: !config.sports.arf.enabled,
          displayOrder: ['fullTime'],
          searchAliases: [
            { id: 'sports.australianFootball', defaultMessage: 'Australian Football' },
          ],
          markets: {
            fullTime: {
              id: 'arf',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        baseball: {
          id: 'baseball',
          name: { id: 'sports.baseball', defaultMessage: 'Baseball' },
          iconPath: Icons.sports.baseball.default,
          hidden: !config.sports.baseball.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'baseball',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        basketball: {
          id: 'basket',
          name: { id: 'sports.basket', defaultMessage: 'Basketball' },
          iconPath: Icons.sports.basketball.default,
          hidden: !config.sports.basket.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'basket',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
            halfTime: {
              id: 'basket_ht',
              name: { id: 'markets.halfTime', defaultMessage: 'Half Time' },
              hidden: false,
            },
            quarter1: {
              id: 'basket_q1',
              name: { id: 'markets.quarter1', defaultMessage: '1st Quarter' },
              hidden: false,
            },
            quarter2: {
              id: 'basket_q2',
              name: { id: 'markets.quarter2', defaultMessage: '2nd Quarter' },
              hidden: false,
            },
          },
        },
        boxing: {
          id: 'boxing',
          name: { id: 'sports.boxing', defaultMessage: 'Boxing' },
          iconPath: Icons.sports.boxing.default,
          hidden: !config.sports.boxing.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'boxing',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        cricket: {
          id: 'cricket',
          name: { id: 'sports.cricket', defaultMessage: 'Cricket' },
          iconPath: Icons.sports.cricket.default,
          hidden: !config.sports.cricket.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'cricket',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        esports: {
          id: 'esports',
          name: { id: 'sports.esports', defaultMessage: 'eSports' },
          iconPath: Icons.sports.esports.default,
          hidden: !config.sports.esports.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'esports',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        football: {
          id: 'fb',
          name: { id: 'sports.football', defaultMessage: 'Football' },
          iconPath: Icons.sports.football.default,
          hidden: !config.sports.fb.enabled,
          displayOrder: [
            'fullTime',
            'halfTime',
            'extraTime',
            'halfTimeFullTime',
            'corners',
            'halfTimeCorners',
          ],
          markets: {
            fullTime: {
              id: 'fb',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
            halfTime: {
              id: 'fb_ht',
              name: { id: 'markets.halfTime', defaultMessage: 'Half Time' },
              hidden: false,
            },
            halfTimeFullTime: {
              id: 'fb_htft',
              name: { id: 'markets.halfTimeFullTime', defaultMessage: 'Half Time/Full Time' },
              hidden: false,
            },
            corners: {
              id: 'fb_corn',
              name: { id: 'markets.corners', defaultMessage: 'Corners' },
              hidden: false,
              iconPath: Icons.sports.football.corner,
            },
            halfTimeCorners: {
              id: 'fb_corn_ht',
              name: { id: 'markets.cornersHalfTime', defaultMessage: 'Half Time Corners' },
              hidden: false,
              iconPath: Icons.sports.football.corner,
            },
            extraTime: {
              id: 'fb_et',
              name: { id: 'markets.extraTime', defaultMessage: 'Extra Time' },
              hidden: false,
            },
          },
        },
        golf: {
          id: 'golf',
          name: { id: 'sports.golf', defaultMessage: 'Golf' },
          iconPath: Icons.sports.golf.default,
          hidden: !config.sports.golf.enabled,
          displayOrder: ['fullTime'],
          searchAliases: [{ id: 'sports.golf', defaultMessage: 'golf' }],
          markets: {
            fullTime: {
              id: 'golf',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        iceHockey: {
          id: 'ih',
          name: { id: 'sports.iceHockey', defaultMessage: 'Ice Hockey' },
          iconPath: Icons.sports.iceHockey.default,
          hidden: !config.sports.ih.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'ih',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
            halfTime: {
              id: 'ih_ht',
              name: { id: 'markets.halfTime', defaultMessage: 'Half Time' },
              hidden: false,
            },
          },
        },
        mma: {
          id: 'mma',
          name: { id: 'sports.mma', defaultMessage: 'MMA' },
          iconPath: Icons.sports.mma.default,
          hidden: !config.sports.mma.enabled,
          displayOrder: ['fullTime'],
          searchAliases: [{ id: 'sports.mixedMartialArts', defaultMessage: 'mixed martial arts' }],
          markets: {
            fullTime: {
              id: 'mma',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        rugbyLeague: {
          id: 'rl',
          name: { id: 'sports.rugbyLeague', defaultMessage: 'Rugby League' },
          iconPath: Icons.sports.rugbyLeague.default,
          hidden: !config.sports.rl.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'rl',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        rugbyUnion: {
          id: 'ru',
          name: { id: 'sports.rugbyUnion', defaultMessage: 'Rugby Union' },
          iconPath: Icons.sports.rugbyUnion.default,
          hidden: !config.sports.ru.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'ru',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        tennis: {
          id: 'tennis',
          name: { id: 'sports.tennis', defaultMessage: 'Tennis' },
          iconPath: Icons.sports.tennis.default,
          hidden: !config.sports.tennis.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'tennis',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        handball: {
          id: 'hand',
          name: { id: 'sports.hand', defaultMessage: 'Handball' },
          iconPath: Icons.sports.handball.default,
          hidden: !config.sports.hand.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'hand',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
            halfTime: {
              id: 'hand_ht',
              name: { id: 'markets.halfTime', defaultMessage: 'Half Time' },
              hidden: false,
            },
          },
        },
        darts: {
          id: 'darts',
          name: { id: 'sports.darts', defaultMessage: 'Darts' },
          iconPath: Icons.sports.darts?.default || Icons.sports.tennis.default,
          hidden: !config.sports.darts.enabled,
          displayOrder: ['fullTime'],
          markets: {
            fullTime: {
              id: 'darts',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
        horse: {
          id: 'horse',
          name: { id: 'sports.horse', defaultMessage: 'Horse Racing' },
          iconPath: Icons.sports.horse.default,
          hidden: !config.sports.horse.enabled,
          displayOrder: ['fullTime'],
          searchAliases: [{ id: 'sports.horse', defaultMessage: 'horse' }],
          markets: {
            fullTime: {
              id: 'horse',
              name: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
              hidden: false,
            },
          },
        },
      },
    },
    styles: {
      wide: {
        height: '48px',
      },
      narrow: {
        height: '36px',
      },
    },
  },

  positionGrids: {
    colourShades: true, // Should we use the three shades of green/red to show size of profit/loss
    scaleColoursByMinValue: true, // Should we calculate the shades using the min profit/loss or 0
  },

  orders: {
    showStakeDistributionGraph: false, // They keep changing their mind so I'm adding a toggle.
    showGraphForSingleBetOrigin: false, // Show graph when there is only 1 bookie.
    disableLoadingPlaceholders: false,
    placeholderAnimationThreshold: 50, // The placeholders will not animate above this row count for performance.
    debounceSearch: 500, // How long to debounce search input for, 0 for no debouncing.
    animateExtraInfo: true,
  },

  placers: {
    xOffset: 1.5 * 30 * 12, //approximately a betslip and a half
    yOffset: 200, //how much to push down
    notInBottomPx: 200, //don't open betslip in the bottom x pixels of the screen
    extraEdgeHandicaps: 2, //how many extra edge handicaps to add (this is for an advanced feature)
    multipleBetslipOnEachSide: 1, //with the multiple betslip option, how many on each side to open
    handicapsOnEachSide: 2, //how many handicaps to show on each side, and how many more on expanding
  },

  // this needs to coordinate with the BE
  darkOrderMinSizes: {
    EUR: 600,
    GBP: 500,
    BRL: 3000,
    USD: 700,
    THB: 20000,
    NOK: 6000,
    HKD: 5000,
    SGD: 900,
    CNY: 4000,
    USDT: 700,
    SEK: 7000,
    AUD: 900,
  },

  registration: {
    currencies: [], //currencies
    kycEmail: '',
    maxDepositWithdraw: 2000, //in GBP
    maxKycUploadLimit: 8 * 1024 * 1024,

    //! This is `.join`ed at the end!
    kycAcceptedFileTypes: [
      // Images
      '.jpg',
      '.jpeg',
      'image/jpeg',
      '.png',
      'image/png',

      // PDF
      '.pdf',
      'application/pdf',

      // Microsoft Word (old and new formats)
      // Not sure why we accept doc and dox in the first place.
      // Expecially since it's easy to add socially engineer and add "malicious code" into them.
      '.doc',
      'application/msword',
      '.docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

      // Open Document Text. The fate of this is to be determined, so left it commented.
      // ".odt", "application/vnd.oasis.opendocument.text"
    ].join(','),
  },

  settingsOptions: {
    //timeouts available in betslip
    timeouts: [
      5,
      10,
      15,
      20,
      30,
      60,
      120,
      300,
      600,
      900,
      1200,
      1800,
      3600,
      7200,
      14400,
      21600,
      43200,
      86400,
      172800,
      259200,
    ],
    //timeout options depends on IR/ DB, classic/
    conditionalTimeouts: {
      closeAtKickOff: {
        value: 'Close at Kick Off',
        name: { id: 'trade.placer.closeAtKickOff', defaultMessage: 'Close at Kick Off' },
        keepOpenIr: false,
      },
      closeAtMaterialEvent: {
        value: 'Close at Material Event',
        name: { id: 'trade.placer.closeAtEvent', defaultMessage: 'Close at Material Event' },
        keepOpenIr: false,
      },
      keepOpenIR: {
        value: 'Keep open IR',
        name: { id: 'trade.placer.keepOpenIrShort', defaultMessage: 'Keep open IR' },
        keepOpenIr: true,
      },
    },
    // The whitelabel ids to disable toggle the 'Use equivalent bets' toggle for.
    equivBetsToggleDisabled: ['sportmarket', 'premiumtradings', 'odds4sports'],
    //[ADVANCED] how should the betslip wait for PMMs on the API side
    waitForPmms: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
    //account reselection options
    reselectAccounts: [
      {
        type: 'never',
        name: { id: 'settingsOptions.reselectAccounts.never', defaultMessage: 'Never' },
      },
      {
        type: 'only_placement_bookies',
        name: {
          id: 'settingsOptions.reselectAccounts.onlyPlacementBookies',
          defaultMessage: 'Only bookies at placement',
        },
      },
      {
        type: 'always',
        name: {
          id: 'settingsOptions.reselectAccounts.always',
          defaultMessage: 'Add new bookies to order',
        },
      },
    ],
    //[ADVANCED] multiple account selection options
    multipleAccounts: [
      {
        type: true,
        name: { id: 'settingsOptions.multipleAccounts.selectAll', defaultMessage: 'Select all' },
      },
      {
        type: false,
        name: { id: 'settingsOptions.multipleAccounts.selectOne', defaultMessage: 'Select one' },
      },
    ],

    //trade page view options
    userTradeView: [
      {
        type: 'default',
        name: { id: 'settingsOptions.layouts.default', defaultMessage: 'European' },
      },
      {
        type: 'asian',
        name: { id: 'settingsOptions.layouts.asian', defaultMessage: 'Asian' },
      },
    ],
    //event sort options
    sortEventsBy: [
      {
        type: 'comp',
        name: { id: 'settingsOptions.eventSorts.comp', defaultMessage: 'Competition name' },
      },
      {
        type: 'time',
        name: { id: 'settingsOptions.eventSorts.time', defaultMessage: 'Event start time' },
      },
    ],
    //price type options
    priceType: [
      {
        type: 'dec',
        name: { id: 'settingsOptions.priceType.dec', defaultMessage: 'Decimal' },
      },
      {
        type: 'ol',
        name: { id: 'settingsOptions.priceType.ol', defaultMessage: 'Hong Kong' },
      },
      {
        type: 'uso',
        name: { id: 'settingsOptions.priceType.uso', defaultMessage: 'United States' },
      },
      // {
      //   'type': 'maly',
      //   'name': {id: 'settingsOptions.priceType.maly', defaultMessage: 'Malay Odds'}
      // },
      {
        type: 'indo',
        name: { id: 'settingsOptions.priceType.indo', defaultMessage: 'Indo Odds' },
      },
    ],
    //currency options translations
    currency: [
      {
        type: 'gbp',
        name: { id: 'currencies.poundSterling', defaultMessage: 'Pound Sterling' },
      },
      {
        type: 'eur',
        name: { id: 'currencies.euro', defaultMessage: 'Euro' },
      },
      {
        type: 'usd',
        name: { id: 'currencies.usDollar', defaultMessage: 'US Dollar' },
      },
      {
        type: 'hkd',
        name: { id: 'currencies.hongKongDollar', defaultMessage: 'HongKong Dollar' },
      },
      {
        type: 'cny',
        name: { id: 'currencies.chineseYuan', defaultMessage: 'Chinese Yuan' },
      },
      {
        type: 'thb',
        name: { id: 'currencies.thaiBhat', defaultMessage: 'Thai Bhat' },
      },
      {
        type: 'twd',
        name: { id: 'currencies.taiwanNewDollar', defaultMessage: 'Taiwan New Dollar' },
      },
      {
        type: 'krw',
        name: { id: 'currencies.southKoreanWon', defaultMessage: 'South Korean Won' },
      },
      {
        type: 'idr',
        name: { id: 'currencies.indonesianRupiah', defaultMessage: 'Indonesian Rupiah' },
      },
      {
        type: 'kidr',
        name: {
          id: 'currencies.indonesianRupiahThousand',
          defaultMessage: 'Indonesian Rupiah (thousand)',
        },
      },
      {
        type: 'aud',
        name: { id: 'currencies.australianDollar', defaultMessage: 'Australian Dollar' },
      },
      {
        type: 'sgd',
        name: { id: 'currencies.singaporeDollar', defaultMessage: 'Singapore Dollar' },
      },
      {
        type: 'myr',
        name: { id: 'currencies.malaysianRinggit', defaultMessage: 'Malaysian Ringgit' },
      },
      {
        type: 'nok',
        name: { id: 'currencies.norwegianKrone', defaultMessage: 'Norwegian Krone' },
      },
      {
        type: 'sek',
        name: { id: 'currencies.swedishKrona', defaultMessage: 'Swedish Krona' },
      },
      {
        type: 'smrkgbp',
        name: { id: 'currencies.smrkGBP', defaultMessage: 'Smrk GBP' },
      },
      {
        type: 'brl',
        name: { id: 'currencies.brasilianReal', defaultMessage: 'Brasilian Real' },
      },
      {
        type: 'usdt',
        name: { id: 'currencies.tetherUSDT', defaultMessage: 'Tether (USDT)' },
      },
      {
        type: 'singeur',
        name: { id: 'currencies.singEurCny', defaultMessage: 'Sing Fixed EURCNY' },
      },
    ],
    //theme options
    themes: [
      {
        type: 'default',
        name: { id: 'settingsOptions.themes.dark', defaultMessage: 'Dark' },
      },
      {
        type: 'light',
        name: { id: 'settingsOptions.themes.light', defaultMessage: 'Light' },
      },
    ],
    //languages options (also depends on the supported laanguages)
    languages: [
      {
        type: 'en',
        name: { id: 'settingsOptions.language.en', defaultMessage: 'English' },
        code: 'en',
        flag: 'UK',
        enabled: true,
      },
      {
        type: 'zh',
        name: { id: 'settingsOptions.language.zh', defaultMessage: 'Chinese' },
        code: 'zh_CN',
        flag: 'CN',
        enabled: true,
      },
      // {
      //   'type': 'ko',
      //   'name': {id: 'settingsOptions.language.ko', defaultMessage: 'Korean'},
      //   'code': 'ko',
      //   'flag': 'KR',
      //   'enabled': true
      // },
      // {
      //   'type': 'ro',
      //   'name': {id: 'settingsOptions.language.ro', defaultMessage: 'Romanian'},
      //   'code': 'ro_RO',
      //   'flag': 'RO',
      //   'enabled': true
      // },
      {
        type: 'pt',
        name: { id: 'settingsOptions.language.pt', defaultMessage: 'Portuguese' },
        code: 'pt_PT',
        flag: 'BR',
        enabled: true,
      },
      {
        type: 'ru',
        name: { id: 'settingsOptions.language.ru', defaultMessage: 'Russian' },
        code: 'ru_RU',
        flag: 'RU',
        enabled: true,
      },
      {
        type: 'es',
        name: { id: 'settingsOptions.language.es', defaultMessage: 'Spanish' },
        code: 'es_ES',
        flag: 'ES',
        enabled: true,
      },
      {
        type: 'tr',
        name: { id: 'settingsOptions.language.tr', defaultMessage: 'Turkish' },
        code: 'tr_TR',
        flag: 'TR',
        enabled: true,
      },
    ],
    //kyc document options and translations
    kycDocuments: [
      {
        type: 'proofOfIdentity',
        name: { id: 'settingsOptions.kyc.proofOfIdentity', defaultMessage: 'Proof of Identity' },
      },
      {
        type: 'proofOfAddress',
        name: { id: 'settingsOptions.kyc.proofOfAddress', defaultMessage: 'Proof of Address' },
      },
    ],
    //countries options and translations
    countries: [
      {
        type: 'AL',
        name: {
          id: 'settingsOptions.countries.Albania',
          defaultMessage: 'Albania',
        },
      },
      {
        type: 'AD',
        name: {
          id: 'settingsOptions.countries.Andorra',
          defaultMessage: 'Andorra',
        },
      },
      {
        type: 'AR',
        name: {
          id: 'settingsOptions.countries.Argentina',
          defaultMessage: 'Argentina',
        },
      },
      {
        type: 'AM',
        name: {
          id: 'settingsOptions.countries.Armenia',
          defaultMessage: 'Armenia',
        },
      },
      {
        type: 'AT',
        name: {
          id: 'settingsOptions.countries.Austria',
          defaultMessage: 'Austria',
        },
      },
      {
        type: 'AZ',
        name: {
          id: 'settingsOptions.countries.Azerbaijan',
          defaultMessage: 'Azerbaijan',
        },
      },
      {
        type: 'BA',
        name: {
          id: 'settingsOptions.countries.Bosnia and Herzegovina',
          defaultMessage: 'Bosnia and Herzegovina',
        },
      },
      {
        type: 'BD',
        name: {
          id: 'settingsOptions.countries.Bangladesh',
          defaultMessage: 'Bangladesh',
        },
      },
      {
        type: 'BY',
        name: {
          id: 'settingsOptions.countries.Belarus',
          defaultMessage: 'Belarus',
        },
      },
      {
        type: 'BR',
        name: {
          id: 'settingsOptions.countries.Brazil',
          defaultMessage: 'Brazil',
        },
      },
      {
        type: 'CI',
        name: {
          id: "settingsOptions.countries.Côte d'Ivoire",
          defaultMessage: "Côte d'Ivoire",
        },
      },
      {
        type: 'CW',
        name: {
          id: 'settingsOptions.countries.Curacao',
          defaultMessage: 'Curacao',
        },
      },
      {
        type: 'DO',
        name: {
          id: 'settingsOptions.countries.Dominican Republic',
          defaultMessage: 'Dominican Republic',
        },
      },
      {
        type: 'KH',
        name: {
          id: 'settingsOptions.countries.Cambodia',
          defaultMessage: 'Cambodia',
        },
      },
      {
        type: 'CL',
        name: {
          id: 'settingsOptions.countries.Chile',
          defaultMessage: 'Chile',
        },
      },
      {
        type: 'CR',
        name: {
          id: 'settingsOptions.countries.Costa Rica',
          defaultMessage: 'Costa Rica',
        },
      },
      {
        type: 'CY',
        name: {
          id: 'settingsOptions.countries.Cyprus',
          defaultMessage: 'Cyprus',
        },
      },
      {
        type: 'EC',
        name: {
          id: 'settingsOptions.countries.Ecuador',
          defaultMessage: 'Ecuador',
        },
      },
      {
        type: 'EG',
        name: {
          id: 'settingsOptions.countries.Egypt',
          defaultMessage: 'Egypt',
        },
      },
      {
        type: 'EE',
        name: {
          id: 'settingsOptions.countries.Estonia',
          defaultMessage: 'Estonia',
        },
      },
      {
        type: 'FI',
        name: {
          id: 'settingsOptions.countries.Finland',
          defaultMessage: 'Finland',
        },
      },
      {
        type: 'GE',
        name: {
          id: 'settingsOptions.countries.Georgia',
          defaultMessage: 'Georgia',
        },
      },
      {
        type: 'GI',
        name: {
          id: 'settingsOptions.countries.Gibraltar',
          defaultMessage: 'Gibraltar',
        },
      },
      {
        type: 'HR',
        name: {
          id: 'settingsOptions.countries.Croatia (Hrvatska)',
          defaultMessage: 'Croatia (Hrvatska)',
        },
      },
      {
        type: 'HU',
        name: {
          id: 'settingsOptions.countries.Hungary',
          defaultMessage: 'Hungary',
        },
      },
      {
        type: 'IS',
        name: {
          id: 'settingsOptions.countries.Iceland',
          defaultMessage: 'Iceland',
        },
      },
      {
        type: 'IM',
        name: {
          id: 'settingsOptions.countries.Isle of Man',
          defaultMessage: 'Isle of Man',
        },
      },
      {
        type: 'IN',
        name: {
          id: 'settingsOptions.countries.India',
          defaultMessage: 'India',
        },
      },
      {
        type: 'JP',
        name: {
          id: 'settingsOptions.countries.Japan',
          defaultMessage: 'Japan',
        },
      },
      {
        type: 'KZ',
        name: {
          id: 'settingsOptions.countries.Kazakhstan',
          defaultMessage: 'Kazakhstan',
        },
      },
      {
        type: 'KG',
        name: {
          id: 'settingsOptions.countries.Kyrgyzstan',
          defaultMessage: 'Kyrgyzstan',
        },
      },
      {
        type: 'LV',
        name: {
          id: 'settingsOptions.countries.Latvia',
          defaultMessage: 'Latvia',
        },
      },
      {
        type: 'LI',
        name: {
          id: 'settingsOptions.countries.Liechtenstein',
          defaultMessage: 'Liechtenstein',
        },
      },
      {
        type: 'LT',
        name: {
          id: 'settingsOptions.countries.Lithuania',
          defaultMessage: 'Lithuania',
        },
      },
      {
        type: 'LU',
        name: {
          id: 'settingsOptions.countries.Luxembourg',
          defaultMessage: 'Luxembourg',
        },
      },
      {
        type: 'MO',
        name: {
          id: 'settingsOptions.countries.Macau',
          defaultMessage: 'Macau',
        },
      },
      {
        type: 'MK',
        name: {
          id: 'settingsOptions.countries.Macedonia',
          defaultMessage: 'Macedonia',
        },
      },
      {
        type: 'MY',
        name: {
          id: 'settingsOptions.countries.Malaysia',
          defaultMessage: 'Malaysia',
        },
      },
      {
        type: 'MT',
        name: {
          id: 'settingsOptions.countries.Malta',
          defaultMessage: 'Malta',
        },
      },
      {
        type: 'MX',
        name: {
          id: 'settingsOptions.countries.Mexico',
          defaultMessage: 'Mexico',
        },
      },
      {
        type: 'MD',
        name: {
          id: 'settingsOptions.countries.Moldova',
          defaultMessage: 'Moldova',
        },
      },
      {
        type: 'MC',
        name: {
          id: 'settingsOptions.countries.Monaco',
          defaultMessage: 'Monaco',
        },
      },
      {
        type: 'ME',
        name: {
          id: 'settingsOptions.countries.Montenegro',
          defaultMessage: 'Montenegro',
        },
      },
      {
        type: 'MA',
        name: {
          id: 'settingsOptions.countries.Morocco',
          defaultMessage: 'Morocco',
        },
      },
      {
        type: 'NL',
        name: {
          id: 'settingsOptions.countries.Netherlands',
          defaultMessage: 'Netherlands',
        },
      },
      {
        type: 'NC',
        name: {
          id: 'settingsOptions.countries.New Caledonia',
          defaultMessage: 'New Caledonia',
        },
      },
      {
        type: 'NZ',
        name: {
          id: 'settingsOptions.countries.New Zealand',
          defaultMessage: 'New Zealand',
        },
      },
      {
        type: 'NI',
        name: {
          id: 'settingsOptions.countries.Nicaragua',
          defaultMessage: 'Nicaragua',
        },
      },
      {
        type: 'NG',
        name: {
          id: 'settingsOptions.countries.Nigeria',
          defaultMessage: 'Nigeria',
        },
      },
      {
        type: 'NO',
        name: {
          id: 'settingsOptions.countries.Norway',
          defaultMessage: 'Norway',
        },
      },
      {
        type: 'PA',
        name: {
          id: 'settingsOptions.countries.Panama',
          defaultMessage: 'Panama',
        },
      },
      {
        type: 'PY',
        name: {
          id: 'settingsOptions.countries.Paraguay',
          defaultMessage: 'Paraguay',
        },
      },
      {
        type: 'PH',
        name: {
          id: 'settingsOptions.countries.Philippines',
          defaultMessage: 'Philippines',
        },
      },
      {
        type: 'QA',
        name: {
          id: 'settingsOptions.countries.Qatar',
          defaultMessage: 'Qatar',
        },
      },
      {
        type: 'SM',
        name: {
          id: 'settingsOptions.countries.San Marino',
          defaultMessage: 'San Marino',
        },
      },
      {
        type: 'SA',
        name: {
          id: 'settingsOptions.countries.Saudi Arabia',
          defaultMessage: 'Saudi Arabia',
        },
      },
      {
        type: 'RS',
        name: {
          id: 'settingsOptions.countries.Serbia',
          defaultMessage: 'Serbia',
        },
      },
      {
        type: 'RU',
        name: {
          id: 'settingsOptions.countries.Russian Federation',
          defaultMessage: 'Russian Federation',
        },
      },
      {
        type: 'SC',
        name: {
          id: 'settingsOptions.countries.Seychelles',
          defaultMessage: 'Seychelles',
        },
      },
      {
        type: 'SI',
        name: {
          id: 'settingsOptions.countries.Slovenia',
          defaultMessage: 'Slovenia',
        },
      },
      {
        type: 'KR',
        name: {
          id: 'settingsOptions.countries.South Korea',
          defaultMessage: 'South Korea',
        },
      },
      {
        type: 'SK',
        name: {
          id: 'settingsOptions.countries.Slovakia (Slovak Republic)',
          defaultMessage: 'Slovakia (Slovak Republic)',
        },
      },
      {
        type: 'CH',
        name: {
          id: 'settingsOptions.countries.Switzerland',
          defaultMessage: 'Switzerland',
        },
      },
      {
        type: 'TH',
        name: {
          id: 'settingsOptions.countries.Thailand',
          defaultMessage: 'Thailand',
        },
      },
      {
        type: 'TN',
        name: {
          id: 'settingsOptions.countries.Tunisia',
          defaultMessage: 'Tunisia',
        },
      },
      {
        type: 'UA',
        name: {
          id: 'settingsOptions.countries.Ukraine',
          defaultMessage: 'Ukraine',
        },
      },
      {
        type: 'AE',
        name: {
          id: 'settingsOptions.countries.United Arab Emirates',
          defaultMessage: 'United Arab Emirates',
        },
      },
      {
        type: 'VE',
        name: {
          id: 'settingsOptions.countries.Venezuela',
          defaultMessage: 'Venezuela',
        },
      },
      {
        type: 'VN',
        name: {
          id: 'settingsOptions.countries.Vietnam',
          defaultMessage: 'Vietnam',
        },
      },
    ],
    //terms for translation purposes
    terms: {
      beta: {
        name: { id: 'terms.side.beta', defaultMessage: 'Beta' },
        url: 'beta.html',
        lastUpdated: '2018-10-16',
      },
      terms: {
        name: { id: 'terms.side.termsOfUse', defaultMessage: 'Terms of Use' },
        url: 'terms.html',
        lastUpdated: '2018-10-16',
      },
      suppTerms: {
        name: { id: 'terms.side.supplementaryTerms', defaultMessage: 'Supplementary Terms' },
        url: 'sup_terms.html',
        lastUpdated: '2020-07-26',
      },
      privacy: {
        name: { id: 'terms.side.privacy', defaultMessage: 'Privacy Policy' },
        url: 'privacy.html',
        lastUpdated: '2018-10-16',
      },
      acceptable: {
        name: { id: 'terms.side.acceptable', defaultMessage: 'Acceptable Use' },
        url: 'acceptable.html',
        lastUpdated: '2018-10-16',
      },
      responsible: {
        name: { id: 'terms.side.responsible', defaultMessage: 'Responsible Gambling' },
        url: 'responsible.html',
        lastUpdated: '2018-10-16',
      },
    },
    //default page sizes used in history bets and history orders
    pageSizes: [10, 25, 50, 100, 250, 500, 750, 1000],
  },

  support: {
    sentry: {
      dsn: 'https://e2ca029396f24558afb6185f3b23a3f0@o592703.ingest.sentry.io/5857313',
    },

    tools: {
      soundManagerDebug: false, //debug disabled for sound manger plugin
      logBuffer: 1000, //how many messages to save in the log buffer

      dontLog: [
        'playSound', //pointless/spammy
        'stopSound', //pointless/spammy
        'getCustomerTransfersResponse', //spammy
        'windowResize', //spammy
        'validationLoopResponse', //might be left over from registration
        'betslipOrderShowHover', //spammy
        'betslipOrderHideHover', //spammy
      ],
    },

    handleOwnErrors: [
      'tickersResponse',
      'createCustomerTransferResponse', //handles own error

      'setCreditLimitResponse', //need to put error in modal
      'setCommRateResponse', //need to put error in modal
      'changeCustomerGroupResponse', //need to put error in modal
      'editCreateCustomerResponse', //need to put error in modal
      'newCustomerTransferResponse', //need to put error in modal

      'retryTransfer', // next 9 are batch transfer management
      'newCustomerTransferResponse', //retry logic
      'newBatchTransfer', //retry logic
      'newBatchBalanceTransfer', //retry logic
      'newCustomerBalanceTransferResponse', //retry logic
      'retryBalanceTransfer', //retry logic
      'setCreditLimitResponse', //retry logic
      'retryCreditTransfer', //retry logic
      'batchCreditTransfer', //retry logic

      'registerResponse', //registration handles it's own problems
      'validateEmailResponse', //email validation handles it's own errors
      'validationLoopResponse', //handles own errors
      'validateRegisterResponse', //own messaging
      'sendDetailsToPartnerResponse', //own messaging
      'resendRegistrationEmailResponse', //own messaging
      'resetPasswordValidateTokenResponse', //validate if the reset token is correct

      'customerTransferActionResponse', //we embrace the failure

      'groupRefreshBookieAccountBalanceResponse', //we embrace the failure as a chance to set balance to unknown
      'customerRefreshBookieAccountBalanceResponse', //we embrace the failure as a chance to set balance to unknown

      'agentGetGroupTagRulesResponse',
      'agentSetGroupTagRulesResponse',
      'kycDocumentsSubmitError',
      'kycDocumentsSubmitResponse',

      'getPtEvents', // agent pt - handle own errors - in agent base
      'loadPtEvents',
      'getPtEventInfo', // agent pt - handle own errors - in agent base
      'loadPtEventInfo',
      'getPtEventInfoSuper', // agent pt - handle own errors - in agent base
      'loadPtEventInfoSuper',
    ],

    recordGA: {
      register: {
        value: '',
        label: [],
      },
      registrationIncrementStep: {
        value: '',
        label: [],
      },
      registrationDecrementStep: {
        value: '',
        label: [],
      },
      sendDetailsToPartner: {
        value: '',
        label: [],
      },
      resendRegistrationEmail: {
        value: '',
        label: [],
      },
      kycDocumentsSubmit: {
        value: '',
        label: [],
      },
      restrictionsSelfExclusionSubmit: {
        value: '',
        label: [],
      },
      restrictionsPreferencesSubmit: {
        value: '',
        label: [],
      },
      openDepositWithdrawForm: {
        value: '',
        label: [],
      },
      closeDepositWithdrawForm: {
        value: '',
        label: [],
      },
      createCustomerTransfer: {
        value: '',
        label: [],
      },
      closeDirectBankingDetails: {
        value: '',
        label: [],
      },
      //fake action in routes.js
      useMobileInterface: {
        value: '',
        label: [],
      },
      useDesktopInterface: {
        value: '',
        label: [],
      },
      toggleMessagesOverlay: {
        value: '',
        label: [],
      },
      acceptAnnouncement: {
        value: 'announcement',
        label: [],
      },
      skipAnnouncements: {
        value: '',
        label: [],
      },
      toggleBetTypesOverlay: {
        value: '',
        label: [],
      },
      toggleFeedbackOverlay: {
        value: '',
        label: [],
      },
      togglePayoutCalc: {
        value: '',
        label: [],
      },
      toggleEventTaggingMode: {
        value: '',
        label: [],
      },
      changeEventTaggingColor: {
        value: '',
        label: ['eventId', 'color'],
      },
      changeEventTagColor: {
        value: 'color',
        label: ['eventId', 'color', 'info'],
      },
      placerPopConfirm: {
        value: '',
        label: [],
        nonInteraction: true,
      },
      placerAcceptConfirm: {
        value: '',
        label: [],
      },
      placerRejectConfirm: {
        value: '',
        label: [],
      },
      toggleTradeSide: {
        value: '',
        label: [],
      },
      marketMainToggle: {
        value: '',
        label: ['marketId'],
      },
      marketSideToggle: {
        value: '',
        label: ['marketId'],
      },
      navigateUserToSonic: {
        value: '',
        label: ['suffix'],
      },
    },
  },

  //MATERIAL UI ONLY
  //for styled components check themes.js and themes folder
  themes: {
    default: {
      palette: {
        type: 'dark',
        primary: {
          main: '#FFFFFF', //this has to contrast the paper color
        },
        secondary: {
          main: '#666666', //this should be visible both against dark and light
        },
        background: {
          paper: '#060606', //background of nav and dialogs
        },
        common: {
          accent: '#7781e9', //accent color applied to highlighted stuff
        },
      },
      typography: {
        subtitle1: {
          color: '#999', //shoud be visible against paper
        },
      },
      overrides: {
        MuiDrawer: {
          paper: {
            backgroundColor: '#333',
          },
        },
        MuiDialog: {
          paper: {
            backgroundColor: '#333',
            padding: 0,
            margin: 0,
          },
        },
        MuiCheckbox: {
          colorSecondary: {
            color: '#666666',
          },
        },
        MuiTableCell: {
          root: {
            padding: '0 .5em',
            borderBottom: '1px solid #EEE',
          },
          body: {
            color: '#222',
          },
          head: {
            backgroundColor: '#222',
          },
        },
        MuiTableRow: {
          head: {
            height: '3em',
          },
        },
        MuiPaper: {
          elevation2: {
            backgroundColor: '#333',
          },
          elevation3: {
            backgroundColor: '#E6E6E8',
          },
        },
      },
    },
    light: {
      palette: {
        type: 'light',
        primary: {
          main: '#222', //this has to contrast the paper color
        },
        secondary: {
          main: '#888888', //this should be visible both against dark and light
        },
        background: {
          paper: '#DDD', //background of nav and dialogs
        },
        common: {
          accent: '#7781e9', //accent color applied to highlighted stuff
        },
      },
      typography: {
        subtitle1: {
          color: '#999', //shoud be visible against paper
        },
      },
      overrides: {
        MuiDrawer: {
          paper: {
            backgroundColor: '#EEE',
          },
        },
        MuiDialog: {
          paper: {
            backgroundColor: '#EEE',
          },
        },
        MuiCheckbox: {
          colorSecondary: {
            color: '#666666',
          },
        },
        MuiTableCell: {
          root: {
            padding: '0 .5em',
            borderBottom: '1px solid #CCC',
          },
          body: {
            color: '#222',
          },
          head: {
            backgroundColor: '#DDD',
          },
        },
        MuiTableRow: {
          head: {
            height: '3em',
          },
        },
        MuiPaper: {
          elevation2: {
            backgroundColor: '#EEE',
          },
          elevation3: {
            backgroundColor: '#EAEAEC',
          },
        },
      },
    },
  },

  timings: {
    //containers
    displayDraggableAnyway: 125, //display placer anyway after, even if no data has loaded
    containerRepositionAfter: 0.5 * 1000, //how long to wait until you reposition the betslip so more accounts are visible

    //finances
    finacesReloadDebounce: 1000, //how long to debounce the reload of finance/banking data for whitelabel users
    paymentsReload: 30 * 1000, //how often to reload the payments

    //trade
    creditUpdate: 10 * 1000, //how long to display credit up down arrows
    midnightPoll: 60 * 1000, //because we have to redo the markets at midnight
    scoreUpdates: {
      default: {
        fillTime: 0, // how long the scores have the background and color applied for
        animationTime: 5000, // how long the animation fades out for
        cooldownPeriod: 6000, // how long before another animation can occur (including animation time)
      },
      cricket: {
        fillTime: 0, // how long the scores have the background and color applied for
        animationTime: 3000, // how long the animation fades out for
        cooldownPeriod: 3000, // how long before another animation can occur (including animation time)
      },
      fb: {
        fillTime: 30000, // how long the scores have the background and color applied for
        animationTime: 5000, // how long the animation fades out for
        cooldownPeriod: 36000, // how long before another animation can occur (including animation time)
      },
    },

    //betbar
    betbarOrderForceRefresh: 1 * 1000, //used to refresh the seconds timer in the betbar orders
    betbarResizingCleanup: 1 * 1000, //this fixes the betbar in case the drag was not ended properly

    //admin/agent
    adminSearchDebounce: 1.25 * 1000, //how much to debounce admin and agent search
    agentTagsPreviewReload: 3 * 1000, //how often to reload the agent tags preview
    timeBetweenBatchCalls: 0.1 * 1000, // the time between batched balance and credit transfers,

    //registration/wl
    registrationValidationFrequency: 3 * 1000, //check registration status
    whitelabelRedirect: 0, //how long to wait before redirecting users to a different whitelabel using redirectTo
  },

  ptConfig: {
    strategies: {
      all: {
        value: 'all',
        name: { id: 'positionConfig.strategy.all', defaultMessage: 'All Strategies' },
      },
      auto: {
        value: 'auto',
        name: { id: 'positionConfig.strategy.auto', defaultMessage: 'Auto' },
      },
      manual: {
        value: 'manual',
        name: { id: 'positionConfig.strategy.manual', defaultMessage: 'Manual' },
      },
    },
  },
};

merge(local, config);

//need to re-apply whitelabel config because the local default might override code configs that came from the whitelabel in the first place
//this is mainly for dev-mode whitelabel to work correctly
merge(local, Whitelabel);

if (window && window.WL_CONFIG) {
  merge(local, window.WL_CONFIG);
  if (window.WL_CONFIG.config.supportedLanguages) {
    local.supportedLanguages = window.WL_CONFIG.config.supportedLanguages;
  }
}

export default local;
