/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  targetUser: '',
  targetGroup: '',
  name: '',
  targetCcy: '',
  error: '',
  errorData: null,
  status: '',
  isLoading: false,
});

const functions = {
  //open set credit limit modal
  openSetCreditLimit: (state, action) => {
    state = state.asMutable();
    state = state.set('targetUser', action.data.targetUser);
    state = state.set('targetGroup', action.data.targetGroup);
    state = state.set('name', action.data.name);
    state = state.set('targetCcy', action.data.targetCcy);
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    return state.asImmutable();
  },

  //close credit limit modal
  closeSetCreditLimit: (state, _action) => {
    state = state.asMutable();
    state = state.set('targetUser', '');
    state = state.set('targetGroup', '');
    state = state.set('name', '');
    state = state.set('targetCcy', '');
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    return state.asImmutable();
  },

  //actual set credit action
  setCreditLimit: (state, action) => {
    const targetUser = state.get('targetUser', '');
    const targetGroup = state.get('targetGroup', '');

    let ccyCode = state.get('targetCcy', '');

    // if setting group credit limit - they can choose currency
    if (action.data.data.currency !== '') {
      ccyCode = action.data.data.currency;
    }

    // if empty string is specified, then assume unlimited credit limit
    const amount = action.data.data.amount.trim() === '' ? null : action.data.data.amount;
    const creditLimit = [ccyCode, amount];

    state = state.asMutable();
    if (targetUser) {
      DSM.create(
        `/v1/customers/${targetUser}/`,
        {
          method: 'PATCH',
          body: {
            creditLimitComment: action.data.data.description,
            creditLimit,
          },
          extras: {
            targetUser,
            creditLimit,
          },
          message: 'setCreditLimitResponse',
        },
        action.data.actions
      );

      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
    } else if (targetGroup) {
      DSM.create(
        `/v1/groups/${targetGroup}/`,
        {
          method: 'PATCH',
          body: {
            creditLimitComment: action.data.data.description,
            creditLimit,
          },
          extras: {
            targetGroup,
            creditLimit,
          },
          message: 'setCreditLimitResponse',
        },
        action.data.actions
      );

      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
    }

    return state.asImmutable();
  },

  //credit limit response
  setCreditLimitResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
