/** @format */

import styled from 'styled-components';
import { SCREEN_BREAK } from '../elements';
import { Button } from '../../interface/Button';
import config from '../../../config';

export const EventActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const EventName = styled.div`
  margin-right: 10px;
`;

export const EventTime = styled.div`
  margin-right: 10px;
  font-size: 12px;
`;

export const EventButton = styled(Button)`
  margin: 0px 5px;
  margin-right: 30px;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    display: none;
  }
`;

export const EventStatusElement = styled.div`
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background: ${({ marketId, theme }) => theme[marketId]};
  margin-right: 10px;
  margin-bottom: 3px;
`;

export const EventSearchResultDisplayElement = styled.div`
  color: #ddd;
  font-size: 15px;
  padding: 5px;
  padding: 8px 0px 8px 20px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  user-select: none;

  &:nth-child(odd) {
    background: #272727;

    &:hover {
      background: #393939;
    }
  }

  &:nth-child(even) {
    background: #2d2d2d;

    &:hover {
      background: #333333;
    }
  }

  &:hover {
    background: #494949;
  }

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    font-size: 12px;
    padding: 8px 0px 8px 14px;
  }
`;
