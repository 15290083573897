/** @format */

import * as Immutable from 'immutable';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { batch } from 'react-redux';

import config from '../config';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { ThunkAction } from 'redux-thunk';

interface BodyStruct {
  page: number;
  pageSize: number;
  operation?: string;
  status?: string;
}

let initialState = Immutable.fromJS({
  isLoading: true,
  transfers: null,

  operation: 'all',
  status: 'all',

  pendingActions: {},
  page: 1,
  pageSize: 50,
});

const ACTION_METHODS = {
  // Cancels an incomplete transfer
  cancel: 'POST',

  // In case of bank transfers, show the payment instruction
  details: 'GET',
};

const buildActionUrl = (paymentId: string, action: string): string =>
  `/cs/payments/${paymentId}/${action}`;

export function requestPaymentDetails(
  paymentId: string
): ThunkAction<void, typeof initialState, unknown, Action<string>> {
  return async function (dispatch: Dispatch): Promise<void> {
    const url = buildActionUrl(paymentId, 'details');
    const dsResp = await DSM.create(url, { method: 'GET', startsPaused: true }).start();
    const dsRespParsed = await dsResp.json();

    // Handle this more gracefully maybe?
    if (dsRespParsed.status === 'error' || dsRespParsed.data == null) return;

    batch(() => {
      // We emmit this action so it changed the direction to `deposit`
      // otherwise it defaults to 'withdraw' and looks wrong in the UI
      dispatch({
        type: 'openDepositWithdrawForm',
        data: { direction: 'deposit' },
      });

      // We pretend this is a response from the request-transfer call.
      // We can treat them the same for now, and given that the deposit dialog
      // is in a weird place, let's not fiddle with it too much
      dispatch({
        type: 'createCustomerTransferResponse',
        data: {
          ...dsRespParsed,
          extras: { paymentId, direct: true, syntehtic: true, action: 'details' },
        },
      });
    });
  };
}

export function closeAndCancelRequest(
  paymentId: string
): ThunkAction<void, typeof initialState, unknown, Action<string>> {
  return async function (dispatch: Dispatch): Promise<void> {
    batch(() => {
      dispatch({ type: 'closeDirectBankingDetails' });
      dispatch({
        type: 'customerTransferAction',
        data: {
          paymentId,
          action: 'cancel',
          extras: { paymentId, syntehtic: true, action: 'cancel' },
          // Lol…
          // $FlowFixMe
          actions: bindActionCreators(
            {
              customerTransferActionResponse: actions['customerTransferActionResponse'],
            },
            dispatch
          ),
        },
      });
    });
  };
}

const functions = {
  getCustomerTransfers: (state: typeof initialState, action): boolean => {
    let body = {
      page: state.get('page'),
      pageSize: state.get('pageSize'),
    };

    if (state.get('operation') !== 'all') {
      body['operation'] = state.get('operation');
    }

    if (state.get('status') !== 'all') {
      body['status'] = state.get('status');
    }

    DSM.ensureOne(
      '/cs/payments/',
      {
        method: 'GET',
        message: 'getCustomerTransfersResponse',
        interval: config.timings.paymentsReload,
        body,
      },
      action.data.actions,
      'paymentsStream'
    );

    return state.set('isLoading', true);
  },

  getCustomerTransfersResponse: (state: typeof initialState, action): boolean => {
    if (action.data.status === 'ok') {
      state = state.set('transfers', Immutable.fromJS(action.data.data));
    }

    return state.set('isLoading', false);
  },

  /**
   * @deprecated
   */
  customerTransferAction: (state: typeof initialState, action): typeof initialState => {
    const actionUrl = buildActionUrl(action.data.paymentId, action.data.action);
    const method = ACTION_METHODS[action.data.action];

    if (!method) {
      console.error(
        `Unknown cs/payments action '${action.data.action}'. This action won't be triggered.`
      );
      return state;
    }

    if (action.data.action) {
      DSM.create(
        actionUrl,
        {
          method,
          body: action.data.body,
          extras: {
            paymentId: action.data.paymentId,
            action: action.data.action,
          },
          message: 'customerTransferActionResponse',
          errorMessage: 'customerTransferActionResponse',
        },
        action.data.actions
      );

      state = state.setIn(['pendingActions', action.data.paymentId], true);
    }

    return state;
  },

  disconnectPaymentsStream: (state: typeof initialState): typeof initialState => {
    DSM.stop('paymentsStream');
    return state;
  },

  customerTransferActionResponse: (state: typeof initialState, action): typeof initialState => {
    DSM.forceIntervalRequest('paymentsStream', config.timings.finacesReloadDebounce);
    return state.setIn(['pendingActions', action.data.extras.paymentId], false);
  },

  closeDepositWithdrawForm: (state: typeof initialState): typeof initialState => {
    DSM.forceIntervalRequest('paymentsStream', config.timings.finacesReloadDebounce);
    return state;
  },

  closeDirectBankingDetails: (state: typeof initialState): typeof initialState => {
    DSM.forceIntervalRequest('paymentsStream', config.timings.finacesReloadDebounce);
    return state;
  },

  updateBankingPageSize: (state: typeof initialState, action): typeof initialState => {
    return state.set('pageSize', action.data);
  },

  updateBankingPage: (state: typeof initialState, action): typeof initialState => {
    return state.set('page', action.data);
  },

  updateBankingStatus: (state: typeof initialState, action): typeof initialState => {
    return state.set('status', action.data);
  },

  updateBankingOperation: (state: typeof initialState, action): typeof initialState => {
    return state.set('operation', action.data);
  },
};

export default function reducer(
  state: typeof initialState = initialState,
  action
): typeof initialState {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
