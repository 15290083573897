/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import config from '../../config';

import { fromJS } from 'immutable';
let initialState = fromJS({
  editCreateUsersError: '',
  editCreateUsersData: null,
  editCreateUsersStatus: '',
  editCreateUserLoading: false,

  changeGroupError: '',
  changeGroupErrorData: null,
  changeGroupStatus: '',
  changeGroupLoading: false,

  isLoadingCustomerToEdit: false,
});

const functions = {
  //attempts to unlock a customer
  unlockCustomer: (state, action) => {
    if (action.data.target) {
      DSM.create(
        `/v1/customers/${action.data.target}/`,
        {
          method: 'PATCH',
          body: {
            locked: false,
          },
          extras: {
            target: action.data.target,
          },
          message: 'unlockCustomerResponse',
        },
        action.data.actions
      );
    }

    return state;
  },

  //response to customer unlock
  unlockCustomerResponse: (state, _action) => {
    //handled by agents base
    return state;
  },

  //resets terms and conditions
  //this is done indirectly via user-prefs so we need to get userprefs first
  resetTermsAndConditions: (state, action) => {
    if (action.data.target) {
      DSM.create(
        `/web/preferences/${action.data.target}/customersettings/`,
        {
          method: 'PUT',
          body: { terms: {} },
          extras: {
            target: action.data.target,
          },
          message: 'resetTermsAndConditionsResponse',
        },
        action.data.actions
      );
    }

    return state;
  },

  //react to the successful reset of user t&c
  resetTermsAndConditionsResponse: (state, action) => {
    if (window) {
      if (action.data.status === 'ok') {
        window.alert(`Terms reset for ${action.data.extras.target}`);
      } else {
        window.alert(`Error terms reset for ${action.data.extras.target}`);
      }
    }

    return state;
  },

  //used to enable all selected customers
  //[UNUSED / EXPERIMENTAL]
  toggleAllCustomers: (state, action) => {
    if (action.data.targets && action.data.targets.length) {
      for (let idx in action.data.targets) {
        let target = action.data.targets[idx];
        setTimeout(() => {
          DSM.create(
            `/v1/customers/${target}/`,
            {
              method: 'PATCH',
              body: {
                config: {
                  login: action.data.to,
                },
              },
              extras: {
                target,
                active: action.data.to,
              },
              message: 'toggleAllCustomersResponse',
            },
            action.data.actions
          );
        }, idx * config.timings.timeBetweenBatchCalls);
      }
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
