/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../reducers/app';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';

import routes from '../routes';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import { SettingsContext } from './shared/SettingsContext';

import { PositiveButton, Dialog, DialogContent, DialogActions } from './interface';

class Announcements extends React.PureComponent {
  static contextType = SettingsContext;

  acceptAnnouncement = () => {
    this.props.actions.acceptAnnouncement();
    Telemetry.recordGA('user_action', `acceptAnnoucement`, `acceptAnnoucement`);
  };

  handleNavigation = () => {
    let suffix = '';
    if(this.props.currentAnnouncement === 'greyhound') {
      suffix = '/greyhound';
    } else if(this.props.currentAnnouncement === 'politics') {
      suffix = '/politics'
    }
    Telemetry.recordGA('user_action', `navigateFromAnnouncement`, `location=${suffix}`);

    this.props.actions.navigateUserToSonic({ suffix });
  }

  render() {
    return this.props.currentAnnouncement ? (
      <Dialog className="announcement" open={!!this.props.currentAnnouncement}>
        <DialogContent style={{ padding: 0, overflowY: 'auto' }} onClick={this.handleNavigation}>
          <iframe
            style={{
              display: 'block',
              width: '100%',
              minHeight: '450px',
              minWidth: '700px',
              border: 'none',
              pointerEvents: 'none'
            }}
            title={this.props.currentAnnouncement}
            src={`${routes.basename}/wl/${Whitelabel.id}/announcements/${this.context.language}/${
              Whitelabel.announcements[this.props.currentAnnouncement].url
            }`}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <PositiveButton onClick={this.acceptAnnouncement}>
            <FormattedMessage id="announcement.confirm" defaultMessage="I Understand" />
          </PositiveButton>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => ({
  currentAnnouncement: state.getIn(['ui', 'currentAnnouncement'], '')
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
