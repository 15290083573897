/** @format */

import { Tooltip } from '@mollybet/ui';
import React, { useContext } from 'react';
import { SettingsContext, SettingsContextType } from '../shared/SettingsContext';
import { EmptyPropsType, DemoWrapperPropsType, ConditionalDemoWrapperPropsType } from './types';

const NULL_FUNCTION = () => {};

/**
 * This tries to override all element props that might do unwanted stuff, for example href linking
 * to a disabled page or onClick opening a disabled modal.
 */
export const getPropOverrides = (element: React.ReactElement<any>): React.ReactElement<any> => {
  const overrides: typeof element.props = { ...element.props };

  // Remove the props that do things on elements
  if (element.props.href) overrides.href = undefined;
  if (element.props.to) overrides.to = undefined;
  if (element.props.title) overrides.title = undefined;
  if (element.props.onChange) overrides.onChange = NULL_FUNCTION;
  if (element.props.onMouseDown) overrides.onMouseDown = NULL_FUNCTION;
  if (element.props.onMouseUp) overrides.onMouseUp = NULL_FUNCTION;
  if (element.props.onPointerDown) overrides.onPointerDown = NULL_FUNCTION;
  if (element.props.onPointerUp) overrides.onPointerUp = NULL_FUNCTION;
  if (element.props.onClick) overrides.onClick = NULL_FUNCTION;

  overrides.style = { ...(element.props.style || {}), opacity: 0.5 };

  return overrides;
};

/**
 * This element wraps another element and conditionaly disables it if the ui is in demo mode,
 * it shouldnt cause any change in dom structure.
 */
export const DemoWrapper = <DefaultWrapperProps extends EmptyPropsType = EmptyPropsType>({
  message = 'Disabled in demo',
  DefaultWrapper,
  defaultWrapperProps = {} as DefaultWrapperProps,
  children,
}: React.PropsWithChildren<DemoWrapperPropsType<DefaultWrapperProps>>): React.ReactElement<
  any,
  any
> | null => {
  const context = useContext<SettingsContextType>(SettingsContext);

  if (!context.isDemo)
    return DefaultWrapper ? (
      <DefaultWrapper {...defaultWrapperProps}>{children}</DefaultWrapper>
    ) : (
      <>{children}</>
    );

  const modifiedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child))
      return DefaultWrapper ? (
        <DefaultWrapper {...defaultWrapperProps}>{child}</DefaultWrapper>
      ) : (
        <>{child}</>
      );

    return (
      <Tooltip content={message}>{React.cloneElement(child, getPropOverrides(child))}</Tooltip>
    );
  });

  return <>{modifiedChildren}</>;
};

/**
 * This element wraps another element if wrap prop is true and conditionaly disables it if the
 * ui is in demo mode, it shouldnt cause any change in dom structure.
 */
export const ConditionalDemoWrapper = <
  DefaultWrapperProps extends EmptyPropsType = EmptyPropsType
>({
  message = 'Disabled in demo',
  wrap = false,
  DefaultWrapper,
  defaultWrapperProps = {} as DefaultWrapperProps,
  children,
}: React.PropsWithChildren<
  ConditionalDemoWrapperPropsType<DefaultWrapperProps>
>): React.ReactElement<any, any> | null =>
  wrap ? (
    <DemoWrapper
      message={message}
      DefaultWrapper={DefaultWrapper}
      defaultWrapperProps={defaultWrapperProps}
    >
      {children}
    </DemoWrapper>
  ) : DefaultWrapper ? (
    <DefaultWrapper {...defaultWrapperProps}>{children}</DefaultWrapper>
  ) : (
    <>{children}</>
  );
