/** @format */

// @flow

import React from 'react';
import styled, { type StyledComponent } from 'styled-components';
import { Icon as Icn } from '@mdi/react';

import { type Theme } from '@mollybet/ui/dist/theme';

const Icon2: StyledComponent<
  { button?: boolean },
  React.ElementConfig<typeof Icn>,
  Theme,
  *
> = styled(Icn).attrs((props) => ({
  ...props,
  title: null,
  color: props.color || props.theme.primary,
}))`
  ${(props) =>
    props.button
      ? `&:hover {
      background-color: rgba(128, 128, 128, .25);
      border-radius: 12px;
      cursor: pointer;
    }`
      : ''}
  ${(props) => props.warning && 'border-bottom: 2px solid yellow;'}
`;

// Extract unnecessary props by deconstructing them
export const Icon = ({ sideIsOpen, isExpanded, ...props }) =>
  props.title ? (
    <span title={props.title}>
      <Icon2 {...props} />
    </span>
  ) : (
    <Icon2 {...props} />
  );
