/**
 * This file combines the reducers...
 * you may or may not need/want to do this since each view will probably have its own reducer
 *
 * @format
 */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import config from '../../config';

import { fromJS } from 'immutable';
let initialState = fromJS({
  statements: [],
  statement: null,
  isLoading: true,
  isLoadingStatement: false,
});

const functions = {
  //get statement list
  getStatements: (state, action) => {
    if (!action.data.currentUser) {
      return state;
    }

    DSM.last(
      `/v1/customers/${action.data.currentUser}/statements/`,
      {
        method: 'GET',
        message: 'loadStatements',
        body: {},
      },
      action.data.actions,
      'getStatements'
    );

    return state.set('isLoading', true);
  },

  //response of stement list request
  loadStatements: (state, action) => {
    if (action.data.status === 'ok') {
      return state.mergeDeep({
        isLoading: false,
        statements: action.data.data,
      });
    } else {
      //handled by base
      return state;
    }
  },

  //make request for an individual statement
  statementDetails: (state, action) => {
    if (!action.data.currentUser) {
      return state;
    }

    DSM.last(
      `/v1/customers/${action.data.currentUser}/statements/${action.data.accountCode}/`,
      {
        method: 'GET',
        message: 'loadStatementDetails',
        body: {
          page: action.data.page || 1,
          pageSize: action.data.pageSize || config.pageSizes.statementMovements,
        },
      },
      action.data.actions,
      'statementDetails'
    );

    state = state.setIn('statement', null);
    return state.setIn(['isLoadingStatement'], true);
  },

  //response to individual statement details
  loadStatementDetails: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('isLoadingStatement', false);
      return state.set('statement', fromJS(action.data.data));
    } else {
      //handled by base
      return state;
    }
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};

for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
