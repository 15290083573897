/** @format */

import { actions } from '../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../config';
import { IconButton } from '@mollybet/ui';

import React from 'react';
import { injectIntl } from 'react-intl';
import { SettingsContext } from '../../components/shared/SettingsContext';
import styled, { withTheme } from 'styled-components';

import { Paper, Icon } from '../../components/interface';

import { mdiBell } from '@mdi/js';
import { DemoWrapper } from '../demo';

const MessagesOverlay = styled(Paper)`
  padding: 5px;
  padding-left: 0px;
  right: 10em;
  top: 5em;
  position: fixed;
  border-radius: 0.25em;
  width: 30em;
  font-size: 12px;
`;

const ExtraProfileButtons = styled.div`
  z-index: 10;
  height: ${config.nav.styles.wide.height};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: ${config.nav.styles.narrow.height};
  }
`;

export const IconButtonElement = styled(IconButton)`
  height: 41px;
  //height: ${config.nav.styles.wide.height};
  box-sizing: border-box;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: 30px;
    //height: ${config.nav.styles.narrow.height};
  }
`;

const NotifCount = styled.span`
  position: absolute;
  bottom: -3px;
  right: 0;
  background-color: ${(props) => props.theme.error};
  color: ${(props) => props.theme.colours.white};
  border-radius: 12px;
  font-weight: bold;
  width: 1em;
  height: 1em;
  padding: 3px;
  text-align: center;

  &.should-blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
`;

class ExtraNavButtons extends React.PureComponent {
  static contextType = SettingsContext;

  toggleFeedbackOverlay = () => {
    this.props.actions.toggleFeedbackOverlay();
  };

  toggleMessagesOverlay = () => {
    this.props.actions.toggleMessagesOverlay();
  };

  render() {
    let msgList = [];
    let unseenMessageCount = 0;
    if (this.props.messages && this.props.messages.size) {
      this.props.messages.forEach((msg) => {
        let level = 'info';

        msgList.push({
          id: msg.get('id', '?'),
          level,
          body: msg.get('message', '?'),
        });

        if (
          this.props.seenMessages &&
          !this.props.seenMessages.has('' + msg.get('id', ''), false)
        ) {
          unseenMessageCount++;
        }
      });
    }

    if (unseenMessageCount) {
      this.toggleMessagesOverlay();
    }

    return (
      <>
        <ExtraProfileButtons>
          {this.context.navLayout !== 'narrow' && msgList.length ? (
            <>
              <DemoWrapper>
                <IconButtonElement onClick={this.toggleMessagesOverlay}>
                  <Icon
                    button="true"
                    width={22}
                    height={22}
                    path={mdiBell}
                    color={this.props.theme.colours.white}
                  />
                </IconButtonElement>
              </DemoWrapper>
              {unseenMessageCount ? (
                <NotifCount className="should-blink">{unseenMessageCount}</NotifCount>
              ) : null}
            </>
          ) : null}
        </ExtraProfileButtons>
        {this.props.isMessagesOverlayOpen && this.context.navLayout !== 'narrow' && (
          <MessagesOverlay elevation={4}>
            <ul>
              {msgList.map((el, index) => (
                <li key={index}>
                  <span className={'message-body ' + el.level}>{el.body}</span>
                </li>
              ))}
            </ul>
          </MessagesOverlay>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isMessagesOverlayOpen: state.getIn(['ui', 'isMessagesOverlayOpen'], false),
  messages: state.getIn(['ui', 'tickerMessages'], null),
  seenMessages: state.getIn(['ui', 'settings', 'general', 'seenTickers'], null),
  whitelabelUser: state.getIn(['base', 'switches', 'whitelabelUser'], false),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(injectIntl(ExtraNavButtons)));
