/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  accounts: [],
  isLoading: true,
  isSaving: false,
  isSaved: false,
  isRefreshing: {},
});

const functions = {
  ////// INIT
  //the login response also contains a lit of the bookie accounts
  //so we try to get it from there as well
  loginResponse: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.data.bookieAccounts) {
        state = state.set('accounts', fromJS(action.data.data.bookieAccounts));
      }
      return state.set('isLoading', false);
    } else {
      //handled by base
      return state;
    }
  },

  ////// BOOKIE ACCOUNTS

  //get the bookie accounts for the current user
  getBookieAccounts: (state, action) => {
    DSM.last(
      `/v1/customers/${action.data.currentUser}/bookie_accounts/`,
      {
        method: 'GET',
        message: 'handleBookieAccounts',
      },
      action.data.actions,
      'getBookieAccounts'
    );

    state = state.set('accounts', fromJS([]));
    state = state.set('isSaving', false);
    return state.set('isLoading', true);
  },

  //handle the bookie account data
  handleBookieAccounts: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('accounts', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //handled by base
      return state;
    }
  },

  //set what bookie accounts are enabled
  //if they are missing from the array they will be disabled so you have to be careful to send everything
  setBookieAccountsEnabled: (state, action) => {
    DSM.last(
      `/v1/customers/${action.data.currentUser}/bookie_accounts/enable_accounts/`,
      {
        method: 'PUT',
        message: 'setBookieAccountsEnabledResponse',
        body: {
          accounts: action.data.accounts,
        },
      },
      action.data.actions,
      'setBookieAccountsEnabled'
    );

    //have to do this pre-emptively because of how settings/bookies works (or doesn't)
    // state.get('accounts').forEach((account, index) => {
    //   state = state.setIn(
    //     ['accounts', index, 'enabled'],
    //     action.data.accountIds.indexOf(account.get('id')) !== -1
    //   );
    // });

    state = state.set('isSaving', true);
    return state.set('isSaved', false);
  },

  //confirm that the action was successful
  setBookieAccountsEnabledResponse: (state, _action) => {
    state = state.set('isSaving', false);
    return state.set('isSaved', true);
  },

  //set what bookie accounts are enabled
  //if they are missing from the array they will be disabled so you have to be careful to send everything
  setBookiesEnabled: (state, action) => {
    DSM.last(
      `/v1/customers/${action.data.currentUser}/bookie_accounts/enable_bookies/`,
      {
        method: 'PUT',
        message: 'setBookiesEnabledResponse',
        body: {
          bookies: action.data.bookies,
        },
      },
      action.data.actions,
      'setBookiesEnabled'
    );
    //have to do this pre-emptively because of how settings/bookies works (or doesn't)
    // state.get('accounts').forEach((account, index) => {
    //   state = state.setIn(
    //     ['accounts', index, 'enabled'],
    //     action.data.accountIds.indexOf(account.get('id')) !== -1
    //   );
    // });

    state = state.set('isSaving', true);
    return state.set('isSaved', false);
  },

  //confirm that the action was successful
  setBookiesEnabledResponse: (state, _action) => {
    state = state.set('isSaving', false);
    return state.set('isSaved', true);
  },

  //refresh the account balance for a particular account
  customerRefreshBookieAccountBalance: (state, action) => {
    DSM.create(
      `/v1/customers/${action.data.username}/bookie_accounts/kick_balance/`,
      {
        method: 'POST',
        body: {
          accountId: action.data.accountId,
        },
        extras: {
          accountId: action.data.accountId,
        },
        message: 'customerRefreshBookieAccountBalanceResponse',
      },
      action.data.actions
    );

    return state.setIn(['isRefreshing', action.data.accountId], true);
  },

  //update balance with result
  customerRefreshBookieAccountBalanceResponse: (state, action) => {
    let balance = '';
    if (action.data.status === 'ok') {
      balance = action.data.data.balance;
    }

    let accounts = state.getIn(['accounts']).toJS();
    //sadly we have to look for the account in an inefficient way
    for (let i in accounts) {
      if (accounts[i].id === action.data.extras.accountId) {
        accounts[i].bookieBalance = balance;
      }
    }

    state = state.set('accounts', fromJS(accounts));
    return state.setIn(['isRefreshing', action.data.extras.accountId], false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
