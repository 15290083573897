/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  isLoading: true,
  isDownloading: false,
  isDownloadFailed: false,
  transfers: null,
});

const functions = {
  //get customer transfers from agent pov
  agentGetCustomerTransfers: (state, action) => {
    let headers = null;
    if (action.data.format) {
      headers = {};
      if (action.data.format === 'csv') {
        headers['Accept'] = 'text/csv';
      } else if (action.data.format === 'xls') {
        headers['Accept'] = 'application/vnd.ms-excel';
      }
    }

    DSM.last(
      `/v1/transfers/`,
      {
        method: 'GET',
        headers,
        downloadAs: action.data.format
          ? `${action.data.username}-transfers.${action.data.format}`
          : '',
        message: 'agentGetCustomerTransfersResponse',
        body: {
          username: action.data.username,
        },
        extras: {
          format: action.data.format,
        },
      },
      action.data.actions,
      'agentGetCustomerTransfers'
    );

    if (!action.data.format) {
      state = state.set('isLoading', true);
    } else {
      state = state.set('isDownloading', action.data.format);
    }

    return state;
  },

  //react to the customer transfers data
  agentGetCustomerTransfersResponse: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }

      state = state.set('transfers', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }

      //error handled by base
      return state;
    }
  },

  //reload when a new transfer happens
  newCustomerTransferResponse: (state, action) => {
    if (action.data.status === 'ok' && action.data.extras.targetUser) {
      DSM.last(
        `/v1/transfers/`,
        {
          method: 'GET',
          message: 'agentGetCustomerTransfersResponse',
          body: {
            username: action.data.extras.targetUser,
          },
        },
        action.data.actions,
        'newCustomerTransferResponse'
      );

      return state.set('isLoading', true);
    }

    return state;
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
