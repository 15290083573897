/** @format */

import styled from 'styled-components';

export const COMPETITIONS_RESULTS_MIN_DISPLAY_WIDTH = 450;
export const EVENTS_RESULTS_MIN_DISPLAY_WIDTH = 800;
export const SCREEN_BREAK =
  COMPETITIONS_RESULTS_MIN_DISPLAY_WIDTH + EVENTS_RESULTS_MIN_DISPLAY_WIDTH;

export const FlexContainerElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FavouriteContainer = styled.div`
  margin-right: 14px;
`;
