/** @format */

import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../reducers/app';

import { injectIntl, FormattedMessage } from 'react-intl';
import config from '../config';
import { SettingsContext } from './shared/SettingsContext';
import TranslatedBetType from './shared/TranslatedBetType';
import {
  formatPriceToDecimal,
  formatPrice,
  formatAmount,
} from '@mollybet/frontend-common/dist/lib/formatters';
import currencies from '@mollybet/frontend-common/dist/lib/currencies';
import styled, { withTheme } from 'styled-components';

import {
  Icon,
  Button,
  NegativeButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from './interface';

import { TextField } from '@mollybet/ui';

import { mdiClose } from '@mdi/js';

const PriceCalculatorEntries = styled.div`
  display: flex;
  flex-direction: column;

  .entries {
    margin-bottom: 12px;
  }
`;

const Entry = styled.div`
  margin-bottom: 0.5em;

  &.header {
    border-bottom: 0.15em solid ${(props) => props.theme.separator};
    font-weight: bold;
    margin-bottom: 12px;
    padding-bottom: 0.5em;
  }

  .entry-remove {
    width: 5em;
    vertical-align: top;
    display: inline-block;
  }

  .entry-name {
    width: 30em;
    text-align: left;
    vertical-align: bottom;
    margin-right: 12px;
    display: inline-block;
  }

  .entry-price {
    width: 10em;
    display: inline-block;

    > div {
      width: 100%;
      vertical-align: initial;
    }

    input,
    label {
      color: ${(props) => props.theme.foreground};
    }

    fieldset {
      border-color: ${(props) => props.theme.foreground};
    }
  }

  .entry-status {
    .win,
    .half-win,
    .half-loss,
    .loss,
    .void {
      width: 5em;
      text-align: center;
      display: inline-block;
    }
  }
`;

const PayoutTotals = styled.div`
  margin: 12px auto 12px auto;

  .stake-input {
    > div {
      width: 10em;
      vertical-align: bottom;
    }

    input,
    label {
      color: ${(props) => props.theme.foreground};
    }

    fieldset {
      border-color: ${(props) => props.theme.foreground};
    }
  }

  .times,
  .equals {
    padding: 0 1em;
    font-size: 1.5em;
  }

  .total-price,
  .total-payout {
    font-size: 1.5em;

    .total-pl {
      margin-left: 2em;

      .pl {
        margin-left: 0.5em;

        &.negative {
          color: ${(props) => props.theme.negative};
        }

        &.positive {
          color: ${(props) => props.theme.positive};
        }
      }
    }
  }
`;

class PayoutCalc extends React.Component {
  static contextType = SettingsContext;

  state = {
    stake: '',
    entries: {},
  };

  togglePayoutCalc = () => {
    this.props.actions.togglePayoutCalc();
  };

  addEntry = () => {
    let entries = { ...this.state.entries };
    entries[+new Date() + ''] = {
      price: '',
      status: 'w',
    };
    this.setState({ entries });
  };

  removeEntry = (id) => () => {
    let entries = { ...this.state.entries };
    delete entries[id];
    this.setState({ entries });
  };

  changeEntryPrice = (id) => (event) => {
    let entries = { ...this.state.entries };
    entries[id].price = event.target.value;
    this.setState({ entries });
  };

  changeEntryOutcome = (id, to) => (_event) => {
    let entries = { ...this.state.entries };
    entries[id].status = to;
    this.setState({ entries });
  };

  changeStake = (event) => {
    this.setState({ stake: event.target.value });
  };

  calcCummPrice = () => {
    let cummPrice = 1;
    for (let entryId in this.state.entries) {
      let entry = this.state.entries[entryId];
      if (entry.status === 'v') {
        continue;
      }

      let _price = formatPriceToDecimal(entry.price, this.context.priceType);

      if (entry.status === 'v/w') {
        _price = (_price + 1) / 2;
      } else if (entry.status === 'l/v') {
        _price = 0.5;
      } else if (entry.status === 'l') {
        _price = 0;
      }

      cummPrice *= _price;
    }

    return cummPrice;
  };

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.isPayoutCalcOpen !== this.props.isPayoutCalcOpen) {
      if (this.props.isPayoutCalcOpen) {
        if (this.props.targetOrder) {
          let entries = this.props.targetOrder.getIn(['accasInfo', 'entries'], null);
          if (entries) {
            let _entries = [];
            entries
              .sortBy((e) => e.get('startTime', 0))
              .forEach((entry) => {
                _entries.push({
                  name: (
                    <span>
                      {entry.get('homeName')} &mdash; {entry.get('awayName')}
                    </span>
                  ),
                  betTypeDesc: (
                    <TranslatedBetType
                      betTypeDescription={entry.get('betTypeDesc')}
                      home={entry.get('homeName')}
                      away={entry.get('awayName')}
                      betType={entry.get('betType', '')}
                    />
                  ),
                  compName: entry.get('compName'),
                  sport: entry.get('sport'),
                  price: formatPrice(entry.get('gotPrice', 1), this.context.priceType),
                  status: entry.get('result') || 'w',
                  locked: !!entry.get('result'),
                });
              });
            this.setState({
              stake: formatAmount(
                this.props.targetOrder.getIn(['stake', '1'], ''),
                this.props.targetOrder.getIn(['stake', '0'], ''),
                this.context.displayCcy,
                this.context.xrates,
                false,
                true
              ),
              entries: _entries,
            });
          }
        }
      } else {
        this.setState({
          stake: '',
          entries: {},
        });
      }
    }
  }

  render() {
    let entries = [];
    for (let entryId in this.state.entries) {
      entries.push(
        <Entry>
          {!this.props.targetOrder && (
            <span className="entry-remove">
              <Icon
                width={24}
                height={24}
                path={mdiClose}
                button="true"
                onClick={this.removeEntry(entryId)}
              />
            </span>
          )}
          {this.props.targetOrder && (
            <span className="entry-name">
              <span title={this.state.entries[entryId].compName}>
                {this.state.entries[entryId].name}
              </span>
              <br />
              {this.props.intl.formatMessage(
                config.sportNames[this.state.entries[entryId].sport].name
              )}
              <br />
              {this.state.entries[entryId].betTypeDesc}
            </span>
          )}
          <span className="entry-price">
            <TextField
              disabled={this.props.targetOrder}
              value={this.state.entries[entryId].price}
              label={<FormattedMessage id="payoutCalc.price" defaultMessage="Price" />}
              type="text"
              shrinked={true}
              onChange={this.changeEntryPrice(entryId)}
            />
          </span>
          <span className="entry-status">
            <span className="win">
              {(!this.state.entries[entryId].locked ||
                this.state.entries[entryId].status === 'w') && (
                <Checkbox
                  color={
                    this.state.entries[entryId].locked
                      ? this.props.theme.separator
                      : this.props.theme.paper.fontColor[1]
                  }
                  checked={this.state.entries[entryId].status === 'w'}
                  disabled={this.state.entries[entryId].locked}
                  onClick={this.changeEntryOutcome(entryId, 'w')}
                />
              )}
            </span>
            <span className="half-win">
              {(!this.state.entries[entryId].locked ||
                this.state.entries[entryId].status === 'v/w') && (
                <Checkbox
                  color={
                    this.state.entries[entryId].locked
                      ? this.props.theme.separator
                      : this.props.theme.paper.fontColor[1]
                  }
                  checked={this.state.entries[entryId].status === 'v/w'}
                  disabled={this.state.entries[entryId].locked}
                  onClick={this.changeEntryOutcome(entryId, 'v/w')}
                />
              )}
            </span>
            <span className="half-loss">
              {(!this.state.entries[entryId].locked ||
                this.state.entries[entryId].status === 'l/v') && (
                <Checkbox
                  color={
                    this.state.entries[entryId].locked
                      ? this.props.theme.separator
                      : this.props.theme.paper.fontColor[1]
                  }
                  checked={this.state.entries[entryId].status === 'l/v'}
                  disabled={this.state.entries[entryId].locked}
                  onClick={this.changeEntryOutcome(entryId, 'l/v')}
                />
              )}
            </span>
            <span className="loss">
              {(!this.state.entries[entryId].locked ||
                this.state.entries[entryId].status === 'l') && (
                <Checkbox
                  color={
                    this.state.entries[entryId].locked
                      ? this.props.theme.separator
                      : this.props.theme.paper.fontColor[1]
                  }
                  checked={this.state.entries[entryId].status === 'l'}
                  disabled={this.state.entries[entryId].locked}
                  onClick={this.changeEntryOutcome(entryId, 'l')}
                />
              )}
            </span>
            <span className="void">
              {(!this.state.entries[entryId].locked ||
                this.state.entries[entryId].status === 'v') && (
                <Checkbox
                  color={
                    this.state.entries[entryId].locked
                      ? this.props.theme.separator
                      : this.props.theme.paper.fontColor[1]
                  }
                  checked={this.state.entries[entryId].status === 'v'}
                  disabled={this.state.entries[entryId].locked}
                  onClick={this.changeEntryOutcome(entryId, 'v')}
                />
              )}
            </span>
          </span>
        </Entry>
      );
    }

    let orderPL = this.props.targetOrder ? this.props.targetOrder.get('profitLoss', null) : null;
    let valPL = orderPL ? orderPL.get('1', 0) : 0;

    return (
      <Dialog maxWidth="md" fullWidth={false} open={this.props.isPayoutCalcOpen}>
        <DialogTitle>
          {this.props.targetOrder ? (
            <FormattedMessage
              id="payoutCalc.accumulatorSummary"
              defaultMessage="Accumulator Summary"
            />
          ) : (
            <FormattedMessage id="payoutCalc.payoutCalculator" defaultMessage="Payout Calculator" />
          )}
          &nbsp;
          {this.props.targetOrder ? (
            <span>[{this.props.targetOrder.get('orderId', '?')}]</span>
          ) : null}
        </DialogTitle>
        <DialogContent style={{ maxWidth: 'unset' }}>
          <PriceCalculatorEntries>
            <div className="entries">
              {Object.keys(this.state.entries).length ? (
                <Entry className="header">
                  {!this.props.targetOrder && <span className="entry-remove"></span>}
                  {this.props.targetOrder && (
                    <span className="entry-name">
                      <FormattedMessage id="payoutCalc.description" defaultMessage="Description" />
                    </span>
                  )}
                  <span className="entry-price">
                    <FormattedMessage id="payoutCalc.price" defaultMessage="Price" />
                  </span>
                  <span className="entry-status">
                    <span className="win">
                      <FormattedMessage id="payoutCalc.win" defaultMessage="Win" />
                    </span>
                    <span className="half-win">
                      <FormattedMessage id="payoutCalc.halfWin" defaultMessage="Half-Win" />
                    </span>
                    <span className="half-loss">
                      <FormattedMessage id="payoutCalc.halfLoss" defaultMessage="Half-Loss" />
                    </span>
                    <span className="loss">
                      <FormattedMessage id="payoutCalc.loss" defaultMessage="Loss" />
                    </span>
                    <span className="void">
                      <FormattedMessage id="payoutCalc.void" defaultMessage="Void" />
                    </span>
                  </span>
                </Entry>
              ) : null}
              {entries}
            </div>
            {!this.props.targetOrder && (
              <div className="entry-adder">
                <Button onClick={this.addEntry}>
                  <FormattedMessage id="payoutCalc.add" defaultMessage="Add" />
                </Button>
              </div>
            )}
            <PayoutTotals>
              <span className="stake-input">
                <TextField
                  disabled={this.props.targetOrder}
                  value={this.state.stake + ''}
                  type="text"
                  shrinked={true}
                  label={
                    <FormattedMessage
                      id="payoutCalc.stake"
                      defaultMessage="Stake ({ccy})"
                      values={{
                        ccy: this.context?.displayCcy
                          ? currencies[this.context?.displayCcy]?.symbol
                          : '?',
                      }}
                    />
                  }
                  onChange={this.changeStake}
                />
              </span>
              <span className="times">@</span>
              <span className="total-price">
                {formatPrice(
                  this.props.targetOrder
                    ? this.props.targetOrder.get('price', 1)
                    : this.calcCummPrice(),
                  this.context.priceType,
                  this.props.targetOrder
                    ? this.props.targetOrder.get('orderType', '') === 'lay'
                    : ''
                )}
              </span>
              <span className="equals">&#61;</span>
              <span className="total-payout">
                {formatAmount(this.state.stake * this.calcCummPrice(), this.context.displayCcy)}
                {orderPL && (
                  <span className="total-pl">
                    [
                    <FormattedMessage id="payoutCalc.profitLoss" defaultMessage="P/L" />
                    &#8776;
                    <span className={`pl ${valPL < 0 ? 'negative' : valPL > 0 ? 'positive' : ''}`}>
                      {formatAmount(
                        orderPL.get('1', 0),
                        orderPL.get('0', 0),
                        this.context.displayCcy,
                        this.context.xrates
                      )}
                    </span>
                    ]
                  </span>
                )}
              </span>
            </PayoutTotals>
          </PriceCalculatorEntries>
        </DialogContent>
        <DialogActions>
          <NegativeButton onClick={this.togglePayoutCalc}>
            <FormattedMessage id="payoutCalc.saveAndClose" defaultMessage="Close" />
          </NegativeButton>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
  let payoutCalcTargetOrder = state.getIn(['ui', 'payoutCalcTargetOrder'], null);
  let targetOrder;

  if (payoutCalcTargetOrder) {
    let orders = state.getIn(['orders', 'orders'], null);
    let found = false;
    orders.forEach((_order) => {
      if (_order.get('orderId') === payoutCalcTargetOrder) {
        targetOrder = _order;
        found = true;
      }
    });

    if (!found) {
      let orders = state.getIn(['activePositions', 'activeParlays'], null);
      orders.forEach((_order) => {
        if (_order.get('orderId') === payoutCalcTargetOrder) {
          targetOrder = _order;
          found = true;
        }
      });
    }

    if (!found) {
      targetOrder = state.getIn(['betbar', 'orders', payoutCalcTargetOrder], null);
    }
  }

  let ret = {
    isPayoutCalcOpen: state.getIn(['ui', 'isPayoutCalcOpen'], false),
    payoutCalcTargetOrder,
    targetOrder,
  };

  return ret;
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['ui', 'isPayoutCalcOpen'], null) ===
        next.getIn(['ui', 'isPayoutCalcOpen'], null) &&
      prev.getIn(['ui', 'payoutCalcTargetOrder'], null) ===
        next.getIn(['ui', 'payoutCalcTargetOrder'], null) &&
      prev.getIn(['orders', 'orders'], null) === next.getIn(['orders', 'orders'], null) &&
      prev.getIn(['betbar', 'orders'], null) === next.getIn(['betbar', 'orders'], null)
    );
  },
})(withTheme(injectIntl(PayoutCalc)));
