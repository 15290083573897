/** @format */

import config from '../../config';
import { sizes } from '../../themes';
import { actions } from '../../reducers/app';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { getBaseSport } from '@mollybet/frontend-common/dist/lib/trade';
import ComponentErrorBoundary from '../error-boundary/ComponentErrorBoundary';

import { Paper } from '../../components/interface';

import sportIcons from '../../lib/assets/sports';
import { SettingsContext } from '../../components/shared/SettingsContext';

const MiniTitle = styled(Paper)`
  position: absolute;
  right: 2px;
  top: 2px;
  font-weight: bold;
  background-color: ${(props) => props.theme.primary};
  padding: 2px 3px;
  font-size: 11px;
  line-height: 11px;
`;

const SportSelectorHolder = styled.div`
  z-index: 5;
  position: absolute;
  left: 0;
  top: ${sizes.navMenuHeight}px;
`;

const SportMenu = styled.span`
  position: absolute;
  left: 0;
  z-index: 3;
  width: 42px;
  top: -48px;
  left: 60px !important;

  img {
    width: 30px;
    height: 24px;
    padding: 6px;
    vertical-align: top;
  }
`;

const SportSelectorPanel = styled(Paper)`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -12px;
  left: ${sizes.navMenuLogoWidthNarrow}px;
`;

const SubSportSelector = styled.span`
  position: relative;
  background-color: inherit;
  min-width: 42px;

  img {
    width: 24px;
    height: 30px;
    padding: 3px 8px;
    vertical-align: top;
  }
`;

const SportSelector = styled.span`
  float: left;
  overflow: hidden;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  min-width: 42px;
`;

function miniTitle(sport) {
  let suffix = sport.split('_').pop();
  let ret;

  switch (suffix) {
    case 'et': {
      ret = <FormattedMessage id="sports.et.mini" defaultMessage="ET" />;
      break;
    }
    case 'ht': {
      ret = <FormattedMessage id="sports.ht.mini" defaultMessage="HT" />;
      break;
    }
    case 'htft': {
      ret = <FormattedMessage id="sports.htft.mini" defaultMessage="HTFT" />;
      break;
    }
    case 'q1': {
      ret = <FormattedMessage id="sports.q1.mini" defaultMessage="Q1" />;
      break;
    }
    default:
      ret = null;
      break;
  }

  if (ret) {
    return <MiniTitle elevation={0}>{ret}</MiniTitle>;
  } else {
    return ret;
  }
}

class SportSelectorComp extends React.PureComponent {
  static contextType = SettingsContext;

  state = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.props.actions.closeSearch();
    this.setState({ expanded: !this.state.expanded });
  };

  shouldShow = (sport) => {
    //is a main sport and (is enabled or user has advanced trade settings)
    return (
      (config.sports[getBaseSport(sport)].enabled &&
        (!this.context.parlayMode || config.parlays.allowedSports.includes(sport))) ||
      this.context.featureAdvancedTradeSettings
    );
  };

  render() {
    let selectors = [];
    let sportMenu;

    for (let sport in config.sports) {
      if (!this.shouldShow(sport)) {
        continue;
      }

      //there might be some left over experimental sports (mainly for super users)
      if (!this.context.sport) {
        break;
      }

      let subSports = [];
      for (let subSport of config.sports[sport].correlatedOrder) {
        if (this.context.sport !== subSport || config.sports[sport].correlatedOrder.length > 1) {
          if (!(subSport === 'horse' && !this.context.featureHorse)) {
            subSports.push(
              <Link key={`sub_${subSport}`} to={`/trade/${subSport}`}>
                <SubSportSelector
                  onClick={this.toggleExpanded}
                  title={this.props.intl.formatMessage(config.sportNames[subSport].name)}
                >
                  <img
                    className={this.props.theme.palette === 'light' ? ' invert' : ''}
                    alt={this.props.intl.formatMessage(config.sportNames[subSport].name)}
                    src={sportIcons[subSport]}
                  />
                  {miniTitle(subSport)}
                </SubSportSelector>
              </Link>
            );
          }
        }

        if (this.context.sport === subSport) {
          let _sportMenu = (
            <ComponentErrorBoundary key={this.context.sport}>
              <SportMenu
                key={this.context.sport}
                title={this.props.intl.formatMessage(config.sportNames[this.context.sport].name)}
                onClick={
                  window.location.pathname.indexOf('trade') !== -1 ? this.toggleExpanded : null
                }
              >
                <img
                  className={this.props.theme.palette === 'light' ? ' invert' : ''}
                  alt={this.props.intl.formatMessage(config.sportNames[this.context.sport].name)}
                  src={sportIcons[this.context.sport]}
                />
                {miniTitle(this.context.sport)}
              </SportMenu>
            </ComponentErrorBoundary>
          );

          if (window.location.pathname.indexOf('trade') !== -1) {
            sportMenu = _sportMenu;
          } else {
            sportMenu = <Link to={'/trade'}>{_sportMenu}</Link>;
          }
        }
      }

      selectors.push(<SportSelector key={sport}>{subSports}</SportSelector>);
    }
    return (
      <SportSelectorHolder>
        {sportMenu}
        {this.state.expanded && <SportSelectorPanel elevation={0}>{selectors}</SportSelectorPanel>}
      </SportSelectorHolder>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps, null)(injectIntl(withTheme(SportSelectorComp)));
