/** @format */

import * as Sentry from '@sentry/react';
import React from 'react';
import config from '../../config';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ApplicationErrorHolder = styled.div`
  padding: 2em;

  h2 {
    color: ${(props) => props.theme.error};
  }
`;

class ViewErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  reloadPage() {
    if (window) {
      window.location.reload();
    }
  }

  componentDidCatch(error, _info) {
    let errStr = error ? error.toString() : '?';
    //if it is a JS chunk loading error it means that we've probably deployed
    if (
      errStr.indexOf('Loading chunk') !== -1 ||
      errStr.indexOf('Loading CSS') !== -1 ||
      errStr.indexOf('Unexpected token') !== -1 ||
      errStr.indexOf('expected expression') !== -1 ||
      errStr.indexOf('ChunkLoadError') !== -1
    ) {
      setTimeout(function () {
        if (window) {
          window.location.reload();
        }
      }, config.timings.reloadAferChunkFailure);
      this.setState({ error: null });
    } else {
      Sentry.captureException(error);
      if (config.support.tools.aggressiveErrorMessage) {
        this.setState({ error: errStr });
      }
    }
  }

  render() {
    if (this.state.error) {
      return (
        <ApplicationErrorHolder>
          <h2 className="application-error">
            <FormattedMessage
              id="application.error.title"
              defaultMessage="Sorry! An error has occured ..."
            />
          </h2>
          <p>
            <FormattedMessage
              id="application.error.contents"
              defaultMessage="Please send this to your support team ({email}): {error}"
              values={{
                email: Whitelabel.feedbackEmail,
                error: this.state.error,
              }}
            />
          </p>
          <br />
          <Link to="/" onClick={this.reloadPage}>
            <FormattedMessage
              id="application.error.resolution"
              defaultMessage="Reloading the page might fix this problem"
            />
          </Link>
        </ApplicationErrorHolder>
      );
    } else {
      return this.props.children;
    }
  }
}

export default injectIntl(ViewErrorBoundary);
