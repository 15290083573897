/** @format */

import fb from '../../media/sports/football.svg';
import fb_ht from '../../media/sports/football_ht.svg';
import fb_corn from '../../media/sports/footballcorn.svg';
import fb_corn_ht from '../../media/sports/footballcorn_ht.svg';
import fb_et from '../../media/sports/football_et.svg';
import fb_htft from '../../media/sports/football_htft.svg';
import ih from '../../media/sports/icehockey.svg';
import ih_ht from '../../media/sports/icehockey_ht.svg';
import baseball from '../../media/sports/baseball.svg';
import basket from '../../media/sports/basketball.svg';
import basket_ht from '../../media/sports/basketball_ht.svg';
import tennis from '../../media/sports/tennis.svg';
import cricket from '../../media/sports/cricket.svg';
import boxing from '../../media/sports/boxing.svg';
import golf from '../../media/sports/golf.svg';
import mma from '../../media/sports/mma.svg';
import rl from '../../media/sports/rugbyleague.svg';
import ru from '../../media/sports/rugbyunion.svg';
import af from '../../media/sports/handegg.svg';
import arf from '../../media/sports/rugbyunion.svg';
import esports from '../../media/sports/esports.svg';
import hand from '../../media/sports/handball.svg';
import darts from '../../media/sports/darts.svg';
import horse from '../../media/sports/horse.svg';

const sports = {
  fb,
  fb_ht,
  fb_corn,
  fb_corn_ht,
  fb_et,
  fb_htft,
  ih,
  ih_ht,
  baseball,
  basket,
  basket_ht,
  golf,
  tennis,
  cricket,
  boxing,
  mma,
  rl,
  ru,
  af,
  arf,
  esports,
  hand,
  darts,
  horse,
};

export default sports;
