/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  targetUser: '',
  targetCcy: '',
  error: '',
  errorData: null,
  targetUserAccountingInfo: null,
  status: '',
  isLoading: false,
});

const functions = {
  //open customer transfer modal
  openCustomerTransfer: (state, action) => {
    state = state.set('targetUser', action.data.targetUser);
    state = state.set('targetCcy', action.data.targetCcy);
    return state;
  },

  //close customer transfer modal
  closeCustomerTransfer: (state, _action) => {
    state = state.asMutable();
    state = state.set('targetUser', '');
    state = state.set('targetCcy', '');
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    state = state.set('targetUserAccountingInfo', null);
    return state.asImmutable();
  },

  //create a new customer transfer
  newCustomerTransfer: (state, action) => {
    let targetUser = state.get('targetUser', '');
    if (targetUser) {
      DSM.create(
        `/v1/transfers/`,
        {
          method: 'POST',
          body: {
            ...action.data.data,
            onlyIfAvailable: false,
          },
          extras: {
            streams: ['xfer', 'discount'],
            targetUser,
            currentUser: action.data.currentUser,
          },
          message: 'newCustomerTransferResponse',
        },
        action.data.actions
      );
    }

    state = state.asMutable();
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', true);
    return state.asImmutable();
  },

  //response to a new customer transfer
  newCustomerTransferResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }

    return state;
  },

  //get the current balance of a customer
  //we need to show this information in the modal
  newTransferCurrentBalance: (state, action) => {
    DSM.create(
      `/v1/customers/${action.data.username}/accounting_info/`,
      {
        message: 'newTransferCurrentBalanceResponse',
        method: 'GET',
        ignoreErrors: true, //adriano wants to ignore this
      },
      action.data.actions
    );

    return state;
  },

  //react to the new customer balance information
  newTransferCurrentBalanceResponse: (state, action) => {
    if (action.data.status === 'ok') {
      return state.set('targetUserAccountingInfo', fromJS(action.data.data));
    } else {
      return state;
    }
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
