/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatHandicap, toBetParams } from '@mollybet/frontend-common/dist/lib/betTypes';

import {
  mdiCancel,
  mdiCheck,
  mdiCheckAll,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircle,
} from '@mdi/js';
import { mix } from 'polished';

export function inferStatusIconPath(status) {
  if (status === 'full_void') return mdiCloseCircle;
  else if (status === 'void') return mdiCloseCircle;
  else if (status === 'partial_void') return mdiCloseCircle;
  else if (status === 'failed') return mdiClose;
  else if (status === 'done') return mdiCheck;
  else if (status === 'reconciled') return mdiCheckAll;
  else if (status === 'settled') return mdiCheckAll;
  else if (status === 'placing') return mdiClockOutline;
  else if (status === 'preparing') return mdiClockOutline;
  else if (status === 'pending') return mdiClockOutline;
  else if (status === 'open') return mdiClockOutline;
  else if (status === 'order') return mdiClockOutline;
  else if (status === 'danger') return mdiClockOutline;
  else if (status === 'tracking') return mdiClockOutline;
  else if (status === 'unknown') return mdiClockOutline;
  else return null;
}

export function inferStatusColour(status, theme) {
  if (status === 'full_void') return theme.colours.warning;
  else if (status === 'void') return theme.colours.warning;
  else if (status === 'partial_void') return theme.colours.warning;
  else if (status === 'failed') return theme.colours.error;
  else if (status === 'done') return theme.colours.success;
  else if (status === 'reconciled') return theme.colours.success;
  else if (status === 'settled') return theme.colours.success;
  else if (status === 'placing') return theme.colours.warning;
  else if (status === 'preparing') return theme.colours.warning;
  else if (status === 'pending') return theme.colours.warning;
  else if (status === 'open') return theme.colours.warning;
  else if (status === 'order') return theme.colours.warning;
  else if (status === 'danger') return theme.colours.warning;
  else if (status === 'tracking') return theme.colours.warning;
  else if (status === 'unknown') return theme.colours.warning;
  else return null;
}

export function translateStatusText(status) {
  if (status === 'full_void')
    return <FormattedMessage id="order.statuses.fullVoid" defaultMessage="Full Void" />;
  else if (status === 'partial_void')
    return <FormattedMessage id="order.statuses.partialVoid" defaultMessage="Partial Void" />;
  else if (status === 'failed')
    return <FormattedMessage id="order.statuses.failed" defaultMessage="Failed" />;
  else if (status === 'done')
    return <FormattedMessage id="order.statuses.done" defaultMessage="Done" />;
  else if (status === 'reconciled')
    return <FormattedMessage id="order.statuses.reconciled" defaultMessage="Settled" />;
  else if (status === 'settled')
    return <FormattedMessage id="order.statuses.settled" defaultMessage="Settled" />;
  else if (status === 'placing')
    return <FormattedMessage id="order.statuses.placing" defaultMessage="Placing" />;
  else if (status === 'preparing')
    return <FormattedMessage id="order.statuses.preparing" defaultMessage="Preparing" />;
  else if (status === 'pending')
    return <FormattedMessage id="order.statuses.pending" defaultMessage="Pending" />;
  else if (status === 'open')
    return <FormattedMessage id="order.statuses.open" defaultMessage="Placing" />;
  else if (status === 'order')
    return <FormattedMessage id="order.statuses.order" defaultMessage="Order" />;
  else if (status === 'danger')
    return <FormattedMessage id="order.statuses.danger" defaultMessage="Danger" />;
  else if (status === 'tracking')
    return <FormattedMessage id="order.statuses.tracking" defaultMessage="Tracking" />;
  else if (status === 'unknown')
    return <FormattedMessage id="order.statuses.unknown" defaultMessage="Unknown" />;
  else return <span>{status}</span>;
}
export const inferTextStatus = translateStatusText;

export function translateBetbarStatus(status, closed) {
  if (status === 'success-stake')
    return <FormattedMessage id="betbar.stakeStatus.success" defaultMessage="success" />;
  else if (status === 'danger-stake')
    return <FormattedMessage id="order.stakeStatus.danger" defaultMessage="danger" />;
  else if (status === 'inprogress-stake')
    return <FormattedMessage id="order.stakeStatus.inprogress" defaultMessage="in progress" />;
  else if (status === 'unplaced-stake')
    return !closed ? (
      <FormattedMessage id="order.stakeStatus.unassigned" defaultMessage="unassigned" />
    ) : (
      <FormattedMessage id="order.statuses.unplaced" defaultMessage="unplaced" />
    );
  else return <span>{status}</span>;
}

export function parseLayAndDarkBetTypes(
  betTypeDescription,
  orderType,
  showOrderType = true,
  isDark = false
) {
  if (betTypeDescription) {
    // if orderType is lay then we want to remove the prefix AGAINST
    if (orderType === 'lay') {
      betTypeDescription = betTypeDescription.split(' ').slice(1).join(' ');
    }

    // if there isnt an explicit orderType display then preface the bet description with Lay
    if (showOrderType === true && orderType === 'lay') {
      betTypeDescription = 'Lay ' + betTypeDescription;
    }

    if (isDark) {
      betTypeDescription = betTypeDescription + ' (dark)';
    }
  }

  return betTypeDescription;
}

export function translateBetType(betType, home, away, intl, orderType) {
  if (betType) {
    if (betType.includes('custom')) return 'Custom';

    // deal with betTypes which includes under score, so can match betType in config
    betType = betType.replace(/moBothScore/gi, 'mo_both_score');
    betType = betType.replace(/noGoal/gi, 'no_goal');
    betType = betType.replace(/win90/gi, 'win_90');
    betType = betType.replace(/exactTotal/gi, 'exact_total');

    const isUnparsedMultirunner = betType.startsWith('unparsed,multirunner,');
    betType = betType.replace('unparsed,multirunner,', '');

    //deal with tennis void rule variations

    betType = betType.replace(/(vwhole|vset1|vset2|vset3|vset4)/g, 'vwhatever');
    let isIr = betType.indexOf('ir,') !== -1;
    let isAgainst = betType.startsWith('against');
    let betParams = toBetParams(betType);
    let offerGroup = betParams.offerGroup;
    let betTypeConfig = betParams.config;
    let handicap = betType.split(betParams.baseBetType + ',')[1];

    let shouldInvert = false;
    let atoms = betType.split(',');
    //has away or player 2
    if (atoms.indexOf('a') !== -1 || atoms.indexOf('p2') !== -1) {
      shouldInvert = true;
    }

    let homeScore = null;
    let awayScore = null;

    if (isIr) {
      let _last = betType.split('ir,')[1];
      homeScore = _last.split(',')[0];
      awayScore = _last.split(',')[1];
    }

    if (!betTypeConfig || !offerGroup) {
      console.warn('Unsupported bet type translation: ', betType);
      return '';
    }

    let descr = betTypeConfig.template;
    if (isIr && betTypeConfig.irTemplate) {
      descr = betTypeConfig.irTemplate;
    }

    // deal with unparsed multirunners
    if (isUnparsedMultirunner) {
      return (
        (isAgainst && orderType !== 'lay'
          ? intl.formatMessage({
              id: 'against',
              defaultMessage: 'AGAINST',
            }) + ' '
          : '') + betType.split(',').slice(-1)[0]
      );
    }

    return (
      (isAgainst && orderType !== 'lay'
        ? intl.formatMessage({
            id: 'against',
            defaultMessage: 'AGAINST',
          }) + ' '
        : '') +
      intl.formatMessage(descr, {
        home,
        away,
        handicap: formatHandicap(handicap, shouldInvert, offerGroup),
        homeScore,
        awayScore,
      })
    );
  } else {
    return betType;
  }
}

export function translateAccaStatus(status) {
  if (status === 'w')
    return <FormattedMessage id="order.statuses.accas.win" defaultMessage="win" />;
  else if (status === 'l')
    return <FormattedMessage id="order.statuses.accas.loss" defaultMessage="loss" />;
  else if (status === 'v')
    return <FormattedMessage id="order.statuses.accas.void" defaultMessage="void" />;
  else if (status === 'v/w')
    return <FormattedMessage id="order.statuses.accas.voidWin" defaultMessage="v/win" />;
  else if (status === 'l/v')
    return <FormattedMessage id="order.statuses.accas.voidLoss" defaultMessage="loss/v" />;
  else return <FormattedMessage id="order.statuses.accas.open" defaultMessage="open" />;
}

export function inferAccaStatusIconPath(status) {
  if (status === 'w') return mdiCheck;
  else if (status === 'l') return mdiClose;
  else if (status === 'v') return mdiCancel;
  else if (status === 'v/w') return mdiCheck;
  else if (status === 'l/v') return mdiClose;
  else return mdiClockOutline;
}

export function inferAccaStatusColour(status, theme) {
  if (status === 'w') return theme.colours.success;
  else if (status === 'l') return theme.colours.error;
  else if (status === 'v') return mix(0.6, theme.foreground, theme.background);
  else if (status === 'v/w') return theme.colours.success;
  else if (status === 'l/v') return theme.colours.error;
  else return theme.colours.warning;
}
