/** @format */

import dark from './themes/dark-theme';
import light from './themes/light-theme';
import sizes from './themes/sizes';

export default {
  default: dark,
  light,
};

export { sizes };
