/** @format */

import React, { useContext } from 'react';

import { actions } from '../../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import config from '../../../config';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { IntlShape, useIntl } from 'react-intl';
import {
  ActionsType,
  FilterOptionType,
  FormatMessageOptionsType,
  MapDispatchToPropsType,
  MapStateToPropsType,
  MarketLabelElementPropsType,
  MarketLabelPropsType,
  MarketOptionType,
  MarketSelectorPropsType,
  OptionsType,
  PlaceholderPropsType,
  ReactSelectStateObjectType,
  ReactSelectStyleObjectType,
  SportConfigType,
  SportLabelPropsType,
  SportOptionType,
} from './types';
import { Map as ImmutableMap } from 'immutable';
import { SettingsContext, SettingsContextType } from '../../../components/shared/SettingsContext';
import Select, { FormatOptionLabelMeta, Styles } from 'react-select';
import { mdiMagnify } from '@mdi/js';
import { Icon } from '../../interface';
import { DemoWrapper } from '../../demo';

const SportLabelElement = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'icon text';

  color: ${({ theme }) => theme.fonts.colours[1]};
  text-transform: none;
`;

const SportLabelIconElement = styled(Icon)`
  height: ${({ theme }) => theme.fonts.size.default};
  width: ${({ theme }) => theme.fonts.size.default};
  margin-right: 1rem;
`;

const SportLabelNameElement = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.subtext};
  font-weight: bold;
  letter-spacing: 1px;
`;

const MarketLabelElement = styled.div<MarketLabelElementPropsType>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'icon text';
  align-items: center;
  padding-left: ${({ indent, context }) => (indent && context === 'menu' ? indent * 1 : 0)}rem;
  color: inherit;
`;

const MarketLabelIconElement = styled(Icon)`
  height: ${({ theme }) => theme.fonts.size.default};
  width: ${({ theme }) => theme.fonts.size.default};
  margin-right: 1rem;
`;

const MarketLabelNameElement = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.default};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PlaceholderElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSearchIcon = styled(Icon)`
  min-width: 24px;
  max-width: 24px;
  padding-right: 0.5rem;
`;

const SportLabel: React.FunctionComponent<SportLabelPropsType> = ({ iconPath, name, showIcon }) => (
  <SportLabelElement>
    {showIcon ? <SportLabelIconElement path={iconPath} showIcon={showIcon} /> : null}
    <SportLabelNameElement>{name}</SportLabelNameElement>
  </SportLabelElement>
);

const MarketLabel: React.FunctionComponent<MarketLabelPropsType> = ({
  iconPath,
  name,
  showIcon,
  context,
  selectValue,
  value,
  theme,
  indent,
}) => {
  const colour =
    context === 'menu' && selectValue && selectValue.value === value
      ? theme.colours.backgrounds[0]
      : theme.fonts.colours[0];
  return (
    <MarketLabelElement indent={indent} context={context}>
      {showIcon ? <MarketLabelIconElement path={iconPath} color={colour} size={1.25} /> : null}
      <MarketLabelNameElement>{name}</MarketLabelNameElement>
    </MarketLabelElement>
  );
};

const Placeholder: React.FunctionComponent<PlaceholderPropsType> = ({ theme }) => (
  <PlaceholderElement>
    <StyledSearchIcon path={mdiMagnify} color={theme.fonts.colours[2]} />
    <span>Search sports</span>
  </PlaceholderElement>
);

const buildSearchTerms = (
  intl: IntlShape,
  name: FormatMessageOptionsType,
  searchAliases?: FormatMessageOptionsType[]
): string[] => {
  const searchTerms = [intl.formatMessage(name)];

  if (searchAliases) {
    searchAliases.forEach((alias: FormatMessageOptionsType): void => {
      searchTerms.push(intl.formatMessage(alias));
    });
  }

  return searchTerms;
};

const combineSearchTerms = (sportSearchTerms: string[], marketSearchTerms: string[]): string[] =>
  marketSearchTerms.reduce((acc: string[], marketSearchTerm: string): string[] => {
    sportSearchTerms.forEach((sportSearchTerm: string): void => {
      acc.push(`${sportSearchTerm.toLowerCase()} ${marketSearchTerm.toLowerCase()}`);
    });
    return acc;
  }, []);

const createFlatSportOptions = (
  sport: SportConfigType,
  navLayout: string,
  intl: IntlShape,
  context: { [key: string]: any }
): MarketOptionType[] | null => {
  if (sport.hidden || (sport.id === 'horse' && !context.featureHorse)) return null;

  const sportSearchTerms = buildSearchTerms(intl, sport.name, sport.searchAliases);
  const [intlSportName] = sportSearchTerms;

  return sport.displayOrder.reduce(
    (acc: MarketOptionType[], marketKey: string): MarketOptionType[] => {
      const market = sport.markets[marketKey];

      if (context.parlayMode && !config.parlays.allowedSports.includes(market.id)) return acc;
      if (config.sports[sport.id]?.multirunner && !context.featureMultirunner) return acc;

      const marketSearchTerms = buildSearchTerms(intl, market.name, market.searchAliases);
      const searchTerms = combineSearchTerms(sportSearchTerms, marketSearchTerms);
      const [intlMarketName] = marketSearchTerms;
      let name = `${intlSportName}: ${intlMarketName}`;

      if (marketKey === 'fullTime' && config.nav.marketSelector.hideFullTimeMarketQualifier) {
        name = intlSportName;
      }

      acc.push({
        searchTerms,
        value: market.id,
        props: {
          name,
          iconPath: market.iconPath || sport.iconPath,
          showIcon: navLayout !== 'narrow' && config.nav.marketSelector.showMarketIcon,
        },
      });

      return acc;
    },
    []
  );
};

const createGroupedSportOptions = (
  sport: SportConfigType,
  navLayout: string,
  intl: IntlShape,
  context: { [key: string]: any }
): SportOptionType | MarketOptionType | null => {
  if (sport.hidden) return null;

  const sportSearchTerms = buildSearchTerms(intl, sport.name, sport.searchAliases);
  const [intlSportName] = sportSearchTerms;

  if (!config.nav.marketSelector.collapseSingleMarketSports || sport.displayOrder.length > 1) {
    const Demo = sport.id === 'fb' ? React.Fragment : DemoWrapper;
    return {
      value: sport.displayOrder[0],
      label: (
        <Demo>
          <SportLabel
            iconPath={sport.iconPath}
            name={intlSportName}
            showIcon={navLayout !== 'narrow' && config.nav.marketSelector.showSportIcon}
          />
        </Demo>
      ),
      disabled: context.isDemo && sport.id !== 'fb',
      options: sport.displayOrder.reduce(
        (acc: MarketOptionType[], marketKey: string): MarketOptionType[] => {
          const market = sport.markets[marketKey];

          if (context.parlayMode && !config.parlays.allowedSports.includes(market.id)) return acc;
          if (config.sports[sport.id]?.multirunner && !context.featureMultirunner) return acc;

          const marketSearchTerms = buildSearchTerms(intl, market.name, market.searchAliases);
          const searchTerms = combineSearchTerms(sportSearchTerms, marketSearchTerms);
          const [intlMarketName] = marketSearchTerms;

          acc.push({
            searchTerms,
            value: market.id,
            props: {
              iconPath: market.iconPath || sport.iconPath,
              name: intlMarketName,
              showIcon: navLayout !== 'narrow' && config.nav.marketSelector.showMarketIcon,
              indent: config.nav.marketSelector.collapseSingleMarketSports ? 1 : 0,
            },
          });

          return acc;
        },
        []
      ),
    };
  }

  const flatOption = createFlatSportOptions(sport, navLayout, intl, context);

  return flatOption ? flatOption[0] : null;
};

const filterOption = (option: FilterOptionType<MarketOptionType>, query: string): boolean => {
  if (!query || query.length === 0) return true;

  const cleanQuery = query.trim().toLowerCase();

  if (!cleanQuery.length) return true;

  for (let index = 0; index < option.data.searchTerms.length; index += 1) {
    if (option.data.searchTerms[index].includes(cleanQuery)) return true;
  }
  return false;
};

const findOption = (options: OptionsType, value: string): MarketOptionType =>
  // @ts-ignore: Doesn't recognise the if statement filtering Sport and Market options.
  options.reduce(
    (acc: MarketOptionType, option: SportOptionType | MarketOptionType): MarketOptionType => {
      if (acc) {
      } else if ('options' in option) {
        acc = findOption(option.options, value);
      } else if (option.value === value) {
        acc = option;
      }

      return acc;
    },
    undefined
  );

const formatOptionLabel = (
  option: MarketOptionType,
  meta: FormatOptionLabelMeta<MarketOptionType, false>
): JSX.Element => {
  const ThemedMarketLabel = withTheme(MarketLabel);
  let selectValue = meta.selectValue;

  if (Array.isArray(selectValue)) {
    selectValue = meta.selectValue && meta.selectValue[0];
  }

  return (
    <ThemedMarketLabel
      {...option.props}
      context={meta.context}
      // @ts-ignore: Linting not detecting check above.
      selectValue={selectValue}
      value={option.value}
    />
  );
};

const DumbMarketSelector: React.FunctionComponent<MarketSelectorPropsType> = ({
  actions,
  history,
  match,
  market,
  navLayout,
  theme,
}) => {
  const intl = useIntl();
  const context = useContext<SettingsContextType>(SettingsContext);

  const marketOptions = config.nav.marketSelector.displayOrder.reduce(
    (acc: OptionsType, sport: string): OptionsType => {
      if (config.nav.marketSelector.groupItems) {
        const option = createGroupedSportOptions(
          config.nav.marketSelector.sports[sport],
          navLayout,
          intl,
          context
        );
        if (option) acc.push(option);
      } else {
        const options = createFlatSportOptions(
          config.nav.marketSelector.sports[sport],
          navLayout,
          intl,
          context
        );
        if (options) acc.push(...options);
      }
      return acc;
    },
    []
  );

  const currentValue =
    (match.path.startsWith('/event/:selectedSport') || match.path.startsWith('/trade/:sport')) &&
    findOption(marketOptions, market);

  const onChange = (event): void => {
    actions.changeSport({ sport: event.value });
    if (match.path.startsWith('/event')) {
      history.push(`/event/${event.value}`);
    } else {
      history.push(`/trade/${event.value}`);
    }
  };

  const maxMenuHeight = Math.min(
    context.interfaceHeight - context.navMenuHeight,
    // 10 items seemed a reasonable size.
    config.nav.marketSelector.maxMarketsToDisplay > 0
      ? config.nav.marketSelector.maxMarketsToDisplay * 32
      : Infinity
  );

  // @ts-ignore: React select
  const styles: Styles = {
    // @ts-ignore: Package uses incorrect typing.
    container: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      minWidth: '16rem',
      margin: '5px 1rem 0',
    }),
    // @ts-ignore: Package uses incorrect typing.
    control: (
      provided: ReactSelectStyleObjectType,
      state: ReactSelectStateObjectType
    ): ReactSelectStyleObjectType => ({
      ...provided,
      backgroundColor: theme.colours.backgrounds[0],
      borderWidth: '1px',
      borderColor: state.isFocused ? theme.colours.primary : theme.colours.backgrounds[1],
      boxShadow: '0 !important',
      borderRadius: '0.25rem',
      height: '36px',

      '&:hover': {
        borderColor: state.isFocused ? theme.colours.primary : theme.colours.backgrounds[2],
      },
    }),
    // @ts-ignore: Package uses incorrect typing.
    dropdownIndicator: (
      provided: ReactSelectStyleObjectType,
      state: ReactSelectStateObjectType
    ): ReactSelectStyleObjectType => ({
      ...provided,
      color: theme.fonts.colours[1],
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'transform 300ms',

      '&:hover': {
        color: theme.fonts.colours[0],
      },
    }),
    // @ts-ignore: Package uses incorrect typing.
    indicatorSeparator: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      display: 'none',
    }),
    // @ts-ignore: Package uses incorrect typing.
    input: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      color: theme.fonts.colours[0],
      width: '12rem',
    }),
    // @ts-ignore: Package uses incorrect typing.
    menu: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      backgroundColor: theme.colours.backgrounds[0],
      overflow: 'hidden',
      borderRadius: '0 0 0.25rem 0.25rem',
      margin: '5px 0',
      boxShadow: theme.shadows[1],
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.colours.backgrounds[1],

      '*': {
        userSelect: 'none',
      },
    }),
    // @ts-ignore: Package uses incorrect typing.
    menuList: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      '&::-webkit-scrollbar': {
        height: '1em',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: theme.colours.backgrounds[0],
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '0',
        border: '0.25em solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: theme.separator,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.colours.backgrounds[0],
        border: 'none',
      },
    }),
    // @ts-ignore: Package uses incorrect typing.
    option: (
      provided: ReactSelectStyleObjectType,
      state: ReactSelectStateObjectType
    ): ReactSelectStyleObjectType => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.fonts.colours[0] : theme.colours.backgrounds[0],
      color: state.isSelected ? theme.colours.backgrounds[0] : theme.fonts.colours[0],
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: state.isSelected ? theme.fonts.colours[0] : theme.colours.backgrounds[1],
      },
    }),
    // @ts-ignore: Package uses incorrect typing.
    placeholder: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      color: theme.fonts.colours[2],
    }),
    // @ts-ignore: Package uses incorrect typing.
    singleValue: (provided: ReactSelectStyleObjectType): ReactSelectStyleObjectType => ({
      ...provided,
      color: theme.fonts.colours[0],

      svg: {
        fill: theme.fonts.colours[0],
      },
    }),
  };

  return (
    <Select
      options={marketOptions}
      value={currentValue}
      styles={styles}
      placeholder={<Placeholder theme={theme} />}
      onClick={actions.closeSearch}
      onChange={onChange}
      maxMenuHeight={maxMenuHeight}
      filterOption={filterOption}
      //@ts-ignore:
      formatOptionLabel={formatOptionLabel}
    />
  );
};

const mapDispatchToProps: MapDispatchToPropsType = (dispatch: Dispatch) => ({
  actions: bindActionCreators<{}, ActionsType>(
    {
      closeSearch: actions.closeSearch,
      changeSport: actions.changeSport,
    },
    dispatch
  ),
});

// turn state of combined reducers into state required by component
const mapStateToProps: MapStateToPropsType = (state: ImmutableMap<string, any>) => ({
  navLayout: state.getIn(['base', 'uiSpecs', 'navLayout'], 'normal'),
  market: state.getIn(['trade', 'sport'], null),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(DumbMarketSelector)));
