/**
 * This file uses sass-variable-loader to export the basic style variable as js, thus making them useable in the React components.
 *
 * @format
 */

/*eslint-disable*/
import colors from '!!sass-variable-loader!./styles/colors.sass';
import sizes from '!!sass-variable-loader!./styles/sizes.sass';
/*eslint-enable*/

export default {
  colors,
  sizes,
  coloredButton: function (theme, color) {
    return {
      color: theme.palette.getContrastText(theme.palette.common[color]),
      backgroundColor: theme.palette.common[color],
      '&:hover': {
        color: theme.palette.getContrastText(theme.palette.common.buttonHover),
        backgroundColor: theme.palette.common.buttonHover,
      },
      '&[disabled]': {
        color: theme.palette.getContrastText(theme.palette.common.buttonDisabled),
        backgroundColor: theme.palette.common.buttonDisabled,
      },
    };
  },
  themeBase: {
    palette: {
      error: {
        main: '#FF0000',
      },
      common: {
        positive: '#2E7D32',
        negative: '#FF0000',
        buttonDisabled: '#999999',
        buttonHover: '#444444',
        accent: '#7781e9',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: 'Roboto, "Segoe UI", "Lucida Grande", "Helvetica", "Arial", sans-serif',
      useNextVariants: true,
      body1: {
        fontSize: '1em',
      },
      subtitle1: {
        fontSize: 12,
      },
      h1: {
        fontSize: 24, //standard header
      },
      h2: {
        fontSize: 20,
      },
      h3: {
        fontSize: 18,
      },
    },
    shape: {
      borderRadius: 0,
    },
    overrides: {
      MuiDrawer: {
        paper: {
          width: sizes.sideWidth,
          boxSizing: 'border-box',
        },
      },
      MuiDialog: {
        paper: {
          padding: 0,
        },
      },
      MuiDialogActions: {
        root: {
          margin: 0,
          display: 'flex',
          flexFlow: 'row',
        },
        action: {
          margin: 0,
          display: 'flex',
          flexGrow: 1,
        },
      },
      MuiFormControlLabel: {
        label: {
          minWidth: '10em',
        },
        root: {
          marginLeft: 0,
        },
        labelPlacementStart: {
          marginRight: '1.5em',
          marginLeft: '0',
        },
        labelPlacementTop: {
          display: 'flex',
        },
      },
      MuiTable: {
        root: {
          width: 'auto',
        },
      },
      MuiIconButton: {
        root: {
          padding: '.1em',
        },
      },
      MuiTableRow: {
        root: {
          height: '2em',
        },
      },
      MuiTableCell: {
        root: {
          borderColor: colors.borderColor,
        },
      },
      MuiPaper: {
        elevation2: {
          boxShadow: 'none',
        },
        elevation3: {
          boxShadow: 'none',
        },
      },
      MuiButton: {
        sizeSmall: {
          padding: '0 4px',
          minWidth: 'initial',
          fontSize: '.75em',
        },
      },
      MuiLink: {
        root: {
          color: '#222',
        },
      },
      MuiSelect: {
        select: {
          '&$disabled': {
            color: colors.borderColor,
          },
        },
      },
    },
    transitions: {
      // So we have `transition: none;` everywhere
      create: () => 'none',
    },
  },
};
