/** @format */

import styled from 'styled-components';
import config from '../../../config';

export const SportResultDisplayElement = styled.div`
  padding: 14px 14px 8px 14px;
  font-size: 10px;
  font-weight: 500;
  box-sizing: border-box;
  margin: 0px 10px;
  text-transform: uppercase;
  color: ${({ hasResults }) => (hasResults ? 'white' : 'darkgrey')};
  border-bottom: ${({ active }) => (active ? 'solid 1px' : '')};
  cursor: pointer;
  user-select: none;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    display: ${({ hasResults, active }) => (hasResults || active ? 'block' : 'none')};
    color: ${({ active }) => (active ? 'white' : 'darkgrey')};
  }
`;
