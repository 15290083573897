/** @format */

import React, { useEffect, useRef } from 'react';
import {
  SearchBoxContainerElement,
  TextFieldElement,
  SearchBarIconButton,
  ButtonElement,
} from './elements';
import { useTheme } from 'styled-components';
import { actions } from '../../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from '../../interface';
import { mdiMagnify } from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import ComponentErrorBoundary from '../../error-boundary/ComponentErrorBoundary';

const SearchBox = ({ actions, searchBarValue, stateSearchBarFocusState }) => {
  const theme = useTheme();
  const textFieldElement = useRef();
  const { setSearchBarValue, unfocusSearchBar } = actions;
  useEffect(() => {
    if (textFieldElement.current) {
      textFieldElement.current.focus();
    }
    if (stateSearchBarFocusState === 'focused' && textFieldElement?.current) {
      textFieldElement.current.focus();
    }
  }, [textFieldElement?.current, stateSearchBarFocusState]);
  const setSearchBarValueHelper = (e) => {
    setSearchBarValue(e.target.value);
  };
  const closeSearchBarHelper = () => {
    unfocusSearchBar();
    setSearchBarValue('');
  };
  return (
    <SearchBoxContainerElement>
      <ComponentErrorBoundary>
        <SearchBarIconButton onClick={closeSearchBarHelper}>
          <Icon width={48} height={48} path={mdiMagnify} color={theme.colours.white} />;
        </SearchBarIconButton>
        <TextFieldElement
          ref={textFieldElement}
          spellcheck="false"
          autoComplete="false"
          placeholder="Search"
          value={searchBarValue}
          onChange={setSearchBarValueHelper}
        />
        <ButtonElement onClick={closeSearchBarHelper}>
          <FormattedMessage id="base.message.close" defaultMessage="close" />
        </ButtonElement>
      </ComponentErrorBoundary>
    </SearchBoxContainerElement>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, _ownProps) => ({
  searchBarFocusState: state.getIn(['ui', 'searchBarFocusState'], 'unfocused'),
  searchBarValue: state.getIn(['ui', 'searchBarValue'], ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
