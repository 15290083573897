/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';
import config from '../../../config';
import { toStdDate } from '@mollybet/frontend-common/dist/lib/time';

import { fromJS } from 'immutable';

const _newCustomerData = {
  groupId: '',
  name: '',
  username: '',
  password: '',
  creditLimit: '0', // formatted by a func in the reducer
  commissionRate: '', // formatted by a func in the reducer
  ccyCode: '',
  tags: {},
  active: true,
  roles: { customer: true, bet_placer: true, trade_page_viewer: true },
  // added error data
  status: '',
  errorInfo: '',
};

let initialState = fromJS({
  formOpen: false,
  batchNewCustomerData: [{ ..._newCustomerData }],
  csvFormOpen: false,
  csvFormData: '',
  currentUser: undefined,
  groupOptions: [],
});

// formats data as required by the api
const prepareDataForAPICall = (customerData, currentUser) => {
  if (customerData['creditLimit'] !== '') {
    customerData['creditLimit'] = [customerData['ccyCode'], customerData['creditLimit']];
  } else {
    customerData['creditLimit'] = [customerData['ccyCode'], null];
  }
  customerData[
    'creditLimitComment'
  ] = `initial credit limit when user created by ${currentUser} at ${new Date().toUTCString()}`;
  customerData['commissionRate'] = {
    ratePc: customerData['commissionRate'],
    startDate: toStdDate(),
  };
  delete customerData['status'];
  delete customerData['errorInfo'];
  return customerData;
};

const functions = {
  //open modal
  openBatchEditCreateCustomer: (state, _action) => {
    return state.set('formOpen', true);
  },

  //close modal
  closeBatchEditCreateCustomer: (state, _action) => {
    return state.set('formOpen', false);
  },

  //update data in modal
  updateBatchNewCustomerData: (state, action) => {
    return state.set('batchNewCustomerData', fromJS(action.data.batchNewCustomerData));
  },

  //toggles whether the csv form is shown
  toggleNewCustomerCSVForm: (state, _action) => {
    const csvFormState = state.get('csvFormOpen');
    return state.set('csvFormOpen', !csvFormState);
  },

  //updates state with the contents of the csv form
  handleChangeNewCustomerCSVFormData: (state, action) => {
    return state.set('csvFormData', action.data.data);
  },

  //parses the contents of the csv form and turns them into rows in the form
  parseNewCustomerCSVData: (state, action) => {
    const csvDataRows = state.get('csvFormData').trim().split(/\r?\n/); //parse data adding it to formData
    const newCustomerData = [];
    const groups = action.data.groups;

    csvDataRows.forEach((row) => {
      const rowData = row.split(',').map((rd) => rd.trim());
      //translate group name to id
      if (rowData[3].match(/[a-z]+/gi)) {
        for (let grId in groups) {
          if (groups[grId].name === rowData[3]) {
            rowData[3] = groups[grId].id;
          }
        }
      }
      const user = {
        username: rowData[0],
        password: rowData[1],
        name: rowData[2],
        groupId: rowData[3], // call func to extract this
        ccyCode: rowData[4], // call func to extract this
        commissionRate: parseFloat(rowData[5]),
        creditLimit: parseFloat(rowData[6]),
        tags: {},
        config: {
          // pre-set config
          login: true,
          spyOnGroup: false,
          seeAccounts: false, // was hideAccounts
          webPlaceBets: true,
          agent: false,
          getCommissions: false,
          agentCreateUsers: false,
          sudo: false,
          apiAccess: false,
          apiSeeEvents: false,
          apiPlaceBets: false,
        },
        // added error data
        status: '',
        errorInfo: '',
      };
      newCustomerData.push({ ...user });
    });
    state = state.set('batchNewCustomerData', fromJS(newCustomerData));
    return state;
  },

  //actually make all the requests for creating users
  batchCreateNewCustomers: (state, action) => {
    const transferData = Object.values(action.data.data);
    const batchLength = transferData.length;
    for (let j = 0; j < batchLength; j++) {
      transferData[j]['status'] = 'pending';
      transferData[j]['errorInfo'] = '';
      state = state.set('batchNewCustomerData', fromJS(transferData));
    }

    // func called once for each form row (each new customer)
    let i = 0;
    const makeTransfer = () => {
      DSM.create(
        '/v1/customers/',
        {
          method: 'POST',
          body: prepareDataForAPICall({ ...action.data.data[i] }, state.get('currentUser')),
          extras: {
            transfer: { index: i, transferData: Object.values(action.data.data) },
            target: '',
            superuser: false,
          },
          message: 'editBatchCreateCustomerResponse',
        },
        action.data.actions
      );

      i++;
      if (i < batchLength) {
        setTimeout(makeTransfer, config.timings.timeBetweenBatchCalls);
      }
    };

    if (batchLength) {
      makeTransfer();
    }

    return state;
  },

  //response to each customer creation
  editBatchCreateCustomerResponse: (state, action) => {
    const transferData = action.data.extras.transfer.transferData;
    if (action.data.status === 'ok') {
      transferData[action.data.extras.transfer.index]['status'] = 'success';
      state = state.set('batchNewCustomerData', fromJS(transferData));
    } else {
      const parsedError = extractErrorMessage(action.data.data);
      transferData[action.data.extras.transfer.index]['status'] = 'failure';
      transferData[action.data.extras.transfer.index]['errorInfo'] = parsedError;
      state = state.set('batchNewCustomerData', fromJS(transferData));
    }
    return state;
  },

  //sometimes the API fails
  //and we have to retry certain rows
  retryCustomerCreation: (state, action) => {
    const batchNewCustomerData = state.get('batchNewCustomerData');
    let transferData = batchNewCustomerData;
    transferData = Object.values(transferData.toJS());
    const i = action.data.index;
    DSM.create(
      '/v1/customers/',
      {
        method: 'POST',
        body: prepareDataForAPICall({ ...transferData[i] }, state.get('currentUser')),
        extras: {
          transfer: { index: i, transferData: transferData },
          target: '',
          superuser: false,
        },
        message: 'editBatchCreateCustomerResponse',
      },
      action.data.actions
    );
    transferData[i]['status'] = 'pending';
    transferData[i]['errorInfo'] = '';
    return state.set('batchNewCustomerData', fromJS(transferData));
  },

  //???
  updateCurrentUser: (state, action) => {
    state = state.set('currentUser', action.data.currentUser);
    return state.set('groupOptions', action.data.groupOptions);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
