/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  isLoading: true,
  bookieAccounts: null,
});

const functions = {
  //get agent bookie summary
  //this is basically just a different view on bookie accounts
  agentGetGroupBookieSummary: (state, action) => {
    DSM.last(
      `/v1/groups/${action.data.group}/bookie_accounts/`,
      {
        method: 'GET',
        message: 'agentGetGroupBookieSummaryResponse',
        body: {},
      },
      action.data.actions,
      'agentGetGroupBookieSummary'
    );

    return state.set('isLoading', true);
  },

  //handle bookie summary data
  agentGetGroupBookieSummaryResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('bookieAccounts', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //error handled by base
      return state;
    }
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
