/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  isLoading: true,
  overallBalances: null,
});

const functions = {
  //get customer accounting info summary
  //this should be same info as in top right money holder dropdown
  agentGetCustomerSummary: (state, action) => {
    DSM.last(
      `/v1/customers/${action.data.username}/accounting_info/`,
      {
        message: 'agentGetCustomerSummaryResponse',
        method: 'GET',
        ignoreErrors: true, //adriano wants to ignore this
      },
      action.data.actions,
      'agentGetCustomerSummary'
    );

    return state.set('isLoading', true);
  },

  //react to customer summary data
  agentGetCustomerSummaryResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('overallBalances', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      return state;
    }
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
