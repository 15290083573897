/**
 * This file combines the reducers...
 * you may or may not need/want to do this since each view will probably have its own reducer
 *
 * @format
 */

import combineReducers from 'redux-immutable-combine-reducers';

import customers from './customers';
import { actions as customerActions } from './customers';

import bookies from './bookies';
import { actions as bookiesActions } from './bookies';

import matrix from './matrix';
import { actions as matrixActions } from './matrix';

import allTransfers from './allTransfers';
import { actions as allTransfersActions } from './allTransfers';

//subsections
import customerBalances from './customer/balances';
import { actions as customerBalancesActions } from './customer/balances';

import customerCommissionRate from './customer/commissionRate';
import { actions as customerCommissionRateActions } from './customer/commissionRate';

import customerCreditLimit from './customer/creditLimit';
import { actions as customerCreditLimitActions } from './customer/creditLimit';

import customerSummary from './customer/summary';
import { actions as customerSummaryActions } from './customer/summary';

import customerTransfers from './customer/transfers';
import { actions as customerTransfersActions } from './customer/transfers';

import groupCreditLimit from './group/creditLimit';
import { actions as groupCreditLimitActions } from './group/creditLimit';

import groupBookieSummary from './group/bookieSummary';
import { actions as groupBookieSummaryActions } from './group/bookieSummary';

//modals
import changeGroup from './modals/changeGroup';
import { actions as changeGroupActions } from './modals/changeGroup';

import editCreateUser from './modals/editCreateUser';
import { actions as editCreateUserActions } from './modals/editCreateUser';

import editCustomerAccas from './modals/editCustomerAccas';
import { actions as editCustomerAccasActions } from './modals/editCustomerAccas';

import editCommRate from './modals/editCommRate';
import { actions as editCommRateActions } from './modals/editCommRate';

import editCreditLimit from './modals/editCreditLimit';
import { actions as editCreditLimitActions } from './modals/editCreditLimit';

import createTransfer from './modals/createTransfer';
import { actions as createTransferActions } from './modals/createTransfer';

import batchTransfer from './modals/batchTransfer';
import { actions as batchTransferActions } from './modals/batchTransfer';

import balanceManager from './modals/balanceManager';
import { actions as balanceManagerActions } from './modals/balanceManager';

import creditManager from './modals/creditManager';
import { actions as creditManagerActions } from './modals/creditManager';

import batchEditCreateUser from './modals/batchEditCreateUser';
import { actions as batchEditCreateUserActions } from './modals/batchEditCreateUser';

import commissionManager from './modals/commissionManager';
import { actions as commissionManagerActions } from './modals/commissionManager';

import { toCamelCase, toCamelCaseAll } from '@mollybet/frontend-common/dist/lib/camelSnake';
import DSM from '@mollybet/frontend-common/dist/lib/DSM';
//import * as Sentry from '@sentry/browser';

import { fromJS, List } from 'immutable';

let initialState = fromJS({
  groups: {},
  customers: {},
  customersTicked: {},

  currentGroup: {},
  currentCustomer: {},

  isLoadingGroups: false,
  isLoadingGroupsSuper: false,
  isLoadingCustomers: false,
  isLoadingCustomersSuper: false,

  isLoadingStreams: {
    balance: false,
    xfer: false,
    discount: false,
    commissionDue: false,
    commission: false,
    plCommission: false,
    stake: false,
  },

  isDownloading: false,
  isDownloadFailed: false,

  searchBarFocusState: 'unfocused',
  searchString: '',
  showInactiveCustomers: false,
  showEmptyGroups: false,
  searchCustomers: true,
  searchGroups: false,

  membersOfGroup: {},
  activeMembersofGroup: {},
  customerProfile: {},
  isLoadingCustomerProfile: false,
  ptEvents: undefined,
  isLoadingPtEvents: false,
  ptEventSummary: undefined,
  isLoadingPtEventSummary: false,
  isLoadingPtConfigs: false,
  isLoadingPtConfigOverrides: false,
  isLoadingCompetitions: false,
  ptConfigs: undefined,
  ptConfigOverrides: undefined,
  competitions: undefined,

  foundItems: [],
  prevSection: 'customers',

  forexHist: [],
});

let ptConfigTimeout = null;

function applyFiltersToState(state) {
  let _groups = state.get('groups');
  let _customers = state.get('customers');
  let showInactiveCustomers = state.get('showInactiveCustomers', true);
  let showEmptyGroups = state.get('showEmptyGroups', true);
  let searchString = state.get('searchString', '').toLowerCase();
  if (searchString.length < 2) {
    searchString = '';
  }

  let searchCustomers = state.get('searchCustomers');
  let searchGroups = state.get('searchGroups');

  let foundItems = [];

  if (_groups) {
    _groups
      .toOrderedMap()
      .sortBy((v, _k) => v.get('name', ''))
      .forEach((group, groupId) => {
        let customers = group.get('customers', null);
        let groupName = group.get('name', '').toLowerCase();
        let foundCustomers = [];
        if (customers) {
          customers
            .toOrderedMap()
            .sortBy((v, k) => k)
            .forEach((_customer, username) => {
              let customer = _customers.get(username);
              if (customer) {
                if (!showInactiveCustomers && !customer.get('active', false)) {
                  return;
                }

                if (searchString) {
                  let name = customer.get('name', '').toLowerCase();
                  let username = customer.get('username', '').toLowerCase();
                  let userId = (customer.get('id', '') + '').toLowerCase();

                  let found = false;

                  if (searchCustomers) {
                    if (
                      name.indexOf(searchString) !== -1 ||
                      username.indexOf(searchString) !== -1 ||
                      userId.indexOf(searchString) !== -1
                    ) {
                      found = true;
                    }
                  }

                  if (searchGroups) {
                    if (
                      groupName.indexOf(searchString) !== -1 ||
                      groupId.indexOf(searchString) !== -1
                    ) {
                      found = true;
                    }
                  }

                  if (!found) {
                    return;
                  }
                }

                foundCustomers.push(customer);
              }
            });
        }

        if (!customers || !customers.size) {
          if (searchGroups && showEmptyGroups) {
            if (groupName.indexOf(searchString) !== -1 || groupId.indexOf(searchString) !== -1) {
              foundItems.push(group);
            }
          }
        }

        if (foundCustomers.length || (showEmptyGroups && !searchString)) {
          foundItems.push(group);
          foundItems = foundItems.concat(foundCustomers);
        }
      });
  }

  foundItems = List(foundItems);
  return state.set('foundItems', foundItems);
}

const functions = {
  ////// LOADING

  //load the groups list
  //honnor the super-user flag and do not return balances
  getGroups: (state, action) => {
    const filters = { balances: false };
    const extras = {};

    if (action.data.superuser != null) filters.honour_superuser = action.data.superuser;
    if (action.data.superuser != null) extras.superuser = action.data.superuser;
    if (action.data.balances != null) filters.balances = action.data.balances;

    DSM.last(
      `/v1/groups/`,
      {
        method: 'GET',
        body: filters,
        extras,
        message: 'loadGroups',
      },
      action.data.actions,
      'getGroups'
    );

    if (action.data.superuser) {
      return state.set('isLoadingGroupsSuper', true);
    } else {
      return state.set('isLoadingGroups', true);
    }
  },

  //load the list of customers
  //honnor super-user settings and respect the inactive filter
  //returning inactive customers is very expensive
  getCustomers: (state, action) => {
    if (action.data.superuser) {
      if (!action.data.format) {
        state = state.set('isLoadingCustomersSuper', true);
      } else {
        state = state.set('isDownloading', action.data.format);
      }
    } else {
      if (!action.data.format) {
        state = state.set('isLoadingCustomers', true);
      } else {
        state = state.set('isDownloading', action.data.format);
      }
    }

    let headers = null;
    if (action.data.format) {
      headers = {};
      if (action.data.format === 'csv') {
        headers['Accept'] = 'text/csv';
      } else if (action.data.format === 'xls') {
        headers['Accept'] = 'application/vnd.ms-excel';
      }
    }

    const filters = {};
    const extras = {};

    if (action.data.inactive != null) filters.inactive = action.data.inactive;
    if (filters.inactive == null) filters.inactive = state.get('showInactiveCustomers', false);
    if (action.data.hidden != null) filters.hidden = action.data.hidden;
    if (action.data.lastLogin != null) filters.last_login = action.data.lastLogin;
    if (action.data.permissions != null) filters.permissions = action.data.permissions;
    if (action.data.superuser != null) filters.honour_superuser = action.data.superuser;
    if (action.data.customer_ids != null)
      filters.customer_ids = Array.isArray(action.data.customer_ids)
        ? action.data.customer_ids.join(',')
        : action.data.customer_ids;
    if (action.data.superuser != null) extras.superuser = action.data.superuser;
    if (action.data.format != null) extras.format = action.data.format;

    DSM.last(
      '/v1/customers/',
      {
        method: 'GET',
        body: filters,
        headers,
        caseConvert: false,
        downloadAs: action.data.format ? `customers.${action.data.format}` : '',
        extras,
        message: 'loadCustomers',
      },
      action.data.actions,
      'getCustomers'
    );

    return state;
  },

  getPtConfigs: (state, action) => {
    DSM.create(
      '/web/ptevents/configs/',
      {
        method: 'GET',
        caseConvert: false,
        message: 'loadPtConfigs',
      },
      action.data.actions
    );
    state = state.set('isLoadingPtConfigs', true);
    return state;
  },

  loadPtConfigs: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('ptConfigs', fromJS([...action.data.data.configs]));
      state = state.set('isLoadingPtConfigs', false);
      return state;
    } else {
      state = state.set('isLoadingPtConfigs', false);
      return state;
    }
  },

  getPtConfigOverrides: (state, action) => {
    // Slight hack to stopp multiple requests being fired
    clearTimeout(ptConfigTimeout);
    ptConfigTimeout = setTimeout(
      () =>
        DSM.create(
          '/web/pt/configoverrides',
          {
            method: 'GET',
            caseConvert: false,
            message: 'loadPtConfigOverrides',
          },
          action.data.actions
        ),
      100
    );
    state = state.set('isLoadingPtConfigOverrides', true);
    return state;
  },

  loadPtConfigOverrides: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('ptConfigOverrides', fromJS([...action.data.data]));
      state = state.set('isLoadingPtConfigOverrides', false);
      return state;
    } else {
      state = state.set('isLoadingPtConfigOverrides', false);
      return state;
    }
  },

  createPtConfigOverride: (state, action) => {
    DSM.create(
      `/web/pt/configoverrides/`,
      {
        method: 'POST',
        caseConvert: false,
        message: 'getPtConfigOverrides',
        body: {
          customer_id: action.data.override.customer_id,
          group_id: action.data.override.group_id,
          strategy: action.data.override.strategy,
          sport: action.data.override.sport,
          lay_fraction: action.data.override.lay_fraction,
          exposure_limit: action.data.override.exposure_limit,
          notes: action.data.override.notes,
        },
      },
      action.data.actions
    );
    return state;
  },

  updatePtConfigOverride: (state, action) => {
    DSM.create(
      `/web/pt/configoverrides/${action.data.override.id}`,
      {
        method: 'PUT',
        caseConvert: false,
        message: 'getPtConfigOverrides',
        body: {
          lay_fraction: action.data.override.lay_fraction,
          exposure_limit: action.data.override.exposure_limit,
          notes: action.data.override.notes,
        },
      },
      action.data.actions
    );
    return state;
  },

  banPtConfigOverride: (state, action) => {
    DSM.create(
      `/web/pt/configoverrides/${action.data.override.id}`,
      {
        method: 'PUT',
        caseConvert: false,
        message: 'getPtConfigOverrides',
        body: { lay_fraction: 0, exposure_limit: 0 },
      },
      action.data.actions
    );
    return state;
  },

  deletePtConfigOverride: (state, action) => {
    DSM.create(
      `/web/pt/configoverrides/${action.data.override.id}`,
      {
        method: 'DELETE',
        caseConvert: false,
        message: 'getPtConfigOverrides',
      },
      action.data.actions
    );
    return state;
  },

  getCompetitions: (state, action) => {
    DSM.create(
      '/v1/competitions',
      {
        method: 'GET',
        caseConvert: false,
        message: 'loadCompetitions',
      },
      action.data.actions
    );
    state = state.set('isLoadingCompetitions', true);
    if (!state.get('competitions')) state = state.set('competitions', fromJS({}));
    action.data.ids.forEach(
      (competitionId) => (state = state.setIn(['competitions', parseInt(competitionId)], {}))
    );
    return state;
  },

  loadCompetitions: (state, action) => {
    if (action.data.status === 'ok') {
      const competitions = state.get('competitions');
      action.data.data.forEach((competition) => {
        if (competitions.has(competition.id)) {
          state = state.setIn(['competitions', competition.id], fromJS(competition));
        }
      });
      state = state.set('isLoadingCompetitions', false);
      return state;
    } else {
      state = state.set('isLoadingCompetitions', false);
      return state;
    }
  },

  getCustomerProfile: (state, action) => {
    const customer = action.data.customer;
    state = state.set('isLoadingCustomerProfile', true);
    DSM.create(
      `/web/pt/customers/${customer}`,
      {
        method: 'GET',
        caseConvert: false,
        message: 'loadCustomerProfile',
      },
      action.data.actions
    );
  },

  loadCustomerProfile: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('customerProfile', fromJS(action.data.data));
      state = state.set('isLoadingCustomerProfile', false);
      return state;
    } else {
      state = state.set('customerProfile', fromJS(['Error: Could not find customer']));
      state = state.set('isLoadingCustomerProfile', false);
      return state;
    }
  },

  getPtEvents: (state, action) => {
    const decision_maker = action.data.decision_maker;
    const sport = action.data.sport;
    let queryString = '';
    if (decision_maker && sport) {
      queryString = `?sport=${sport}&decision_maker=${decision_maker}`;
    } else if (decision_maker) {
      queryString = `?decision_maker=${decision_maker}`;
    } else if (sport) {
      queryString = `?sport=${sport}`;
    }
    DSM.create(
      `/web/ptevents/${queryString}`,
      {
        method: 'GET',
        caseConvert: false,
        message: 'loadPtEvents',
      },
      action.data.actions
    );
    state = state.set('isLoadingPtEvents', true);
    return state;
  },

  loadPtEvents: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('ptEvents', fromJS([...action.data.data.events]));
      state = state.set('isLoadingPtEvents', false);
      return state;
    } else {
      state = state.set('isLoadingPtEvents', false);
      return state;
    }
  },

  getPtEventInfoSuper: (state, action) => {
    const sport = action.data.sport;
    const eventId = action.data.eventId;
    const decision_maker = action.data.decision_maker;
    state = state.remove('ptEventSummary');
    DSM.create(
      `/web/ptevents/${sport}/${eventId}${
        decision_maker ? `?decision_maker=${decision_maker}` : ''
      }`,
      {
        method: 'GET',
        message: 'loadPtEventInfoSuper',
        extras: {
          sport,
        },
      },
      action.data.actions
    );
    state = state.set('isLoadingPtEventSummary', true);
    return state;
  },

  loadPtEventInfoSuper: (state, action) => {
    if (action.data.status === 'ok') {
      let totals = {};
      let entries;
      //in non-fb there are no entries and totals are inside position
      if (action.data.data.entries) {
        entries = action.data.data.entries;
      } else {
        entries = action.data.data.position;
      }
      for (let entry of entries) {
        totals[entry.betType] = {
          gotPrice: entry.weightedPriceDec,
          gotStake: ['GBP', entry.agentStakeGbp],
        };
      }
      let data = {
        byCustomer: action.data.data.byCustomer,
        position: action.data.data.position,
        positionType: action.data.data.positionType,
        ...action.data.extras,
        totals,
        eventInfo: {
          ...action.data.data.eventInfo,
          results: action.data.data.results,
        },
        isLoading: false,
      };
      state = state.set('ptEventSummary', fromJS(data));
      state = state.set('isLoadingPtEventSummary', false);
      return state;
    } else {
      state = state.set('isLoadingPtEventSummary', false);
      return state;
    }
  },

  //handle the group list data
  //have to take into account if superuser if not
  loadGroups: (state, action) => {
    if (action.data.status === 'ok') {
      let groups = {};
      for (let group of action.data.data) {
        groups[group.id] = group;
      }
      state = state.mergeDeepIn(['groups'], groups);
      state = applyFiltersToState(state);
      if (action.data.extras.superuser) {
        return state.set('isLoadingGroupsSuper', false);
      } else {
        return state.set('isLoadingGroups', false);
      }
    } else {
      //errors handled in base automatically
      return state;
    }
  },

  //handle the customer list
  //this also distributes them to groups
  loadCustomers: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }
      let groups = {};
      let customers = {};

      const activeMembersofGroup = {};
      const membersOfGroup = {};

      for (let customer of action.data.data) {
        //preserve tags
        let tags = {};
        if (customer.tags) {
          tags = { ...customer.tags };
        }
        customer = toCamelCaseAll(customer);
        customer.tags = tags;
        if (!groups[customer.groupId]) {
          groups[customer.groupId] = {
            customers: {},
            masterAgents: [],
          };
        }

        customers[customer.username] = customer;
        groups[customer.groupId].customers[customer.username] = true;

        if (customer.roles && customer.roles.indexOf('master_agent') !== -1) {
          groups[customer.groupId].masterAgents.push(customer.id);
        }

        //group activeness stuff
        if (typeof membersOfGroup[customer.groupId] === 'undefined') {
          membersOfGroup[customer.groupId] = 0;
        }
        if (typeof activeMembersofGroup[customer.groupId] === 'undefined') {
          activeMembersofGroup[customer.groupId] = 0;
        }
        membersOfGroup[customer.groupId]++;
        if (customer.active) {
          activeMembersofGroup[customer.groupId]++;
        }
      }

      //this is so that reloading customers fixes what groups they land in
      let _groups = state.get('groups');
      if (_groups) {
        _groups.forEach((group, groupId) => {
          state = state.removeIn(['groups', groupId, 'customers']);
        });
      }

      state = state.set('membersOfGroup', fromJS(membersOfGroup));
      state = state.set('activeMembersofGroup', fromJS(activeMembersofGroup));
      state = state.mergeDeepIn(['groups'], groups);
      state = state.mergeDeepIn(['customers'], customers);
      state = applyFiltersToState(state);

      if (action.data.extras.superuser) {
        return state.set('isLoadingCustomersSuper', false);
      } else {
        return state.set('isLoadingCustomers', false);
      }
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }

      //errors handled in base automatically
      return state;
    }
  },

  //load an individual customer
  //used mainly for refreshing one customer after an update
  loadCustomer: (state, action) => {
    if (action.data.status === 'ok') {
      //preserve tags
      let tags = {};
      if (action.data.data && action.data.data.tags) {
        tags = { ...action.data.data.tags };
      }
      action.data.data = toCamelCaseAll(action.data.data);
      action.data.data.tags = tags;

      let newGroupId = action.data.data.groupId + '';
      let oldGroupId = state.getIn(['customers', action.data.data.username, 'groupId'], null);
      state = state.mergeDeepIn(['customers', action.data.data.username], action.data.data);

      if (newGroupId !== oldGroupId) {
        //add to new group
        state = state.setIn(['groups', newGroupId, 'customers', action.data.data.username], true);
        //remove from old group
        state = state.removeIn(['groups', oldGroupId, 'customers', action.data.data.username]);
      }

      state = applyFiltersToState(state);
    } else {
      //errors handled in base automatically
    }

    return state;
  },

  ////// UPDATE CUSTOMER STATES

  //update the locked state of a customer
  unlockCustomerResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.setIn(['customers', action.data.extras.target, 'locked'], false);
    }

    return state;
  },

  //handle the changing of a user group
  changeCustomerGroupResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let newGroupId = action.data.extras.groupId;
      let customer = state.getIn(['customers', action.data.extras.target], null);

      if (customer) {
        //add to new group
        state = state.setIn(
          ['groups', newGroupId + '', 'customers', customer.get('username')],
          true
        );
        //remove from old group
        state = state.removeIn([
          'groups',
          customer.get('groupId'),
          'customers',
          customer.get('username'),
        ]);
        //update group id
        state = state.setIn(['customers', action.data.extras.target, 'groupId'], newGroupId + '');
      }

      return applyFiltersToState(state);
    }

    return state;
  },

  //update a customer when a customer has been edited or created
  editCreateCustomerResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let targetUser = action.data.extras.target;
      if (action.data.data && action.data.data.username) {
        targetUser = action.data.data.username;
      }
      //reload customer
      DSM.create(
        `/v1/customers/${targetUser}/`,
        {
          method: 'GET',
          caseConvert: false,
          message: 'loadCustomer',
        },
        action.data.actions
      );
    }

    return state;
  },

  //apply active-ness to all customers then apply filters
  toggleAllCustomersResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.setIn(
        ['customers', action.data.extras.target, 'active'],
        action.data.extras.active
      );
      return applyFiltersToState(state);
    } else {
      return state;
    }
  },

  //get customer balances
  getCustomersBalances: (state, action) => {
    if (action.data.username && action.data.streams && action.data.streams.length) {
      let streams = [];
      for (let stream of action.data.streams) {
        if (!state.getIn(['isLoadingStreams', toCamelCase(stream)], false)) {
          streams.push(stream);
        }
      }

      let headers = null;
      if (action.data.format) {
        headers = {};
        if (action.data.format === 'csv') {
          headers['Accept'] = 'text/csv';
        } else if (action.data.format === 'xls') {
          headers['Accept'] = 'application/vnd.ms-excel';
        }
      }

      DSM.create(
        `/v1/customers/${action.data.username}/balances/summary_by_user/`,
        {
          method: 'GET',
          body: {
            streams: action.data.format ? action.data.streams : streams,
            layout: action.data.format ? 'list' : '',
          },
          headers,
          downloadAs: action.data.format
            ? `customer-${action.data.type}.${action.data.format}`
            : '',
          extras: {
            streams,
            format: action.data.format,
          },
          caseConvert: false,
          message: 'getCustomersBalancesResponse',
        },
        action.data.actions
      );

      if (!action.data.format) {
        for (let stream of streams) {
          state = state.setIn(['isLoadingStreams', toCamelCase(stream)], true);
        }
      } else {
        state = state.set('isDownloading', action.data.format);
      }
    }

    return state;
  },

  //handle customer balances response
  getCustomersBalancesResponse: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }

      for (let customer in action.data.data) {
        state = state.mergeDeepIn(['customers', customer + ''], action.data.data[customer]);
      }
    }

    if (!action.data.extras.username) {
      for (let stream of action.data.extras.streams) {
        state = state.setIn(['isLoadingStreams', toCamelCase(stream)], false);
      }
    }

    //special case for download
    if (action.data.extras && action.data.extras.format) {
      state = state.set('isDownloading', false);
      state = state.set('isDownloadFailed', true);
      return state;
    }

    return applyFiltersToState(state);
  },

  //update credit limit if it's set
  setCreditLimitResponse: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.extras.targetUser) {
        state = state.setIn(
          ['customers', action.data.extras.targetUser + '', 'creditLimit'],
          fromJS(action.data.extras.creditLimit)
        );
      } else if (action.data.extras.targetGroup) {
        state = state.setIn(
          ['groups', action.data.extras.targetGroup + '', 'creditLimit'],
          fromJS(action.data.extras.creditLimit)
        );
      }

      return applyFiltersToState(state);
    } else {
      //errors handled in base automatically
      return state;
    }
  },

  //update comm rate response
  setCommRateResponse: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.extras.targetUser) {
        //HACK we should first check if it actually applies from this date
        try {
          if (action.data.extras.ratePc !== null) {
            state = state.setIn(
              ['customers', action.data.extras.targetUser + '', 'commissionRate', 'ratePc'],
              action.data.extras.ratePc
            );
          } else {
            state = state.removeIn([
              'customers',
              action.data.extras.targetUser + '',
              'commissionRate',
            ]);
          }
        } catch (err) {
          //setting the commissionRate is a best attempt thing anyway
          //Sentry.captureException(err);
        }
      }

      return applyFiltersToState(state);
    } else {
      //errors handled in base automatically
      return state;
    }
  },

  //on a transfer we need to update some of the customer accounting info for a specific customer
  newCustomerTransferResponse: (state, action) => {
    if (action.data.extras.targetUser) {
      DSM.create(
        `/v1/customers/${action.data.extras.currentUser}/balances/summary_by_user/`,
        {
          method: 'GET',
          body: {
            streams: action.data.extras.streams,
            username: action.data.extras.targetUser,
          },
          extras: {
            username: action.data.extras.targetUser,
          },
          message: 'getCustomersBalancesResponse',
        },
        action.data.actions
      );
    }

    return state;
  },

  ////// SEARCH BAR AND FILTERING

  //focus search bar
  focusSearchBarAdmin: (state, _action) => {
    return state.setIn(['searchBarFocusState'], 'focused');
  },

  //unfocus search bar
  unfocusSearchBarAdmin: (state, _action) => {
    return state.setIn(['searchBarFocusState'], 'unfocused');
  },

  //toggle showing active/inactive customers
  agentShowInactiveCustomerToggle: (state, _action) => {
    let curr = state.get('showInactiveCustomers', false);
    state = state.set('showInactiveCustomers', !curr);
    return applyFiltersToState(state);
  },

  //toggle showing/not empty group
  agentShowEmptyGroupToggle: (state, _action) => {
    let curr = state.get('showEmptyGroups', false);
    state = state.set('showEmptyGroups', !curr);
    return applyFiltersToState(state);
  },

  //react to the change in the search string
  agentChangeSearchString: (state, action) => {
    if (state.get('searchString') !== action.data.searchString) {
      state = state.set('searchString', action.data.searchString);
      state = applyFiltersToState(state);
    }

    return state;
  },

  //toggle searching in customer info
  agentSearchCustomerToggle: (state, _action) => {
    let curr = state.get('searchCustomers', false);
    state = state.set('searchCustomers', !curr);
    return applyFiltersToState(state);
  },

  //toggle searching in group details
  agentSearchGroupToggle: (state, _action) => {
    let curr = state.get('searchGroups', false);
    state = state.set('searchGroups', !curr);
    return applyFiltersToState(state);
  },

  //ticked customer selection
  //[UNUSED] ?
  toggleCustomerSelection: (state, action) => {
    let username = action.data.username;
    let value = action.data.value;

    if (username) {
      state = state.setIn(['customersTicked', username], value);
    }
    return state;
  },

  //ticked customer selection
  //[UNUSED] ?
  toggleGroupSelection: (state, action) => {
    let groupId = action.data.groupId;
    let value = action.data.value;
    let group = state.getIn(['groups', groupId + ''], null);

    if (group) {
      let customers = group.get('customers', null);
      if (customers) {
        customers.forEach((_customer, _username) => {
          state.get('foundItems').forEach((item) => {
            let username = item.get('username', '');
            if (username === _username) {
              state = state.setIn(['customersTicked', username], value);
            }
          });
        });
      }
    }

    return state;
  },

  //get group information from an agent's pov
  agentGetGroup: (state, action) => {
    if (action.data.group) {
      //reload customer
      DSM.last(
        `/v1/groups/${action.data.group}/`,
        {
          method: 'GET',
          message: 'agentGetGroupResponse',
        },
        action.data.actions,
        'agentGetGroup'
      );
    }

    return state;
  },

  //handle group data response
  agentGetGroupResponse: (state, action) => {
    return state.set('currentGroup', fromJS(action.data.data));
  },

  //get customer from an agent's pov
  agentGetCustomer: (state, action) => {
    if (action.data.username) {
      //reload customer
      DSM.last(
        `/v1/customers/${action.data.username}/`,
        {
          method: 'GET',
          message: 'agentGetCustomerResponse',
        },
        action.data.actions,
        'agentGetCustomer'
      );
    }

    return state;
  },

  //handle response for agent getting a customer
  agentGetCustomerResponse: (state, action) => {
    return state.set('currentCustomer', fromJS(action.data.data));
  },

  //group access list
  loadGroupWlAccessList: (state, action) => {
    if (action.data.groupId) {
      DSM.create(
        `/s/weblogin/group_allowed_whitelabels/${action.data.groupId}/`,
        {
          method: 'GET',
          message: 'loadGroupWlAccessListResponse',
          extras: {
            groupId: action.data.groupId,
          },
        },
        action.data.actions
      );
    }

    return state;
  },

  //group access list
  loadGroupWlAccessListResponse: (state, action) => {
    if (action.data) {
      state = state.setIn(
        ['groups', action.data.extras.groupId + '', 'wlAccessList'],
        fromJS(action.data)
      );
      state = applyFiltersToState(state);
    } else {
      //i guess error will catch
    }

    return state;
  },

  ////// NAV

  //agent go to previous section
  agentFromSection: (state, action) => {
    return state.set('prevSection', action.data.section);
  },

  loadAccas: (state, action) => {
    DSM.create(
      `/web/configs/accas/`,
      {
        method: 'GET',
        message: 'loadAccasResponse',
      },
      action.data.actions
    );

    return state;
  },

  loadAccasResponse: (state, action) => {
    if (action.data) {
      state = state.set(
        'accas',
        fromJS(
          action.data.data.reduce((acc, accas) => ({ ...acc, [accas.username]: { ...accas } }), {})
        )
      );
    }
    return state;
  },

  // agent currency stuff
  loadForexHist: (state, action) => {
    DSM.create(
      `/web/configs/forex/`,
      {
        method: 'GET',
        message: 'loadForexHistResponse',
      },
      action.data.actions
    );

    return state;
  },

  loadForexHistResponse: (state, action) => {
    if (action.data) state = state.set('forexHist', fromJS(action.data.data));
    return state;
  },

  updateForex: (state, action) => {
    const ccyCode = action.data.ccyCode;
    const rate = action.data.rate;

    DSM.create(
      `/web/configs/forex/${ccyCode}`,
      {
        method: 'PUT',
        message: 'updateForexResponse',
        body: { rate },
        extras: { ccyCode },
      },
      action.data.actions
    );

    return state;
  },

  updateForexResponse: (state, action) => {
    if (action.data)
      state = state.update('forexHist', (forexHist) => forexHist.unshift(action.data.data));
    return state;
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },
};

let base = function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
};

export let actions = {
  ...customerActions,
  ...bookiesActions,
  ...matrixActions,
  ...allTransfersActions,
  ...customerBalancesActions,
  ...customerCommissionRateActions,
  ...customerCreditLimitActions,
  ...customerSummaryActions,
  ...customerTransfersActions,
  ...groupCreditLimitActions,
  ...groupBookieSummaryActions,
  ...changeGroupActions,
  ...editCreateUserActions,
  ...editCustomerAccasActions,
  ...editCommRateActions,
  ...editCreditLimitActions,
  ...createTransferActions,
  ...batchTransferActions,
  ...balanceManagerActions,
  ...creditManagerActions,
  ...batchEditCreateUserActions,
  ...commissionManagerActions,
};

for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

const agentReducer = combineReducers({
  base,

  customers,
  bookies,
  matrix,
  allTransfers,

  //agent customer
  customerBalances,
  customerCommissionRate,
  customerCreditLimit,
  customerSummary,
  customerTransfers,

  //agent group
  groupCreditLimit,
  groupBookieSummary,

  //modals
  modals: combineReducers({
    changeGroup,
    editCreateUser,
    editCustomerAccas,
    editCommRate,
    editCreditLimit,
    createTransfer,
    batchTransfer,
    balanceManager,
    creditManager,
    batchEditCreateUser,
    commissionManager,
  }),
});

export default agentReducer;
