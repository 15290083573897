/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  accounts: [],
  bastats: {},
  isLoading: true,
  isSaving: false,
  isLoadingBastats: true,
  isRefreshing: {},
});

const functions = {
  //the thing that actually gets the bookie account data
  groupGetBookieAccounts: (state, action) => {
    DSM.last(
      `/v1/groups/${action.data.groupId}/bookie_accounts/`,
      {
        method: 'GET',
        message: 'groupLoadBookieAccounts',
      },
      action.data.actions,
      'groupGetBookieAccounts'
    );

    state = state.set('accounts', fromJS([]));
    return state.set('isLoading', true);
  },

  //handle the bookie account data
  groupLoadBookieAccounts: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('accounts', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //error handled by base
      return state;
    }
  },

  //set what bookie accounts are enabled
  groupSetBookieAccountsEnabled: (state, action) => {
    DSM.last(
      `/v1/groups/${action.data.groupId}/bookie_accounts/enable_accounts/`,
      {
        method: 'PUT',
        message: 'groupSetBookieAccountsEnabledResponse',
        body: {
          accounts: action.data.accounts,
        },
      },
      action.data.actions,
      'groupSetBookieAccountsEnabled'
    );

    state = state.set('isSaving', true);
    return state.set('isSaved', false);
  },

  //maybe have a confirmation or something?
  groupSetBookieAccountsEnabledResponse: (state, _action) => {
    //handled by agent base
    state = state.set('isSaving', false);
    return state.set('isSaved', true);
  },

  //set what bookie accounts are enabled
  groupSetBookiesEnabled: (state, action) => {
    DSM.last(
      `/v1/groups/${action.data.groupId}/bookie_accounts/enable_bookies/`,
      {
        method: 'PUT',
        message: 'groupSetBookiesEnabledResponse',
        body: {
          bookies: action.data.bookies,
        },
      },
      action.data.actions,
      'groupSetBookiesEnabled'
    );

    state = state.set('isSaving', true);
    return state.set('isSaved', false);
  },

  //maybe have a confirmation or something?
  groupSetBookiesEnabledResponse: (state, _action) => {
    //handled by agent base
    state = state.set('isSaving', false);
    return state.set('isSaved', true);
  },

  //refresh the bookie account balance
  groupRefreshBookieAccountBalance: (state, action) => {
    //DSM.create(`/v1/groups/${action.data.groupId}/bookie_accounts/kick_balance/`, {
    DSM.create(
      `/v1/customers/${action.data.currentUser}/bookie_accounts/kick_balance/`,
      {
        method: 'POST',
        body: {
          accountId: action.data.accountId,
        },
        extras: {
          accountId: action.data.accountId,
        },
        message: 'groupRefreshBookieAccountBalanceResponse',
      },
      action.data.actions
    );

    return state.setIn(['isRefreshing', action.data.accountId], true);
  },

  //populate with new balance result
  groupRefreshBookieAccountBalanceResponse: (state, action) => {
    let balance = '';
    if (action.data.status === 'ok') {
      balance = action.data.data.balance;
    }

    let accounts = state.getIn(['accounts']).toJS();
    //sadly we have to look for the account in an inefficient way
    for (let i in accounts) {
      if (accounts[i].id === action.data.extras.accountId) {
        accounts[i].bookieBalance = balance;
      }
    }

    state = state.set('accounts', fromJS(accounts));
    return state.setIn(['isRefreshing', action.data.extras.accountId], false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
