/**
 * @format
 */

import ZAFlag from '../../media/flags/south-africa.svg';
import ZMFlag from '../../media/flags/zambia.svg';
import ZWFlag from '../../media/flags/zimbabwe.svg';
import LYFlag from '../../media/flags/libya.svg';
import VNFlag from '../../media/flags/vietnam.svg';
import KGFlag from '../../media/flags/kyrgyzstan.svg';
import KHFlag from '../../media/flags/cambodia.svg';
import KIFlag from '../../media/flags/kiribati.svg';
import KMFlag from '../../media/flags/comoros.svg';
import KNFlag from '../../media/flags/saint-kitts-and-nevis.svg';
import KPFlag from '../../media/flags/north-korea.svg';
import KRFlag from '../../media/flags/south-korea.svg';
import KWFlag from '../../media/flags/kuwait.svg';
import KYFlag from '../../media/flags/cayman-islands.svg';
import KZFlag from '../../media/flags/kazakhstan.svg';
import LAFlag from '../../media/flags/laos.svg';
import LBFlag from '../../media/flags/lebanon.svg';
import LCFlag from '../../media/flags/st-lucia.svg';
import LIFlag from '../../media/flags/liechtenstein.svg';
import LKFlag from '../../media/flags/sri-lanka.svg';
import LRFlag from '../../media/flags/liberia.svg';
import LSFlag from '../../media/flags/lesotho.svg';
import LTFlag from '../../media/flags/lithuania.svg';
import LUFlag from '../../media/flags/luxembourg.svg';
import LVFlag from '../../media/flags/latvia.svg';
import MAFlag from '../../media/flags/morocco.svg';
import MCFlag from '../../media/flags/monaco.svg';
import MDFlag from '../../media/flags/moldova.svg';
import MEFlag from '../../media/flags/montenegro.svg';
import MGFlag from '../../media/flags/madagascar.svg';
import MHFlag from '../../media/flags/marshall-island.svg';
import MLFlag from '../../media/flags/mali.svg';
import MMFlag from '../../media/flags/myanmar.svg';
import MNFlag from '../../media/flags/mongolia.svg';
import MOFlag from '../../media/flags/macao.svg';
import MPFlag from '../../media/flags/northern-marianas-islands.svg';
import MQFlag from '../../media/flags/martinique.svg';
import MRFlag from '../../media/flags/mauritania.svg';
import MSFlag from '../../media/flags/montserrat.svg';
import MTFlag from '../../media/flags/malta.svg';
import MUFlag from '../../media/flags/mauritius.svg';
import MVFlag from '../../media/flags/maldives.svg';
import MWFlag from '../../media/flags/malawi.svg';
import MXFlag from '../../media/flags/mexico.svg';
import MYFlag from '../../media/flags/malaysia.svg';
import MZFlag from '../../media/flags/mozambique.svg';
import NAFlag from '../../media/flags/namibia.svg';
import NCFlag from '../../media/flags/france.svg';
import NEFlag from '../../media/flags/niger.svg';
import NFFlag from '../../media/flags/norfolk-island.svg';
import NGFlag from '../../media/flags/nigeria.svg';
import NIFlag from '../../media/flags/nicaragua.svg';
import NLFlag from '../../media/flags/netherlands.svg';
import NOFlag from '../../media/flags/norway.svg';
import NPFlag from '../../media/flags/nepal.svg';
import NRFlag from '../../media/flags/nauru.svg';
import NUFlag from '../../media/flags/niue.svg';
import NZFlag from '../../media/flags/new-zealand.svg';
import OMFlag from '../../media/flags/oman.svg';
import PAFlag from '../../media/flags/panama.svg';
import PEFlag from '../../media/flags/peru.svg';
import PFFlag from '../../media/flags/french-polynesia.svg';
import PGFlag from '../../media/flags/papua-new-guinea.svg';
import PHFlag from '../../media/flags/philippines.svg';
import PKFlag from '../../media/flags/pakistan.svg';
import PLFlag from '../../media/flags/republic-of-poland.svg';
import PMFlag from '../../media/flags/france.svg';
import PNFlag from '../../media/flags/pitcairn-islands.svg';
import PRFlag from '../../media/flags/puerto-rico.svg';
import PTFlag from '../../media/flags/portugal.svg';
import PWFlag from '../../media/flags/palau.svg';
import PYFlag from '../../media/flags/paraguay.svg';
import QAFlag from '../../media/flags/qatar.svg';
import ROFlag from '../../media/flags/romania.svg';
import RSFlag from '../../media/flags/serbia.svg';
import RUFlag from '../../media/flags/russia.svg';
import RWFlag from '../../media/flags/rwanda.svg';
import SAFlag from '../../media/flags/saudi-arabia.svg';
import SBFlag from '../../media/flags/solomon-islands.svg';
import SCFlag from '../../media/flags/seychelles.svg';
import SDFlag from '../../media/flags/sudan.svg';
import SEFlag from '../../media/flags/sweden.svg';
import SGFlag from '../../media/flags/singapore.svg';
import SIFlag from '../../media/flags/slovenia.svg';
import SKFlag from '../../media/flags/slovakia.svg';
import SLFlag from '../../media/flags/sierra-leone.svg';
import SMFlag from '../../media/flags/san-marino.svg';
import SNFlag from '../../media/flags/senegal.svg';
import SOFlag from '../../media/flags/somalia.svg';
import SRFlag from '../../media/flags/suriname.svg';
import STFlag from '../../media/flags/sao-tome-and-principe.svg';
import SVFlag from '../../media/flags/salvador.svg';
import SYFlag from '../../media/flags/syria.svg';
import SZFlag from '../../media/flags/swaziland.svg';
import TDFlag from '../../media/flags/chad.svg';
import TGFlag from '../../media/flags/togo.svg';
import THFlag from '../../media/flags/thailand.svg';
import TJFlag from '../../media/flags/tajikistan.svg';
import TKFlag from '../../media/flags/tokelau.svg';
import TLFlag from '../../media/flags/east-timor.svg';
import TMFlag from '../../media/flags/turkmenistan.svg';
import TNFlag from '../../media/flags/tunisia.svg';
import TOFlag from '../../media/flags/tonga.svg';
import TPFlag from '../../media/flags/east-timor.svg';
import TRFlag from '../../media/flags/turkey.svg';
import TTFlag from '../../media/flags/trinidad-and-tobago.svg';
import TVFlag from '../../media/flags/tuvalu.svg';
import TWFlag from '../../media/flags/taiwan.svg';
import TZFlag from '../../media/flags/tanzania.svg';
import UAFlag from '../../media/flags/ukraine.svg';
import UGFlag from '../../media/flags/uganda.svg';
import UKFlag from '../../media/flags/united-kingdom.svg';
import UMFlag from '../../media/flags/united-states-of-america.svg';
import USFlag from '../../media/flags/united-states-of-america.svg';
import UYFlag from '../../media/flags/uruguay.svg';
import UZFlag from '../../media/flags/uzbekistn.svg';
import VAFlag from '../../media/flags/vatican-city.svg';
import VCFlag from '../../media/flags/st-vincent-and-the-grenadines.svg';
import VEFlag from '../../media/flags/venezuela.svg';
import VGFlag from '../../media/flags/virgin-islands.svg';
import VIFlag from '../../media/flags/virgin-islands.svg';
import VUFlag from '../../media/flags/vanuatu.svg';
import WSFlag from '../../media/flags/samoa.svg';
import YEFlag from '../../media/flags/yemen.svg';
import ADFlag from '../../media/flags/andorra.svg';
import AEFlag from '../../media/flags/united-arab-emirates.svg';
import AFFlag from '../../media/flags/afghanistan.svg';
import AGFlag from '../../media/flags/antigua-and-barbuda.svg';
import AIFlag from '../../media/flags/anguilla.svg';
import ALFlag from '../../media/flags/albania.svg';
import AMFlag from '../../media/flags/armenia.svg';
import AOFlag from '../../media/flags/angola.svg';
import ARFlag from '../../media/flags/argentina.svg';
import ASFlag from '../../media/flags/american-samoa.svg';
import ATFlag from '../../media/flags/austria.svg';
import AUFlag from '../../media/flags/australia.svg';
import AWFlag from '../../media/flags/aruba.svg';
import AZFlag from '../../media/flags/azerbaijan.svg';
import BAFlag from '../../media/flags/bosnia-and-herzegovina.svg';
import BBFlag from '../../media/flags/barbados.svg';
import BDFlag from '../../media/flags/bangladesh.svg';
import BEFlag from '../../media/flags/belgium.svg';
import BFFlag from '../../media/flags/burkina-faso.svg';
import BGFlag from '../../media/flags/bulgaria.svg';
import BHFlag from '../../media/flags/bahrain.svg';
import BIFlag from '../../media/flags/burundi.svg';
import BJFlag from '../../media/flags/benin.svg';
import BMFlag from '../../media/flags/bermuda.svg';
import BNFlag from '../../media/flags/brunei.svg';
import BOFlag from '../../media/flags/bolivia.svg';
import BRFlag from '../../media/flags/brazil.svg';
import BSFlag from '../../media/flags/bahamas.svg';
import BTFlag from '../../media/flags/bhutan.svg';
import BVFlag from '../../media/flags/norway.svg';
import BWFlag from '../../media/flags/botswana.svg';
import BYFlag from '../../media/flags/belarus.svg';
import BZFlag from '../../media/flags/belize.svg';
import CAFlag from '../../media/flags/canada.svg';
import CCFlag from '../../media/flags/cocos-island.svg';
import CDFlag from '../../media/flags/democratic-republic-of-congo.svg';
import CFFlag from '../../media/flags/central-african-republic.svg';
import CGFlag from '../../media/flags/republic-of-the-congo.svg';
import CHFlag from '../../media/flags/switzerland.svg';
import CIFlag from '../../media/flags/ivory-coast.svg';
import CKFlag from '../../media/flags/cook-islands.svg';
import CLFlag from '../../media/flags/chile.svg';
import CMFlag from '../../media/flags/cameroon.svg';
import CNFlag from '../../media/flags/china.svg';
import COFlag from '../../media/flags/colombia.svg';
import CRFlag from '../../media/flags/costa-rica.svg';
import CUFlag from '../../media/flags/cuba.svg';
import CVFlag from '../../media/flags/cape-verde.svg';
import CXFlag from '../../media/flags/christmas-island.svg';
import CYFlag from '../../media/flags/cyprus.svg';
import CZFlag from '../../media/flags/czech-republic.svg';
import DEFlag from '../../media/flags/germany.svg';
import DJFlag from '../../media/flags/djibouti.svg';
import DKFlag from '../../media/flags/denmark.svg';
import DMFlag from '../../media/flags/dominica.svg';
import DOFlag from '../../media/flags/dominican-republic.svg';
import DZFlag from '../../media/flags/algeria.svg';
import ECFlag from '../../media/flags/ecuador.svg';
import EEFlag from '../../media/flags/estonia.svg';
import EGFlag from '../../media/flags/egypt.svg';
import EHFlag from '../../media/flags/western-sahara.svg';
import ERFlag from '../../media/flags/eritrea.svg';
import ESFlag from '../../media/flags/spain.svg';
import ETFlag from '../../media/flags/ethiopia.svg';
import FIFlag from '../../media/flags/finland.svg';
import FJFlag from '../../media/flags/fiji.svg';
import FKFlag from '../../media/flags/falkland-islands.svg';
import FMFlag from '../../media/flags/micronesia.svg';
import FOFlag from '../../media/flags/faroe-islands.svg';
import FRFlag from '../../media/flags/france.svg';
import GAFlag from '../../media/flags/gabon.svg';
import GDFlag from '../../media/flags/grenada.svg';
import GEFlag from '../../media/flags/georgia.svg';
import GFFlag from '../../media/flags/france.svg';
import GHFlag from '../../media/flags/ghana.svg';
import GIFlag from '../../media/flags/gibraltar.svg';
import GLFlag from '../../media/flags/greenland.svg';
import GMFlag from '../../media/flags/gambia.svg';
import GNFlag from '../../media/flags/guinea.svg';
import GPFlag from '../../media/flags/france.svg';
import GQFlag from '../../media/flags/equatorial-guinea.svg';
import GRFlag from '../../media/flags/greece.svg';
import GTFlag from '../../media/flags/guatemala.svg';
import GUFlag from '../../media/flags/guam.svg';
import GWFlag from '../../media/flags/guinea-bissau.svg';
import GYFlag from '../../media/flags/guyana.svg';
import HKFlag from '../../media/flags/hong-kong.svg';
import HNFlag from '../../media/flags/honduras.svg';
import HRFlag from '../../media/flags/croatia.svg';
import HTFlag from '../../media/flags/haiti.svg';
import HUFlag from '../../media/flags/hungary.svg';
import IDFlag from '../../media/flags/indonesia.svg';
import IEFlag from '../../media/flags/ireland.svg';
import ILFlag from '../../media/flags/israel.svg';
import INFlag from '../../media/flags/india.svg';
import IOFlag from '../../media/flags/british-indian-ocean-territory.svg';
import IQFlag from '../../media/flags/iraq.svg';
import IRFlag from '../../media/flags/iran.svg';
import ISFlag from '../../media/flags/iceland.svg';
import ITFlag from '../../media/flags/italy.svg';
import JMFlag from '../../media/flags/jamaica.svg';
import JOFlag from '../../media/flags/jordan.svg';
import JPFlag from '../../media/flags/japan.svg';
import KEFlag from '../../media/flags/kenya.svg';
import XSFlag from '../../media/flags/scotland.svg';
import XWFlag from '../../media/flags/wales.svg';
import XNFlag from '../../media/flags/united-kingdom.svg';
import XIFlag from '../../media/flags/ireland.svg';
import XXFlag from '../../media/flags/international.svg';
import XFFlag from '../../media/flags/CAF.svg';
import XAFlag from '../../media/flags/AFC.svg';
import XCFlag from '../../media/flags/CONCACAF.svg';
import XZFlag from '../../media/flags/CONMEBOL.svg';
import EUFlag from '../../media/flags/european-union.svg';
import XOFlag from '../../media/flags/OFC.svg';
import XEFlag from '../../media/flags/england.svg';
import QVFlag from '../../media/flags/kosovo.svg';
import wFlag from '../../media/flags/Wimbledon.svg';
import fFlag from '../../media/flags/FIFA.svg';
import MKFlag from '../../media/flags/republic-of-macedonia.svg';
import XUFlag from '../../media/flags/international.svg';
import XHFlag from '../../media/flags/IIHF.svg';
import XRFlag from '../../media/flags/international.svg';

const flags = {
  ZA: {
    flag: ZAFlag,
    name: 'South Africa',
  },
  ZM: {
    flag: ZMFlag,
    name: 'Zambia',
  },
  ZW: {
    flag: ZWFlag,
    name: 'Zimbabwe',
  },
  LY: {
    flag: LYFlag,
    name: 'Libya',
  },
  VN: {
    flag: VNFlag,
    name: 'Vietnam',
  },
  KG: {
    flag: KGFlag,
    name: 'Kyrgyzstan',
  },
  KH: {
    flag: KHFlag,
    name: 'Cambodia',
  },
  KI: {
    flag: KIFlag,
    name: 'Kiribati',
  },
  KM: {
    flag: KMFlag,
    name: 'Comoros',
  },
  KN: {
    flag: KNFlag,
    name: 'Saint Kitts And Nevis',
  },
  KP: {
    flag: KPFlag,
    name: "Korea (Democratic People's Republic of)",
  },
  KR: {
    flag: KRFlag,
    name: 'Korea (Republic of)',
  },
  KW: {
    flag: KWFlag,
    name: 'Kuwait',
  },
  KY: {
    flag: KYFlag,
    name: 'Cayman Islands',
  },
  KZ: {
    flag: KZFlag,
    name: 'Kazakhstan',
  },
  LA: {
    flag: LAFlag,
    name: "Lao People's Democratic Republic",
  },
  LB: {
    flag: LBFlag,
    name: 'Lebanon',
  },
  LC: {
    flag: LCFlag,
    name: 'Saint Lucia',
  },
  LI: {
    flag: LIFlag,
    name: 'Liechtenstein',
  },
  LK: {
    flag: LKFlag,
    name: 'Sri Lanka',
  },
  LR: {
    flag: LRFlag,
    name: 'Liberia',
  },
  LS: {
    flag: LSFlag,
    name: 'Lesotho',
  },
  LT: {
    flag: LTFlag,
    name: 'Lithuania',
  },
  LU: {
    flag: LUFlag,
    name: 'Luxembourg',
  },
  LV: {
    flag: LVFlag,
    name: 'Latvia',
  },
  MA: {
    flag: MAFlag,
    name: 'Morocco',
  },
  MC: {
    flag: MCFlag,
    name: 'Monaco',
  },
  MD: {
    flag: MDFlag,
    name: 'Moldova',
  },
  ME: {
    flag: MEFlag,
    name: 'Montenegro',
  },
  MG: {
    flag: MGFlag,
    name: 'Madagascar',
  },
  MH: {
    flag: MHFlag,
    name: 'Marshall Islands',
  },
  ML: {
    flag: MLFlag,
    name: 'Mali',
  },
  MM: {
    flag: MMFlag,
    name: 'Myanmar',
  },
  MN: {
    flag: MNFlag,
    name: 'Mongolia',
  },
  MO: {
    flag: MOFlag,
    name: 'Macau',
  },
  MP: {
    flag: MPFlag,
    name: 'Northern Mariana Islands',
  },
  MQ: {
    flag: MQFlag,
    name: 'Martinique',
  },
  MR: {
    flag: MRFlag,
    name: 'Mauritania',
  },
  MS: {
    flag: MSFlag,
    name: 'Montserrat',
  },
  MT: {
    flag: MTFlag,
    name: 'Malta',
  },
  MU: {
    flag: MUFlag,
    name: 'Mauritius',
  },
  MV: {
    flag: MVFlag,
    name: 'Maldives',
  },
  MW: {
    flag: MWFlag,
    name: 'Malawi',
  },
  MX: {
    flag: MXFlag,
    name: 'Mexico',
  },
  MY: {
    flag: MYFlag,
    name: 'Malaysia',
  },
  MZ: {
    flag: MZFlag,
    name: 'Mozambique',
  },
  NA: {
    flag: NAFlag,
    name: 'Namibia',
  },
  NC: {
    flag: NCFlag,
    name: 'New Caledonia',
  },
  NE: {
    flag: NEFlag,
    name: 'Niger',
  },
  NF: {
    flag: NFFlag,
    name: 'Norfolk Island',
  },
  NG: {
    flag: NGFlag,
    name: 'Nigeria',
  },
  NI: {
    flag: NIFlag,
    name: 'Nicaragua',
  },
  NL: {
    flag: NLFlag,
    name: 'Netherlands',
  },
  NO: {
    flag: NOFlag,
    name: 'Norway',
  },
  NP: {
    flag: NPFlag,
    name: 'Nepal',
  },
  NR: {
    flag: NRFlag,
    name: 'Nauru',
  },
  NU: {
    flag: NUFlag,
    name: 'Niue',
  },
  NZ: {
    flag: NZFlag,
    name: 'New Zealand',
  },
  OM: {
    flag: OMFlag,
    name: 'Oman',
  },
  PA: {
    flag: PAFlag,
    name: 'Panama',
  },
  PE: {
    flag: PEFlag,
    name: 'Peru',
  },
  PF: {
    flag: PFFlag,
    name: 'French Polynesia',
  },
  PG: {
    flag: PGFlag,
    name: 'Papua New Guinea',
  },
  PH: {
    flag: PHFlag,
    name: 'Philippines',
  },
  PK: {
    flag: PKFlag,
    name: 'Pakistan',
  },
  PL: {
    flag: PLFlag,
    name: 'Poland',
  },
  PM: {
    flag: PMFlag,
    name: 'Saint Pierre and Miquelon',
  },
  PN: {
    flag: PNFlag,
    name: 'Pitcairn',
  },
  PR: {
    flag: PRFlag,
    name: 'Puerto Rico',
  },
  PT: {
    flag: PTFlag,
    name: 'Portugal',
  },
  PW: {
    flag: PWFlag,
    name: 'Palau',
  },
  PY: {
    flag: PYFlag,
    name: 'Paraguay',
  },
  QA: {
    flag: QAFlag,
    name: 'Qatar',
  },
  RO: {
    flag: ROFlag,
    name: 'Romania',
  },
  RS: {
    flag: RSFlag,
    name: 'Serbia',
  },
  RU: {
    flag: RUFlag,
    name: 'Russian Federation',
  },
  RW: {
    flag: RWFlag,
    name: 'Rwanda',
  },
  SA: {
    flag: SAFlag,
    name: 'Saudi Arabia',
  },
  SB: {
    flag: SBFlag,
    name: 'Solomon Islands',
  },
  SC: {
    flag: SCFlag,
    name: 'Seychelles',
  },
  SD: {
    flag: SDFlag,
    name: 'Sudan',
  },
  SE: {
    flag: SEFlag,
    name: 'Sweden',
  },
  SG: {
    flag: SGFlag,
    name: 'Singapore',
  },
  SI: {
    flag: SIFlag,
    name: 'Slovenia',
  },
  SK: {
    flag: SKFlag,
    name: 'Slovakia (Slovak Republic)',
  },
  SL: {
    flag: SLFlag,
    name: 'Sierra Leone',
  },
  SM: {
    flag: SMFlag,
    name: 'San Marino',
  },
  SN: {
    flag: SNFlag,
    name: 'Senegal',
  },
  SO: {
    flag: SOFlag,
    name: 'Somalia',
  },
  SR: {
    flag: SRFlag,
    name: 'Suriname',
  },
  ST: {
    flag: STFlag,
    name: 'Sao Tome And Principe',
  },
  SV: {
    flag: SVFlag,
    name: 'El Salvador',
  },
  SY: {
    flag: SYFlag,
    name: 'Syrian Arab Republic',
  },
  SZ: {
    flag: SZFlag,
    name: 'Swaziland',
  },
  TD: {
    flag: TDFlag,
    name: 'Chad',
  },
  TG: {
    flag: TGFlag,
    name: 'Togo',
  },
  TH: {
    flag: THFlag,
    name: 'Thailand',
  },
  TJ: {
    flag: TJFlag,
    name: 'Tajikistan',
  },
  TK: {
    flag: TKFlag,
    name: 'Tokelau',
  },
  TL: {
    flag: TLFlag,
    name: 'Timor-Leste',
  },
  TM: {
    flag: TMFlag,
    name: 'Turkmenistan',
  },
  TN: {
    flag: TNFlag,
    name: 'Tunisia',
  },
  TO: {
    flag: TOFlag,
    name: 'Tonga',
  },
  TP: {
    flag: TPFlag,
    name: 'East Timor',
  },
  TR: {
    flag: TRFlag,
    name: 'Turkey',
  },
  TT: {
    flag: TTFlag,
    name: 'Trinidad And Tobago',
  },
  TV: {
    flag: TVFlag,
    name: 'Tuvalu',
  },
  TW: {
    flag: TWFlag,
    name: 'Taiwan',
  },
  TZ: {
    flag: TZFlag,
    name: 'Tanzania',
  },
  UA: {
    flag: UAFlag,
    name: 'Ukraine',
  },
  UG: {
    flag: UGFlag,
    name: 'Uganda',
  },
  UK: {
    flag: UKFlag,
    name: 'United Kingdom',
  },
  UM: {
    flag: UMFlag,
    name: 'United States Minor Outlying Islands',
  },
  US: {
    flag: USFlag,
    name: 'United States',
  },
  UY: {
    flag: UYFlag,
    name: 'Uruguay',
  },
  UZ: {
    flag: UZFlag,
    name: 'Uzbekistan',
  },
  VA: {
    flag: VAFlag,
    name: 'Holy See (Vatican City State)',
  },
  VC: {
    flag: VCFlag,
    name: 'Saint Vincent And The Grenadines',
  },
  VE: {
    flag: VEFlag,
    name: 'Venezuela',
  },
  VG: {
    flag: VGFlag,
    name: 'Virgin Islands (British)',
  },
  VI: {
    flag: VIFlag,
    name: 'Virgin Islands (U.S.)',
  },
  VU: {
    flag: VUFlag,
    name: 'Vanuatu',
  },
  WS: {
    flag: WSFlag,
    name: 'Samoa',
  },
  YE: {
    flag: YEFlag,
    name: 'Yemen',
  },
  AD: {
    flag: ADFlag,
    name: 'Andorra',
  },
  AE: {
    flag: AEFlag,
    name: 'United Arab Emirates',
  },
  AF: {
    flag: AFFlag,
    name: 'Afghanistan',
  },
  AG: {
    flag: AGFlag,
    name: 'Antigua and Barbuda',
  },
  AI: {
    flag: AIFlag,
    name: 'Anguilla',
  },
  AL: {
    flag: ALFlag,
    name: 'Albania',
  },
  AM: {
    flag: AMFlag,
    name: 'Armenia',
  },
  AO: {
    flag: AOFlag,
    name: 'Angola',
  },
  AR: {
    flag: ARFlag,
    name: 'Argentina',
  },
  AS: {
    flag: ASFlag,
    name: 'American Samoa',
  },
  AT: {
    flag: ATFlag,
    name: 'Austria',
  },
  AU: {
    flag: AUFlag,
    name: 'Australia',
  },
  AW: {
    flag: AWFlag,
    name: 'Aruba',
  },
  AZ: {
    flag: AZFlag,
    name: 'Azerbaijan',
  },
  BA: {
    flag: BAFlag,
    name: 'Bosnia and Herzegovina',
  },
  BB: {
    flag: BBFlag,
    name: 'Barbados',
  },
  BD: {
    flag: BDFlag,
    name: 'Bangladesh',
  },
  BE: {
    flag: BEFlag,
    name: 'Belgium',
  },
  BF: {
    flag: BFFlag,
    name: 'Burkina Faso',
  },
  BG: {
    flag: BGFlag,
    name: 'Bulgaria',
  },
  BH: {
    flag: BHFlag,
    name: 'Bahrain',
  },
  BI: {
    flag: BIFlag,
    name: 'Burundi',
  },
  BJ: {
    flag: BJFlag,
    name: 'Benin',
  },
  BM: {
    flag: BMFlag,
    name: 'Bermuda',
  },
  BN: {
    flag: BNFlag,
    name: 'Brunei Darussalam',
  },
  BO: {
    flag: BOFlag,
    name: 'Bolivia',
  },
  BR: {
    flag: BRFlag,
    name: 'Brazil',
  },
  BS: {
    flag: BSFlag,
    name: 'Bahamas',
  },
  BT: {
    flag: BTFlag,
    name: 'Bhutan',
  },
  BV: {
    flag: BVFlag,
    name: 'Bouvet Island',
  },
  BW: {
    flag: BWFlag,
    name: 'Botswana',
  },
  BY: {
    flag: BYFlag,
    name: 'Belarus',
  },
  BZ: {
    flag: BZFlag,
    name: 'Belize',
  },
  CA: {
    flag: CAFlag,
    name: 'Canada',
  },
  CC: {
    flag: CCFlag,
    name: 'Cocos (Keeling) Islands',
  },
  CD: {
    flag: CDFlag,
    name: 'Congo (Democratic Republic of the)',
  },
  CF: {
    flag: CFFlag,
    name: 'Central African Republic',
  },
  CG: {
    flag: CGFlag,
    name: 'Congo',
  },
  CH: {
    flag: CHFlag,
    name: 'Switzerland',
  },
  CI: {
    flag: CIFlag,
    name: "Côte d'Ivoire",
  },
  CK: {
    flag: CKFlag,
    name: 'Cook Islands',
  },
  CL: {
    flag: CLFlag,
    name: 'Chile',
  },
  CM: {
    flag: CMFlag,
    name: 'Cameroon',
  },
  CN: {
    flag: CNFlag,
    name: 'China',
  },
  CO: {
    flag: COFlag,
    name: 'Colombia',
  },
  CR: {
    flag: CRFlag,
    name: 'Costa Rica',
  },
  CU: {
    flag: CUFlag,
    name: 'Cuba',
  },
  CV: {
    flag: CVFlag,
    name: 'Cape Verde',
  },
  CX: {
    flag: CXFlag,
    name: 'Christmas Island',
  },
  CY: {
    flag: CYFlag,
    name: 'Cyprus',
  },
  CZ: {
    flag: CZFlag,
    name: 'Czech Republic',
  },
  DE: {
    flag: DEFlag,
    name: 'Germany',
  },
  DJ: {
    flag: DJFlag,
    name: 'Djibouti',
  },
  DK: {
    flag: DKFlag,
    name: 'Denmark',
  },
  DM: {
    flag: DMFlag,
    name: 'Dominica',
  },
  DO: {
    flag: DOFlag,
    name: 'Dominican Republic',
  },
  DZ: {
    flag: DZFlag,
    name: 'Algeria',
  },
  EC: {
    flag: ECFlag,
    name: 'Ecuador',
  },
  EE: {
    flag: EEFlag,
    name: 'Estonia',
  },
  EG: {
    flag: EGFlag,
    name: 'Egypt',
  },
  EH: {
    flag: EHFlag,
    name: 'Western Sahara',
  },
  ER: {
    flag: ERFlag,
    name: 'Eritrea',
  },
  ES: {
    flag: ESFlag,
    name: 'Spain',
  },
  ET: {
    flag: ETFlag,
    name: 'Ethiopia',
  },
  FI: {
    flag: FIFlag,
    name: 'Finland',
  },
  FJ: {
    flag: FJFlag,
    name: 'Fiji',
  },
  FK: {
    flag: FKFlag,
    name: 'Falkland Islands (Malvinas)',
  },
  FM: {
    flag: FMFlag,
    name: 'Micronesia',
  },
  FO: {
    flag: FOFlag,
    name: 'Faroe Islands',
  },
  FR: {
    flag: FRFlag,
    name: 'France',
  },
  GA: {
    flag: GAFlag,
    name: 'Gabon',
  },
  GD: {
    flag: GDFlag,
    name: 'Grenada',
  },
  GE: {
    flag: GEFlag,
    name: 'Georgia',
  },
  GF: {
    flag: GFFlag,
    name: 'French Guiana',
  },
  GH: {
    flag: GHFlag,
    name: 'Ghana',
  },
  GI: {
    flag: GIFlag,
    name: 'Gibraltar',
  },
  GL: {
    flag: GLFlag,
    name: 'Greenland',
  },
  GM: {
    flag: GMFlag,
    name: 'Gambia',
  },
  GN: {
    flag: GNFlag,
    name: 'Guinea',
  },
  GP: {
    flag: GPFlag,
    name: 'Guadeloupe',
  },
  GQ: {
    flag: GQFlag,
    name: 'Equatorial Guinea',
  },
  GR: {
    flag: GRFlag,
    name: 'Greece',
  },
  GT: {
    flag: GTFlag,
    name: 'Guatemala',
  },
  GU: {
    flag: GUFlag,
    name: 'Guam',
  },
  GW: {
    flag: GWFlag,
    name: 'Guinea-Bissau',
  },
  GY: {
    flag: GYFlag,
    name: 'Guyana',
  },
  HK: {
    flag: HKFlag,
    name: 'Hong Kong',
  },
  HN: {
    flag: HNFlag,
    name: 'Honduras',
  },
  HR: {
    flag: HRFlag,
    name: 'Croatia (Hrvatska)',
  },
  HT: {
    flag: HTFlag,
    name: 'Haiti',
  },
  HU: {
    flag: HUFlag,
    name: 'Hungary',
  },
  ID: {
    flag: IDFlag,
    name: 'Indonesia',
  },
  IE: {
    flag: IEFlag,
    name: 'Ireland',
  },
  IL: {
    flag: ILFlag,
    name: 'Israel',
  },
  IN: {
    flag: INFlag,
    name: 'India',
  },
  IO: {
    flag: IOFlag,
    name: 'British Indian Ocean Territory',
  },
  IQ: {
    flag: IQFlag,
    name: 'Iraq',
  },
  IR: {
    flag: IRFlag,
    name: 'Iran (Islamic Republic Of)',
  },
  IS: {
    flag: ISFlag,
    name: 'Iceland',
  },
  IT: {
    flag: ITFlag,
    name: 'Italy',
  },
  JM: {
    flag: JMFlag,
    name: 'Jamaica',
  },
  JO: {
    flag: JOFlag,
    name: 'Jordan',
  },
  JP: {
    flag: JPFlag,
    name: 'Japan',
  },
  KE: {
    flag: KEFlag,
    name: 'Kenya',
  },
  XS: {
    flag: XSFlag,
    name: 'Scotland',
  },
  XW: {
    flag: XWFlag,
    name: 'Wales',
  },
  XN: {
    flag: XNFlag,
    name: 'Northern Ireland',
  },
  XI: {
    flag: XIFlag,
    name: 'Ireland (Combined)',
  },
  XX: {
    flag: XXFlag,
    name: 'International',
  },
  XF: {
    flag: XFFlag,
    name: 'FIFA Region: CAF (Africa)',
  },
  XA: {
    flag: XAFlag,
    name: 'FIFA Region: AFC (Asia)',
  },
  XC: {
    flag: XCFlag,
    name: 'FIFA Region: CONCACAF (North America)',
  },
  XZ: {
    flag: XZFlag,
    name: 'FIFA Region: CONMEBOL (South America)',
  },
  EU: {
    flag: EUFlag,
    name: 'FIFA Region: UEFA (Europe)',
  },
  XO: {
    flag: XOFlag,
    name: 'FIFA Region: OFC (Oceania)',
  },
  XE: {
    flag: XEFlag,
    name: 'England',
  },
  QV: {
    flag: QVFlag,
    name: 'Kosovo',
  },
  '.w': {
    flag: wFlag,
    name: 'Wimbledon',
  },
  '.f': {
    flag: fFlag,
    name: 'International world cup',
  },
  MK: {
    flag: MKFlag,
    name: 'Macedonia',
  },
  XU: {
    flag: XUFlag,
    name: 'Unknown',
  },
  XH: {
    flag: XHFlag,
    name: 'IIHF (club teams)',
  },
  XR: {
    flag: XRFlag,
    name: 'FIFA Region: UAFA (Arab FAs)',
  },
};

export const getFlag = (country) => (country ? flags[country.toUpperCase()] || flags.XX : flags.XX);

export default flags;
