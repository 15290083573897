/** @format */
import React, { useCallback, useContext } from 'react';
import { actions } from '../../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FlexContainerElement, FavouriteContainer } from '../elements';
import {
  CompetitionMarketElement,
  CompetitionName,
  CompetitionSearchResultDisplayElement,
  FlagElement,
} from './elements';
import { getBaseSport } from '@mollybet/frontend-common/dist/lib/trade';
import { getFlag } from '../../../lib/assets/flags';
import { Icon } from '../../interface';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { SettingsContext } from '../../shared/SettingsContext';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import ComponentErrorBoundary from '../../error-boundary/ComponentErrorBoundary';

const CompetitionSearchResultDisplay = ({
  name,
  ir,
  today,
  early,
  country,
  isInFavs,
  competitionId,
  actions,
  sport,
  watchingIr,
  watchingToday,
  watchingEarly,
  irExpanded,
  todayExpanded,
  earlyExpanded,
  sideBarIsOpen,
}) => {
  const { pricesSync, pricesBookies, contentLayout } = useContext(SettingsContext);
  const theme = useTheme();
  const history = useHistory();
  const {
    competitionAddFav,
    competitionRemoveFav,
    competitionSubscribe,
    competitionUnsubscribe,
    unfocusSearchBar,
    setSearchBarValue,
    marketMainToggle,
    toggleTradeSide,
  } = actions;

  const scrollToItem = useCallback(
    (prefMarket, competitionId) => {
      const scrollTarget = `${prefMarket}_${competitionId}`;
      if (document) {
        const competitionDisplayLine = document.getElementsByClassName(scrollTarget)[0];
        const tradePage = document.getElementsByClassName('view-contents')[0];
        if (competitionDisplayLine && tradePage) {
          const background = competitionDisplayLine.style.background;
          competitionDisplayLine.style.background = theme.positive;
          setTimeout(() => {
            competitionDisplayLine.style.background = background;
          }, 500);
          tradePage.scrollTop = competitionDisplayLine.offsetTop - 40;
        }
      }
    },
    [theme.positive]
  );

  const addCompFav = useCallback(
    (e) => {
      e.stopPropagation();
      competitionAddFav({
        competitionId,
        marketId: 'early',
        sport,
      });
    },
    [competitionAddFav, competitionId, sport]
  );

  const removeCompFav = useCallback(
    (e) => {
      e.stopPropagation();
      competitionRemoveFav({
        competitionId,
        marketId: 'early',
      });
    },
    [competitionRemoveFav, competitionId]
  );

  const toggleSubscribe = (marketId, isWatching) => (e) => {
    e.stopPropagation();
    if (isWatching) {
      competitionUnsubscribe({
        competitionId,
        sport,
        marketId,
      });
    } else {
      competitionSubscribe({
        competitionId,
        marketId,
        sport,
        pricesSync: pricesSync,
        actions,
        bookies: pricesBookies ? pricesBookies.toJS() : [],
      });
    }
  };

  const handleClick = useCallback(
    (prefMarket) => (e) => {
      if (sideBarIsOpen && contentLayout === 'narrow') {
        toggleTradeSide();
      }
      const avalaibleMarkets = { early: early, today: today, ir: ir };
      if (prefMarket === undefined) {
        for (let marketId in avalaibleMarkets) {
          if (avalaibleMarkets[marketId]) {
            prefMarket = marketId;
            competitionSubscribe({
              competitionId,
              sport,
              marketId,
            });
          }
        }
      } else {
        competitionSubscribe({
          competitionId,
          sport,
          marketId: prefMarket,
        });
      }
      if (!history.location.pathname.includes(`/trade/${sport}`)) {
        history.push(`/trade/${sport}`);
      }
      const expandedMarkets = { ir: irExpanded, today: todayExpanded, early: earlyExpanded };
      if (!expandedMarkets[prefMarket]) {
        marketMainToggle({
          marketId: prefMarket,
        });
      }
      e.stopPropagation();
      // which market do we scroll to? preference IR-today-early
      setTimeout(() => scrollToItem(prefMarket, competitionId), 200);
      unfocusSearchBar();
      setSearchBarValue('');
    },
    [
      competitionId,
      ir,
      today,
      early,
      sport,
      competitionSubscribe,
      history,
      unfocusSearchBar,
      setSearchBarValue,
      scrollToItem,
      irExpanded,
      todayExpanded,
      earlyExpanded,
      marketMainToggle,
      sideBarIsOpen,
      contentLayout,
    ]
  );

  return (
    <ComponentErrorBoundary>
      <CompetitionSearchResultDisplayElement onClick={handleClick()}>
        <FlexContainerElement>
          <FlagElement alt={country} src={getFlag(country).flag} />
          <CompetitionName>{name}</CompetitionName>
        </FlexContainerElement>
        <FlexContainerElement>
          <CompetitionMarketElement
            hide={ir}
            marketId={'ir'}
            watched={watchingIr}
            //onClick={toggleSubscribe('ir', watchingIr)}
            onClick={handleClick('ir')}
          >
            <FormattedMessage id="search.inRunningShort" defaultMessage="IR" />
          </CompetitionMarketElement>
          <CompetitionMarketElement
            hide={today}
            marketId={'today'}
            watched={watchingToday}
            //onClick={toggleSubscribe('today', watchingToday)}
            onClick={handleClick('today')}
          >
            <FormattedMessage id="search.today" defaultMessage="Today" />
          </CompetitionMarketElement>
          <CompetitionMarketElement
            hide={early}
            marketId={'early'}
            watched={watchingEarly}
            //onClick={toggleSubscribe('early', watchingEarly)}
            onClick={handleClick('early')}
          >
            <FormattedMessage id="search.early" defaultMessage="Early" />
          </CompetitionMarketElement>
          <FavouriteContainer>
            <Icon
              width={22}
              height={22}
              button="true"
              onClick={isInFavs ? removeCompFav : addCompFav}
              className="expand-icon"
              color={isInFavs ? '#DAA520' : '#323232'}
              path={isInFavs ? mdiStar : mdiStarOutline}
            />
          </FavouriteContainer>
        </FlexContainerElement>
      </CompetitionSearchResultDisplayElement>
    </ComponentErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, ownProps) => {
  const baseSport = getBaseSport(ownProps.sport);
  const competitionId = ownProps.competitionId;
  return {
    ir: state.getIn(['trade', 'markets', baseSport, 'ir', competitionId], false),
    today: state.getIn(['trade', 'markets', baseSport, 'today', competitionId], false),
    early: state.getIn(['trade', 'markets', baseSport, 'early', competitionId], false),
    isInFavs: state.getIn(
      ['base', 'settings', 'trade', 'faved', 'competitions', competitionId],
      false
    ),
    //competition: state.getIn(['trade', 'competitions', competitionId], null),
    watchingIr: state.getIn(['trade', 'competitions', competitionId, 'isSubscribed', 'ir'], false),
    watchingToday: state.getIn(
      ['trade', 'competitions', competitionId, 'isSubscribed', 'today'],
      false
    ),
    watchingEarly: state.getIn(
      ['trade', 'competitions', competitionId, 'isSubscribed', 'early'],
      false
    ),
    irExpanded: state.getIn(
      ['ui', 'settings', 'trade', 'expanded', 'main', 'markets', 'ir'],
      false
    ),
    todayExpanded: state.getIn(
      ['ui', 'settings', 'trade', 'expanded', 'main', 'markets', 'today'],
      false
    ),
    earlyExpanded: state.getIn(
      ['ui', 'settings', 'trade', 'expanded', 'main', 'markets', 'early'],
      false
    ),
    sideBarIsOpen: state.getIn(['ui', 'settings', 'trade', 'side', 'open'], true),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionSearchResultDisplay);
