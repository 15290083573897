/** @format */
import React, { useCallback } from 'react';
import ComponentErrorBoundary from '../../error-boundary/ComponentErrorBoundary';
import { SportResultDisplayElement } from './elements';

const SportResultDisplay = ({
  sportConfig,
  hasResults,
  currentFilterSport,
  setCurrentFilterSport,
}) => {
  const sportId = sportConfig.id;
  const sportName = sportConfig.name.defaultMessage;
  const setFilterSport = useCallback(() => {
    setCurrentFilterSport(sportId);
  }, [setCurrentFilterSport, sportId]);
  return (
    <ComponentErrorBoundary>
      <SportResultDisplayElement
        hasResults={hasResults}
        onClick={setFilterSport}
        active={sportId === currentFilterSport}
      >
        {sportName}
      </SportResultDisplayElement>
    </ComponentErrorBoundary>
  );
};

export default SportResultDisplay;
