/** @format */

import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from '../../components/interface';
import { mdiCurrencyUsd } from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import ComponentErrorBoundary from '../error-boundary/ComponentErrorBoundary';

const ExternalLink = styled.a`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colours.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 12px;
  box-sizing: border-box;

  &:hover {
    background: radial-gradient(#060606, #1e1e1e);
  }
`;

export const DepositLink = ({ props, context, requestToken }) => {
  // context.featureDepositButton is set by profile's config in frontend_common/src/reducers/base.js
  // so only certain accounts can see depositButton
  const externalUrl = useMemo(() => {
    if (
      Whitelabel?.externalUrls &&
      Whitelabel?.externalUrls?.deposit &&
      context.featureDepositButton
    ) {
      let redirectUrl = Whitelabel?.externalUrls?.deposit;
      if (props.externalURLsToken) {
        let target = redirectUrl.replace('{{token}}', props.externalURLsToken);
        if (props.externalURLsLang) {
          target = target.replace('{{lang}}', props.externalURLsLang);
        }
        return target;
      }
      return redirectUrl;
    }
    return '';
  }, [props.externalURLsToken, props.externalURLsLang, Whitelabel?.externalUrls?.deposit]);

  useEffect(() => {
    if (Whitelabel?.externalUrls?.deposit && context?.featureDepositButton)
      try {
        requestToken(Whitelabel?.externalUrls?.deposit)();
      } catch (err) {
        console.warn('err requesting deposit page token: ', err);
      }
  }, [Whitelabel?.externalUrls?.deposit, context, context?.featureDepositButton, requestToken]);

  return (
    <ComponentErrorBoundary>
      {Whitelabel?.externalUrls &&
      Whitelabel?.externalUrls?.deposit &&
      context.featureDepositButton ? (
        <ExternalLink href={externalUrl} target="_blank">
          <Icon
            color={props.theme.colours.white}
            path={mdiCurrencyUsd}
            width={24}
            height={24}
            style={{ marginRight: '6px' }}
          />
          <FormattedMessage id="menu.money.deposit" defaultMessage="Deposit" />
        </ExternalLink>
      ) : null}
    </ComponentErrorBoundary>
  );
};
