/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (
  <>
    <FormattedMessage id="nav.routes.login_reset" defaultMessage="Reset Password" />
    <FormattedMessage id="nav.routes.login" defaultMessage="Login" />
    <FormattedMessage id="nav.routes.register" defaultMessage="Register" />
    <FormattedMessage id="nav.routes.trade" defaultMessage="Trade" />
    <FormattedMessage id="nav.routes.openOrdersNarrow" defaultMessage="Open Orders" />
    <FormattedMessage id="nav.routes.history.statement" defaultMessage="My Orders" />
    <FormattedMessage id="nav.routes.mobileMenu" defaultMessage="Dashboard" />
    <FormattedMessage id="nav.routes.history.pl" defaultMessage="Analytics" />
    <FormattedMessage id="nav.routes.activePositions" defaultMessage="My Positions" />
    <FormattedMessage id="nav.routes.history" defaultMessage="History" />
    <FormattedMessage id="nav.routes.history.orders" defaultMessage="My Orders" />
    <FormattedMessage id="nav.routes.history.bets" defaultMessage="Analytics" />
    <FormattedMessage id="nav.routes.stream" defaultMessage="Stream" />
    <FormattedMessage id="nav.routes.history.accounting.balances" defaultMessage="Accounting" />
    <FormattedMessage id="nav.routes.history.accounting.plByAgent" defaultMessage="P/L By Agent" />
    <FormattedMessage id="nav.routes.xrates" defaultMessage="Exch. Rates" />
    <FormattedMessage id="nav.routes.bookies" defaultMessage="Bookies" />
    <FormattedMessage id="nav.routes.myAccount" defaultMessage="My Account" />
    <FormattedMessage id="nav.routes.settings" defaultMessage="Settings" />
    <FormattedMessage id="nav.routes.banking" defaultMessage="Banking" />
    <FormattedMessage id="nav.routes.agent" defaultMessage="Agent" />
    <FormattedMessage id="nav.routes.agent.customers" defaultMessage="Customers" />
    <FormattedMessage id="nav.routes.agent.balances" defaultMessage="Balances" />
    <FormattedMessage id="nav.routes.agent.statements" defaultMessage="Statements" />
    <FormattedMessage id="nav.routes.agent.transfers" defaultMessage="Transfers" />
    <FormattedMessage id="nav.routes.agent.allTransfers" defaultMessage="All Transfers" />
    <FormattedMessage id="nav.routes.agent.bookies" defaultMessage="Bookies" />
    <FormattedMessage id="nav.routes.agent.accountProblems" defaultMessage="Acc. Problems" />
    <FormattedMessage id="nav.routes.agent.PLbyGroup" defaultMessage="P/L by Group" />
    <FormattedMessage id="nav.routes.agent.PLbyAgent" defaultMessage="P/L by Agent" />
    <FormattedMessage id="nav.routes.agent.customerSummary" defaultMessage="Customer Summary" />
    <FormattedMessage id="nav.routes.agent.customerBalances" defaultMessage="Customer Statements" />
    <FormattedMessage id="nav.routes.agent.customerStatements" defaultMessage="Customer Balances" />
    <FormattedMessage
      id="nav.routes.agent.customerBookieBalances"
      defaultMessage="Customer Bookie Balances"
    />
    <FormattedMessage
      id="nav.routes.agent.customerCommissionRate"
      defaultMessage="Customer Commission Rate"
    />
    <FormattedMessage
      id="nav.routes.agent.customerCreditLimit"
      defaultMessage="Customer Credit Limit"
    />
    <FormattedMessage id="nav.routes.agent.customerTransfers" defaultMessage="Customer Transfers" />
    <FormattedMessage id="nav.routes.agent.groupCreditLimit" defaultMessage="Group Credit Limit" />
    <FormattedMessage id="nav.routes.agent.groupTagRules" defaultMessage="Group Tag Rules" />
    <FormattedMessage
      id="nav.routes.agent.groupBookieSummary"
      defaultMessage="Group Bookie summary"
    />
    <FormattedMessage id="nav.routes.Admin" defaultMessage="Admin" />
    <FormattedMessage id="nav.routes.admin.customers" defaultMessage="Customers" />
    <FormattedMessage id="nav.routes.admin.online" defaultMessage="Online" />
    <FormattedMessage id="nav.routes.admin.ticker" defaultMessage="Tickers" />
    <FormattedMessage id="nav.routes.admin.xadmin" defaultMessage="XAdmin" />
    <FormattedMessage id="nav.routes.admin.gargoyle" defaultMessage="Gargoyle" />
    <FormattedMessage id="nav.routes.useMobile" defaultMessage="Use Mobile" />
    <FormattedMessage id="nav.routes.logout" defaultMessage="Logout" />
    <FormattedMessage id="nav.routes.terms" defaultMessage="Terms" />
    <FormattedMessage id="nav.routes.unsudo" defaultMessage="Unsudo" />
  </>
);
