/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';
import config from '../../../config';

import { fromJS } from 'immutable';
let initialState = fromJS({
  formOpen: false,
  formData: [
    {
      username: '',
      amount: '',
      description: '',
      status: '',
      errorInfo: '',
      ccyCode: '',
      creditLimit: '',
    },
  ],
});

const functions = {
  //updates the credit transfer forms
  updateCreditFormData: (state, action) => {
    return state.set('formData', fromJS(action.data.formData));
  },

  //opens/ closes the credit transfer modal
  toggleCreditManager: (state, _action) => {
    const formOpen = state.get('formOpen');
    return state.set('formOpen', !formOpen);
  },

  //do a bunch of transfers
  batchCreditTransfer: (state, action) => {
    const transferData = Object.values(action.data.data);
    const batchLength = transferData.length;

    for (let j = 0; j < batchLength; j++) {
      transferData[j]['status'] = 'pending';
      transferData[j]['errorInfo'] = '';
      state = state.set('formData', fromJS(transferData));
    }

    // func called once for each form row (each transfer)
    let i = 0;
    const makeTransfer = () => {
      DSM.create(
        `/v1/customers/${action.data.data[i]['username']}/`,
        {
          method: 'PATCH',
          body: {
            creditLimitComment: action.data.data[i]['description'],
            creditLimit: [action.data.data[i]['ccyCode'], action.data.data[i]['amount']],
          },
          extras: {
            transfer: {
              index: i,
              username: action.data.data[i]['username'],
              formData: Object.values(action.data.data),
            },
            targetUser: action.data.data[i]['username'],
            creditLimit: [action.data.data[i]['ccyCode'], action.data.data.amount],
          },
          message: 'setCreditLimitResponse',
        },
        action.data.actions
      );

      i++;
      if (i < batchLength) {
        setTimeout(makeTransfer, config.timings.timeBetweenBatchCalls);
      }
    };

    if (batchLength) {
      makeTransfer();
    }
    return state;
  },

  //records the status of credit transfer calls
  setCreditLimitResponse: (state, action) => {
    if (action.data.extras.transfer) {
      let formData = action.data.extras.transfer.formData;
      if (action.data.status === 'ok') {
        formData[action.data.extras.transfer.index]['status'] = 'success';
        state = state.set('formData', fromJS(formData));
      } else {
        const parsedError = extractErrorMessage(action.data.data);
        formData[action.data.extras.transfer.index]['status'] = 'failure';
        formData[action.data.extras.transfer.index]['errorInfo'] = parsedError;
        state = state.set('formData', fromJS(formData));
      }
    }
    return state;
  },

  //makes a transfer attempt again for a single row
  retryCreditTransfer: (state, action) => {
    let formData = state.get('formData');
    formData = Object.values(formData.toJS());
    const i = action.data.index;
    DSM.create(
      `/v1/customers/${formData[i]['username']}/`,
      {
        method: 'PATCH',
        body: {
          creditLimitComment: formData[i]['description'],
          creditLimit: [formData[i]['ccyCode'], formData[i]['amount']],
        },
        extras: {
          transfer: {
            index: i,
            username: formData[i]['username'],
            formData: formData,
          },
          targetUser: formData[i]['username'],
          creditLimit: [formData[i]['ccyCode'], formData['amount']],
        },
        message: 'setCreditLimitResponse',
      },
      action.data.actions
    );

    formData[i]['status'] = 'pending';
    formData[i]['errorInfo'] = '';
    return state.set('formData', fromJS(formData));
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
