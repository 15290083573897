/** @format */
import config from './config';
import Session from '@mollybet/frontend-common/dist/lib/Session';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '189b8ad9-68af-42e8-83f6-5608d131f9e3',
  clientToken: 'pub1e2b9f6f3c80cd5002da5b277641f53c',
  site: 'datadoghq.eu',
  service: 'sonic',
  env: !(window.location.host.includes('skaffold') || window.location.host.includes('sandbox'))
    ? 'production'
    : 'development',
  sessionSampleRate: !(
    window.location.host.includes('skaffold') || window.location.host.includes('sandbox')
  )
    ? 100
    : 0,
  sessionReplaySampleRate: 0,
  trackUserInteractions: false,
  trackResources: false,
  trackLongTasks: false,
  defaultPrivacyLevel: 'allow',
});

export const datadog = datadogRum;
datadog.setGlobalContextProperty('is_iframe', false);
datadog.setGlobalContextProperty(
  'mediaType',
  window.innerWidth <= 420
    ? 'smallMobile'
    : window.innerWidth <= config.narrowBreakpointContents
      ? 'mobile'
      : window.innerWidth <= 1087
        ? 'tablet'
        : window.innerWidth <= 1280
          ? 'smallLaptop'
          : window.innerWidth <= 1599
            ? 'laptop'
            : 'desktop',
);
datadog.setGlobalContextProperty('isLegacy', true);

const SHOW_LOGS_COOKIE = 'debug.showTimingLogs';

export const mediaTypeSizeIndex = {
  smallMobile: 1,
  mobile: 2,
  tablet: 3,
  smallLaptop: 4,
  laptop: 5,
  desktop: 6,
};

export const MOLLY_3_PING_ROUTES = ['uipreferences', 'broadcaster'];

export const getBetslipKey = (betslip) =>
  `${betslip.sport}-${betslip.event_id}-${betslip.bet_type}-${betslip.betslip_type}`
    .replaceAll(',', '_')
    .replaceAll('.', '_');

class _Timings {
  overrideLogLevel = false;
  //   cookies?: ClientCookies;
  //   firstPath?: string;
  //   firstPageEnd?: number;

  //   cancelled: number = 0;
  //   path?: string;
  //   pageEnd?: number;
  //   sessionId?: string;

  //   DOMFirstRender?: number;
  //   appFirstRender?: number;
  //   pageChange?: number;

  mediaType =
    window.innerWidth <= 420
      ? 'smallMobile'
      : window.innerWidth <= config.narrowBreakpointContents
        ? 'mobile'
        : window.innerWidth <= 1087
          ? 'tablet'
          : window.innerWidth <= 1280
            ? 'smallLaptop'
            : window.innerWidth <= 1599
              ? 'laptop'
              : 'desktop';
  //   animationFrame?: number;
  //   molly3Ping?: number;

  // Requests
  //   sessionFirstResponse?: number;
  //   preferencesFirstResponse?: number;
  //   settingsFirstResponse?: number;

  // CPriceFeed
  //   cPriceFeedConnecting?: number;
  //   cPriceFeedConnected?: number;
  //   cPriceFeedSynced?: number;
  //   cPriceFeedOked?: number;
  //   cPriceFeedFirstEventMessage?: number;
  //   cPriceFeedFirstOfferMessage?: number;

  // Store
  //   eventStoreSynced?: number;
  //   offerStoreSynced?: number;

  // Trade table
  //   tradeTableFirstRender?: number;
  //   eventRowFirstRender?: number;
  //   priceCellFirstRender?: number;
  //   allEventsWatched?: number;
  //   tradeTableRows?: number;
  //   tradeTableColumns?: number;
  tradeTableGroupEventRows = {};
  //   tradeTablePriceCells?: number;
  //   tradeTableHandicapCells?: number;

  //   tradePageGroup?: TradePageGroupEnum | 'favourite';
  tradeTableGroupIsOpen = {};

  // Betslips
  betslipsCreate = {};
  betslipsSource = {};
  betslipsFirstRender = {};
  betslipsBookiesRender = {};
  betslipsPricesRender = {};
  betslipsClose = {};
  betslipsOffscreen = {};

  //   // Sportsbook
  //   sportsbookSportFirstRender?: number;
  //   sportsbookSportHighlightFirstRender?: number;
  //   sportsbookSportEventFirstRender?: number;
  //   sportsbookSportPriceFirstRender?: number;
  //   sportsbookEventFirstRender?: number;
  //   sportsbookEventPriceFirstRender?: number;

  //   // Orders
  //   ordersTableFirstRender?: number;
  //   ordersTableFiltersChange?: number;
  //   ordersTableFirstRowRender?: number;
  //   ordersTableRows?: number;

  //   // Accounting
  //   accountingTableFirstRender?: number;
  //   accountingTableFiltersChange?: number;
  //   accountingTableFirstRowRender?: number;
  //   accountingTableRows?: number;

  // Class names
  tradeEventRowClassName = {
    favourites: 'favourite-event-row',
    ir: 'ir-event-row',
    today: 'today-event-row',
    early: 'early-event-row',
    outrights: 'multirunner-event-row',
    multirunner: 'multirunner-event-row',
  };
  tradePriceCellClassName = 'price-cell';
  tradeHandicapCellClassName = 'handicap-cell';

  betslipBookies = 'bookies';
  betslipBookie = 'bookie';
  betslipBookiePrice = 'price';

  constructor() {
    this.overrideLogLevel = document.cookie.includes(`${SHOW_LOGS_COOKIE}=true`);
  }

  // Handle log messages for analytics

  showLogs = () => {
    document.cookie = `${SHOW_LOGS_COOKIE}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
    this.overrideLogLevel = document.cookie.includes(`${SHOW_LOGS_COOKIE}=true`);
  };
  hideLogs = () => {
    document.cookie = `${SHOW_LOGS_COOKIE}=false; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    this.overrideLogLevel = document.cookie.includes(`${SHOW_LOGS_COOKIE}=true`);
  };

  log = (context, ...messages) => {
    console.log(
      `⏱️ ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} |`,
      ...messages,
    );
  };

  sendToServer = async (action, data) => {
    window.tabId = window.tabId || Math.floor(Math.random() * 16 ** 6).toString(16);

    const _data = {
      ...data,
      tabId: window.tabId,
      isFirstPage: data?.isFirstPage != null ? data.isFirstPage : !this.pageChange,
      'ping.molly3': this.molly3Ping,
    };

    if (!(window.location.host.includes('skaffold') || window.location.host.includes('sandbox'))) {
      console.log(
        `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} | '${action}' action sent to datadog`,
        _data,
      );
      datadog.addAction(action, _data);
    } else {
      console.log(
        `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} | '${action}' action skipped sending to datadog as not prod`,
        _data,
      );
    }

    _data['context.action'] = action;
    _data['context.isFirstPage'] = !!this.pageChange;
    _data['context.mediaType'] = this.mediaType;
    _data['context.origin'] = 'legacy';
    _data['context.path'] =
      '/' +
      window.location.pathname.replace('http', '').replace('://', '').split('/').slice(1).join('/');
    _data['context.tabId'] = window.tabId;
    _data['context.env'] =
      !window.location.host.includes('skaffold') && !window.location.host.includes('sandbox')
        ? 'production'
        : 'development';

    delete _data.isFirstPage;
    delete _data.tabId;

    // Add molly backend specific data
    if (!(window.location.host.includes('skaffold') || window.location.host.includes('sandbox'))) {
      console.log(
        `🤖 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
          `| '${action}' action sent to molly3`,
        _data,
      );
      fetch('/web/metrics/', {
        method: 'POST',
        body: JSON.stringify(_data),
        headers: { 'Content-Type': 'application/json', session: Session.get('sessionId') },
      });
    } else {
      console.log(
        `🤖 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
          `| '${action}' action skipped sending to molly3 as not prod`,
        _data,
      );
    }
  };

  // Check if the user has changed page
  isFirstPage = () => this.pageEnd === this.firstPageEnd;

  reset = () => {
    this.log({}, 'Resetting timings');
    this.cancelled = 0;

    this.tradeTableFirstRender = undefined;
    this.eventRowFirstRender = undefined;
    this.priceCellFirstRender = undefined;
    this.allEventsWatched = undefined;
    this.tradeTableRows = undefined;
    this.tradeTableColumns = undefined;
    this.tradeTableGroupEventRows = {};
    this.tradeTablePriceCells = undefined;
    this.tradeTableHandicapCells = undefined;

    this.sportsbookSportFirstRender = undefined;
    this.sportsbookSportEventFirstRender = undefined;
    this.sportsbookSportHighlightFirstRender = undefined;
    this.sportsbookSportPriceFirstRender = undefined;
    this.sportsbookEventFirstRender = undefined;
    this.sportsbookEventPriceFirstRender = undefined;

    this.ordersTableFirstRender = undefined;
    this.ordersTableFiltersChange = undefined;
    this.ordersTableFirstRowRender = undefined;
    this.ordersTableRows = undefined;

    this.accountingTableFirstRender = undefined;
    this.accountingTableFiltersChange = undefined;
    this.accountingTableFirstRowRender = undefined;
    this.accountingTableRows = undefined;

    clearInterval(this.tradePageInterval);
    if (this.path?.includes('/trade')) {
      this.startTradePageInterval();
    }
  };

  handlePageChange = () => {
    if (!this.pageChange || this.path !== window.location.pathname) {
      this.path = window.location.pathname;
      this.pageEnd = +new Date();
      this.log({}, 'Page change detected');

      this.pageChange = +new Date();
      this.reset();
    }
  };

  handleVisibilityChange = () => {
    this.log({}, 'Visibility change detected cancelling current timers');
    this.cancelled = +new Date();
  };

  handleMediaTypeChange = (mediaType) => {
    if (mediaType !== this.mediaType) {
      this.log({}, 'Media type change detected cancelling current timers');
      this.cancelled = +new Date();
      this.mediaType = mediaType;
    }
  };

  handleAppRender = (meta) => {
    if (!this.appFirstRender) {
      this.appFirstRender = +new Date();
      this.DOMFirstRender = window.DOMLoaded;
      this.firstPath = window.location.pathname;
      this.firstPageEnd = meta?.end || +new Date();
      this.path = window.location.pathname;
      this.pageEnd = meta?.end || +new Date();

      if (this.appFirstRender && this.DOMFirstRender) {
        this.log(
          {},
          `First app component render ${this.appFirstRender - this.DOMFirstRender}ms after DOM first render.`,
        );
      }

      if (this.path.includes('/trade')) {
        this.startTradePageInterval();
      }
    } else if (window.location.pathname !== this.path) {
      this.handlePageChange();
      this.path = window.location.pathname;
      this.pageEnd = meta?.end || +new Date();
    }
  };

  handleSessionResponse = () => {
    if (!this.sessionFirstResponse) {
      this.sessionFirstResponse = +new Date();
      if (this.appFirstRender && this.sessionFirstResponse) {
        this.log(
          {},
          `Session recieved ${this.sessionFirstResponse - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handlePreferencesResponse = () => {
    if (!this.preferencesFirstResponse) {
      this.preferencesFirstResponse = +new Date();
      if (this.appFirstRender && this.preferencesFirstResponse) {
        this.log(
          {},
          `Preferences recieved ${this.preferencesFirstResponse - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleSettingsResponse = () => {
    if (!this.settingsFirstResponse) {
      this.settingsFirstResponse = +new Date();
      if (this.appFirstRender && this.settingsFirstResponse) {
        this.log(
          {},
          `Settings recieved ${this.settingsFirstResponse - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleCPriceFeedConnecting = () => {
    if (!this.cPriceFeedConnecting) {
      this.cPriceFeedConnecting = +new Date();
      if (this.appFirstRender && this.cPriceFeedConnecting) {
        this.log(
          {},
          `CPriceFeed connecting ${this.cPriceFeedConnecting - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleCPriceFeedConnected = () => {
    if (!this.cPriceFeedConnected) {
      this.cPriceFeedConnected = +new Date();
      if (this.cPriceFeedConnecting && this.cPriceFeedConnected) {
        this.log(
          {},
          `CPriceFeed connected after ${this.cPriceFeedConnected - this.cPriceFeedConnecting}ms.`,
        );
      }
    }
  };

  handleCPriceFeedEventMessage = () => {
    if (!this.cPriceFeedFirstEventMessage) {
      this.cPriceFeedFirstEventMessage = +new Date();
      if (this.cPriceFeedConnected && this.cPriceFeedFirstEventMessage) {
        this.log(
          {},
          `CPriceFeed first event message ${this.cPriceFeedFirstEventMessage - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
    }
  };

  handleCPriceFeedSynced = () => {
    if (!this.cPriceFeedSynced) {
      this.cPriceFeedSynced = +new Date();
      if (this.cPriceFeedConnected && this.cPriceFeedSynced) {
        this.log(
          {},
          `CPriceFeed synced ${this.cPriceFeedSynced - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
    }
  };

  handleCPriceFeedOfferMessage = () => {
    if (!this.cPriceFeedFirstOfferMessage) {
      this.cPriceFeedFirstOfferMessage = +new Date();
      if (this.cPriceFeedConnected && this.cPriceFeedFirstOfferMessage) {
        this.log(
          {},
          `CPriceFeed first offer message ${this.cPriceFeedFirstOfferMessage - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
      setTimeout(this.sendCPriceFeedLoadAction, 1000);
    }
  };

  handleCPriceFeedOked = () => {
    if (!this.cPriceFeedOked) {
      this.cPriceFeedOked = +new Date();
      if (this.cPriceFeedConnected && this.cPriceFeedOked) {
        this.log(
          {},
          `CPriceFeed first batch of offers synced ${this.cPriceFeedOked - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
    }
  };

  handleEventStoreSynced = () => {
    if (!this.eventStoreSynced) {
      this.eventStoreSynced = +new Date();
      if (this.appFirstRender && this.cPriceFeedConnected && this.eventStoreSynced) {
        this.log(
          {},
          `Event store synced ${this.eventStoreSynced - this.appFirstRender}ms after first render, ${this.eventStoreSynced - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
        setTimeout(this.sendCPriceFeedLoadAction, 1000);
      }
    }
  };

  sendCPriceFeedLoadAction = () => {
    if (
      this.appFirstRender &&
      this.cPriceFeedConnecting &&
      this.cPriceFeedConnected &&
      this.cPriceFeedSynced &&
      //   this.eventStoreSynced &&
      !this.pageChange &&
      (!this.cancelled || this.cancelled < this.cPriceFeedConnecting)
    ) {
      this.sendToServer('cpricefeed.load', {
        'cpricefeed.connecting': this.cPriceFeedConnecting - this.appFirstRender,
        'cpricefeed.open': this.cPriceFeedConnected - this.appFirstRender,
        'cpricefeed.synced': this.cPriceFeedSynced - this.appFirstRender,
        'cpricefeed.firstEvent':
          this.cPriceFeedFirstEventMessage &&
          this.cPriceFeedFirstEventMessage - this.appFirstRender,
        'cpricefeed.firstOffer':
          this.cPriceFeedFirstOfferMessage &&
          this.cPriceFeedFirstOfferMessage - this.appFirstRender,
        'cpricefeed.firstOfferBatch':
          this.cPriceFeedOked && this.cPriceFeedOked - this.appFirstRender,
        'store.synced': this.eventStoreSynced && this.eventStoreSynced - this.appFirstRender,
      });
    }
  };

  checkForTradeTable = () => {
    return !!document.querySelector(`#tradeTable`);
  };

  checkForEventRow = () => {
    for (const className of Object.values(this.tradeEventRowClassName)) {
      if (document.querySelector(`#tradeTable .${className}`)) {
        return true;
      }
    }
    return false;
  };

  checkForPriceCell = () => {
    return !!document.querySelector(`#tradeTable .${this.tradePriceCellClassName}`);
  };

  handleAllEventsWatched = (path) => {
    if (this.path && path.endsWith(this.path) && !this.allEventsWatched) {
      this.allEventsWatched = +new Date();
    }
  };

  handleNoEvents = (path) => {
    if (this.path && path.endsWith(this.path) && !this.eventRowFirstRender) {
      this.log({}, `Guessing that there are no events on the trade page.`);
      this.eventRowFirstRender = +new Date();
    }
  };

  handleNoPrices = (path) => {
    if (
      this.path &&
      path.endsWith(this.path) &&
      this.eventRowFirstRender &&
      !this.priceCellFirstRender
    ) {
      this.log({}, `Guessing that there are no prices on the trade page.`);
      this.priceCellFirstRender = +new Date();
    }
  };

  handleTradeTableRender = () => {
    if (!this.tradeTableFirstRender) {
      this.tradeTableFirstRender = +new Date();
      if (this.pageChange && this.tradeTableFirstRender) {
        this.log(
          {},
          `First trade table render ${this.tradeTableFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.tradeTableFirstRender) {
        this.log(
          {},
          `First trade table render ${this.tradeTableFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleEventRowRender = () => {
    if (!this.eventRowFirstRender) {
      this.eventRowFirstRender = +new Date();
      if (this.pageChange && this.eventRowFirstRender) {
        this.log(
          {},
          `First event row rendered ${this.eventRowFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.cPriceFeedConnected && this.eventRowFirstRender) {
        this.log(
          {},
          `First event row rendered ${this.eventRowFirstRender - this.appFirstRender}ms after first render, ${this.eventRowFirstRender - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
    }
  };

  handlePriceCellRender = () => {
    if (this.eventRowFirstRender && !this.priceCellFirstRender) {
      this.priceCellFirstRender = +new Date();
      if (this.pageChange && this.priceCellFirstRender) {
        this.log(
          {},
          `First price cell rendered ${this.priceCellFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.priceCellFirstRender) {
        this.log(
          {},
          `First price cell rendered ${this.priceCellFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  getTradeTableSize = (log = true) => {
    const tradeTable = document.querySelector('#tradeTable');
    this.tradeTableGroupEventRows['favourite'] =
      tradeTable?.querySelectorAll(`.${this.tradeEventRowClassName['favourite']}`).length ||
      undefined;
    this.tradeTableGroupEventRows['ir'] =
      tradeTable?.querySelectorAll(`.${this.tradeEventRowClassName['ir']}`).length || undefined;
    this.tradeTableGroupEventRows['today'] =
      tradeTable?.querySelectorAll(`.${this.tradeEventRowClassName['today']}`).length || undefined;
    this.tradeTableGroupEventRows['early'] =
      tradeTable?.querySelectorAll(`.${this.tradeEventRowClassName['early']}`).length || undefined;
    this.tradeTableGroupEventRows['multirunner'] =
      tradeTable?.querySelectorAll(`.${this.tradeEventRowClassName['multirunner']}`).length ||
      undefined;

    const favouriteColumns =
      tradeTable?.querySelector('.market-header.favs')?.querySelectorAll('.column-names > *')
        ?.length || 0;
    const irColumns =
      tradeTable?.querySelector('.market-header.ir')?.querySelectorAll('.column-names > *')
        ?.length || 0;
    const todayColumns =
      tradeTable?.querySelector('.market-header.today')?.querySelectorAll('.column-names > *')
        ?.length || 0;
    const earlyColumns =
      tradeTable?.querySelector('.market-header.early')?.querySelectorAll('.column-names > *')
        ?.length || 0;
    const multirunnerColumns =
      tradeTable?.querySelector('.market-header.outrights')?.querySelectorAll('.column-names > *')
        ?.length || 0;
    const columns = Math.max(
      favouriteColumns,
      irColumns,
      todayColumns,
      earlyColumns,
      multirunnerColumns,
    );

    if (tradeTable) {
      Timings.handleTradeTableSize(
        tradeTable.querySelectorAll(`.market > *`)?.length || 0,
        // mobile trade table doesnt have ths and is always 4 columns wide
        columns + 6,
        tradeTable.querySelectorAll(`.${this.tradePriceCellClassName}`)?.length || 0,
        undefined,
        log,
      );
    }
  };

  handleTradeTableSize = (rows, columns, priceCells, handicapCells, log = true) => {
    this.tradeTableRows = rows;
    this.tradeTableColumns = columns;
    // These two are hard to get a reliable number on given the staggered loading of prices and
    // watching of events
    this.tradeTablePriceCells = priceCells;
    this.tradeTableHandicapCells = handicapCells;

    if (this.tradeTableRows && this.tradeTableColumns && log) {
      this.log(
        {},
        `Trade table has ${rows.toLocaleString()} rows and ${columns.toLocaleString()} columns, ${(rows * columns).toLocaleString()} cells.` +
          `\n  - Favourites:  ${(this.tradeTableGroupEventRows['favourite'] || 0)?.toLocaleString()}` +
          `\n  - In Running:  ${(this.tradeTableGroupEventRows['ir'] || 0)?.toLocaleString()}` +
          `\n  - Today:       ${(this.tradeTableGroupEventRows['today'] || 0)?.toLocaleString()}` +
          `\n  - Early:       ${(this.tradeTableGroupEventRows['early'] || 0)?.toLocaleString()}` +
          `\n  - Multirunner: ${(this.tradeTableGroupEventRows['multirunner'] || 0)?.toLocaleString()}`,
      );
    }
  };

  tradePageInterval = undefined;
  startTradePageInterval = () => {
    clearInterval(this.tradePageInterval);

    this.tradePageInterval = setInterval(() => {
      if (!this.tradeTableFirstRender && this.checkForTradeTable()) {
        this.handleTradeTableRender();
      }
      if (this.tradeTableFirstRender && this.checkForEventRow()) {
        this.handleEventRowRender();
      }
      if (this.eventRowFirstRender && this.checkForPriceCell()) {
        this.handlePriceCellRender();
      }
      if (this.eventRowFirstRender && this.priceCellFirstRender) {
        this.sendTradeLoadAction();
        clearInterval(this.tradePageInterval);
      }
    }, 10);
  };

  sendTradeLoadAction = () => {
    window.requestAnimationFrame(() => {
      const _rows =
        (document.querySelectorAll('#tradeTable .market')?.length || 0) +
        (document.querySelectorAll('#tradeTable .competition-header')?.length || 0) +
        (document.querySelectorAll('#tradeTable .event')?.length || 0);
      this.getTradeTableSize(false);

      // There should always be rows in the trade table
      if (!_rows) {
        return this.sendTradeLoadAction();
      } else if (
        this.mediaType &&
        mediaTypeSizeIndex[this.mediaType] <= mediaTypeSizeIndex['tablet']
      ) {
        // In mobile mode check if rows are greater than the currently visible trade page group
        if (
          _rows < ((this.tradePageGroup && this.tradeTableGroupEventRows[this.tradePageGroup]) || 0)
        ) {
          return this.sendTradeLoadAction();
        }
      } else {
        // In desktop mode check if rows are greater than the sum of open trade page groups
        if (
          _rows <
          ((this.tradeTableGroupIsOpen['favourite'] &&
            this.tradeTableGroupEventRows['favourite']) ||
            0) +
            ((this.tradeTableGroupIsOpen['ir'] && this.tradeTableGroupEventRows['ir']) || 0) +
            ((this.tradeTableGroupIsOpen['today'] && this.tradeTableGroupEventRows['today']) || 0) +
            ((this.tradeTableGroupIsOpen['early'] && this.tradeTableGroupEventRows['early']) || 0) +
            ((this.tradeTableGroupIsOpen['multirunner'] &&
              this.tradeTableGroupEventRows['multirunner']) ||
              0)
        ) {
          return this.sendTradeLoadAction();
        }
      }

      if (
        this.cancelled &&
        this.priceCellFirstRender &&
        this.cancelled < this.priceCellFirstRender
      ) {
        console.log(
          `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
            `| 'trade.table.load' action skipped as cancel called during the lifecycle`,
        );
      } else if (this.appFirstRender && this.priceCellFirstRender) {
        this.sendToServer('trade.table.load', {
          // Sometimes when switching between subsports, the event rows dont re-render so load
          // time is undefined, in this case 0 is sent because they were on screen immediately
          'trade.eventsLoadTime': this.eventRowFirstRender
            ? this.eventRowFirstRender - (this.pageChange || this.appFirstRender)
            : 0,
          'trade.pricesLoadTime':
            this.priceCellFirstRender - (this.pageChange || this.appFirstRender),
          'trade.table.columns': undefined,
          'trade.table.rows': undefined,
          'trade.table.size': undefined,
          'trade.table.favouriteEventRows': this.tradeTableGroupEventRows['favourite'] || 0,
          'trade.table.irEventRows': this.tradeTableGroupEventRows['ir'] || 0,
          'trade.table.todayEventRows': this.tradeTableGroupEventRows['today'] || 0,
          'trade.table.earlyEventRows': this.tradeTableGroupEventRows['early'] || 0,
          'trade.table.multirunnerEventRows': this.tradeTableGroupEventRows['multirunner'] || 0,
          'trade.table.eventRows':
            (this.tradeTableGroupEventRows['favourite'] || 0) +
            (this.tradeTableGroupEventRows['ir'] || 0) +
            (this.tradeTableGroupEventRows['today'] || 0) +
            (this.tradeTableGroupEventRows['early'] || 0) +
            (this.tradeTableGroupEventRows['multirunner'] || 0),
          isFirstPage: this.isFirstPage(),
        });
      }
    });
  };

  handleBetslipCreate = (betslip) => {
    const key = getBetslipKey(betslip);

    if (!this.betslipsCreate[key]) {
      this.betslipsCreate[key] = +new Date();
      this.betslipsSource[key] = betslip.source || 'unknown';
      this.log({}, `Betslip '${key}' created.`);
      this.startBetslipInterval(key);
    }
  };

  checkForBetslip = (key) => {
    return !!document.querySelector(`#${key}`);
  };

  checkForBetslipBookies = (key) => {
    return !!document.querySelector(`#${key} .betslip-account-info-bookie`);
  };

  checkForBetslipBookiePrices = (key) => {
    return !!document.querySelector(`#${key} .account-pmm-price`);
  };

  handleBetslipRender = (key) => {
    if (!this.betslipsFirstRender[key]) {
      this.betslipsFirstRender[key] = +new Date();
      if (this.betslipsCreate[key] && this.betslipsFirstRender[key]) {
        this.log(
          {},
          `Betslip '${key}' first rendered ${this.betslipsFirstRender[key] - this.betslipsCreate[key]}ms after creation.`,
        );
      }
    }
  };

  handleBetslipBookiesRender = (key) => {
    if (!this.betslipsBookiesRender[key]) {
      this.betslipsBookiesRender[key] = +new Date();
      if (this.betslipsCreate[key] && this.betslipsBookiesRender[key]) {
        this.log(
          {},
          `Betslip '${key}' bookies first rendered ${this.betslipsBookiesRender[key] - this.betslipsCreate[key]}ms after creation.`,
        );
      }
    }
  };

  handleBetslipPriceRender = (key) => {
    if (!this.betslipsPricesRender[key]) {
      this.betslipsPricesRender[key] = +new Date();
      if (this.betslipsCreate[key] && this.betslipsPricesRender[key]) {
        this.log(
          {},
          `Betslip '${key}' prices first rendered ${this.betslipsPricesRender[key] - this.betslipsCreate[key]}ms after creation.`,
        );
      }
    }
  };

  betslipIntervals = {};
  startBetslipInterval = (key) => {
    clearInterval(this.betslipIntervals[key]);

    this.betslipIntervals[key] = setInterval(() => {
      // Exchange betslips have prices from both sides so we need to check the reverse betslip for
      // prices too
      const side = key.includes('lay') ? 'lay' : 'back';
      const reverseKey =
        side === 'lay'
          ? key.replace('against', 'for').replace('lay', 'normal')
          : key.replace('for', 'against').replace('normal', 'lay');

      if (!this.betslipsFirstRender[key] && this.checkForBetslip(key)) {
        this.handleBetslipRender(key);
      }
      if (this.betslipsFirstRender[key] && this.checkForBetslipBookies(key)) {
        this.handleBetslipBookiesRender(key);
      }
      if (this.betslipsBookiesRender[key] || this.betslipsBookiesRender[reverseKey]) {
        if (this.checkForBetslipBookiePrices(key)) {
          this.handleBetslipPriceRender(key);
        }

        if (!this.betslipsPricesRender[key]) {
          // Check reverse betslip for prices
          if (
            !!document.querySelector(
              `#${reverseKey} .${this.betslipBookie} .${this.betslipBookiePrice}.${side}`,
            )
          ) {
            this.handleBetslipPriceRender(key);
          }
        }
      }

      if (this.betslipsFirstRender[key] && +new Date() - this.betslipsFirstRender[key] > 5000) {
        if (!this.betslipsBookiesRender[key]) {
          this.log({}, `Guessing that for betslip '${key}' there are no bookies.`);
          this.betslipsBookiesRender[key] = this.betslipsFirstRender[key];
        }
        if (!this.betslipsPricesRender[key]) {
          this.log({}, `Guessing that for betslip '${key}' there are no prices.`);
          this.betslipsPricesRender[key] = this.betslipsFirstRender[key];
        }
      }

      if (this.betslipsPricesRender[key]) {
        this.sendBetslipLoadAction(key);
        clearInterval(this.betslipIntervals[key]);
      }
    }, 10);
  };

  sendBetslipLoadAction = (key) => {
    this.getTradeTableSize(false);

    const tradePageInfo = {
      'trade.table.eventRows':
        (this.tradeTableGroupEventRows['favourite'] || 0) +
        (this.tradeTableGroupEventRows['ir'] || 0) +
        (this.tradeTableGroupEventRows['today'] || 0) +
        (this.tradeTableGroupEventRows['early'] || 0) +
        (this.tradeTableGroupEventRows['multirunner'] || 0),
    };

    console.log(
      key,
      this.betslipsCreate[key],
      this.betslipsSource[key],
      this.betslipsFirstRender[key],
      this.betslipsBookiesRender[key],
      this.betslipsPricesRender[key],
    );

    if (this.cancelled > this.betslipsCreate[key]) {
      console.log(
        `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
          `| 'betslip.load' action skipped as cancel called during the lifecycle`,
      );
    } else if (
      key.includes('normal') &&
      this.betslipsCreate[key] &&
      this.betslipsSource[key] &&
      this.betslipsFirstRender[key] &&
      this.betslipsBookiesRender[key] &&
      this.betslipsPricesRender[key]
    ) {
      this.sendToServer('betslip.load', {
        'betslip.openTime': this.betslipsFirstRender[key] - this.betslipsCreate[key],
        'betslip.bookiesLoadTime': this.betslipsBookiesRender[key] - this.betslipsCreate[key],
        'betslip.pricesLoadTime': this.betslipsPricesRender[key] - this.betslipsCreate[key],
        'betslip.source': this.betslipsSource[key],
        ...tradePageInfo,
      });
    } else if (
      key.includes('lay') &&
      this.betslipsCreate[key] &&
      this.betslipsSource[key] &&
      this.betslipsPricesRender[key]
    ) {
      this.sendToServer('betslip.load', {
        'betslip.pricesLoadTime': this.betslipsPricesRender[key] - this.betslipsCreate[key],
        'betslip.source': this.betslipsSource[key],
        ...tradePageInfo,
      });
    }
  };

  handleBetslipClose = (betslip) => {
    const key = getBetslipKey(betslip);

    if (this.betslipsCreate[key]) {
      this.log({}, `Betslip '${key}' closed.`);
      clearInterval(this.betslipIntervals[key]);
      this.betslipsClose[key] = +new Date();
      this.startBetslipCloseInterval(key);
    }
  };

  betslipCloseIntervals = {};
  startBetslipCloseInterval = (key) => {
    clearInterval(this.betslipCloseIntervals[key]);

    this.betslipCloseIntervals[key] = setInterval(() => {
      if (!this.checkForBetslip(key)) {
        this.betslipsOffscreen[key] = +new Date();
        this.sendBetslipCloseAction(key);
        clearInterval(this.betslipCloseIntervals[key]);
      }
    }, 10);
  };

  sendBetslipCloseAction = (key) => {
    this.getTradeTableSize(false);

    const tradePageInfo =
      this.tradeTableRows && this.tradeTableColumns
        ? {
            'trade.table.columns': this.tradeTableColumns,
            'trade.table.rows': this.tradeTableRows,
            'trade.table.size': this.tradeTableRows * this.tradeTableColumns,
            'trade.table.eventRows':
              (this.tradeTableGroupEventRows['favourite'] || 0) +
              (this.tradeTableGroupEventRows['ir'] || 0) +
              (this.tradeTableGroupEventRows['today'] || 0) +
              (this.tradeTableGroupEventRows['early'] || 0) +
              (this.tradeTableGroupEventRows['multirunner'] || 0),
          }
        : {};

    if (this.cancelled > this.betslipsClose[key]) {
      console.log(
        `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
          `| 'betslip.close' action skipped as cancel called during the lifecycle`,
      );
    } else if (this.betslipsClose[key] && this.betslipsOffscreen[key]) {
      this.sendToServer('betslip.close', {
        'betslip.closeTime': this.betslipsOffscreen[key] - this.betslipsClose[key],
        'betslip.duration': this.betslipsClose[key] - this.betslipsCreate[key],
        'betslip.source': this.betslipsSource[key],
        ...tradePageInfo,
      });
    }

    delete this.betslipsCreate[key];
    delete this.betslipsFirstRender[key];
    delete this.betslipsBookiesRender[key];
    delete this.betslipsPricesRender[key];
    delete this.betslipsClose[key];
    delete this.betslipsOffscreen[key];

    clearInterval(this.betslipIntervals[key]);
    delete this.betslipIntervals[key];
    clearInterval(this.betslipCloseIntervals[key]);
    delete this.betslipCloseIntervals[key];
  };

  handleSportsbookSportFirstRender = () => {
    if (!this.sportsbookSportFirstRender) {
      this.sportsbookSportFirstRender = +new Date();
      if (this.pageChange && this.sportsbookSportFirstRender) {
        this.log(
          {},
          `First sportsbook sport page render ${this.sportsbookSportFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.sportsbookSportFirstRender) {
        this.log(
          {},
          `First sportsbook sport page render ${this.sportsbookSportFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleSportsbookSportEventRender = () => {
    if (!this.sportsbookSportEventFirstRender) {
      this.sportsbookSportEventFirstRender = +new Date();
      if (this.pageChange && this.sportsbookSportEventFirstRender) {
        this.log(
          {},
          `First sportsbook sport page event rendered ${this.sportsbookSportEventFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (
        this.appFirstRender &&
        this.cPriceFeedConnected &&
        this.sportsbookSportEventFirstRender
      ) {
        this.log(
          {},
          `First sportsbook sport page event rendered ${this.sportsbookSportEventFirstRender - this.appFirstRender}ms after first render, ${this.sportsbookSportEventFirstRender - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
    }
  };

  handleSportsbookSportHighlightRender = () => {
    if (!this.sportsbookSportHighlightFirstRender) {
      this.sportsbookSportHighlightFirstRender = +new Date();
      if (this.pageChange && this.sportsbookSportHighlightFirstRender) {
        this.log(
          {},
          `First sportsbook sport page highlight rendered ${this.sportsbookSportHighlightFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (
        this.appFirstRender &&
        this.cPriceFeedConnected &&
        this.sportsbookSportHighlightFirstRender
      ) {
        this.log(
          {},
          `First sportsbook sport page highlight rendered ${this.sportsbookSportHighlightFirstRender - this.appFirstRender}ms after first render, ${this.sportsbookSportHighlightFirstRender - this.cPriceFeedConnected}ms after cPriceFeed connected.`,
        );
      }
      this.sendSportsbookSportLoadAction();
    }
  };

  handleSportsbookSportPriceRender = () => {
    if (this.sportsbookSportEventFirstRender && !this.sportsbookSportPriceFirstRender) {
      this.sportsbookSportPriceFirstRender = +new Date();
      if (this.pageChange && this.sportsbookSportPriceFirstRender) {
        this.log(
          {},
          `First sportsbook sport page price rendered ${this.sportsbookSportPriceFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.sportsbookSportPriceFirstRender) {
        this.log(
          {},
          `First sportsbook sport page price rendered ${this.sportsbookSportPriceFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
      this.sendSportsbookSportLoadAction();
    }
  };

  sendSportsbookSportLoadAction = () => {
    if (
      this.appFirstRender &&
      this.sportsbookSportFirstRender &&
      this.sportsbookSportEventFirstRender &&
      this.sportsbookSportHighlightFirstRender &&
      this.sportsbookSportPriceFirstRender
    ) {
      if (this.cancelled > this.sportsbookSportFirstRender) {
        console.log(
          `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
            `| 'sportsbook.sport.load' action skipped as cancel called during the lifecycle`,
        );
      } else if (this.sportsbookSportEventFirstRender && this.sportsbookSportPriceFirstRender) {
        this.sendToServer('sportsbook.sport.load', {
          'sportsbook.eventsLoadTime': this.sportsbookSportEventFirstRender
            ? this.sportsbookSportEventFirstRender - (this.pageChange || this.appFirstRender)
            : 0,
          'sportsbook.highlightsLoadTime': this.sportsbookSportHighlightFirstRender
            ? this.sportsbookSportHighlightFirstRender - (this.pageChange || this.appFirstRender)
            : 0,
          'sportsbook.pricesLoadTime':
            this.sportsbookSportPriceFirstRender - (this.pageChange || this.appFirstRender),
          isFirstPage: this.isFirstPage(),
        });
      }
    }
  };

  handleSportsbookEventFirstRender = () => {
    if (!this.sportsbookEventFirstRender) {
      this.sportsbookEventFirstRender = +new Date();
      if (this.pageChange && this.sportsbookEventFirstRender) {
        this.log(
          {},
          `First sportsbook event page render ${this.sportsbookEventFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.sportsbookEventFirstRender) {
        this.log(
          {},
          `First sportsbook event page render ${this.sportsbookEventFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleSportsbookEventPriceRender = () => {
    if (this.sportsbookEventFirstRender && !this.sportsbookEventPriceFirstRender) {
      this.sportsbookEventPriceFirstRender = +new Date();
      if (this.pageChange && this.sportsbookEventPriceFirstRender) {
        this.log(
          {},
          `First sportsbook event page price rendered ${this.sportsbookEventPriceFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.sportsbookEventPriceFirstRender) {
        this.log(
          {},
          `First sportsbook event page price rendered ${this.sportsbookEventPriceFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
      this.sendSportsbookEventLoadAction();
    }
  };

  sendSportsbookEventLoadAction = () => {
    if (this.appFirstRender && this.sportsbookEventFirstRender) {
      if (this.cancelled > this.sportsbookEventFirstRender) {
        console.log(
          `🐶 ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })} ` +
            `| 'sportsbook.event.load' action skipped as cancel called during the lifecycle`,
        );
      } else if (this.sportsbookEventPriceFirstRender) {
        this.sendToServer('sportsbook.event.load', {
          'sportsbook.pricesLoadTime':
            this.sportsbookEventPriceFirstRender - (this.pageChange || this.appFirstRender),
          isFirstPage: this.isFirstPage(),
        });
      }
    }
  };

  handleOrdersTableRender = () => {
    if (!this.ordersTableFirstRender) {
      this.ordersTableFirstRender = +new Date();
      this.ordersTableFirstRowRender = undefined;
      this.ordersTableRows = undefined;
      if (this.pageChange && this.ordersTableFirstRender) {
        this.log(
          {},
          `First orders table rendered ${this.ordersTableFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.ordersTableFirstRender) {
        this.log(
          {},
          `First orders table rendered ${this.ordersTableFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleOrdersTableFilterChange = () => {
    this.ordersTableFiltersChange = +new Date();
    this.ordersTableFirstRowRender = undefined;
    this.ordersTableRows = undefined;
    if (this.ordersTableFiltersChange) {
      this.log({}, `Orders table filters changed.`);
    }
  };

  handleOrdersRowRender = () => {
    if (
      !this.ordersTableFirstRowRender &&
      (this.ordersTableFirstRender || this.ordersTableFiltersChange)
    ) {
      this.ordersTableFirstRowRender = +new Date();
      const start = this.ordersTableFiltersChange || this.ordersTableFirstRender;
      if (this.ordersTableFirstRowRender && start) {
        this.log(
          {},
          `First order table row rendered ${this.ordersTableFirstRowRender - start}ms after ${this.ordersTableFiltersChange ? 'filters changed' : 'table first rendered'}.`,
        );
      }
    }
  };

  handleOrdersTableSize = (rows) => {
    if (!this.ordersTableRows) {
      this.ordersTableRows = rows;

      if (this.ordersTableRows) {
        this.log(
          {},
          `Orders table has ${rows.toLocaleString()} rows and 10 columns, ${(rows * 10).toLocaleString()} cells.`,
        );
      }

      if (this.ordersTableFirstRowRender && this.ordersTableFiltersChange && this.ordersTableRows) {
        this.sendToServer('orders.table.load', {
          ordersLoadTime: this.ordersTableFirstRowRender - this.ordersTableFiltersChange,
          ordersTableRows: this.ordersTableRows,
        });
      }
    }
  };

  handleAccountingTableRender = () => {
    if (!this.accountingTableFirstRender) {
      this.accountingTableFirstRender = +new Date();
      this.accountingTableFirstRowRender = undefined;
      this.accountingTableRows = undefined;
      if (this.pageChange && this.accountingTableFirstRender) {
        this.log(
          {},
          `First accounting table rendered ${this.accountingTableFirstRender - this.pageChange}ms after page change.`,
        );
      } else if (this.appFirstRender && this.accountingTableFirstRender) {
        this.log(
          {},
          `First accounting table rendered ${this.accountingTableFirstRender - this.appFirstRender}ms after first render.`,
        );
      }
    }
  };

  handleAccountingTableFilterChange = () => {
    this.accountingTableFiltersChange = +new Date();
    this.accountingTableFirstRowRender = undefined;
    this.accountingTableRows = undefined;
    if (this.accountingTableFiltersChange) {
      this.log({}, `Accounting table filters changed.`);
    }
  };

  handleAccountingRowRender = () => {
    if (
      !this.accountingTableFirstRowRender &&
      (this.accountingTableFirstRender || this.accountingTableFiltersChange)
    ) {
      this.accountingTableFirstRowRender = +new Date();
      const start = this.accountingTableFiltersChange || this.accountingTableFirstRender;
      if (this.accountingTableFirstRowRender && start) {
        this.log(
          {},
          `First accounting table row rendered ${this.accountingTableFirstRowRender - start}ms after ${this.accountingTableFiltersChange ? 'filters changed' : 'table first rendered'}.`,
        );
      }
    }
  };

  handleAccountingTableSize = (rows) => {
    if (!this.accountingTableRows) {
      this.accountingTableRows = rows;

      if (this.accountingTableRows) {
        this.log(
          {},
          `Accounting table has ${rows.toLocaleString()} rows and 11 columns, ${(rows * 11).toLocaleString()} cells.`,
        );
      }

      if (
        this.accountingTableFirstRowRender &&
        this.accountingTableFiltersChange &&
        this.accountingTableRows
      ) {
        this.sendToServer('accounting.table.load', {
          accountingLoadTime:
            this.accountingTableFirstRowRender - this.accountingTableFiltersChange,
          accountingTableRows: this.accountingTableRows,
        });
      }
    }
  };
}

export const Timings = new _Timings();

window.Timings = Timings;
window.showTimingLogs = Timings.showLogs;
window.hideTimingLogs = Timings.hideLogs;
