/** @format */

import { colours, stateColours, exchangeColours } from './colours';
import { FONT_SIZE } from './sizes';
import { sizes as fontSizes } from './fonts';
import { ThemeType } from './types';
import { betBarProgressColours as betbar } from './betbar';

const lightTheme: Partial<ThemeType> = {
  palette: 'light',

  // ---- Old Theme Style ----
  primary: '#7781e9',

  background: colours.white,
  foreground: colours.black,
  separator: colours.grey.mid,

  negative: colours.red.dark,
  positive: colours.green.dark,

  success: colours.green.dark,
  error: colours.red.dark,
  warning: colours.yellow.mid,

  //impossible outcomes in position grid
  impossible: colours.grey.darker,

  //market colors
  ir: colours.red.mid,
  today: colours.green.mid,
  early: colours.blue.mid,
  favs: colours.yellow.mid,

  //market tints
  irTint: '#ffecec',
  todayTint: '#edf9ec',
  earlyTint: '#e9f4ff',
  favsTint: colours.yellow.light,

  priceColor: colours.blue.dark,
  newScore: '#ff0000',

  //increases and decreases for prices and pmms
  new: colours.yellow.mid,
  increase: colours.blue.mid,
  decrease: colours.red.mid,

  orders: {
    danger: '#AA6688',
    success: '#AAFFAA',
    placing: '#FFFFAA',
    unplaced: '#CCCCFF',
    unassigned: '#CC88FF',

    failed: '#FFAAAA',
    voids: '#d67680',
  },

  eventPage: {
    darkZero: '#0a0a0a',
    darkOne: '#141414',
    darkTwo: '#292929',
    darkThree: '#333333',
    darkFour: '#3d3d3d',
    lightGrey: '#D6D6D6',
    offWhite: '#F5F5F5',
    white: 'white',
    black: 'black',
  },

  // new bet bar colours
  betBarPassive: colours.grey.lighter,
  betBarActive: colours.grey.mid,
  betBarActiveColour: colours.black,
  betBarPassiveColour: colours.black,

  // betslip toggle types
  betslipBackLayToggleBackground: colours.white,
  betslipToggleBackground: colours.white,
  betslipToggleColour: colours.white,
  betslipToggleHoverColour: '#e0e0e0',
  betslipActiveToggleBackground: '#ececec',
  betslipInactiveToggleBackground: 'lightgrey',

  paper: {
    background: ['#F9F9FB', '#EAEAEC', '#DDDDDD', '#F9F9FB', '#FFFFFF'],
    fontColor: ['#222222', '#222222', '#181818', '#181818', '#000000'],
    borderRadius: '5px',
  },

  // bet bar progress colours
  betbar,

  defaultPadding: '20px',
  fontSize: FONT_SIZE + 'px',

  // ---- New theme style ----
  colours: {
    ...colours,
    ...stateColours,
    primary: colours.blue.mid,
    backgrounds: [colours.white, colours.grey.lighter, colours.grey.light],
  },

  exchangeColours,

  fonts: {
    size: fontSizes,
    colours: [colours.black, colours.grey.darker, colours.grey.dark],
  },
};

export default lightTheme;
