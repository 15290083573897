/** @format */

export const FONT_SIZE = 12;

export default {
  navMenuHeight: 4 * FONT_SIZE,
  navMenuHeightNarrow: 3 * FONT_SIZE,
  navMenuLogoWidth: 14 * FONT_SIZE,
  navMenuLogoWidthNarrow: 5 * FONT_SIZE,
  betbarClosedHeight: 2.5 * FONT_SIZE,
  fixedDraggableHolderWidth: 30 * FONT_SIZE,
  defaultOfferGroupWidth: 13 * FONT_SIZE,
  asianOfferGroupWidth: 8 * FONT_SIZE,
  tradeBaseWidth: 62.5 * FONT_SIZE,
  tradeBaseWidthAsian: 56.5 * FONT_SIZE,
  sideWidth: 22 * FONT_SIZE,
  sideClosedMargin: 0,
  sideOpenMargin: 22 * FONT_SIZE,
  betbarMinHeight: 100,
  searchResultHeight: 44,
  fontSize: FONT_SIZE,
};
