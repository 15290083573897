/** @format */

import config from '../config';
import { toCamelCase, toSnakeCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  status: '',
  isRegistering: false,
  registrationSuccess: false,
  forwardToPartner: false,
  isInRestricted: false,
  //isValidating: {},
  validationErrors: {},
  registrationId: '',
  hasErrors: false,
  captchaError: false,
  currentStep: 1,

  isValidatingEmail: false,
  validatingEmailError: '',
  validatingEmailSuccess: false,

  isSendingToPartner: false,
  partnerSendingError: '',
  partnerSendingSuccess: false,

  accountActivationStatus: '',
  accountActivationError: '',
  activationCodeResent: false,
  resendId: '',
});

const MAX_STEPS = 3;
//where things are so the form can go back on error
const stepMap = {
  one: ['firstName', 'lastName', 'dateOfBirth', 'country', 'region', 'city', 'postCode', 'address'],
  two: ['username', 'password1', 'password2', 'email', 'telephone'],
};

const functions = {
  //next step of registration process
  registrationIncrementStep: (state, _action) => {
    let step = Math.min(MAX_STEPS, state.get('currentStep', 1) + 1);
    return state.set('currentStep', step);
  },

  //previous step of registration process
  registrationDecrementStep: (state, _action) => {
    state = state.set('isInRestricted', false);
    state = state.set('forwardToPartner', false);
    let step = Math.max(1, state.get('currentStep', 1) - 1);
    return state.set('currentStep', step);
  },

  //validate registration fields
  validateRegister: (state, action) => {
    const formFields = ['email', 'telephone', 'password1', 'dateOfBirth', 'username'];
    if (!formFields.includes(action.data.field)) {
      return state.setIn(['validationErrors', action.data.field], undefined);
    }
    const validationObject = {};
    if (action.data.field === 'password1' || action.data.field === 'password2') {
      action.data.field = 'password';
      if (action.data.username !== '') {
        validationObject['username'] = action.data.username;
      }
    }
    validationObject[`${action.data.field}`] = action.data.value;
    DSM.create(
      `/cs/register/validation/${toSnakeCase(action.data.field)}`,
      {
        method: 'POST',
        body: validationObject,
        extras: {
          field: action.data.field,
        },
        message: 'validateRegisterResponse',
      },
      action.data.actions
    );

    return state.setIn(['isValidating', action.data.field], true);
  },

  //response to validation request
  //need to check for validation errors
  validateRegisterResponse: (state, action) => {
    state = state.setIn(['isValidating', action.data.extras.field], false);
    if (action.data.status === 'ok') {
      if (action.data.extras.field === 'password') {
        action.data.extras.field = 'password1';
      }
      return state.setIn(['validationErrors', action.data.extras.field], undefined);
    }
    if (action.data.data[action.data.extras.field] !== undefined) {
      const errorData = action.data.data[action.data.extras.field].join(', ');
      if (action.data.extras.field === 'password') {
        action.data.extras.field = 'password1';
      }
      return state.setIn(['validationErrors', action.data.extras.field], errorData);
    }
    return state;
  },

  //registration request
  register: (state, action) => {
    state = state.set('validationErrors', fromJS({}));
    state = state.set('hasErrors', false);
    state = state.set('captchaError', false);

    DSM.create(
      '/cs/register',
      {
        method: 'POST',
        body: action.data.payload,
        extras: {
          username: action.data.payload.username,
          password: action.data.payload.password1,
        },
        message: 'registerResponse',
      },
      action.data.actions
    );

    return state.set('isRegistering', true);
  },

  //registration response
  registerResponse: (state, action) => {
    state = state.set('isRegistering', false);
    if (action.data.status === 'ok') {
      state = state.set('registrationSuccess', true);
      state = state.set('currentStep', 4);
      //const username = action.data.data.username
      const resendId = action.data.data.id;
      state = state.set('resendId', resendId);
    } else {
      if (action.data.code === 'invalid_captcha') {
        state = state.set('captchaError', true);
      } else if (action.data.data.status === 'country_not_served') {
        state = state.set('isInRestricted', true);
      } else if (action.data.data.status === 'forwarded') {
        state = state.set('forwardToPartner', true);
      } else {
        let currStep = null;
        for (let field in action.data.data) {
          state = state.setIn(['validationErrors', field], action.data.data[field].join(' '));

          if (stepMap['one'].indexOf(field) !== -1) {
            currStep = 1;
          }

          if (stepMap['two'].indexOf(field) !== 1 && currStep !== 1) {
            currStep = 2;
          }
        }

        if (currStep) {
          state = state.set('currentStep', currStep);
        }

        state = state.set('hasErrors', true);
      }
    }
    return state;
  },

  //validate email using the registration id
  //registration is something sent to user email
  validateEmail: (state, action) => {
    DSM.create(
      `/cs/verification/${action.data.registrationId}`,
      {
        method: 'POST',
        message: 'validateEmailResponse',
      },
      action.data.actions
    );

    return state.set('isValidatingEmail', true);
  },

  //validation response
  validateEmailResponse: (state, action) => {
    state = state.set('isValidatingEmail', false);

    if (action.data.status === 'ok') {
      state = state.set('validatingEmailSuccess', true);
      state = state.set('accountActivationStatus', 'activating');
    } else {
      state = state.set('validatingEmailSuccess', false);
      state = state.set(
        'validatingEmailError',
        action.data.data
          ? extractErrorMessage(action.data.data)
          : action.data.code.replace(/_/g, ' ')
      );
    }

    return state;
  },

  //resent email with registrationId
  resendRegistrationEmail: (state, action) => {
    DSM.last(
      `/cs/registration/${action.data.registrationId}/resend`,
      {
        method: 'POST',
        body: {},
        message: 'resendRegistrationEmailResponse',
      },
      action.data.actions,
      'resendRegistrationEmail'
    );
    return state;
  },

  //response for the above
  resendRegistrationEmailResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('activationCodeResent', true);
    } else {
      state = state.set('activationCodeResent', 'error');
    }
    return state;
  },

  //we check for validation in an interval
  //we probably should clean this up somewhere as well
  connectValidationLoop: (state, action) => {
    DSM.ensureOne(
      `/cs/verification/${action.data.registrationId}`,
      {
        method: 'GET',
        message: 'validationLoopResponse',
        interval: config.timings.registrationValidationFrequency,
      },
      action.data.actions
    );

    return state;
  },

  //check response of validation loop request
  validationLoopResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('accountActivationStatus', action.data.data.emailStatus || 'activating');
    } else {
      state = state.set('accountActivationError', action.data.code.replace(/_/g, ' '));
    }

    return state;
  },

  //send details to partner whitelabels in case we cannot take customer directly
  sendDetailsToPartner: (state, action) => {
    DSM.create(
      '/cs/register/forward',
      {
        method: 'POST',
        body: action.data.payload,
        message: 'sendDetailsToPartnerResponse',
      },
      action.data.actions
    );

    return state.set('isSendingToPartner', true);
  },

  //response to the above
  sendDetailsToPartnerResponse: (state, action) => {
    state = state.set('isSendingToPartner', false);

    if (action.data.status === 'ok') {
      state = state.set('partnerSendingSuccess', true);
    } else {
      state = state.set('partnerSendingSuccess', false);
      state = state.set(
        'partnerSendingError',
        action.data.data
          ? extractErrorMessage(action.data.data)
          : action.data.code.replace(/_/g, ' ')
      );
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
