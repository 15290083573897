/** @format */

import { colours } from './colours';
import { BetBarProgressColoursType } from './types';

export const betBarProgressColours: BetBarProgressColoursType = {
  // order progress bar colours
  unplacedStakeClosedOrderBackground: '#CCCCFF',
  unplacedStakeClosedOrderForeground: colours.white,
  unplacedStakeOpenOrderBackground: '#CC88FF',
  unplacedStakeOpenOrderForeground: colours.white,
  inprogressStakeBackground: colours.yellow.mid,
  inprogressStakeForeground: colours.black,
  dangerStakeBackground: '#ac6da4',
  dangerStakeForeground: colours.white,
  successStakeBackground: colours.green.light,
  successStakeForeground: colours.white,
};
