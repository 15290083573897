/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { toStdDate } from '@mollybet/frontend-common/dist/lib/time';

import { fromJS } from 'immutable';

let _2MAgo = new Date();
_2MAgo.setMonth(_2MAgo.getMonth() - 2);
let initialState = fromJS({
  dateFrom: toStdDate(_2MAgo),
  dateTo: null,
  filterGroup: '',
  showSubAgentTransfers: false,
  isLoading: true,
  isDownloading: false,
  isDownloadFailed: false,
  transfers: null,
});

const functions = {
  ////// INIT
  //get all transfers
  agentGetAllTransfers: (state, action) => {
    let body = {
      dateFrom: state.get('dateFrom', null),
      dateTo: state.get('dateTo', null),
      groupId: state.get('filterGroup', null),
    };

    let allAgents = state.get('showSubAgentTransfers', false);
    if (allAgents) {
      body['allAgents'] = '1';
    }

    let headers = null;
    if (action.data.format) {
      headers = {};
      if (action.data.format === 'csv') {
        headers['Accept'] = 'text/csv';
      } else if (action.data.format === 'xls') {
        headers['Accept'] = 'application/vnd.ms-excel';
      }
    }

    DSM.last(
      `/v1/transfers/`,
      {
        method: 'GET',
        headers,
        downloadAs: action.data.format ? `all-transfers.${action.data.format}` : '',
        body,
        extras: {
          format: action.data.format,
        },
        message: 'agentGetAllTransfersResponse',
      },
      action.data.actions,
      'agentGetAllTransfers'
    );

    if (!action.data.format) {
      state = state.set('isLoading', true);
      state = state.set('transfers', null);
    } else {
      state = state.set('isDownloading', action.data.format);
    }

    return state;
  },

  //handle transfer response
  agentGetAllTransfersResponse: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }

      state = state.set('transfers', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }

      //error handled by base
      return state;
    }
  },

  ////// FILTERS

  //set the date to to filter
  agentSetTransfersDateTo: (state, action) => {
    return state.set('dateTo', action.data.date);
  },

  //set date from to filter
  agentSetTransfersDateFrom: (state, action) => {
    return state.set('dateFrom', action.data.date);
  },

  //set group restrictions for filter
  agentSetTransfersGroup: (state, action) => {
    return state.set('filterGroup', action.data.group);
  },

  //should show sub-agent transfers
  agentSetSubAgentTransfers: (state, action) => {
    return state.set('showSubAgentTransfers', action.data.value);
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
