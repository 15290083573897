/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  targetUser: '',
  error: '',
  errorData: null,
  status: '',
  isLoading: false,
});

const functions = {
  //open comm rate modal
  openSetCommRate: (state, action) => {
    state = state.asMutable();
    state = state.set('targetUser', action.data.targetUser);
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    return state.asImmutable();
  },

  //close comm rate modal
  closeSetCommRate: (state, _action) => {
    state = state.asMutable();
    state = state.set('targetUser', '');
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    return state.asImmutable();
  },

  //set the commission rate for a target user
  setCommRate: (state, action) => {
    let targetUser = state.get('targetUser', '');

    if (targetUser) {
      let body = {
        commissionRate: action.data.data,
      };
      DSM.create(
        `/v1/customers/${targetUser}/`,
        {
          method: 'PATCH',
          body,
          extras: { ...action.data.data, targetUser },
          message: 'setCommRateResponse',
        },
        action.data.actions
      );

      state = state.asMutable();
      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
      state = state.asImmutable();
    }
    return state;
  },

  //respond to the new comm rate
  setCommRateResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
