/** @format */

import config from '../../config';

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import NavMenuItem from './NavMenuItem';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import { SettingsContext } from '../../components/shared/SettingsContext';
import styled, { withTheme } from 'styled-components';

import { Paper } from '../../components/interface';
import { ConditionalDemoWrapper } from '../demo';

const NavSubMenuComp = styled(Paper)`
  width: 8em;
  overflow: hidden;
  box-shadow: none !important;
  display: inline-block;
  vertical-align: top;

  &.active {
    .menu-title {
      border-bottom: 2px solid ${(props) => props.theme.primary};
      padding-bottom: 0.25em;
    }
  }

  .nav-sub-menu-title {
    text-transform: capitalize;
    width: 100%;
    display: block;
    height: 4em;
    line-height: 4em;
    text-align: center;
    font-weight: 500;
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    width: 6em;
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .nav-sub-menu-title {
      min-width: 6em;
      height: 3em;
      line-height: 3em;
      letter-spacing: 0;
    }
  }
`;

/**
 * This component renders a bunch of menu items as a column. It can support nesting other sub-menus.
 * It requires at least a title.
 */

class NavSubMenu extends React.Component {
  static contextType = SettingsContext;

  state = {
    expanded: false,
  };

  //determine if the submenu item should be highlighted
  isRouteSelected = () => {
    if (this.props.route) {
      let atom = this.props.route.split('/').pop();
      let locs = window.location.pathname.split('/');
      //we are trying to see if the route that the item corresponds to
      //is anywhere in the breadcumbs of the routing; there are probably edge cases where this fails
      return locs.indexOf(atom) !== -1;
    } else {
      return false;
    }
  };

  //toggle expanded state when hovering
  expand = () => {
    this.setState({ expanded: true });
  };

  //contract when mouse has left
  contract = () => {
    this.setState({ expanded: false });
  };

  //toggle version
  toggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  //check if 'onlyShowIf' confition is in the user profile config and is true
  //we probaly want to make this more generic
  onlyShowIf = (it) => {
    if (typeof it.onlyShowIf === 'undefined') {
      return true;
    } else {
      let stack = it.onlyShowIf.split(' ');
      let res = null;

      for (let i = 0; i < stack.length; i++) {
        let flip = stack[i].startsWith('!');
        let condition = stack[i].replace(/^!/, '');
        let ret = false;

        if (condition === 'narrow') {
          ret = this.context.contentLayout === 'narrow' || this.context.navLayout === 'narrow';
        } else if (condition === 'normal') {
          ret = this.context.contentLayout === 'normal';
        } else if (condition === 'sudoer') {
          ret = !!this.props.sudoer;
        } else if (condition === 'mobilePlatformRedirect') {
          ret = !!Whitelabel.mobilePlatformLink;
        } else if (this.props.switches && this.props.switches.has(condition)) {
          ret = this.props.switches.get(condition);
        } else if (this.props.permissions) {
          ret = this.props.permissions.getIn(condition.split('.'), false);
        }
        ret = flip ? !ret : ret;

        if (res === null) {
          res = ret;
        } else if (stack[i - 1] === '||') {
          res = res || ret;
        } else if (stack[i - 1] === '&&') {
          res = res && ret;
        }
      }

      return res;
    }
  };
  /**
   * this method renders the component via React
   */
  render() {
    let navMenuHeight;

    if (this.context.navLayout !== 'narrow') {
      navMenuHeight = 4; //em
    } else {
      navMenuHeight = 3; //em
    }

    //calculate how much vertical height is required based on the number of children
    //this is required for the slide-down animation to work correctly
    let height = 1;
    for (let it of this.props.items) {
      if (it.menu && this.onlyShowIf(it)) {
        height++;
      }
    }
    height = height * navMenuHeight * this.context.fontSize;

    return (
      <NavSubMenuComp
        className={`${this.isRouteSelected() ? ' active' : ''}${
          this.props.sudoHighlight ? ' sudo' : ''
        }`}
        noBr="true"
        elevation={this.props.theme.palette === 'dark' ? 0 : 2}
        style={{
          height: this.state.expanded ? height : navMenuHeight * this.context.fontSize,
        }}
        onMouseEnter={this.context.navLayout !== 'narrow' ? this.expand : null}
        onMouseLeave={this.context.navLayout !== 'narrow' ? this.contract : null}
      >

      <ConditionalDemoWrapper wrap={!this.props.demo}>
        <span
          className="nav-sub-menu-title"
          onClick={this.context.navLayout === 'narrow' ? this.toggle : null}
        >
          <span>{this.props.intl.formatMessage(this.props.title)}</span>
        </span>
      </ConditionalDemoWrapper>
        {this.props.items.map((it, index) => {
          if (it.menu && this.onlyShowIf(it)) {
            return <NavMenuItem key={index} {...it} />;
          } else {
            return null;
          }
        })}
        {this.props.submenus
          ? this.props.submenus.map((it, index) => {
              if (it.menu && this.onlyShowIf(it)) {
                return <NavSubMenu key={index} {...it} />;
              } else {
                return null;
              }
            })
          : []}
      </NavSubMenuComp>
    );
  }
}

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => ({
  routing: state.get('routing', null),
  sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
  permissions: state.getIn(['base', 'profile', 'config'], null),
  switches: state.getIn(['base', 'switches'], null),
});

export default connect(mapStateToProps)(withTheme(injectIntl(NavSubMenu)));
