/** @format */

import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (
  <>
    <FormattedMessage
      id="trade.main.search.teamCompetitionName"
      defaultMessage="team or competition name"
    />
    <FormattedMessage
      id="orders.expandTooltip"
      defaultMessage="View the bets that make up this order."
    />
    <FormattedMessage id="orders.betInformation" defaultMessage="{home} vs {away}" />
    <FormattedMessage id="orders.wantedPrice" defaultMessage="wanted price {price}" />
    <FormattedMessage id="orders.wantedStake" defaultMessage="wanted stake {stake}" />
    <FormattedMessage
      id="orders.detailsTooltip"
      defaultMessage="Open order logs. This contains details about placement rounds and account selection."
    />
    <FormattedMessage
      id="orders.positionTooltip"
      defaultMessage="Open position grid for this event."
    />
    <FormattedMessage id="orders.reloadOrdersTooltip" defaultMessage="Reload orders" />
    <FormattedMessage
      id="nav.feedback.buttonTooltip"
      defaultMessage="Click here to send us a feedback report."
    />
    <FormattedMessage
      id="nav.feedback.passwordTooltip"
      defaultMessage="Passwords must be at least 8 characters long, include at least one digit and cannot be the same as your username."
    />
    <FormattedMessage
      id="nav.menu.money.currencyTooltip"
      defaultMessage="The placement and settlement currency of your account."
    />
    <FormattedMessage
      id="nav.menu.money.displayCurrencyTooltip"
      defaultMessage="The display currency used on the platform. This may be different from your settlement currency and you might experience slight fluctuations in balances if you set this."
    />
    <FormattedMessage
      id="nav.menu.money.creditTooltip"
      defaultMessage="Amount of money currently available for betting (balance + credit limit)."
    />
    <FormattedMessage
      id="nav.menu.money.creditLimitTooltip"
      defaultMessage="How much credit you are given to play with i.e. how much in the negative you can go."
    />
    <FormattedMessage
      id="nav.menu.money.balanceTooltip"
      defaultMessage="Sum of all transactions, settled bets and transfers. For full breakdown go to history - accounting - balances, and click on the balance."
    />
    <FormattedMessage
      id="nav.menu.money.yesterdayPLTooltip"
      defaultMessage="Yesterday PL is calculated as the difference between the balance today at midnight, London time, and yesterday midnight, also London time, excluding transfers."
    />
    <FormattedMessage
      id="nav.menu.money.todayPLTooltip"
      defaultMessage="Today PL is calculated as the difference between the current balance and the balance today at midnight, London time, excluding transfers."
    />
    <FormattedMessage
      id="nav.menu.money.openBetsTooltip"
      defaultMessage="Sum of all unsettled bets."
    />
    <FormattedMessage
      id="nav.menu.money.hideCreditTooltip"
      defaultMessage="This will hide the credit value on every screen. You can still see it by opening this menu."
    />
    <FormattedMessage id="general.draggables.closeTooltip" defaultMessage="Close window." />
    <FormattedMessage
      id="trade.competitionBookieTooltip"
      defaultMessage="Automatically watching competitions from the bookie {bookie}. You are free to watch other competition but they maybe reset."
    />
    <FormattedMessage
      id="trade.parlay.removeBetTooltip"
      defaultMessage="Remove this bet from parlay."
    />
    <FormattedMessage
      id="trade.placer.order.cancelTooltip"
      defaultMessage="Do not create this order"
    />
    <FormattedMessage
      id="trade.placer.order.placeTooltip"
      defaultMessage="Confirm the creation of this order"
    />
    <FormattedMessage
      id="trade.placer.showMoreHandicapsTooltip"
      defaultMessage="Show more handicaps each side if available."
    />
    <FormattedMessage
      id="trade.placer.placeAllTooltip"
      defaultMessage="Attempts to place all open (and possible) handicap lines. Each handicap line should have a stake and a price, otherwise it will be ignored. We suggest that you enable bet confirmation so you can see a summary of what will be placed."
    />
    <FormattedMessage id="trade.betslip.closeTooltip" defaultMessage="Close this betslip line." />
    <FormattedMessage
      id="trade.betslip.futurePositionTooltip"
      defaultMessage="This will open up a possible position grid based on the market you have chosen. You can then adjust the price and stake in the betslip to see what MIGHT happen if you place the bet. You can also combine multiple betslips from the same event and sport into one grid by pressing the same button on all of them."
    />
    <FormattedMessage
      id="trade.betslip.priceHistoryTooltip"
      defaultMessage="Price history data based on the prices and bookies that you have available."
    />
    <FormattedMessage
      id="trade.betslip.flipBetTooltip"
      defaultMessage="Open the exact opposite bet market. For example, this can be used to bet against the Draw in the 1X2 market."
    />
    <FormattedMessage
      id="trade.betslip.useBookiesTooltip"
      defaultMessage="Let the platform place the best possible order for you using available bookies."
    />
    <FormattedMessage
      id="trade.betslip.showMoreTooltip"
      defaultMessage="Show more handicap lines"
    />
    <FormattedMessage
      id="trade.position.hedgeTooltip"
      defaultMessage="The hedge functionality will open a betslip for the opposite bet type. It will then try to find a stake and price to ensure the negative side of the bet is minimized. If you are happy with the selection you can place this bet."
    />
    <FormattedMessage id="trade.priceHistory.bookies.time" defaultMessage="Time" />
    <FormattedMessage
      id="trade.priceHistory.bookies.averageTooltip"
      defaultMessage="Average price across all the available bookmakers at a given time."
    />
    <FormattedMessage
      id="trade.priceHistory.bookies.eventsTooltip"
      defaultMessage="Show match events like goals, corners and red cards."
    />
    <FormattedMessage
      id="trade.priceHistory.bookies.irOnlyTooltip"
      defaultMessage="Show only prices when event is in-running."
    />
    <FormattedMessage id="trade.betbar.showOnlyMeTooltip" defaultMessage="Show only my orders" />
    <FormattedMessage
      id="trade.betbar.showClosedTooltip"
      defaultMessage="Show closed orders as well"
    />
    <FormattedMessage
      id="trade.betbar.detailsTooltip"
      defaultMessage="Open order details window."
    />
    <FormattedMessage
      id="trade.betbar.openPositionTooltip"
      defaultMessage="Open postion grid for this order"
    />
    <FormattedMessage
      id="trade.betbar.cancelTooltip"
      defaultMessage="Attempt to cancel this order"
    />
    <FormattedMessage id="trade.betbar.wantedPrice" defaultMessage="wanted {price}" />
    <FormattedMessage id="trade.betbar.wantedStake" defaultMessage="wanted {stake}" />
    <FormattedMessage
      id="trade.main.competition.unwatchTooltip"
      defaultMessage="Unwatch this competition"
    />
    <FormattedMessage
      id="trade.main.competition.unfavTooltip"
      defaultMessage="Remove this competition from favorites"
    />
    <FormattedMessage
      id="trade.main.competition.favTooltip"
      defaultMessage="Add this competition to favorites"
    />
    <FormattedMessage id="trade.main.event.notification.score" defaultMessage="Score" />
    <FormattedMessage id="trade.main.event.notification.redCards" defaultMessage="Red Cards" />
    <FormattedMessage
      id="trade.main.event.notification.statusInRunning"
      defaultMessage="Match is now in running !"
    />
    <FormattedMessage
      id="trade.main.event.hotTooltip"
      defaultMessage="This event currently has a lot of betting activity."
    />
    <FormattedMessage
      id="trade.main.event.actions.removeFavTooltip"
      defaultMessage="Remove this event from favorites."
    />
    <FormattedMessage
      id="trade.main.event.actions.addFavTooltip"
      defaultMessage="Add this event to favorites."
    />
    <FormattedMessage
      id="trade.main.event.actions.expandExtrasTooltip"
      defaultMessage="Expand extra correlated sports."
    />
    <FormattedMessage
      id="trade.main.event.actions.eventHistoryTooltip"
      defaultMessage="Open even in-running information."
    />
    <FormattedMessage
      id="trade.main.event.actions.seeLiveTooltip"
      defaultMessage="See event live feed."
    />
    <FormattedMessage
      id="trade.main.sport.showLessTooltip"
      defaultMessage="Do not show all offer lines."
    />
    <FormattedMessage
      id="trade.main.sport.showMoreTooltip"
      defaultMessage="Show all offer lines."
    />
    <FormattedMessage id="trade.main.sport.positionTooltip" defaultMessage="Open position grid." />
    <FormattedMessage
      id="trade.main.parlayToggleTooltip"
      defaultMessage="Enable or disable parlay betting mode."
    />
    <FormattedMessage
      id="trade.side.betting.confirmBetPlacementTooltip"
      defaultMessage="Show a confirmation popup every time you attempt to place an order. It will contain information about the bet(s) you are trying to place and execution conditions."
    />
    <FormattedMessage
      id="trade.side.betting.simpleBetslipTooltip"
      defaultMessage="Use a simplified version of the betslip. Bookie accounts and other settings are hidden and you only need to enter the stake you want."
    />
    <FormattedMessage
      id="trade.side.betting.resizableBetslipTooltip"
      defaultMessage="Allow the resizing of the betslip from the bottom right corner. You will lose the automatic resizing functionality."
    />
    <FormattedMessage
      id="trade.side.betting.verticalBetslipTooltip"
      defaultMessage="Always use narrower vertical betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showMaxOrderTooltip"
      defaultMessage="Show the maximum order in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showMinOrderTooltip"
      defaultMessage="Show the minimum order in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showExpectedWinTooltip"
      defaultMessage="Show the expected returns in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showAveragePriceTooltip"
      defaultMessage="Show the weighted average price in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showMinPriceTooltip"
      defaultMessage="Show the minimum price in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.showMaxPriceTooltip"
      defaultMessage="Show the maximum price in the betslip."
    />
    <FormattedMessage
      id="trade.side.betting.betslipTintTooltip"
      defaultMessage="Lightly color the betslip background based on the in running, today or early status of the market."
    />
    <FormattedMessage
      id="trade.side.betting.bookieTintColorsTooltip"
      defaultMessage="Lightly color bookie account backgrounds. The colors are consistent with usage all over the website."
    />
    <FormattedMessage
      id="trade.side.betting.bookieLogosTooltip"
      defaultMessage="Show bookie logos instead of shorthand names."
    />
    <FormattedMessage
      id="trade.side.betting.showBetslipAgentTooltip"
      defaultMessage="Show agent name in betslip betting accounts."
    />
    <FormattedMessage
      id="trade.side.betting.extraEdgeHandicapsTooltip"
      defaultMessage="Allow opening higher/lower handicap lines even if they do not exists. For example if only +1.5 and +1.75 exist naturally, we will auto insert +1, +1.25, +2, +2.25 so you may bet on it using exchanges or for the future."
    />
    <FormattedMessage
      id="trade.side.betting.autofillMiddleHandicapsTooltip"
      defaultMessage="Allow opening intermediate handicap lines even if they do not exists. For example if only +1.5 and +3.5 exist naturally, we will auto insert +2.5 so you may bet on it using exchanges or for the future."
    />
    <FormattedMessage
      id="trade.side.betting.useEquivalentBetsTooltip"
      defaultMessage="Should we try and place equivalent bets with different bet types to what you have requested? For instance, if you asked for under 0.5 goals, we could place a correct score 0-0 bet to try and get more volume."
    />
    <FormattedMessage
      id="trade.side.betting.multipleHandicapLinesTooltip"
      defaultMessage="Do you want betslips to have multiple handicap lines open by default ?"
    />
    <FormattedMessage
      id="trade.side.betting.allowPutOffersOnExchangeTooltip"
      defaultMessage="If an exchange account is selected, but no liquidity is available, should we attempt to put an offer up on the exchange?"
    />
    <FormattedMessage
      id="trade.side.betting.ignoreSystemMaintenanceTooltip"
      defaultMessage="Ignore any system maintenance or panic messages when placing an order. This is not recommended."
    />
    <FormattedMessage
      id="trade.side.betting.autoAddBetsToFavsTooltip"
      defaultMessage="Automatically add events that you bet on to your favorites."
    />
    <FormattedMessage
      id="trade.side.betting.advancedOrderPlacementOptionsTooltip"
      defaultMessage="Show advanced order expiry options like keeping an order open in running or taking best closing price."
    />
    <FormattedMessage
      id="trade.side.betting.waitForPmmsTooltip"
      defaultMessage="Wait for bookie offers before showing the betslip. This may be useful under poor network conditions."
    />
    <FormattedMessage
      id="trade.side.betting.multipleAccountsOnBetslipTooltip"
      defaultMessage="What to do when there is more than one account for the same bookie on the betslip? More information on the exact behaviour can be found in the help section."
    />
    <FormattedMessage
      id="trade.side.betting.adaptiveSelectionTooltip"
      defaultMessage="Should orders reselect accounts and bookies during placement? More information on the exact behaviour can be found in the help section."
    />
    <FormattedMessage
      id="trade.side.betting.maximumBetTooltip"
      defaultMessage="What is the largest order a user can place in their chosen currency?"
    />
    <FormattedMessage
      id="trade.side.betting.defaultExpiryTooltip"
      defaultMessage="When a betslip is opened, how much should we default to having the order expiry ?"
    />
    <FormattedMessage
      id="trade.side.betting.defaultStakeTooltip"
      defaultMessage="When a betslip is opened, how much should we default to having in the stake box ?"
    />
    <FormattedMessage
      id="trade.side.betting.defaultPriceTooltip"
      defaultMessage="When a betslip is opened, how much should we default to having in the price box ?"
    />
    <FormattedMessage
      id="trade.side.betting.customPlaceInputsTooltip"
      defaultMessage="A comma separated list of place buttons with preset values (for example: 10,20,50,100)."
    />
    <FormattedMessage
      id="trade.side.competitions.hot"
      defaultMessage="This competition contains events with a lot of betting activity on them,"
    />
    <FormattedMessage
      id="trade.side.market.unwatchAllTooltip"
      defaultMessage="Clear all your watched competitions except for the ones required by favorites."
    />
    <FormattedMessage
      id="trade.side.market.watchAllTooltip"
      defaultMessage="Watch all the available competitions. This will put a lot of strain on your machine and may cause freezing."
    />
    <FormattedMessage
      id="trade.side.market.unwatchAllWarning"
      defaultMessage="Are you sure you want to unwatch all your competitions?"
    />
    <FormattedMessage
      id="trade.side.market.watchAllWarning"
      defaultMessage="Are you sure you want to all the competitions?"
    />
    <FormattedMessage
      id="trade.side.notification.removeTooltip"
      defaultMessage="Remove this notification."
    />
    <FormattedMessage
      id="trade.side.trading.trading.tradeTintTooltip"
      defaultMessage="Lightly the background of event markets."
    />
    <FormattedMessage
      id="trade.side.trading.hideFavsCompetitionsHeadersTooltip"
      defaultMessage="Hide competition headers in the favorites market."
    />
    <FormattedMessage
      id="trade.side.trading.hideExchangeOnlyLinesTooltip"
      defaultMessage="Hide markets and handicap lines that only use exchange offers."
    />
    <FormattedMessage
      id="trade.side.trading.themeTooltip"
      defaultMessage="General interface theme."
    />
    <FormattedMessage
      id="trade.side.trading.userTradeViewTooltip"
      defaultMessage="Trade page event layout."
    />
    <FormattedMessage
      id="trade.side.trading.priceTypeTooltip"
      defaultMessage="Odds type to use on platform (trade, history)."
    />
    <FormattedMessage
      id="trade.side.trading.compBookieTooltip"
      defaultMessage="The competitions selected on the trade page will always default to the ones offered by the selected bookie. If none is selected then it will show only the competitions the user has chosen."
    />
    <FormattedMessage
      id="trade.side.trading.priceBookiesTooltip"
      defaultMessage="Trade page will display only events that have prices from these bookies."
    />
    <FormattedMessage
      id="trade.side.trading.playSoundsTooltip"
      defaultMessage="Play trade page sounds for goals, red cards and kick-off."
    />
    <FormattedMessage
      id="trade.side.trading.browserNotificationsTooltip"
      defaultMessage="Pop up browser notifications for goals, red cards, kick-off and order status updates."
    />
    <FormattedMessage
      id="trade.side.trading.oldSportSelectorTooltip"
      defaultMessage="Old style sport selector inside options side-menu."
    />
    <FormattedMessage
      id="trade.side.tradeCompetitionsTooltip"
      defaultMessage="The list of the available competitions for a selected sport."
    />
    <FormattedMessage
      id="trade.side.tradeNotificationsTooltip"
      defaultMessage="Trade page notifications."
    />
    <FormattedMessage
      id="trade.side.tradeSettingsTooltip"
      defaultMessage="Trade page layout, display and sport markets."
    />
    <FormattedMessage
      id="trade.side.betSettingsTooltip"
      defaultMessage="General betslip settings and display."
    />
    <FormattedMessage
      id="trade.side.performanceSettingsTooltip"
      defaultMessage="Trade page performance settings."
    />
    <FormattedMessage
      id="trade.side.dataStreamsTooltip"
      defaultMessage="Show status of data streams to/from micro-services and APIs."
    />
    <FormattedMessage
      id="agent.balances.unlimitedTooltip"
      defaultMessage="Unlimited credit. Will be limited by the group credit limit if there is one. Use with caution."
    />
    <FormattedMessage
      id="agent.balances.notSetTooltip"
      defaultMessage="No credit limit is set. By default this is equivalent to a credit limit of 0."
    />
    <FormattedMessage
      id="agent.balances.unlimitedTooltip"
      defaultMessage="Unlimited credit. Will be limited by the group credit limit if there is one. Use with caution."
    />
    <FormattedMessage
      id="agent.balances.notSetTooltip"
      defaultMessage="No credit limit is set. By default this is equivalent to a credit limit of 0."
    />
    <FormattedMessage
      id="agent.balances.setCommRateTooltip"
      defaultMessage="Confirm setting new commission rate."
    />
    <FormattedMessage
      id="agent.balances.setCommRateTooltip"
      defaultMessage="Confirm setting new commission rate."
    />
    <FormattedMessage
      id="agent.balances.setCommRateTooltip"
      defaultMessage="Confirm setting new commission rate."
    />
    <FormattedMessage
      id="agent.balances.searchCustomersTooltip"
      defaultMessage="Search inside customer username, name, id."
    />
    <FormattedMessage
      id="agent.balances.searchGroupsTooltip"
      defaultMessage="Search inside group name, id."
    />
    <FormattedMessage
      id="agent.balances.newCreditLimit.amountTooltip"
      defaultMessage="The user/group credit limit in the user/group currency. The user/group will not be able to bet beyond this."
    />
    <FormattedMessage
      id="agent.balances.newCreditLimit.descriptionTooltip"
      defaultMessage="A note for the credit limit set. The customer will also see this."
    />
    <FormattedMessage
      id="agent.balances.newCommRate.percentageTooltip"
      defaultMessage="The commission percentage applied to all user betting."
    />
    <FormattedMessage
      id="settings.bookies.minimumTooltip"
      defaultMessage="Set a minimum bookmaker balance."
    />
    <FormattedMessage id="settings.bookies.termsTooltip" defaultMessage="Go to bookmaker terms." />
    <FormattedMessage
      id="settings.bookies.settlementTooltip"
      defaultMessage="Go to bookmaker settlement info."
    />
    <FormattedMessage
      id="settings.bookies.refreshBalanceTooltip"
      defaultMessage="Manually refresh account balance."
    />
    <FormattedMessage
      id="agent.customer.creditLimit.unlimitedTooltip"
      defaultMessage="Unlimited credit. Will be limited by the group credit limit if there is one."
    />
    <FormattedMessage
      id="agent.customers.searchCustomersTooltip"
      defaultMessage="Search inside customer username, name, id."
    />
    <FormattedMessage
      id="agent.customers.searchGroupsTooltip"
      defaultMessage="Search inside group name, id."
    />
    <FormattedMessage
      id="agent.transfers.transferTooltip"
      defaultMessage="Make the current transfer to the user."
    />
    <FormattedMessage
      id="agent.transfers.searchCustomersTooltip"
      defaultMessage="Search inside customer username, name, id."
    />
    <FormattedMessage
      id="agent.transfers.searchGroupsTooltip"
      defaultMessage="Search inside group name, id."
    />
    <FormattedMessage
      id="agent.transfers.newTransfer.amountTooltip"
      defaultMessage="The amount transferred, in the customer account currency."
    />
    <FormattedMessage
      id="agent.transfers.newTransfer.discountTooltip"
      defaultMessage="An adjustment for the new balance of the customer after the transfer, on top of the amount."
    />
    <FormattedMessage
      id="agent.transfers.newTransfer.descriptionTooltip"
      defaultMessage="A text description for the transfer. The customer will also see this."
    />
    <FormattedMessage
      id="settings.bookies.minimumTooltip"
      defaultMessage="Set a minimum bookmaker balance."
    />
    <FormattedMessage id="settings.bookies.termsTooltip" defaultMessage="Go to bookmaker terms." />
    <FormattedMessage
      id="settings.bookies.settlementTooltip"
      defaultMessage="Go to bookmaker settlement info."
    />
    <FormattedMessage
      id="settings.bookies.refreshBalanceTooltip"
      defaultMessage="Manually refresh account balance."
    />
    <FormattedMessage
      id="bets.saved.loadSavedTooltip"
      defaultMessage="Load a previously saved history/bets view."
    />
    <FormattedMessage
      id="bets.saved.saveCurrentTooltip"
      defaultMessage="Save current history/bets view."
    />
    <FormattedMessage
      id="bets.saved.deleteTooltip"
      defaultMessage="Delete the current history/bets view."
    />
    <FormattedMessage
      id="bets.saved.resetTooltip"
      defaultMessage="Reset all the filters in the current history/bets view."
    />
    <FormattedMessage
      id="myAccount.autoBindSearchTooltip"
      defaultMessage="Automatically bind the browser text find functionality (ctrl+F) to the smart search on the current page if there is one. This works for the Trade and Agent pages."
    />
  </>
);
