/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import * as Immutable from 'immutable';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { actions } from './app';

export type DocumentStatus = string; // TODO narrow to enum

export interface KycDocument {
  description: string;
  proofOfAddress: boolean;
  proofOfIdentity: boolean;
  status: DocumentStatus;
}

export interface SetKycStatus {
  type: 'myaccount/setKycStatus';
  identityVerified: boolean;
  addressVerified: boolean;
}

export interface SetKycDocuments {
  type: 'myaccount/setKycDocuments';
  documents: KycDocument[];
}

export interface LogOut {
  type: 'logout';
}

export interface Preferences {
  maxOrder?: string;
  maxWeeklyDeposit?: string;
  maxMonthlyDeposit?: string;
}

export interface RestrictionConfig {
  login: boolean;
}

export interface FutureRestriction {
  effectiveFrom: string;
  payload: {
    preferences?: Preferences;
    config?: RestrictionConfig;
  };
}

export interface SetRestrictions {
  type: 'myaccount/setRestrictions';
  current: {
    config: RestrictionConfig;
    ccyCode: string;
    preferences: Preferences;
  };
  future: FutureRestriction[];
}

export type Actions = SetKycStatus | SetKycDocuments | SetRestrictions;

export interface KycSetStatusPayload {
  addressVerified: boolean;
  identityVerified: boolean;
}

let initialState = Immutable.fromJS({
  identityVerified: undefined,
  addressVerified: undefined,

  documents: [],

  restrictions: {
    current: {
      config: {
        login: true,
      },
      ccyCode: '', // not used, but present in response
      preferences: {
        maxOrder: '',
        maxWeeklyDeposit: '',
        maxMonthlyDeposit: '',
      },
    },
    future: [],
  },
});

export const setKycStatus = (payload: KycSetStatusPayload): SetKycStatus => ({
  ...payload,
  type: 'myaccount/setKycStatus',
});

export const setKycDocuments = (documents: KycDocument[]): SetKycDocuments => ({
  documents,
  type: 'myaccount/setKycDocuments',
});

export const setRestrictions = (payload: SetRestrictions): SetRestrictions => ({
  ...payload,
  type: 'myaccount/setRestrictions',
});

export function requestKycStatus() {
  return async function (dispatch: Dispatch) {
    const dsResp = await DSM.create('/cs/kyc/status', {
      method: 'GET',
      startsPaused: true,
    }).start();
    const dsRespParsed = await dsResp.json();

    //? This probably means we got a 429 Too Many Requests
    //? We should come up with a more graceful caching mechanism, though.
    if (dsRespParsed.data == null) return;

    dispatch(
      setKycStatus({
        identityVerified: dsRespParsed.data.identityVerified,
        addressVerified: dsRespParsed.data.addressVerified,
      })
    );
  };
}

export function requestKycDocuments(): ThunkAction<
  void,
  typeof initialState,
  unknown,
  Action<string>
> {
  return async function (dispatch: Dispatch): Promise<void> {
    const dsResp = await DSM.create('/cs/kyc/documents/', {
      method: 'GET',
      startsPaused: true,
    }).start();
    const dsRespParsed = await dsResp.json();

    //? This probably means we got a 429 Too Many Requests
    //? We should come up with a more graceful caching mechanism, though.
    if (dsRespParsed.data == null) return;

    dispatch(setKycDocuments(dsRespParsed.data));
  };
}

export function requestRestrictions(): ThunkAction<
  void,
  typeof initialState,
  unknown,
  Action<string>
> {
  return async function (dispatch: Dispatch): Promise<void> {
    const dsResp = await DSM.create('/cs/restrictions', {
      method: 'GET',
      startsPaused: true,
    }).start();
    const dsRespParsed = await dsResp.json();

    //? This probably means we got a 429 Too Many Requests
    //? We should come up with a more graceful caching mechanism, though.
    if (dsRespParsed.data == null) return;

    dispatch(setRestrictions(dsRespParsed.data));
  };
}

export function requestAccountDeactivation(
  start: string,
  end: string
): ThunkAction<void, typeof initialState, unknown, Action<string>> {
  return async function (dispatch: Dispatch): Promise<void> {
    const dsReq = DSM.create('/cs/restrictions/exclusion', {
      method: 'POST',
      startsPaused: true,
      body: { start, end },
    });

    const dsResp = await dsReq.start();
    const dsRespParsed = await dsResp.json();

    //? This probably means we got a 429 Too Many Requests
    //? We should come up with a more graceful caching mechanism, though.
    if (dsRespParsed.data == null) return;

    dispatch(setRestrictions(dsRespParsed.data));
    dispatch(actions.logout());
  };
}

export function requestPreferenceChange(
  prefs: Preferences
): ThunkAction<void, typeof initialState, unknown, Action<string>> {
  return async function (dispatch: Dispatch): Promise<void> {
    const dsReq = DSM.create('/cs/restrictions/preferences', {
      method: 'POST',
      startsPaused: true,
      body: prefs,
    });

    const dsResp = await dsReq.start();
    const dsRespParsed = await dsResp.json();

    //? This probably means we got a 429 Too Many Requests
    //? We should come up with a more graceful caching mechanism, though.
    if (dsRespParsed.data == null) return;

    dispatch(setRestrictions(dsRespParsed.data));
  };
}

export function requestPasswordChange(
  currentPassword: string,
  password: string
): ThunkAction<void, typeof initialState, unknown, Action<string>> {
  return async function (dispatch: Dispatch, getState: () => typeof initialState): Promise<any> {
    const username = getState().getIn(['base', 'profile', 'username']);

    const dsReq = DSM.create(`/v1/customers/${username}/`, {
      method: 'PATCH',
      startsPaused: true,
      body: { password, currentPassword },
    });

    return dsReq.start();
  };
}

// basically the API returns an array of things, so we need a secondary routing of events
// We can't type the state using Immutable, because we're not using records
// flowlint-next-line unclear-type:off
export default function reducer(
  state: typeof initialState = initialState,
  action: Actions
): typeof initialState {
  switch (action.type) {
    case 'myaccount/setKycStatus': {
      return state
        .set('identityVerified', action.identityVerified)
        .set('addressVerified', action.addressVerified);
    }

    case 'myaccount/setKycDocuments': {
      return state.set('documents', Immutable.List(action.documents));
    }

    case 'myaccount/setRestrictions': {
      return state
        .setIn(['restrictions', 'current'], Immutable.fromJS(action.current))
        .setIn(['restrictions', 'future'], Immutable.fromJS(action.future));
    }

    default:
      /*:: (action: empty) */
      return state;
  }
}
