/**
 * This file combines the reducers...
 * you may or may not need/want to do this since each view will probably have its own reducer
 *
 * @format
 */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  bookieBalances: [],
  overallBalances: {},
  isLoading: true,
  isDownloading: false,
  isDownloadFailed: false,
});

const functions = {
  //get balances
  //in the case where the user can see bookie accounts we also load the bookie account balances
  getBalances: (state, action) => {
    if (!action.data.format) {
      DSM.last(
        `/v1/customers/${action.data.currentUser}/balances/`,
        {
          method: 'GET',
          message: 'loadOverallBalances',
          body: {
            streams: ['balance', 'stake'],
          },
        },
        action.data.actions,
        'getBalances'
      );
    }

    if (action.data.seeAccounts) {
      let headers = null;
      if (action.data.format) {
        headers = {};
        if (action.data.format === 'csv') {
          headers['Accept'] = 'text/csv';
        } else if (action.data.format === 'xls') {
          headers['Accept'] = 'application/vnd.ms-excel';
        }
      }

      DSM.last(
        `/v1/customers/${action.data.currentUser}/bookie_balances/`,
        {
          method: 'GET',
          message: 'loadBookieBalances',
          headers,
          downloadAs: action.data.format ? `accounting-balances.${action.data.format}` : '',
          body: {
            streams: ['stake', 'pl', 'pl_commission', 'kickback'],
          },
          extras: {
            format: action.data.format,
          },
        },
        action.data.actions,
        'getBalancesBookie'
      );

      if (!action.data.format) {
        state = state.set('isLoading', true);
      } else {
        state = state.set('isDownloading', action.data.format);
      }
    }

    return state;
  },

  //handle basic balances data
  loadOverallBalances: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('overallBalances', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      return state;
    }
  },

  //handle bookie balances data
  loadBookieBalances: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }
      return state.set('bookieBalances', fromJS(action.data.data));
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }

      //error handled by base
      return state;
    }
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};

for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
