/** @format */

import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { SettingsContext } from './SettingsContext';
import { translateBetType } from '../../lib/orders';
import { parseLayAndDarkBetTypes } from '../../lib/orders';
import { FormattedMessage } from 'react-intl';

const TranslatedBetType = (props) => {
  const context = useContext(SettingsContext);
  if (props.betType.includes('custom'))
    return (
      <span>
        <FormattedMessage id="orders.cashoutPosition" defaultMessage="Cash Out Position" />
      </span>
    );

  let betTypeDescription = props.betTypeDescription;
  if (
    !betTypeDescription ||
    betTypeDescription === 'None' ||
    betTypeDescription === '?' ||
    context.featureTranslatedBetTypes
  ) {
    let _betTypeDescription = translateBetType(
      props.betType,
      props.home,
      props.away,
      props.intl,
      props.orderType
    );
    if (_betTypeDescription) {
      betTypeDescription = _betTypeDescription;
    }
  }
  betTypeDescription = parseLayAndDarkBetTypes(
    betTypeDescription,
    props.orderType,
    props.showOrderType,
    props.exchangeMode === 'dark'
  );

  const title = `${betTypeDescription || ''} ${
    context.featureShowInternalInfo ? `[${props.betType}]` : ''
  }`.trim();

  return <span title={title || undefined}>{betTypeDescription}</span>;
};

export default injectIntl(TranslatedBetType);
