/** @format */

import { Dispatch } from 'redux';
import { Map as ImmutableMap } from 'immutable';
import { ThemeType } from '../../themes/types';

export enum SendingStatusEnum {
  SENDING = 'sending',
  SENT = 'sent',
  ERROR = 'error',
}

// @ts-ignore: Element labels are allowed
export interface FeedbackOptionType {
  value: string;
  label: JSX.Element;
}

export interface FeedbackTextAreaHelpPropsType {
  remaining: number;
}

export interface SendButtonElementPropsType {
  sendingStatus: SendingStatusEnum | null;
}

export interface SendButtonPropsType {
  sendingStatus: SendingStatusEnum | null;
  sendFeedback: () => void;
}

export interface SentAnimationElementPropsType {
  duration: number;
}

export interface SentAnimationPropsType {
  duration: number;
  theme: ThemeType;
  sendingStatus: SendingStatusEnum | null;
}

export interface ActionsType {
  [actionName: string]: any;
  toggleFeedbackOverlay: () => void;
}

export interface FeedbackDialogPropsType {
  actions: ActionsType;
  sendingStatus: SendingStatusEnum | null;
  isFeedbackOverlayOpen: boolean;
}

export type MapDispatchToPropsType = (dispatch: Dispatch) => { actions: ActionsType };

export type MapStateToPropsType = (
  state: ImmutableMap<string, any>,
  props: FeedbackDialogPropsType
) => {
  sendingStatus: SendingStatusEnum | null;
  isFeedbackOverlayOpen: boolean;
};
