/** @format */

import React, { useContext, useState } from 'react';

import {
  BetTypeOptionsPropsType,
  ChangesType,
  CheckboxContainerElementProps,
  EnabledGroupsType,
  MapDispatchToPropsType,
  MapStateToPropsType,
} from './types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { actions } from '../../../reducers/app';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import config from '../../../config';
import { SettingsContext, SettingsContextType } from '../../shared/SettingsContext';
import { Dialog, DialogTitle, DialogContent, Checkbox } from '../../interface';
import { ThemeType } from '../../../themes/types';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import ComponentErrorBoundary from '../../../components/error-boundary/ComponentErrorBoundary';

const CheckboxContainerElement = styled.div<CheckboxContainerElementProps>`
  label {
    width: 100%;
  }
`;

const DialogContentMax = styled(DialogContent)`
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 1em;
  }
  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.colours.white};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.25em solid transparent;
    background-clip: content-box;
    background-color: ${({ theme }) => theme.separator};
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colours.white};
    border: none;
  }
`;

const createOptionCheckBoxes = (
  offerGroups: string[],
  enabledGroups: EnabledGroupsType,
  changes: ChangesType,
  updateChanges: (changes: ChangesType) => void,
  saveChanges: (changes: ChangesType) => void,
  theme: ThemeType,
  intl: IntlShape
): JSX.Element[] =>
  offerGroups.map(
    (offerGroupKey: string): JSX.Element => {
      const changed = offerGroupKey in changes;
      const enabled = changed ? changes[offerGroupKey] : enabledGroups.get(offerGroupKey) || false;
      const onChange = (): void => {
        const newChanges = { ...changes };

        if (changed) {
          delete newChanges[offerGroupKey];
        } else {
          newChanges[offerGroupKey] = !enabledGroups.get(offerGroupKey);
        }

        saveChanges({ [offerGroupKey]: !enabled });
        updateChanges(newChanges);
      };

      return (
        <ComponentErrorBoundary key={offerGroupKey}>
          <CheckboxContainerElement changed={changed}>
            <Checkbox
              checked={enabled}
              color={theme.fonts.colours[1]}
              label={intl.formatMessage(config.offerGroups[offerGroupKey].name)}
              onChange={onChange}
            />
          </CheckboxContainerElement>
        </ComponentErrorBoundary>
      );
    }
  );

const DumbBetTypeOptions: React.FunctionComponent<BetTypeOptionsPropsType> = ({
  actions,
  baseSport,
  isBetTypesOverlayOpen,
  sport,
  enabledGroups,
}) => {
  // @ts-ignore: Doesnt pick up custom theme
  const theme: ThemeType = useTheme();
  const intl = useIntl();
  const [changes, updateChanges] = useState<ChangesType>({});
  const context = useContext<SettingsContextType>(SettingsContext);

  const close = (): void => {
    updateChanges({});
    actions.toggleBetTypesOverlay();
  };
  const save = (changes: ChangesType): void => {
    Telemetry.recordGA('user_action', 'updateMarketsDisplayed', 'updateMarketsDisplayed');
    actions.applySettings({ trade: { enabledGroups: { [baseSport]: changes } } });
  };

  return (
    <ComponentErrorBoundary>
      <Dialog open={isBetTypesOverlayOpen} closeWith={close} backdropClick={close}>
        <DialogTitle>
          <FormattedMessage
            id="betTypeOptions.availableMarkets"
            defaultMessage="Available markets for {sport}"
            values={{
              sport: config.sportNames[sport]
                ? intl.formatMessage(config.sportNames[sport].name)
                : sport,
            }}
          />
          <br />
          {context.featureParlay ? (
            context.parlayMode ? (
              <FormattedMessage
                id="betTypeOptions.parlayMode"
                defaultMessage="(accumulator mode)"
              />
            ) : (
              <FormattedMessage
                id="betTypeOptions.singleBetMode"
                defaultMessage="(single bet mode)"
              />
            )
          ) : null}
        </DialogTitle>
        <DialogContentMax>
          {enabledGroups &&
            createOptionCheckBoxes(
              config.sports[baseSport].offerGroupsOrder,
              enabledGroups,
              changes,
              updateChanges,
              save,
              theme,
              intl
            )}
        </DialogContentMax>
      </Dialog>
    </ComponentErrorBoundary>
  );
};

const mapDispatchToProps: MapDispatchToPropsType = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-ignore
const mapStateToProps: MapStateToPropsType = (state): Omit<BetTypeOptionsPropsType, 'actions'> => {
  let baseSport = state.getIn(['trade', 'baseSport'], '');
  return {
    baseSport,
    isBetTypesOverlayOpen: state.getIn(['ui', 'isBetTypesOverlayOpen'], false),
    sport: state.getIn(['trade', 'sport'], ''), //need
    enabledGroups: state.getIn(['base', 'settings', 'trade', 'enabledGroups', baseSport], null),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base', 'settings'], null) === next.getIn(['base', 'settings'], null) &&
      prev.getIn(['ui', 'isBetTypesOverlayOpen'], null) ===
        next.getIn(['ui', 'isBetTypesOverlayOpen'], null) &&
      prev.getIn(['trade', 'baseSport'], null) === next.getIn(['trade', 'baseSport'], null) &&
      prev.getIn(['trade', 'sport'], null) === next.getIn(['trade', 'sport'], null)
    );
  },
  // @ts-ignore
})(DumbBetTypeOptions);
