/** @format */

import { ColoursType, StateColoursType, ExchangeColoursType } from './types';

export const exchangeColours: ExchangeColoursType = {
  // back and lay colours
  back: 'rgba(0,66,107,1)',
  lightBack: 'rgba(0,66,107,0.1)',
  mediumBack: 'rgba(0,66,107,0.2)',
  lay: 'rgba(243,96,133,1)',
  lightLay: 'rgba(243,96,133,0.1)',
  mediumLay: 'rgba(243,96,133,0.2)',
};

export const colours: ColoursType = {
  black: '#000000',
  white: '#ffffff',
  grey: {
    darker: '#4b4b4b',
    dark: '#a7a7a7',
    mid: '#d3d3d3',
    light: '#eaeaea',
    lighter: '#f7f7f7',
  },
  red: {
    darker: '#a70c36',
    dark: '#c43a4f',
    mid: '#dd5c61',
    light: '#f58686',
    lighter: '#f8aaaa',
  },
  green: {
    darker: '#05654b',
    dark: '#098458',
    mid: '#34ac70',
    light: '#5acc86',
    lighter: '#7ae597',
  },
  blue: {
    dark: '#5858d0',
    mid: '#7781e9',
    light: '#92a1f0',
    lighter: '#bac0f4',
  },
  yellow: {
    mid: '#face55',
    light: '#fafef5',
  },
};

export const stateColours: StateColoursType = {
  success: colours.green.light,
  warning: colours.yellow.mid,
  error: colours.red.mid,
};
