/** @format */

import styled from 'styled-components';
import { SCREEN_BREAK } from '../elements';

export const FlagElement = styled.img`
  width: 18px;
  min-width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.separator};

  @media only screen and (max-width: ${370}px) {
    margin-right: 5px;
  }

  @media only screen and (max-width: ${350}px) {
    display: none;
  }
`;

export const CompetitionSearchResultDisplayElement = styled.div`
  color: #ddd;
  font-size: 15px;
  padding: 5px;
  padding: 8px 0px 8px 20px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  user-select: none;

  &:hover {
    background: #121212;
  }

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    font-size: 12px;
    padding: 8px 0px 8px 14px;
  }
`;

export const CompetitionName = styled.div`
  margin-right: 10px;
`;

export const CompetitionMarketElement = styled.div`
  font-size: 12px;
  color: ${({ watched }) => (watched ? 'white' : 'grey')};
  border-bottom: solid 1px;
  border-color: ${({ marketId, theme }) => theme[marketId]};
  padding: 10px;
  margin-right: 10px;
  visibility: ${({ hide }) => (hide ? '' : 'hidden')};

  :hover {
    //background: #1c1c1c;
    border: solid 1px;
    border-color: ${({ marketId, theme }) => theme[marketId]};
    border-radius: 2px;
    padding: 9px 9px 10px 9px;
  }
`;
