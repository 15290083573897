/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import routes from '../routes';
import { FormattedMessage } from 'react-intl';

import './FourOughFour.sass';

const sticker =
  Whitelabel.logos && Whitelabel.logos.coin && Whitelabel.logos.coin.light ? (
    <img
      alt="logo"
      className="fourOughFourLogo"
      src={`${routes.basename}${Whitelabel.logos.coin.light}`}
    />
  ) : (
    <h1 className="fourOughFourErrorCode">404</h1>
  );

const FourOughFour = () => {
  return (
    <div className="fourOughFourContainer">
      <Link className="displayBox" to={routes.default}>
        {sticker}
        <div className="errorInfo">
          <h1>
            <FormattedMessage id="404.pageNotFound" defaultMessage="Page not found" />
          </h1>
          <p>
            <FormattedMessage
              id="404.cantFind"
              defaultMessage="We can't find the page you've specified."
            />
          </p>
          <p>
            <FormattedMessage
              id="404.clickToReturn"
              defaultMessage="Click here to return to the Home page"
            />
          </p>
        </div>
      </Link>
    </div>
  );
};

export default FourOughFour;
