/** @format */

// @flow
import config from './config';
import { version } from '../package.json';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import cookie from 'cookie';

import reducers from './reducers/app';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import { actions as actionCreators } from './reducers/app';

import App from './App';

//Error reporting
if (process.env.NODE_ENV === 'production') {
  if (config.support.sentry.dsn) {
    Sentry.init({
      dsn: config.support.sentry.dsn,
      integrations: [new Integrations.BrowserTracing()],
      release: version,
      environment: process.env.NODE_ENV,
      ignoreErrors: config.support.sentry.ignoreErrors
    });

    Sentry.setTag({ whitelabel: Whitelabel.name });
  }
}

let devtoolsEnhancer;
try {
  devtoolsEnhancer = composeWithDevTools({
    actionCreators,
    trace: sessionStorage.getItem('trace'),
  });
} catch (err) {}

//* Not used _for now_
//! before we use this, we should set this up _inside_ the react tree,
//! so it's compatible with server-side rendering.
//! But, before then we should address https://bugzilla.smithersbet.com/show_bug.cgi?id=38692
const extra = {
  api: undefined, // Axios or DataStream2
  stream: undefined, // WebSocket
  pricefeed: undefined, // WebSocket

  // Might not need to keep track of this one, especially if we make it work as a babel plugin
  lognoice: undefined,
};

const store = createStore(
  reducers,
  undefined,
  devtoolsEnhancer(applyMiddleware(ReduxThunk.withExtraArgument(extra)))
);

if (process.env.NODE_ENV === 'development') {
  console.debug('Store accessible through `resduxStore` in development');
  window.reduxStore = store;
}

//disable sound manager debug
if (window && window.soundManager) {
  try {
    window.soundManager.setup({ debugMode: config.support.tools.soundManagerDebug });
  } catch (err) {
    //can't do much about this
    //Sentry.captureException(err);
    console.warn('Sound manager setup error', err);
  }
}

//change title
if (document) {
  document.title = `${Whitelabel.name} - Login`;
}

const mql = window.matchMedia(`(max-width: ${config.narrowBreakpointContents}px)`);
const cookieJar = cookie.parse(document.cookie);
const discoveryCookieName = 'mobileDiscovered';

if (mql.matches && !cookieJar[discoveryCookieName] && process.env.NODE_ENV !== 'development') {
  window.location.href = Whitelabel.mobilePlatformLink;
}

function runApp() {
  const mountNode = document.getElementById('root');

  if (!mountNode) {
    document.write('Cannot mount application.');
    return;
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    mountNode
  );
}

try {
  //serviceWorker.register()
  runApp();
} catch (err) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(err);
  } else {
    throw err;
  }
}
