/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';
import { fromJS } from 'immutable';

let initialState = fromJS({
  direction: '',
  isCreatingTransfer: false,
  transferError: '',
  transferErrorReason: '',
  transferStatus: '',
  action: null,
  details: {},
});

const functions = {
  ////// DEPOSIT MODAL

  //open withdrawal form
  openDepositWithdrawForm: (state, action) => {
    return state.set('direction', action.data ? action.data.direction : 'deposit');
  },

  //close and reset withdrawal form
  closeDepositWithdrawForm: (state, _action) => {
    state = fromJS({
      direction: '',
      isCreatingTransfer: false,
      transferError: '',
      transferErrorReason: '',
      transferStatus: '',
      action: null,
      details: {},
    });
    return state;
  },

  //create a customer transfer
  createCustomerTransfer: (state, action) => {
    DSM.create(
      '/cs/payments/',
      {
        method: 'POST',
        message: 'createCustomerTransferResponse',
        errorMessage: 'createCustomerTransferResponse', //fail to the same point
        body: {
          amount: action.data.amount,
          operation: action.data.operation,
          language: action.data.language,
          direct: action.data.direct, //only direct payments atm
        },
        extras: {
          direct: action.data.direct,
        },
      },
      action.data.actions
    );
    state = fromJS({
      isCreatingTransfer: true,
      transferError: '',
      transferErrorReason: '',
      transferStatus: '',
      action: null,
      details: {},
    });
    return state;
  },

  //response for customer transfer creation
  createCustomerTransferResponse: (state, action) => {
    state = state.set('transferStatus', action.data.status);
    state = state.set('isCreatingTransfer', false);

    if (action.data.status !== 'ok') {
      state = state.set(
        'transferErrorReason',
        action.data.data ? action.data.data.reason : 'unknown'
      );
      state = state.set('transferError', extractErrorMessage(action.data.data));
    } else {
      if (action.data.extras.action) {
        state = state.set('action', action.data.extras.action);
      }

      if (action.data.extras.direct) {
        //close amount dialog
        //we still need the direction
        //state = state.set('direction', '');
        //if it's a direct payment show some details
        state = state.set('details', fromJS(action.data.data));
      } else {
        if (window && action.data.data.redirectUrl) {
          window.location.href = action.data.data.redirectUrl;
        }
      }
    }

    return state;
  },

  //close direct banking details
  closeDirectBankingDetails: (state, _action) => {
    state = fromJS({
      direction: '',
      isCreatingTransfer: false,
      transferError: '',
      transferErrorReason: '',
      transferStatus: '',
      action: null,
      details: {},
    });
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
