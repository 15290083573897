/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';
import config from '../../../config';
import { toStdDate } from '@mollybet/frontend-common/dist/lib/time';

import { fromJS } from 'immutable';

const defaultDate = toStdDate();
let initialState = fromJS({
  formOpen: false,
  formData: [
    {
      username: '',
      percent: 0,
      date: defaultDate,
      status: '',
      errorInfo: '',
    },
  ],
});

const functions = {
  //open/close
  toggleBatchEditCommissions: (state, _action) => {
    const formOpen = state.get('formOpen');
    return state.set('formOpen', !formOpen);
  },

  //update form data
  updateCommissionForm: (state, action) => {
    return state.set('formData', fromJS(action.data.formData));
  },

  //update commission for each customer
  newBatchCommissionUpdate: (state, action) => {
    const transferData = Object.values(action.data.data);
    const batchLength = transferData.length;
    for (let j = 0; j < batchLength; j++) {
      transferData[j]['status'] = 'pending';
      transferData[j]['errorInfo'] = '';
      state = state.set('formData', fromJS(transferData));
    }

    // func called once for each form row (each transfer)
    let i = 0;
    const makeTransfer = () => {
      DSM.create(
        `/v1/customers/${action.data.data[i]['username']}/`,
        {
          method: 'PATCH',
          body: {
            commissionRate: {
              rate_pc: parseFloat(action.data.data[i].percent),
              start_date: action.data.data[i]['date'],
            },
          },
          extras: {
            transfer: {
              index: i,
              username: action.data.data[i]['username'],
              formData: Object.values(action.data.data),
            },
            streams: ['xfer', 'discount', 'balance'],
            targetUser: action.data.data[i]['username'],
            currentUser: action.data.currentUser,
          },
          message: 'newBatchCommissionUpdateResponse',
        },
        action.data.actions
      );

      i++;
      if (i < batchLength) {
        setTimeout(makeTransfer, config.timings.timeBetweenBatchCalls);
      }
    };

    if (batchLength) {
      makeTransfer();
    }

    return state;
  },

  //respond to each commission update
  newBatchCommissionUpdateResponse: (state, action) => {
    let formData = action.data.extras.transfer.formData;
    if (action.data.status === 'ok') {
      formData[action.data.extras.transfer.index]['status'] = 'success';
      state = state.set('formData', fromJS(formData));
    } else {
      const parsedError = extractErrorMessage(action.data.data);
      formData[action.data.extras.transfer.index]['status'] = 'failure';
      formData[action.data.extras.transfer.index]['errorInfo'] = parsedError;
      state = state.set('formData', fromJS(formData));
    }
    return state;
  },

  //retry individual updates
  retryCommissionUpdate: (state, action) => {
    let formData = state.get('formData');
    formData = Object.values(formData.toJS());
    const i = action.data.index;
    DSM.create(
      `/v1/customers/${formData[i]['username']}/`,
      {
        method: 'PATCH',
        body: {
          commissionRate: {
            rate_pc: parseFloat(formData[i].percent),
            start_date: formData[i]['date'],
          },
        },
        extras: {
          transfer: {
            index: i,
            username: formData[i]['username'],
            formData: formData,
          },
          targetUser: formData[i]['username'],
        },
        message: 'newBatchCommissionUpdateResponse',
      },
      action.data.actions
    );

    formData[i]['status'] = 'pending';
    formData[i]['errorInfo'] = '';
    return state.set('formData', fromJS(formData));
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
