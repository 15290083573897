/** @format */

import React from 'react';
import { actions } from '../../reducers/app';

import { closeAndCancelRequest } from '../../reducers/finances';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import currencies from '@mollybet/frontend-common/dist/lib/currencies';
import { SettingsContext } from './SettingsContext';
import styled, { withTheme } from 'styled-components';
import routes from '../../routes';

import {
  PositiveButton,
  NegativeButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from '../interface';

import { TextField } from '@mollybet/ui';

const CrateMoneyTransfer = styled(Dialog)`
  .dialog-body {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    max-height: inherit;
    margin-bottom: 12px;

    ul {
      list-style: none;
    }

    hr {
      margin: 18px 0 6px 0;
    }

    h3 {
      margin: 18px 0 6px 0;
      text-align: center;
    }

    h2 {
      font-size: 15px;
    }

    h1 {
      text-align: center;
      font-size: 18px;
    }
  }

  input,
  label {
    color: ${(props) => props.theme.foreground};
  }

  fieldset {
    border-color: ${(props) => props.theme.foreground};
  }

  .dialog-error,
  .dialog-success {
    padding: 12px;
    margin-top: 24px;
    text-align: center;
    color: ${(props) => props.theme.colours.white};

    span:first-child {
      margin-right: 12px;
      font-weight: bold;
    }
  }

  .dialog-error {
    background-color: ${(props) => props.theme.error};

    a {
      color: ${(props) => props.theme.colours.white};
    }
  }

  .dialog-success {
    background-color: ${(props) => props.theme.success};
  }
`;

const mapKeyToTranslation = (key) => {
  switch (key) {
    case 'bankName':
      return <FormattedMessage id="deposit.direct.bankName" defaultMessage="Bank Name" />;
    case 'bankAddress':
      return <FormattedMessage id="deposit.direct.bankAddress" defaultMessage="Bank Address" />;
    case 'currency':
      return <FormattedMessage id="deposit.direct.currency" defaultMessage="Currency" />;
    case 'accountNumber':
      return <FormattedMessage id="deposit.direct.accountNumber" defaultMessage="Account Number" />;
    case 'sortCode':
      return <FormattedMessage id="deposit.direct.sortCode" defaultMessage="Sort Code" />;
    case 'iban':
      return <FormattedMessage id="deposit.direct.iban" defaultMessage="IBAN" />;
    case 'swiftBic':
      return <FormattedMessage id="deposit.direct.swiftBic" defaultMessage="SWIFT BIC" />;
    case 'accountName':
      return <FormattedMessage id="deposit.direct.accountName" defaultMessage="Account Name" />;
    default:
      return key;
  }
};

const mapFailureReason = (reason) => {
  switch (reason) {
    case 'max_weekly_deposit_exceeded':
      return (
        <FormattedMessage
          id="deposit.direct.maxWeeklyDepositExceeded"
          defaultMessage="Max. weekly deposit exceded"
        />
      );
    case 'kyc_required':
      return (
        <a href={`${routes.basename}/myaccount/verification`}>
          <FormattedMessage
            id="deposit.direct.kycRequired"
            defaultMessage="KYC required, please complete KYC by clicking here"
          />
        </a>
      );
    case 'payment_not_allowed':
      return (
        <FormattedMessage
          id="deposit.direct.paymentNotAllowed"
          defaultMessage="Payment not allowed"
        />
      );
    case 'insufficient_balance':
      return (
        <FormattedMessage
          id="deposit.direct.insufficientBalance"
          defaultMessage="Insufficient balance"
        />
      );
    case 'unsupported_customer_group':
      return (
        <FormattedMessage
          id="deposit.direct.unsupportedCustomerGroup"
          defaultMessage="Unsupported customer group"
        />
      );
    case 'payment_creation_conflict':
      return (
        <FormattedMessage
          id="deposit.direct.paymentCreationConflict"
          defaultMessage="Too many concurrent payments"
        />
      );
    default:
      return null;
  }
};

class Deposit extends React.PureComponent {
  static contextType = SettingsContext;

  state = {
    amount: '0',
    direct: false,
  };

  closeTransfer = () => {
    this.props.actions.closeDepositWithdrawForm();
  };

  changeAmount = (event) => {
    this.setState({
      amount: event.target.value,
    });
  };

  canCreateTransfer = () => {
    return true;
  };

  createTransfer = () => {
    let amount = parseFloat(this.state.amount);
    this.props.actions.createCustomerTransfer({
      operation: this.props.direction,
      amount,
      language: this.context.language,
      direct: this.state.direct,
      actions: this.props.actions,
    });
  };

  render() {
    let bankDetails;
    if (this.props.bankDetails) {
      bankDetails = this.props.bankDetails.toJS();
    }

    let failureReason = mapFailureReason(this.props.transferErrorReason);

    return (
      <>
        <CrateMoneyTransfer open={!!this.props.direction}>
          <DialogTitle>
            {this.props.direction === 'deposit' ? (
              <FormattedMessage
                id="deposit.createTransferDeposit"
                defaultMessage="Make a new deposit"
              />
            ) : (
              <FormattedMessage
                id="deposit.createTransferWithdrawal"
                defaultMessage="Make a new withdrawal"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <div className="dialog-body">
              <h1 className={`current-balance${this.props.balance < 0 ? ' negative' : ''}`}>
                <FormattedMessage
                  id="deposit.currentBalance"
                  defaultMessage="Your Current Balance: {balance}"
                  values={{
                    balance: formatAmount(
                      this.props.balance,
                      this.context.ccyCode,
                      this.context.displayCcy,
                      this.context.xrates
                    ),
                  }}
                />
              </h1>
              <TextField
                value={this.state.amount}
                onChange={this.changeAmount}
                title={this.props.intl.formatMessage({
                  id: 'deposit.amountTooltip',
                  defaultMessage: 'The amount you wish to deposit or withdraw',
                })}
                label={
                  <FormattedMessage
                    id="deposit.amount"
                    defaultMessage="Amount ({ccyCode})"
                    values={{
                      ccyCode: this.context.displayCcy
                        ? currencies[this.context.displayCcy].symbol
                        : '',
                    }}
                  />
                }
              />
              <br />
              <br />
              <Checkbox
                checked={this.state.direct}
                onChange={(_event) => this.setState({ direct: !this.state.direct })}
                color={this.props.theme.paper.fontColor[1]}
                label={
                  <FormattedMessage
                    id="deposit.directBankDeposit"
                    defaultMessage="Transfer from bank"
                  />
                }
              />
            </div>
            {this.props.transferStatus && this.props.transferStatus !== 'ok' && (
              <div className="dialog-error">
                {!failureReason && <span>[{this.props.transferError}]</span>}
                {failureReason && <span>{failureReason}</span>}
              </div>
            )}
            {this.props.transferStatus === 'ok' && (
              <div className="dialog-success">
                <FormattedMessage
                  id="deposit.transfer.success"
                  defaultMessage="Please follow the instructions on the payment page..."
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <NegativeButton onClick={this.closeTransfer}>
              <FormattedMessage id="deposit.transfer.close" defaultMessage="Close" />
            </NegativeButton>
            <PositiveButton
              onClick={this.createTransfer}
              disabled={!this.canCreateTransfer() || this.props.isCreatingTransfer}
            >
              <FormattedMessage id="deposit.transfer.proceed" defaultMessage="Proceed" />
            </PositiveButton>
          </DialogActions>
        </CrateMoneyTransfer>
        <CrateMoneyTransfer open={!!this.props.paymentId}>
          <DialogTitle>
            {this.props.direction === 'deposit' ? (
              <FormattedMessage
                id="deposit.createDirectDeposit"
                defaultMessage="Make a new Direct Deposit"
              />
            ) : (
              <FormattedMessage
                id="deposit.createDirectWithdrawal"
                defaultMessage="Make a new Direct Withdrawal"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <div className="dialog-body">
              <h1 className="payment-id">
                <FormattedMessage
                  id="deposit.paymentId"
                  defaultMessage="Payment ID: {paymentId}"
                  values={{
                    paymentId: this.props.paymentId,
                  }}
                />
              </h1>
              <br />
              <p className="payment-id-explanation">
                <FormattedMessage
                  id="deposit.paymentIdExplanation"
                  defaultMessage="You have to include this as a reference code when you make the direct bank transfer or withdrawal request. This will help us credit your account quickly and accurately. You can contact us at: {email}"
                  values={{
                    email: Whitelabel.feedbackEmail,
                  }}
                />
              </p>
              {bankDetails && (
                <>
                  <hr />
                  <h2>
                    <FormattedMessage
                      id="deposit.bankingDetails"
                      defaultMessage="Our banking details"
                    />
                  </h2>
                  <br />
                  <ul className="banking-details">
                    {Object.keys(bankDetails).map((key) => (
                      <li key={key}>
                        {mapKeyToTranslation(key)}: &nbsp; {bankDetails[key]}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <PositiveButton onClick={() => this.props.actions.closeDirectBankingDetails()}>
              <FormattedMessage id="deposit.directTransfer.ok" defaultMessage="OK" />
            </PositiveButton>

            {!this.props.becauseDetailsAction && (
              <NegativeButton
                onClick={() => this.props.actions.closeAndCancelRequest(this.props.paymentId)}
              >
                <FormattedMessage id="finances.cancel" defaultMessage="Cancel" />
              </NegativeButton>
            )}
          </DialogActions>
        </CrateMoneyTransfer>
      </>
    );
  }
}

// turn state of combined reducers into state required by component
const mapStateToProps = (state) => ({
  direction: state.getIn(['deposit', 'direction'], 'deposit'),
  isCreatingTransfer: state.getIn(['deposit', 'isCreatingTransfer'], false),
  transferError: state.getIn(['deposit', 'transferError'], ''),
  transferErrorReason: state.getIn(['deposit', 'transferErrorReason'], ''),
  transferStatus: state.getIn(['deposit', 'transferStatus'], ''),
  balance: state.getIn(['base', 'profile', 'balance'], 0),

  becauseDetailsAction: state.getIn(['deposit', 'action'], null) === 'details',
  paymentId: state.getIn(['deposit', 'details', 'paymentId'], ''),
  bankDetails: state.getIn(['deposit', 'details', 'bankDetails'], null),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(actions, dispatch),
    closeAndCancelRequest,
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return (
      prev.getIn(['base'], null) === next.getIn(['base'], null) &&
      prev.getIn(['deposit'], null) === next.getIn(['deposit'], null)
    );
  },
})(withTheme(injectIntl(Deposit)));
