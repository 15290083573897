/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';

let initialState = fromJS({
  groups: [],
  capitalists: [],
  bookies: [],
  accounts: [],
  status: {
    capitalistAddStatus: undefined,
    groupAddStatus: undefined,
    accountSubmissionStatus: {},
    accountStatus: {},
  },
});

const realValue = (val) => {
  if (val === '' || val === undefined || val === ' ') return undefined;
  if (isNaN(parseInt(val))) return undefined;
  return parseInt(val);
};

const realFloatValue = (val) => {
  if (val === '' || val === undefined || val === ' ') return undefined;
  if (isNaN(parseFloat(val))) return undefined;
  return parseFloat(val);
};

const parseBlackListValue = (val) => {
  if (val === null || val === '') return undefined;
  const parsedVal = parseInt(val);
  if (isNaN(parsedVal)) return undefined;
  return parsedVal;
  // const trueVals = val
  //   .split('+')
  //   .map((part) => part.trim())
  //   .map((part) => parseInt(part))
  //   .filter((part) => !isNaN(part))
  //   .filter((part) => typeof part === 'number');
  // if (trueVals.length === 0) return undefined;
  // return trueVals;
};

const functions = {
  //get matrix groups
  getGroups: (state, action) => {
    DSM.create(
      '/v1/matrix/groups/',
      {
        method: 'GET',
        message: 'getGroupsResponse',
      },
      action.data.actions
    );
    return state;
  },
  getGroupsResponse: (state, action) => {
    state = state.set('groups', [...Object.entries(action.data.data)]);
    return state;
  },

  addGroups: (state, action) => {
    DSM.create(
      '/v1/matrix/groups/',
      {
        method: 'POST',
        body: {
          name: action.data.group,
        },
        message: 'addGroupsResponse',
      },
      action.data.actions
    );
    return state;
  },
  addGroupsResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.setIn(['status', 'groupAddStatus'], 'success');
    } else {
      state = state.setIn(['status', 'groupAddStatus'], 'failure');
    }
    return state;
  },

  //get matrix groups
  getCapitalists: (state, action) => {
    DSM.create(
      '/v1/matrix/capitalists/',
      {
        method: 'GET',
        message: 'getCapitalistsResponse',
      },
      action.data.actions
    );
    return state;
  },
  getCapitalistsResponse: (state, action) => {
    state = state.set('capitalists', [...Object.entries(action.data.data)]);
    return state;
  },

  //add matrix
  addCapitalists: (state, action) => {
    DSM.create(
      '/v1/matrix/capitalists/',
      {
        method: 'POST',
        body: {
          name: action.data.capitalist,
        },
        message: 'addCapitalistsResponse',
      },
      action.data.actions
    );
    return state;
  },
  addCapitalistsResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.setIn(['status', 'capitalistAddStatus'], 'success');
    } else {
      state = state.setIn(['status', 'capitalistAddStatus'], 'failure');
    }
    return state;
  },

  clearStatus: (state, action) => {
    state = state.setIn(['status', 'groupAddStatus'], undefined);
    state = state.setIn(['status', 'capitalistAddStatus'], undefined);
    state = state.setIn(['status', 'accountSubmissionStatus'], fromJS({}));
    state = state.setIn(['status', 'accountStatus'], {});
    return state;
  },

  //get matrix groups
  getBookies: (state, action) => {
    DSM.create(
      '/v1/matrix/bookies/',
      {
        method: 'GET',
        message: 'getBookiesResponse',
      },
      action.data.actions
    );
    return state;
  },
  getBookiesResponse: (state, action) => {
    state = state.set('bookies', [...Object.values(action.data.data)]);
    return state;
  },

  //add matrix
  submitAccount: (state, action) => {
    const {
      blacklistAll,
      blacklistEvent,
      blacklistSelection,
      bookie,
      capitalist,
      maximum,
      minimum,
      mollyGroup,
      notes,
      password,
      settlementCurrency,
      stake,
      stakeShare,
      username,
      //website,
      websiteCurrency,
      agent,
      index,
    } = action.data;
    const postBody = {
      bookie,
      username,
      password: password,
      ccy_code: settlementCurrency.toUpperCase(),
      capitalist_id: parseInt(capitalist),
      agent_id: realValue(agent),
      group_ids: mollyGroup.map((val) => parseInt(val)),
      display_ccy_code: websiteCurrency.toUpperCase(),
      stake_share: 100 - realFloatValue(stakeShare),
      display_stake_increment: realFloatValue(stake),
      min_display_bet: realFloatValue(minimum),
      max_display_bet: realFloatValue(maximum),
      blacklist_account: parseBlackListValue(blacklistAll),
      blacklist_event: parseBlackListValue(blacklistEvent),
      blacklist_selection: parseBlackListValue(blacklistSelection),
      description: notes,
    };
    const parsedBody = {};
    Object.entries(postBody).forEach(([key, value]) => {
      if (value !== undefined) {
        parsedBody[key] = value;
      }
    });
    DSM.create(
      '/v1/matrix/accounts/',
      {
        method: 'POST',
        body: { ...parsedBody },
        message: 'submitAccountResponse',
        extras: {
          index: index,
        },
      },
      action.data.actions
    );
    return state;
  },

  submitAccountResponse: (state, action) => {
    const index = action.data.extras.index;
    if (action.data.status && action.data.status === 'ok') {
      state = state.setIn(['status', 'accountSubmissionStatus', index], 'success');
    } else {
      state = state.setIn(['status', 'accountSubmissionStatus', index], 'failure');
    }
    return state;
  },

  //get submitted accounts
  getAccounts: (state, action) => {
    DSM.create(
      '/v1/matrix/accounts/',
      {
        method: 'GET',
        message: 'getAccountsResponse',
      },
      action.data.actions
    );
    return state;
  },
  getAccountsResponse: (state, action) => {
    state = state.set('accounts', [...Object.values(action.data.data)]);
    return state;
  },

  //get matrix groups
  closeAccount: (state, action) => {
    const { agent_id, account_id, index } = action.data;
    DSM.create(
      '/v1/matrix/accounts/close/',
      {
        method: 'POST',
        message: 'closeAccountResponse',
        body: {
          agent_id,
          account_id,
        },
        extras: { index: index },
      },
      action.data.actions
    );
    return state;
  },
  closeAccountResponse: (state, action) => {
    const index = action.data.extras.index;
    if (action.data.status && action.data.status === 'ok') {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'success' });
    } else {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'failure' });
    }
    return state;
  },

  //get matrix groups
  updatePassword: (state, action) => {
    const { agent_id, account_id, new_password, index } = action.data;
    DSM.create(
      '/v1/matrix/accounts/password/',
      {
        method: 'PUT',
        message: 'updatePasswordResponse',
        body: {
          agent_id,
          account_id,
          new_password,
        },
        extras: { index: index },
      },
      action.data.actions
    );
    return state;
  },
  updatePasswordResponse: (state, action) => {
    const index = action.data.extras.index;
    if (action.data.status && action.data.status === 'ok') {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'success' });
    } else {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'failure' });
    }
    return state;
  },

  updateGroup: (state, action) => {
    const { agent_id, account_id, new_group_ids, index } = action.data;
    DSM.create(
      '/v1/matrix/accounts/groups/',
      {
        method: 'PUT',
        message: 'updateGroupResponse',
        body: {
          agent_id,
          account_id,
          new_group_ids,
        },
        extras: { index: index },
      },
      action.data.actions
    );
    return state;
  },
  updateGroupResponse: (state, action) => {
    const index = action.data.extras.index;
    if (action.data.status && action.data.status === 'ok') {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'success' });
    } else {
      state = state.setIn(['status', 'accountStatus'], { [index]: 'failure' });
    }
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
