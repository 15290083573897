/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import {
  toCamelCase,
  toCamelCaseAll,
  toSnakeCaseAll,
} from '@mollybet/frontend-common/dist/lib/camelSnake';
import { extractErrorMessage } from '@mollybet/frontend-common/dist/lib/formatters';

import { fromJS } from 'immutable';
let initialState = fromJS({
  formOpen: false,
  targetUser: '',
  error: '',
  errorData: null,
  status: '',
  isLoading: false,

  editedCustomer: {},
  isLoadingCustomerToEdit: false,
});

const functions = {
  //open the customer edit-creation modal
  openEditCreateCustomer: (state, action) => {
    state = state.asMutable();
    state = state.set('formOpen', true);
    state = state.set('targetUser', action.data.targetUser);
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    state = state.set('isLoadingCustomerToEdit', false);
    state = state.remove('editedCustomer');
    return state.asImmutable();
  },

  //close edit-creation modal
  closeEditCreateCustomer: (state, _action) => {
    state = state.asMutable();
    state = state.set('formOpen', false);
    state = state.set('targetUser', '');
    state = state.set('error', '');
    state = state.set('errorData', null);
    state = state.set('status', '');
    state = state.set('isLoading', false);
    state = state.set('isLoadingCustomerToEdit', false);
    state = state.remove('editedCustomer');
    return state.asImmutable();
  },

  //request existing customer data and userprefs
  loadExistingCustomer: (state, action) => {
    let targetUser = state.get('targetUser', '');

    if (targetUser) {
      DSM.last(
        `/v1/customers/${targetUser}/`,
        {
          method: 'GET',
          caseConvert: false,
          extras: {
            targetUser,
          },
          message: 'loadExistingCustomerResponse',
        },
        action.data.actions,
        'loadExistingCustomer'
      );
      state = state.set('isLoadingCustomerToEdit', true);
    }

    return state;
  },

  //respond to customer data
  loadExistingCustomerResponse: (state, action) => {
    state = state.set('isLoadingCustomerToEdit', false);

    if (action.data.status !== 'ok') {
      state = state.set('status', action.data.status);
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
      return state;
    } else {
      state = state.set('isLoadingCustomerToEdit', false);
      //preserve tags
      let tags = {};
      if (action.data.data && action.data.data.tags) {
        tags = { ...action.data.data.tags };
      }
      let data = toCamelCaseAll(action.data.data);
      data['tags'] = tags;

      return state.set('editedCustomer', fromJS(data));
    }
  },

  //create customer or apply changes to customer
  editCreateCustomer: (state, action) => {
    let targetUser = state.get('targetUser', '');
    state = state.asMutable();

    //preserve tags
    let tags;
    if (action.data.data.tags) {
      tags = { ...action.data.data.tags };
    }
    let body = { ...action.data.data };
    body = toSnakeCaseAll(body);
    if (tags) {
      body['tags'] = tags;
    }
    delete body['preferences'];

    if (targetUser) {
      DSM.create(
        `/v1/customers/${targetUser}/`,
        {
          method: 'PATCH',
          body,
          caseConvert: false,
          extras: {
            target: targetUser,
          },
          message: 'editCreateCustomerResponse',
        },
        action.data.actions
      );

      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
      state = state.set('isLoadingCustomerToEdit', false);
    } else {
      DSM.create(
        '/v1/customers/',
        {
          method: 'POST',
          body,
          caseConvert: false,
          extras: {
            target: action.data.username,
          },
          message: 'editCreateCustomerResponse',
        },
        action.data.actions
      );

      state = state.set('error', '');
      state = state.set('errorData', null);
      state = state.set('status', '');
      state = state.set('isLoading', true);
      state = state.set('isLoadingCustomerToEdit', false);
    }

    return state.asImmutable();
  },

  //response to customer edit or creation
  editCreateCustomerResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }

    return state;
  },

  //update customer user prefs response
  editCustomerPrefsResponse: (state, action) => {
    state = state.set('status', action.data.status);
    state = state.set('isLoading', false);

    if (action.data.status !== 'ok') {
      state = state.set('error', action.data.code);
      state = state.set('errorData', extractErrorMessage(action.data.data));
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
