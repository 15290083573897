/** @format */

import React, { useEffect } from 'react';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';
import { useLocation } from 'react-router-dom';
import { onCLS, onFID, onLCP } from 'web-vitals/attribution';

const PlatformHealthRecorder = ({}) => {
  const location = useLocation();
  useEffect(() => {
    // if location has changed then we record web vitals
    onCLS(Telemetry.recordWebVitals);
    onFID(Telemetry.recordWebVitals);
    onLCP(Telemetry.recordWebVitals);
    // also record the page view
    //Telemetry.pageView(location.pathname);
  }, [location.pathname]);
  return <></>;
};

export default PlatformHealthRecorder;
