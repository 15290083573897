/** @format */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { actions } from '../../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventSearchResultDisplay from '../events/EventSearchResultDisplay';
import CompetitionSearchResultDisplay from '../competitions/CompetitionSearchResultDisplay';
import Fuse from 'fuse.js';
import SportResultDisplay from '../sports/SportResultDisplay';
import {
  EventResultsContainer,
  SearchResultsContainerElement,
  HeaderElement,
  NoResultsElement,
  CompetitionsResultsContainer,
  SearchSportResultsDisplayContainer,
  SearchResultsDisplay,
  HeaderContainer,
  NarrowViewSearchBoxContainer,
} from './elements';
import { SCREEN_BREAK } from '../elements';
import config from '../../../config';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '../../../lib/usePrevious';
import { getBaseSport } from '@mollybet/frontend-common/dist/lib/trade';
import SearchBox from '../search-box/SearchBox';
import { FormattedMessage } from 'react-intl';
import ComponentErrorBoundary from '../../error-boundary/ComponentErrorBoundary';
import { SettingsContext } from '../../shared/SettingsContext';
import { sizes } from '../../../themes';

const SearchResultsContainer = ({
  searchBarFocusState,
  markets,
  events,
  competitions,
  searchBarValue,
  sport,
  autoSearchBind,
  actions,
  betbarExpanded,
  betbarHeight,
}) => {
  const eventsFuse = useRef();
  const competitionsFuse = useRef();
  const [eventResults, setEventResults] = useState([]);
  const [competitionResults, setCompetitionResults] = useState([]);
  const [sportsWithResults, setSportsWithResults] = useState(new Set([sport]));
  const [currentFilterSport, setCurrentFilterSport] = useState(sport);
  const {
    focusSearchBar,
    unfocusSearchBar,
    setSearchBarValue,
    eventRemoveFav,
    eventAddFav,
  } = actions;
  const { interfaceHeight, contentLayout } = useContext(SettingsContext);

  useEffect(() => {
    setCurrentFilterSport(sport);
  }, [sport]);

  const windowWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // when screen is full width if search only finds competitions - we suggest events from these competitions and vice versa
  // when not full width we hide these suggestions as the display is a column and the suggestions may hide the relevant info
  const hideSuggestions = windowWidth < SCREEN_BREAK;

  // close if click link and move to diff page
  let location = useLocation();
  const prevLocation = usePrevious(location.pathname);

  // close modal on route change - i.e. when click link
  if (location.pathname !== prevLocation) {
    unfocusSearchBar();
    setSearchBarValue('');
  }

  const getSuggestedCompetitions = useCallback(
    (filterSport) => {
      const suggestedComps = [];
      // const compResultSports = new Set();
      competitions.forEach((competition, competitionId) => {
        if (
          competition.get('isSuggested')?.size &&
          getBaseSport(competition.get('sport')) === filterSport
        ) {
          suggestedComps.push({
            competitionId,
            name: competition.get('name'),
            sport: competition.get('sport', ''),
            country: competition.get('country'),
          });
        }
      });
      return suggestedComps;
    },
    [competitions]
  );

  // this should be a callback
  const getCompetitionsFromEvents = useCallback(
    (matchingEvents) => {
      if (hideSuggestions || !matchingEvents.length) return [];
      const competitionIds = new Set();
      for (let event of matchingEvents) {
        competitionIds.add(event.competitionId);
      }
      const results = [];
      for (let competitionId of competitionIds.values()) {
        const competition = competitions.get(competitionId);
        results.push({
          competitionId,
          name: competition.get('name'),
          sport: competition.get('sport', ''),
          country: competition.get('country'),
        });
      }
      return results;
    },
    [hideSuggestions, competitions]
  );

  // this should be a callback
  const getEventsFromCompetitions = useCallback(
    (matchingCompetitions) => {
      if (hideSuggestions || !matchingCompetitions.length) return [];
      const competitionId = matchingCompetitions[0].competitionId;
      const results = [];
      events.forEach((event, eventId) => {
        //skip Test events
        if (event.get('competitionId') === competitionId) {
          results.push({
            eventId,
            away: event.get('away'),
            awayEn: event.get('awayEn'),
            home: event.get('home'),
            homeEn: event.get('homeEn'),
            marketId: event.get('marketId'),
            sport: event.get('sport'),
            competitionId: event.get('competitionId'),
            startTime: new Date(event.get('startTime', '')),
            matchName: `${event.get('home')} v ${event.get('away')}`,
            matchNameEng: `${event.get('homeEn')} vs. ${event.get('awayEn')}`,
            matchNameReverse: `${event.get('away')} vs. ${event.get('home')}`,
          });
        }
      });
      // since these are only suggestions we can sort by start time
      results.sort((a, b) => a.startTime - b.startTime);
      return results;
    },
    [hideSuggestions, events]
  );

  // use on mount to set hotkeys for ctrl-f and esc
  useEffect(() => {
    const catchCtrlF = (e) => {
      if (e.ctrlKey && e.keyCode === 70 && autoSearchBind) {
        focusSearchBar();
        e.preventDefault();
      }
    };
    const catchEsc = (e) => {
      if (e.keyCode === 27) {
        unfocusSearchBar();
        setSearchBarValue('');
        e.preventDefault();
      }
    };
    if (autoSearchBind) {
      window.addEventListener('keydown', catchCtrlF);
    }
    window.addEventListener('keydown', catchEsc);
    return () => {
      window.removeEventListener('keydown', catchCtrlF);
      window.removeEventListener('keydown', catchEsc);
    };
  }, [autoSearchBind, unfocusSearchBar, setSearchBarValue, focusSearchBar]);

  // build event fuse
  useEffect(() => {
    // only build fuse once per mount
    if (events?.size && searchBarFocusState === 'focused' && !eventsFuse?.current) {
      let eventsSearchSet = [];
      events.forEach((event, eventId) => {
        //skip Test events
        if (event.get('home', '')?.indexOf('Test: ') === 0) {
          return;
        }
        eventsSearchSet.push({
          eventId,
          away: event.get('away'),
          awayEn: event.get('awayEn'),
          home: event.get('home'),
          homeEn: event.get('homeEn'),
          marketId: event.get('marketId'),
          sport: event.get('sport'),
          competitionId: event.get('competitionId'),
          startTime: new Date(event.get('startTime', '')),
          matchName: event.get('eventName') || `${event.get('home')} v ${event.get('away')}`,
          matchNameEng:
            event.get('eventName') || `${event.get('homeEn')} vs. ${event.get('awayEn')}`,
          matchNameReverse:
            event.get('eventName') || `${event.get('away')} vs. ${event.get('home')}`,
        });
      });
      // location - where in the text the pattern is expected to be found
      // threshold - how strict a matching alg do we want
      // distance - how important is it that matches are in the right location (default is 100 so not an issue for us)
      eventsFuse.current = new Fuse(eventsSearchSet, {
        caseSensitive: false,
        isCaseSensitive: false,
        threshold: 0.35,
        minMatchCharLength: 3,
        ignoreLocation: true,
        keys: [
          'home',
          'homeEn',
          'away',
          'awayEn',
          'name',
          'matchName',
          'matchNameEng',
          'matchNameReverse',
        ],
      });
    }
    return () => {
      events.current = null;
    };
  }, [events, searchBarFocusState]);

  // built competitions fuse
  useEffect(() => {
    // only build fuse once per mount
    if (
      markets?.size &&
      competitions?.size &&
      searchBarFocusState === 'focused' &&
      !competitionsFuse.current
    ) {
      //this is kinda very expensive
      let competitionsSearchSet = [];
      if (markets) {
        competitions.forEach((competition, competitionId) => {
          competitionsSearchSet.push({
            competitionId,
            name: competition.get('name'),
            sport: competition.get('sport', ''),
            country: competition.get('country'),
          });
        });
      }

      // location - where in the text the pattern is expected to be found
      // threshold - how strict a matching alg do we want
      // distance - how important is it that matches are in the right location (default is 100 so not an issue for us)
      competitionsFuse.current = new Fuse(competitionsSearchSet, {
        shouldSort: true,
        caseSensitive: false,
        threshold: 0.2,
        minMatchCharLength: 3,
        keys: ['name', 'country'],
      });
    }
    return () => {
      competitionsFuse.current = null;
    };
  }, [markets, searchBarFocusState, competitions]);

  useEffect(() => {
    // this is what calculates the results from the user search value
    if (
      competitionsFuse?.current &&
      eventsFuse?.current &&
      searchBarValue &&
      searchBarValue.length >= 3
    ) {
      const matchingEvents = eventsFuse.current.search(searchBarValue);
      const matchingCompetitions = competitionsFuse.current.search(searchBarValue);
      const sportsWithResults = new Set([currentFilterSport]);

      const filteredMatchingEvents = [];
      for (let event of matchingEvents) {
        if (event.sport) {
          sportsWithResults.add(getBaseSport(event.sport));
        }
        if (event.sport === currentFilterSport) {
          filteredMatchingEvents.push(event);
        }
      }
      const filteredMatchingCompetitions = [];
      for (let comp of matchingCompetitions) {
        if (comp.sport) {
          sportsWithResults.add(getBaseSport(comp.sport));
        }
        if (comp.sport === currentFilterSport) {
          filteredMatchingCompetitions.push(comp);
        }
      }
      setSportsWithResults(sportsWithResults);
      if (filteredMatchingEvents.length && filteredMatchingCompetitions.length) {
        // have matches for both so set as such
        setEventResults(filteredMatchingEvents);
        setCompetitionResults(filteredMatchingCompetitions);
      } else if (filteredMatchingEvents.length) {
        // have matches for events
        // show those events and their competitions
        setEventResults(filteredMatchingEvents);
        setCompetitionResults(getCompetitionsFromEvents(filteredMatchingEvents));
      } else if (filteredMatchingCompetitions.length) {
        // having matches for  competitions
        // show the competitions and the events from the top comp
        setEventResults(getEventsFromCompetitions(filteredMatchingCompetitions));
        setCompetitionResults(filteredMatchingCompetitions);
      } else {
        // dont have matches for either
        // show no results (confusing to show suggested results)
        setEventResults([]);
        setCompetitionResults([]);
      }
    } else {
      // no search input given
      // show suggested competitions and their events
      setSportsWithResults(new Set());
      const suggestedCompetitions = getSuggestedCompetitions(currentFilterSport);
      setCompetitionResults(suggestedCompetitions);
      setEventResults(getEventsFromCompetitions(suggestedCompetitions));
    }
  }, [
    searchBarValue,
    currentFilterSport,
    hideSuggestions,
    getSuggestedCompetitions,
    getCompetitionsFromEvents,
    getEventsFromCompetitions,
  ]);

  if (searchBarFocusState === 'unfocused') return null;

  const pageHasBetBar =
    location.pathname.includes('/trade') ||
    location.pathname.includes('/event') ||
    location.pathname.includes('/activePositions');

  let betBarSize = !pageHasBetBar
    ? 0
    : sizes.betbarClosedHeight + (betbarExpanded ? betbarHeight : 0);
  return (
    <SearchResultsContainerElement betBarSize={betBarSize}>
      <ComponentErrorBoundary>
        <NarrowViewSearchBoxContainer>
          <SearchBox />
        </NarrowViewSearchBoxContainer>
        <SearchSportResultsDisplayContainer>
          <ComponentErrorBoundary>
            {config.nav.marketSelector.displayOrder.map((sport) => {
              const sportConfig = config.nav.marketSelector.sports[sport];
              return (
                <SportResultDisplay
                  sportConfig={sportConfig}
                  hasResults={sportsWithResults.has(sportConfig.id)}
                  currentFilterSport={currentFilterSport}
                  setCurrentFilterSport={setCurrentFilterSport}
                  key={sportConfig?.id}
                />
              );
            })}
          </ComponentErrorBoundary>
        </SearchSportResultsDisplayContainer>
        <HeaderContainer>
          <HeaderElement>
            <FormattedMessage id="search.competitions" defaultMessage="Competitions" />
          </HeaderElement>
          <HeaderElement>
            <FormattedMessage id="search.events" defaultMessage="Events" />
          </HeaderElement>
        </HeaderContainer>
        <SearchResultsDisplay>
          <CompetitionsResultsContainer>
            <ComponentErrorBoundary>
              <HeaderElement>
                <FormattedMessage id="search.competitions" defaultMessage="Competitions" />
              </HeaderElement>
              {competitionResults.length ? (
                competitionResults.map((result) => (
                  <CompetitionSearchResultDisplay
                    sport={result.sport}
                    name={result.name}
                    competitionId={result.competitionId}
                    country={result.country}
                    watchingEarly={result.watchingEarly}
                    key={result?.competitionId}
                  />
                ))
              ) : (
                <NoResultsElement>
                  <FormattedMessage id="search.noResults" defaultMessage="No results found" />
                </NoResultsElement>
              )}
            </ComponentErrorBoundary>
          </CompetitionsResultsContainer>
          <EventResultsContainer>
            <HeaderElement>
              <FormattedMessage id="search.events" defaultMessage="Events" />
            </HeaderElement>
            <ComponentErrorBoundary>
              {eventResults.length ? (
                eventResults.map((result) => (
                  <EventSearchResultDisplay
                    key={`${result.eventId}+${result.marketId}`}
                    eventRemoveFav={eventRemoveFav}
                    eventAddFav={eventAddFav}
                    eventId={result.eventId}
                    sport={result.sport}
                  />
                ))
              ) : (
                <NoResultsElement>
                  <FormattedMessage id="search.noResults" defaultMessage="No results found" />
                </NoResultsElement>
              )}
            </ComponentErrorBoundary>
          </EventResultsContainer>
        </SearchResultsDisplay>
      </ComponentErrorBoundary>
    </SearchResultsContainerElement>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, _ownProps) => {
  const sport = getBaseSport(state.getIn(['trade', 'sport'], 'fb'));
  return {
    sport,
    searchBarFocusState: state.getIn(['ui', 'searchBarFocusState'], 'unfocused'),
    competitions: state.getIn(['trade', 'competitions'], null),
    events: state.getIn(['trade', 'events'], null),
    markets: state.getIn(['trade', 'markets'], null),
    searchBarValue: state.getIn(['ui', 'searchBarValue'], ''),
    autoSearchBind: state.getIn(['base', 'settings', 'general', 'autoSearchBind'], false),
    betbarExpanded: state.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false),
    betbarHeight: state.getIn(
      ['ui', 'settings', 'trade', 'betbar', 'height'],
      config.betbar.minHeight
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsContainer);
