/**
 * This file combines the reducers...
 * you may or may not need/want to do this since each view will probably have its own reducer
 *
 * @format
 */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import { toStdDate } from '@mollybet/frontend-common/dist/lib/time';

import { fromJS } from 'immutable';

const dateToday = new Date();

let initialState = fromJS({
  data: {},
  page: 1,
  dateTo: toStdDate(dateToday),
  dateFrom: toStdDate(dateToday.setDate(dateToday.getDate() - 90)),
  searchTerm: '',
  isLoading: true,
  isDownloading: false,
  isDownloadFailed: false,
  transactionDetails: null,
  isLoadingTransactionDetails: true,
});

const functions = {
  //make request to get the stream movements
  getStreamMovements: (state, action) => {
    let search = state.get('searchTerm', null);
    let body = {
      dateFrom: state.get('dateFrom', null),
      dateTo: state.get('dateTo', null),
      search: search ? encodeURIComponent(search) : null,
      includeBetDetails: true,
    };

    let headers = null;
    if (action.data.format) {
      headers = {};
      if (action.data.format === 'csv') {
        headers['Accept'] = 'text/csv';
      } else if (action.data.format === 'xls') {
        headers['Accept'] = 'application/vnd.ms-excel';
      }
      body['layout'] = 'list';
      body['pageSize'] = 65535;
    } else {
      body['page'] = state.get('page', 1);
    }

    DSM.last(
      `/v1/customers/${action.data.username}/balances/${action.data.stream}/`,
      {
        method: 'GET',
        message: 'loadStreamMovements',
        headers,
        downloadAs: action.data.format ? `accounting-stream.${action.data.format}` : '',
        body,
        extras: {
          format: action.data.format,
        },
      },
      action.data.actions,
      'getStreamMovements'
    );

    //reset stream data, otherwise the user gets a screen full of lies
    if (!action.data.format) {
      state = state.remove('data');
      state = state.set('isLoading', true);
    } else {
      state = state.set('isDownloading', action.data.format);
    }

    return state;
  },

  //handle stream movement data
  loadStreamMovements: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }

      state = state.set('isLoading', false);
      state = state.set('data', fromJS(action.data.data));
      return state;
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }

      //handled by base
      return state;
    }
  },

  //update page parameter
  setStreamPage: (state, action) => {
    return state.set('page', action.data.page);
  },

  //update date to parameter
  setStreamDateTo: (state, action) => {
    state = state.set('page', 1);
    return state.set('dateTo', action.data.date);
  },

  //update date from parameter
  setStreamDateFrom: (state, action) => {
    state = state.set('page', 1);
    return state.set('dateFrom', action.data.date);
  },

  //update search term parameter
  setStreamSearchTerm: (state, action) => {
    state = state.set('page', 1);
    return state.set('searchTerm', action.data.search || null);
  },

  //get details of a certain transaction
  getBetBalanceDetails: (state, action) => {
    if (action.data.betId) {
      DSM.last(
        `/v1/bet_accounting/${action.data.betId}/`,
        {
          method: 'GET',
          message: 'loadBetBalanceDetails',
        },
        action.data.actions,
        'getBetBalanceDetails'
      );

      return state.set('isLoadingTransactionDetails', true);
    } else {
      return state;
    }
  },

  //handle transaction detail data
  loadBetBalanceDetails: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('transactionDetails', fromJS(action.data.data));
    }

    return state.set('isLoadingTransactionDetails', false);
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};

for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
