/** @format */

import styled from 'styled-components';
import config from '../../../config';
import {
  SCREEN_BREAK,
  COMPETITIONS_RESULTS_MIN_DISPLAY_WIDTH,
  EVENTS_RESULTS_MIN_DISPLAY_WIDTH,
} from '../elements';

export const HeaderElement = styled.div`
  color: #ddd;
  font-size: 18px;
  padding: 18px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  box-sizing: border-box;
  background: #494949;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  ${HeaderElement}:first-child {
    width: 35%;
    min-width: 450px;
    box-sizing: border-box;
    border-right: solid 1px black;
  }
  ${HeaderElement}:nth-child(2) {
    box-sizing: border-box;
    width: 65%;
  }

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    display: none;
  }
`;

export const EventResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  width: 65%;
  min-width: ${() => `${EVENTS_RESULTS_MIN_DISPLAY_WIDTH}px`};
  background: #1e1e1e;

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    min-width: 100%;
    height: auto;
    overflow-y: visible;
  }

  ${HeaderElement} {
    display: none;
    @media only screen and (max-width: ${SCREEN_BREAK}px) {
      display: block;
    }
  }
`;

export const CompetitionsResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-grow: 1;
  background: #060606;
  border-right: solid 1px black;
  height: 100%;
  overflow-y: auto;
  width: 35%;
  min-width: ${() => `${COMPETITIONS_RESULTS_MIN_DISPLAY_WIDTH}px`};

  ${HeaderElement} {
    display: none;
  }

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    min-width: 100%;
    border-right: none;
    height: auto;
    overflow-y: visible;

    ${HeaderElement} {
      display: block;
    }
  }
`;

export const SearchSportResultsDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px 10px 10px;
  background: #1d1c1c;
`;

export const NoResultsElement = styled.div`
  color: #ddd;
  font-size: 15px;
  padding: 20px;
  cursor: pointer;
  font-weight: 700;
`;

export const SearchResultsContainerElement = styled.div`
  position: fixed;
  top: ${config.nav.styles.wide.height};
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  background: #060606;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${({ betBarSize }) => `calc(100% - ${config.nav.styles.wide.height} - ${betBarSize}px)`};
  user-select: none;
  transition: height 300ms;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    top: ${config.nav.styles.narrow.height};
    height: ${({ betBarSize }) =>
      `calc(100% - ${config.nav.styles.narrow.height} - ${betBarSize}px)`};
  }

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    overflow-y: auto;
  }
`;

export const SearchResultsDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  @media only screen and (max-width: ${SCREEN_BREAK}px) {
    height: auto;
    flex-direction: column;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 1em;
  }

  *::-webkit-scrollbar-corner {
    background-color: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.15em solid transparent;
    background-clip: content-box;
    border-radius: 5px;
    background-color: #ffffff;
  }

  *::-webkit-scrollbar-track {
    background-color: #060606;
    border: none;
  }
`;

export const NarrowViewSearchBoxContainer = styled.div`
  display: none;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    display: flex;
    flex-direction: center;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
  }
`;
