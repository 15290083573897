/** @format */
import React from 'react';
import * as Sentry from '@sentry/react';
import config from '../../config';

class ComponentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, _info) {
    let errStr = error ? error.toString() : '?';
    //if it is a JS chunk loading error it means that we've probably deployed
    if (
      errStr.indexOf('Loading chunk') !== -1 ||
      errStr.indexOf('Loading CSS') !== -1 ||
      errStr.indexOf('Unexpected token') !== -1 ||
      errStr.indexOf('expected expression') !== -1 ||
      errStr.indexOf('ChunkLoadError') !== -1
    ) {
      setTimeout(function () {
        if (window) {
          window.location.reload();
        }
      }, config.timings.reloadAferChunkFailure);
      this.setState({ error: null });
    } else {
      Sentry.captureException(error);
      if (config.support.tools.aggressiveErrorMessage) {
        this.setState({ error: errStr });
      }
    }
  }

  render() {
    if (this.state.hasError) {
      // default val of customErrorDisplay is null
      return this.props.customErrorDisplay !== undefined ? this.props.customErrorDisplay : null;
    }

    return this.props.children;
  }
}

export default ComponentErrorBoundary;
