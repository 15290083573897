/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';

import { fromJS } from 'immutable';
let initialState = fromJS({
  isLoading: true,
  creditLimits: null,
});

const functions = {
  //get customer credit limit history
  agentGetCustomerCreditLimit: (state, action) => {
    DSM.last(
      `/v1/customers/${action.data.username}/credit_limit_history/`,
      {
        method: 'GET',
        message: 'agentGetCustomerCreditLimitResponse',
        body: {},
      },
      action.data.actions,
      'agentGetCustomerCreditLimit'
    );

    return state.set('isLoading', true);
  },

  //handle customer credit limit response
  agentGetCustomerCreditLimitResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('creditLimits', fromJS(action.data.data));
      return state.set('isLoading', false);
    } else {
      //error handled by base
      return state;
    }
  },

  //update customer credit limit
  setCreditLimitResponse: (state, action) => {
    if (action.data.status === 'ok' && action.data.extras.targetUser) {
      DSM.last(
        `/v1/customers/${action.data.extras.targetUser}/credit_limit_history/`,
        {
          method: 'GET',
          message: 'agentGetCustomerCreditLimitResponse',
          body: {},
        },
        action.data.actions,
        'setCreditLimitResponse'
      );

      return state.set('isLoading', true);
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
