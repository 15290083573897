/** @format */

import styled, { type StyledComponent } from 'styled-components';
import { type Theme } from '@mollybet/ui/dist/theme';
import { Button, NegativeButton, PositiveButton } from './Button';

import { Modal } from '@mollybet/ui';

export const Dialog = styled(Modal)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.black};
`;

export const DialogTitle: StyledComponent<{ ... }, Theme, *> = styled.h1`
  grid-area: title;

  text-align: left;
  font-size: 1.25rem;
  padding: 0 1.5rem;
  margin: 1.5rem 0 0;
  display: block;
  width: 100%;
  box-sizing: border-box;

  svg {
    margin-right: 20px;
    vertical-align: middle;
  }
`;

export const DialogContent: StyledComponent<{ ... }, Theme, *> = styled.div`
  grid-area: body;

  display: block;
  padding: 0 1.5rem;
  margin: 1.5rem 0;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
`;

export const DialogActions: StyledComponent<{ ... }, Theme, *> = styled.div`
  grid-area: actions;

  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  ${Button},
  ${NegativeButton},
  ${PositiveButton} {
    padding: 10px 20px;
    display: flex;
    flex-grow: 1;
    text-transform: uppercase;
    border-radius: 0;
    text-align: center;

    span {
      width: 100%;
      display: block;
    }
  }
`;
