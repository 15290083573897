/** @format */

import config from '../../config';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';

import { Button, PositiveButton } from '../../components/interface';
import { DemoWrapper } from '../demo';
import { IconButton } from '@mollybet/ui';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../../reducers/app';

const LogOutButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
  font-size: 16px;

  ${Button},
  ${PositiveButton} {
    margin-right: 6px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    padding: 0px 4px;
    line-height: 2em;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: solid 1px ${(props) => props.theme.separator};
`;

const CreditDetail = styled.div`
  padding: 4px 13px 4px 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const CreditNumber = styled.span`
  color: #4b4b4b;
  font-weight: bold;
`;

const SmartNumber = styled.span`
  color: #246eb9;
  font-weight: bold;
`

const CreditName = styled.span`
  color: #4b4b4b;
  display: flex;
  align-items: center;
`;

const CreditLink = styled(Link)`
  color: #4b4b4b;

  &:hover {
    text-decoration: underline;
  }
`

const SmartCreditInfoButton = styled(IconButton)`
  padding: 2px;
  margin: 0 2px;
`;

const CreditInformation = ({ props, showSmartCredit, createDeposit }) => {
  const popUpAnnouncement = () => {
    props.actions.popUpSmartCreditAnnouncement();
  }

  return (
    <div>
      {props.hideCredit ? (
        <CreditDetail>
          <CreditName
            title={props.intl.formatMessage({
              id: 'nav.menu.money.creditTooltip',
              defaultMessage:
                'Amount of money currently available for betting (balance + credit limit)',
            })}
          >
            <FormattedMessage id="menu.money.credit" defaultMessage="Credit" />
          </CreditName>
          <CreditNumber
            title={
              props.creditLimit !== null
                ? formatAmount(
                    props.credit,
                    props.ccyCode,
                    props.displayCcy,
                    props.xrates,
                    props.credit > 1000000
                  )
                : props.totalBookieBalance
                ? formatAmount(
                    props.totalBookieBalance,
                    props.ccyCode,
                    props.displayCcy,
                    props.xrates,
                    props.totalBookieBalance > 1000000
                  )
                : 'unlimited'
            }
          >
            {props.creditLimit !== null ? (
              formatAmount(
                props.credit,
                props.ccyCode,
                props.displayCcy,
                props.xrates,
                props.credit > 1000000
              )
            ) : props.totalBookieBalance ? (
              formatAmount(
                props.totalBookieBalance,
                props.ccyCode,
                props.displayCcy,
                props.xrates,
                props.totalBookieBalance > 1000000
              )
            ) : (
              <FormattedMessage id="menu.money.creditUnlimited" defaultMessage="Unlimited" />
            )}
          </CreditNumber>
        </CreditDetail>
      ) : null}
      {props.creditLimit !== 0 ? (
        <CreditDetail>
          <CreditName
            title={props.intl.formatMessage({
              id: 'nav.menu.money.creditLimitTooltip',
              defaultMessage:
                'How much credit you are given to play with i.e. how much in the negative you can go',
            })}
          >
            <FormattedMessage id="menu.money.creditLimit" defaultMessage="Credit Limit" />
          </CreditName>
          <CreditNumber title={formatAmount(props.creditLimit, props.ccyCode)}>
            {props.creditLimit !== null ? (
              formatAmount(
                props.creditLimit,
                props.ccyCode,
                props.displayCcy,
                props.xrates,
                props.creditLimit > 1000000
              )
            ) : (
              <FormattedMessage id="menu.money.creditLimitUnlimited" defaultMessage="Unlimited" />
            )}
          </CreditNumber>
        </CreditDetail>
      ) : null}
      <CreditDetail>
        <CreditName
          title={props.intl.formatMessage({
            id: 'nav.menu.money.balanceTooltip',
            defaultMessage:
              'Sum of all transactions, settled bets and transfers. For full breakdown go to history - accounting - balances, and click on the balance',
          })}
        >
          <FormattedMessage id="menu.money.balance" defaultMessage="Balance" />
        </CreditName>
        <DemoWrapper>
          <CreditNumber title={formatAmount(props.balance, props.ccyCode)}>
            <CreditLink to="/history/accounting/balances">
              {formatAmount(
                props.balance,
                props.ccyCode,
                props.displayCcy,
                props.xrates,
                props.balance > 1000000
              )}
            </CreditLink>
          </CreditNumber>
        </DemoWrapper>
      </CreditDetail>
     
      {showSmartCredit ? (
        <CreditDetail>
          <CreditName>
            <FormattedMessage id="menu.money.smartCredit" defaultMessage="Smart Credit" />
            <SmartCreditInfoButton onClick={popUpAnnouncement}>
              <Icon size={1} path={mdiInformationOutline} color="#000"/>
            </SmartCreditInfoButton>
          </CreditName>
          <SmartNumber title={formatAmount(props.smartCredit, props.ccyCode)}>
            + {formatAmount(
              props.smartCredit,
              props.ccyCode,
              props.displayCcy,
              props.xrates,
              props.smartCredit > 1000000
            )}
          </SmartNumber>
        </CreditDetail>
      ) : null}
       <CreditDetail>
        <CreditName
          title={props.intl.formatMessage({
            id: 'nav.menu.money.openBetsTooltip',
            defaultMessage: 'Sum of all unsettled bets',
          })}
        >
          <FormattedMessage id="menu.money.openStakes" defaultMessage="Open Stakes" />
        </CreditName>
        <DemoWrapper>
          <CreditNumber title={formatAmount(props.openStakes, props.ccyCode)}>
            <CreditLink to="/history/accounting/stake">
              - {formatAmount(
                props.openStakes,
                props.ccyCode,
                props.displayCcy,
                props.xrates,
                props.openStakes > 1000000
              )}
            </CreditLink>
          </CreditNumber>
        </DemoWrapper>
      </CreditDetail>
      <Divider/>
      <CreditDetail>
        <CreditName
          title={props.intl.formatMessage({
            id: 'nav.menu.money.todayPLTooltip',
            defaultMessage:
              'Today PL is calculated as the difference between the current balance and the balance today at midnight, London time, excluding transfers',
          })}
        >
          <FormattedMessage id="menu.money.todayPL" defaultMessage="Today P/L" />
        </CreditName>
        <CreditNumber title={formatAmount(props.todayPl, props.ccyCode)}>
          {formatAmount(
            props.todayPl,
            props.ccyCode,
            props.displayCcy,
            props.xrates,
            props.todayPl > 1000000
          )}
        </CreditNumber>
      </CreditDetail>
      <CreditDetail>
        <CreditName
          title={props.intl.formatMessage({
            id: 'nav.menu.money.yesterdayPLTooltip',
            defaultMessage:
              'Yesterday PL is calculated as the difference between the balance today at midnight, London time, and yesterday midnight, also London time, excluding transfers',
          })}
        >
          <FormattedMessage id="menu.money.yesterdayPL" defaultMessage="Yest. P/L" />
        </CreditName>
        <CreditNumber title={formatAmount(props.yesterdayPl, props.ccyCode)}>
          {formatAmount(
            props.yesterdayPl,
            props.ccyCode,
            props.displayCcy,
            props.xrates,
            props.yesterdayPl > 1000000
          )}
        </CreditNumber>
      </CreditDetail>
      {props.whitelabelUser && (
        <LogOutButtonContainer>
          <PositiveButton onClick={createDeposit}>
            <FormattedMessage id="menu.money.deposit" defaultMessage="Deposit" />
          </PositiveButton>
        </LogOutButtonContainer>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapDispatchToProps)(CreditInformation);