/** @format */

import DataStream from '@mollybet/frontend-common/dist/lib/DataStream';

export const isSandbox = (): boolean => window.location.host.includes('shared-sandbox.');
export const isSkaffold = (): boolean => window.location.host.includes('.skaffold');

export const getToolsUrl = (
  protocol: string = 'https',
  defaultValue: string = 'tools.devmolly.com'
): string => {
  if (isSandbox()) return `${protocol}://tools.shared-sandbox.devmolly.com`;
  if (isSkaffold()) {
    return 'http://tools.skaffold';
    // const hostRedirect = DataStream.getHostRedirect();
    // const pathRedirect = hostRedirect && hostRedirect.split('://')[1];
    // return `${protocol}://${pathRedirect || 'tools.skaffold'}`;
  }
  return `${protocol}://${defaultValue}`;
};
