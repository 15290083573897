/** @format */

import React, { useContext, useCallback } from 'react';
import { FavouriteContainer, FlexContainerElement } from '../elements';
import {
  EventButton,
  EventStatusElement,
  EventActions,
  EventTime,
  EventName,
  EventSearchResultDisplayElement,
} from './elements';
import { Icon } from '../../interface';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { actions } from '../../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parseEventStageForTradePage } from '@mollybet/frontend-common/dist/lib/formatters';
import { formatEventTime } from '@mollybet/frontend-common/dist/lib/formatters';
import { SettingsContext } from '../../shared/SettingsContext';
import { getBaseSport } from '@mollybet/frontend-common/dist/lib/trade';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import ComponentErrorBoundary from '../../error-boundary/ComponentErrorBoundary';

const EventSearchResultDisplay = ({
  name,
  competitionId,
  eventId,
  sport,
  startTime,
  marketId,
  eventAddFav,
  eventRemoveFav,
  isInFavs,
  irstatus,
  baseSport,
  watched,
  actions,
  marketIsExpanded,
  sideBarIsOpen,
  favsIsExpanded,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { timezone, pricesBookies, pricesSync, contentLayout } = useContext(SettingsContext);
  const {
    competitionSubscribe,
    unfocusSearchBar,
    setSearchBarValue,
    marketMainToggle,
    toggleTradeSide,
  } = actions;

  const scrollToItem = (marketId, competitionId, eventId) => {
    const scrollTarget = `${marketId}_${competitionId}_${eventId}`;
    if (document) {
      const eventDisplayLine = document.getElementsByClassName(scrollTarget)[0];
      const tradePage = document.getElementsByClassName('view-contents')[0];
      if (eventDisplayLine && tradePage) {
        const background = eventDisplayLine.style.background;
        eventDisplayLine.style.background = theme.positive;
        setTimeout(() => {
          eventDisplayLine.style.background = background;
        }, 1500);
        tradePage.scrollTop = eventDisplayLine.offsetTop - 40;
      }
    }
  };

  const addFav = (e) => {
    e.stopPropagation();
    eventAddFav({
      eventId,
      competitionId,
      sport,
    });
  };

  const removeFav = (e) => {
    e.stopPropagation();
    eventRemoveFav({
      eventId,
    });
  };

  // const toggleSubscribe = (marketId, isWatching) => (e) => {
  //   e.stopPropagation();
  //   if (!isWatching) {
  //     competitionSubscribe({
  //       competitionId,
  //       marketId,
  //       sport,
  //       pricesSync,
  //       actions,
  //       bookies: pricesBookies ? pricesBookies.toJS() : [],
  //     });
  //   }
  // };

  const navigateToEvent = (e) => {
    e.stopPropagation();
    history.push(`/event/${baseSport}/${competitionId}/${eventId}`);
    if (sideBarIsOpen && contentLayout === 'narrow') {
      toggleTradeSide();
    }
    unfocusSearchBar();
    setSearchBarValue('');
  };

  const navigate = (e) => {
    e.stopPropagation();
    addFav(e);
    if (!history.location.pathname.includes(`/trade/${baseSport}`)) {
      history.push(`/trade/${baseSport}`);
    }
    if (sideBarIsOpen && contentLayout === 'narrow') {
      toggleTradeSide();
    }
    unfocusSearchBar();
    setSearchBarValue('');
    if (!favsIsExpanded) {
      marketMainToggle({
        marketId: 'favs',
      });
    }
    setTimeout(() => {
      scrollToItem(marketId, competitionId, eventId);
    }, 200);
  };

  let evTime = formatEventTime(eventId, startTime, irstatus, baseSport, marketId, timezone);
  let eventStage = irstatus ? irstatus.get('eventStage', null) : null;
  const periodTime = irstatus ? irstatus.get('periodTime', undefined) : undefined;
  if (eventStage) {
    eventStage = parseEventStageForTradePage(eventStage, evTime, periodTime);
  }
  let parsedEventTime = eventStage !== null ? eventStage : evTime;

  return (
    <ComponentErrorBoundary>
      <EventSearchResultDisplayElement onClick={navigate}>
        <FlexContainerElement>
          <EventStatusElement marketId={marketId} />
          <EventTime>{parsedEventTime}</EventTime>
          <EventName>{name}</EventName>
        </FlexContainerElement>
        <FlexContainerElement>
          <EventActions>
            <EventButton onClick={navigateToEvent}>
              <FormattedMessage id="search.allPrices" defaultMessage="All Prices" />
            </EventButton>
            <FavouriteContainer>
              <Icon
                width={22}
                height={22}
                button="true"
                onClick={isInFavs ? removeFav : addFav}
                className="expand-icon"
                color={isInFavs ? '#DAA520' : '#595959'}
                path={isInFavs ? mdiStar : mdiStarOutline}
              />
            </FavouriteContainer>
          </EventActions>
        </FlexContainerElement>
      </EventSearchResultDisplayElement>
    </ComponentErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, ownProps) => {
  const baseSport = getBaseSport(ownProps.sport);
  const marketId = state.getIn(['trade', 'events', ownProps.eventId, 'marketId'], '');
  const competitionId = state.getIn(['trade', 'events', ownProps.eventId, 'competitionId'], false);
  const name = state.getIn(['trade', 'events', ownProps.eventId, 'eventName'], false);
  const home = state.getIn(['trade', 'events', ownProps.eventId, 'home'], false);
  const away = state.getIn(['trade', 'events', ownProps.eventId, 'away'], false);
  return {
    isInFavs: state.getIn(['trade', 'events', ownProps.eventId, 'isInFavs'], false),
    home,
    away,
    name: name || `${home || '-'} vs ${away || '-'}`,
    competitionId,
    sport: state.getIn(['trade', 'events', ownProps.eventId, 'sport'], false),
    startTime: state.getIn(['trade', 'events', ownProps.eventId, 'startTime'], false),
    marketId,
    irstatus: state.getIn(['trade', 'events', ownProps.eventId, 'irstatus', baseSport], null),
    baseSport,
    watched: state.getIn(['trade', 'competitions', competitionId, 'isSubscribed', marketId], false),
    marketIsExpanded: state.getIn(
      ['ui', 'settings', 'trade', 'expanded', 'main', 'markets', marketId],
      false
    ),
    favsIsExpanded: state.getIn(
      ['ui', 'settings', 'trade', 'expanded', 'main', 'markets', 'favs'],
      false
    ),
    sideBarIsOpen: state.getIn(['ui', 'settings', 'trade', 'side', 'open'], true),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSearchResultDisplay);
