/** @format */

import config from '../../config';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

import React, { useEffect, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Button, PositiveButton } from '../../components/interface';
import { DemoWrapper } from '../../components/demo';
import ComponentErrorBoundary from '../error-boundary/ComponentErrorBoundary';
import { SettingsContext } from '../shared/SettingsContext';

import {
  mdiEmailOutline,
  mdiChatOutline,
  mdiCogs,
  mdiWalletOutline,
  mdiViewGridPlus,
  mdiCurrencyUsd,
  mdiArrowLeftRight,
  mdiAccountTie,
  mdiFaceAgent,
  mdiChartBar,
  mdiTable,
  mdiChartLine,
} from '@mdi/js';
import { isSkaffold } from '../../lib/getToolsUrl';
import DataStream from '@mollybet/frontend-common/dist/lib/DataStream';
import { lastWeek } from '@mollybet/frontend-common/dist/lib/time';

const UnsudoButtonElement = styled(Button)`
  background: #ffbf00;
`;

const OptionExternalLink = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  padding: 5px 10px 3px 10px;
  color: ${(props) => props.theme.paper.fontColor[4]};
  box-sizing: border-box;

  &:hover {
    background: ${(props) => props.theme.paper.background[4 - 2]};
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    padding: 0px 10px 0px 5px;
    line-height: 2.2em;
  }
`;

const OptionLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  padding: 5px 10px 3px 10px;
  color: ${(props) => props.theme.paper.fontColor[4]};
  box-sizing: border-box;

  &:hover {
    background: ${(props) => props.theme.paper.background[4 - 2]};
  }
`;

const Option = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  padding: 5px 10px 3px 10px;
  color: ${(props) => props.theme.paper.fontColor[4]};
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.paper.background[4 - 2]};
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    padding: 0px 10px 0px 5x;
    line-height: 2.2em;
  }
`;

const TermsAndConditionsLinkElement = styled(Link)`
  color: grey;
  font-size: 12px;
  text-decoration: underline;
  padding-left: 10px;
`;

const LogOutButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
  font-size: 16px;

  ${Button},
  ${PositiveButton} {
    margin-right: 6px;
    text-transform: uppercase;
    cursor: pointer;
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    padding: 0px 4px;
    line-height: 2em;
  }
`;

const Divider = styled.div`
  width: 100%;
  padding-top: 3px;
  height: 3px;
  border-bottom: solid 1px ${(props) => props.theme.separator};
`;

const MenuOptions = ({
  props,
  context,
  toggleFeedbackOverlay,
  toggleBetTypesOverlay,
  requestToken,
}) => {

  const { isAgentsMollybet } = useContext(SettingsContext);

  // for now want to hide this as beautifying the dropdown menu
  const _isSkaffold = false && isSkaffold();
  const _isSandboxRedirect = DataStream.getHostRedirect()?.includes('shared-sandbox');
  const _isProductionRedirect = DataStream.getHostRedirect()?.includes('mollybet');

  const toggleSandboxRedirect = () => {
    if (!_isSkaffold || _isSandboxRedirect) DataStream.setHostRedirect('');
    else DataStream.setHostRedirect('https://shared-sandbox.devmolly.com/');
  };
  const toggleProductionRedirect = () => {
    if (!_isSkaffold || _isProductionRedirect) DataStream.setHostRedirect('');
    else DataStream.setHostRedirect('https://mollybet.com/');
  };

  const unsudoHelper = () => {
    props.actions.unsudoCustomer({
      redirectUrl: '/admin/customers',
    });
  };

  useEffect(() => {
    if (Whitelabel?.externalUrls?.deposit && context?.featureDepositButton)
      try {
        requestToken(Whitelabel?.externalUrls?.deposit)();
      } catch (err) {
        console.warn('err requesting deposit page token: ', err);
      }
  }, [Whitelabel?.externalUrls?.deposit, context, context?.featureDepositButton, requestToken]);

  return (
    <div className="terms-and-logout">
      {!isAgentsMollybet && (context?.superuser || context?.limitedAdmin) ? (
        <DemoWrapper>
          <OptionLink to={'/admin/customers'}>
            <Icon
              color={props.theme.colours.black}
              path={mdiFaceAgent}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.admin" defaultMessage="Admin" />
          </OptionLink>
        </DemoWrapper>
      ) : null}
      {!isAgentsMollybet && (context?.agent || context?.superuser) ? (
        <DemoWrapper>
          <OptionLink to={'/agent/customers'}>
            <Icon
              color={props.theme.colours.black}
              path={mdiAccountTie}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.agent" defaultMessage="Agent" />
          </OptionLink>
        </DemoWrapper>
      ) : null}
      {!isAgentsMollybet && (context.superuser || context.limitedAdmin || context.agent) ? <Divider /> : null}
      {!isAgentsMollybet && 
      <>
        <DemoWrapper>
          <OptionLink
            to={`/history/bets#q=f:bookie_date_day:ge:${
              lastWeek()['dateFrom']
            }/f:bookie_date_day:le:${lastWeek()['dateTo']}`}
          >
            <Icon
              color={props.theme.colours.black}
              path={mdiTable}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.analytics" defaultMessage="Analytics" />
          </OptionLink>
        </DemoWrapper>
        <DemoWrapper>
          <OptionLink to="/activePositions">
            <Icon
              color={props.theme.colours.black}
              path={mdiChartLine}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.activePositions" defaultMessage="Active Positions" />
          </OptionLink>
        </DemoWrapper>
        <DemoWrapper>
          <OptionLink to="/dashboard">
            <Icon
              color={props.theme.colours.black}
              path={mdiChartBar}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.dashboard" defaultMessage="Dashboard" />
          </OptionLink>
        </DemoWrapper>
        </>}
      {!isAgentsMollybet && <Divider />}
      {!isAgentsMollybet && <ComponentErrorBoundary>
        {Whitelabel?.externalUrls &&
        Whitelabel?.externalUrls?.withdraw &&
        context.featureDepositButton ? (
          <DemoWrapper>
            <OptionExternalLink href={Whitelabel?.externalUrls?.withdraw} target="_blank">
              <Icon
                color={props.theme.colours.black}
                path={mdiCurrencyUsd}
                width={24}
                height={24}
                style={{ marginRight: '6px' }}
              />
              <FormattedMessage id="menu.money.withdraw" defaultMessage="Withdraw" />
            </OptionExternalLink>
          </DemoWrapper>
        ) : null}
      </ComponentErrorBoundary>}
      {!isAgentsMollybet && <>
      <DemoWrapper>
        <OptionLink to="/history/accounting/balances">
          <Icon
            color={props.theme.colours.black}
            path={mdiWalletOutline}
            width={24}
            height={24}
            style={{ marginRight: '6px' }}
          />
          <FormattedMessage id="menu.money.accounting" defaultMessage="Accounting" />
        </OptionLink>
      </DemoWrapper>
      <DemoWrapper>
        <OptionLink to="/settings">
          <Icon
            color={props.theme.colours.black}
            path={mdiCogs}
            width={24}
            height={24}
            style={{ marginRight: '6px' }}
          />
          <FormattedMessage id="menu.money.settings" defaultMessage="Settings" />
        </OptionLink>
      </DemoWrapper>
      </>
    }

      {!isAgentsMollybet && context.contentLayout === 'narrow' && (
        <DemoWrapper>
          <Option to="" onClick={toggleBetTypesOverlay}>
            <Icon
              color={props.theme.colours.black}
              path={mdiViewGridPlus}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            <FormattedMessage id="menu.money.tradeBetTypes" defaultMessage="Bet Types" />
          </Option>
        </DemoWrapper>
      )}
      <ComponentErrorBoundary>
        {!isAgentsMollybet && Whitelabel?.externalUrls && Whitelabel?.externalUrls?.['chat'] ? (
          <DemoWrapper>
            <OptionExternalLink href={Whitelabel?.externalUrls?.['chat']} target="_blank">
              <Icon
                color={props.theme.colours.black}
                path={mdiChatOutline}
                width={24}
                height={24}
                style={{ marginRight: '6px' }}
              />
              <FormattedMessage id="menu.money.chat" defaultMessage="Chat" />
            </OptionExternalLink>
          </DemoWrapper>
        ) : null}
      </ComponentErrorBoundary>
      {!isAgentsMollybet && <DemoWrapper>
        <Option to="" onClick={toggleFeedbackOverlay}>
          <Icon
            color={props.theme.colours.black}
            path={mdiEmailOutline}
            width={24}
            height={24}
            style={{ marginRight: '6px' }}
          />
          <FormattedMessage id="menu.money.contactUs" defaultMessage="Contact Us" />
        </Option>
      </DemoWrapper>
      }
      {_isSkaffold && (
        <>
          <Divider />
          <OptionLink to="#" onClick={toggleSandboxRedirect}>
            <Icon
              color={props.theme.colours.black}
              path={mdiArrowLeftRight}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            Switch to {_isSandboxRedirect ? 'development' : 'sandbox'} backend
          </OptionLink>
        </>
      )}
      {_isSkaffold && (
        <>
          <OptionLink to="#" onClick={toggleProductionRedirect}>
            <Icon
              color={props.theme.colours.black}
              path={mdiArrowLeftRight}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
            />
            Switch to {_isProductionRedirect ? 'development' : 'production'} backend
          </OptionLink>
        </>
      )}
      <LogOutButtonContainer>
        {!isAgentsMollybet && <DemoWrapper>
          <TermsAndConditionsLinkElement to="/terms">
            <FormattedMessage id="menu.money.terms" defaultMessage="Terms & Conditions" />
          </TermsAndConditionsLinkElement>
        </DemoWrapper>}
        {props.sudoer ? (
          <UnsudoButtonElement variant="primary" onClick={unsudoHelper}>
            <FormattedMessage id="menu.money.logOut" defaultMessage="Unsudo" />
          </UnsudoButtonElement>
        ) : (
          <DemoWrapper>
            <Button variant="primary" onClick={props.actions.logout}>
              <FormattedMessage id="menu.money.logOut" defaultMessage="Log Out" />
            </Button>
          </DemoWrapper>
        )}
      </LogOutButtonContainer>
    </div>
  );
};

export default MenuOptions;
