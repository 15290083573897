/** @format */

import React from 'react';
import loader from '../../media/loading-circle.svg';

export default () => (
  <div
    className="generic-loader"
    style={{
      justifyContent: 'center',
      margin: '12px auto',
      width: '100%',
      display: 'flex',
    }}
  >
    <img
      alt="loading"
      src={loader}
      style={{
        width: '30px',
        height: '30px',
      }}
    />
  </div>
);
